import { useTranslation } from '@hooks';

import { Container } from './styled';

const BackToTriggerTypeButton = ({ testId, disabled, ...props }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container $disabled={disabled} data-testid={`${testId}-back-to-all-triggers-btn`} {...props}>
      <svg width="20" style={{ marginRight: 4 }} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.25 10L12.5 3.75L13.375 4.625L8 10L13.375 15.375L12.5 16.25L6.25 10Z" fill={disabled ? '#7C94B1' : "#0C1015"}/>
      </svg>
      {p('back_to_trigger_type')}
    </Container>
  );
};

export default BackToTriggerTypeButton;
