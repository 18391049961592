import styled from 'styled-components';

export const ListWrapper = styled.ul`
    gap: 10px;
    display: flex;
    flex-direction: column;
`;

export const SubTitle = styled.p`
    display: flex;
    align-items: center;
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 14px;
`

export const IconContainer = styled.p`
    margin: 0%;
    height: 100%;
    span {
        height: 100%;
        &:hover {
            transition: all 0.3s ease ;
            color: #1A232E !important;
        }
    }
`