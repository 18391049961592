import React, { useImperativeHandle } from 'react';
import { useReactFlow, useViewport } from 'reactflow';

import { testId } from '@utils';

import { Slider } from '@components';

import { Container, ZoomButton } from './styled';

const ZoomSlider = ({ instance, preview, vertical }, ref) => {
  const { x, y, zoom } = useViewport();
  const reactFlow = useReactFlow();

  useImperativeHandle(ref, () => ({
    getPositionAndZoom: () => ({ x, y, zoom }),
    zoomTo: instance?.zoomTo,
    setTransform: instance?.setTransform
  }), [x, y, zoom, instance]);

  const handleZoomIn = () => {
    reactFlow?.zoomTo(zoom + 0.2);
  };

  const handleZoomOut = () => {
    reactFlow?.zoomTo(zoom - 0.2);
  };

  return (
    <Container vertical={vertical} preview={preview}>
      <ZoomButton vertical={vertical} preview={preview} {...testId('workflow-actionsbar-zoom-minus')()} onClick={handleZoomIn}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M10.125 6.75H7.875V4.5H6.75V6.75H4.5V7.875H6.75V10.125H7.875V7.875H10.125V6.75Z" fill="#1A232E"/>
          <path d="M12.0644 11.25C12.9925 10.148 13.501 8.75326 13.5 7.3125C13.5 6.08873 13.1371 4.89244 12.4572 3.87491C11.7773 2.85738 10.811 2.06432 9.68036 1.596C8.54974 1.12768 7.30564 1.00515 6.10538 1.24389C4.90512 1.48264 3.80262 2.07194 2.93728 2.93728C2.07194 3.80262 1.48264 4.90512 1.24389 6.10538C1.00515 7.30564 1.12768 8.54974 1.596 9.68036C2.06432 10.811 2.85738 11.7773 3.87491 12.4572C4.89244 13.1371 6.08873 13.5 7.3125 13.5C8.75326 13.501 10.148 12.9925 11.25 12.0644L15.5171 16.3125L16.3125 15.5171L12.0644 11.25ZM7.3125 12.375C6.31123 12.375 5.33245 12.0781 4.49993 11.5218C3.6674 10.9655 3.01853 10.1749 2.63536 9.24984C2.25219 8.32479 2.15194 7.30689 2.34728 6.32486C2.54262 5.34283 3.02477 4.44078 3.73277 3.73277C4.44078 3.02477 5.34283 2.54262 6.32486 2.34728C7.30689 2.15194 8.32479 2.25219 9.24984 2.63536C10.1749 3.01853 10.9655 3.6674 11.5218 4.49993C12.0781 5.33245 12.375 6.31123 12.375 7.3125C12.3735 8.6547 11.8397 9.94151 10.8906 10.8906C9.94151 11.8397 8.6547 12.3735 7.3125 12.375Z" fill="#1A232E"/>
        </svg>
      </ZoomButton>
      <Slider
        testId={'workflow-actionsbar-zoom-slider'}
        step={0.1}
        value={zoom}
        vertical={vertical}
        onChange={reactFlow?.zoomTo}
        min={0.1}
        max={2}
      />
      <ZoomButton vertical={vertical} preview={preview} {...testId('workflow-actionsbar-zoom-plus')()} onClick={handleZoomOut}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M10.125 6.75H4.5V7.875H10.125V6.75Z" fill="#1A232E"/>
          <path d="M12.0644 11.25C12.9925 10.148 13.501 8.75326 13.5 7.3125C13.5 6.08873 13.1371 4.89244 12.4572 3.87491C11.7773 2.85738 10.811 2.06432 9.68036 1.596C8.54974 1.12768 7.30564 1.00515 6.10538 1.24389C4.90512 1.48264 3.80262 2.07194 2.93728 2.93728C2.07194 3.80262 1.48264 4.90512 1.24389 6.10538C1.00515 7.30564 1.12768 8.54974 1.596 9.68036C2.06432 10.811 2.85738 11.7773 3.87491 12.4572C4.89244 13.1371 6.08873 13.5 7.3125 13.5C8.75326 13.501 10.148 12.9925 11.25 12.0644L15.5171 16.3125L16.3125 15.5171L12.0644 11.25ZM7.3125 12.375C6.31123 12.375 5.33245 12.0781 4.49993 11.5218C3.6674 10.9655 3.01853 10.1749 2.63536 9.24984C2.25219 8.32479 2.15194 7.30689 2.34728 6.32486C2.54262 5.34283 3.02477 4.44078 3.73277 3.73277C4.44078 3.02477 5.34283 2.54262 6.32486 2.34728C7.30689 2.15194 8.32479 2.25219 9.24984 2.63536C10.1749 3.01853 10.9655 3.6674 11.5218 4.49993C12.0781 5.33245 12.375 6.31123 12.375 7.3125C12.3735 8.6547 11.8397 9.94151 10.8906 10.8906C9.94151 11.8397 8.6547 12.3735 7.3125 12.375Z" fill="#1A232E"/>
        </svg>
      </ZoomButton>
    </Container>
  );
};

export default React.forwardRef(ZoomSlider);
