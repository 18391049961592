import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NoIntegrationDisclaimer = styled.div`
  background: #FFFAED;
  border: 1px solid #FFDC7B;
  height: 56px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OutlinedButton = styled.div`
  border: 1px solid #31C447;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #31C447;
  font-size: 14px;
  padding: 5px 0;
  margin-top: 20px;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
