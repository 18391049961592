import styled from 'styled-components';

export const Title = styled.div`
  color: var(--text-primary-body, #1a232e);
  text-align: center;

  /* Heading 3 */
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  text-align: center;
  margin-bottom: 50px;
`;

export const Container = styled.div`
    width: 100%;
    padding: 0 30px;
`

export const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    border-radius: 7px;
    border: 1px solid var(--cards-border, #D3DBE7);
    background: var(--Colors-Additional-White, #FFF);
`

export const SettingsTitle = styled.h5`
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    margin-bottom: 14px;
`

export const PickerContainer = styled.div`
    width: 125px;
    margin-left: 20px;

`