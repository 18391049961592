import { AnimatePresence } from 'framer-motion';

import { useModalState, useTranslation } from '@hooks';

import { WorkflowNodes } from '@constants';

import { update } from '@utils';

import {
  ChangeTriggerModal,
  CustomerAdditionTriggerSettings,
  EntryFromWorkflowTriggerSettings,
  EventTriggerSettings,
  SegmentTriggerSettings,
  TriggerTypeSettings
} from './components';
import { Container } from './styled';

import { WorkflowSetupSteps } from '../../constants';
import { createEmptyEntryPoint } from '../../utils';

const TriggerStep = ({ onStepChange, worker, errors, onClearError, onWorkerChange, loading, direction }) => {
  const { t } = useTranslation();
  const changeTriggerModal = useModalState();
  const triggerType = worker.tree_map.children[0].data?.name;

  const handleSelectTriggerType = (triggerType) => {
    const [{ data: { children: [node] } }, style] = createEmptyEntryPoint(triggerType, t);

    onWorkerChange(w => ({
      ...w,
      tree_map: {
        ...worker.tree_map,
        children: [{
          ...node,
          children: w.tree_map?.children?.[0]?.children,
        }],
      },
      styles: { ...w.styles, styles: { ...(w.styles?.styles || {}), [node.id]: style } },
    }));
  };

  const handleTriggerSettingsChange = (updater, clear = false) => {
    onWorkerChange(w => ({
      ...w,
      target_audience: clear ? null : w.target_audience,
      tree_map: {
        ...worker.tree_map,
        children: update(worker.tree_map.children, updater)
      },
    }));
  };

  const handleSubmit = () => {
    onStepChange(WorkflowSetupSteps.PARTICIPATION);
  };

  const handleDiscardTrigger = () => {
    if (worker.status !==0) return;
    if (worker.target_audience) {
      return changeTriggerModal.open();
    }

    handleDiscardTriggerConfirm();
  };

  const handleDiscardTriggerConfirm = () => {
    changeTriggerModal.close();
    onStepChange(WorkflowSetupSteps.TRIGGER, w => ({
      ...w,
      target_audience: null,
      tree_map: {
        ...worker.tree_map,
        children: update(worker.tree_map.children, () => [{ type: null, children: worker.tree_map.children?.[0]?.children }])
      },
    }), true, false);
  };

  return (
    <Container custom={direction}>
      <ChangeTriggerModal
        opened={changeTriggerModal.opened}
        onClose={changeTriggerModal.close}
        onConfirm={handleDiscardTriggerConfirm}
      />
      <AnimatePresence initial={false} mode="wait">
        {!triggerType && (
          <TriggerTypeSettings
            key={'no-trigger'}
            onSelect={handleSelectTriggerType}
          />
        )}
        {triggerType === WorkflowNodes.RESOURCE && (
          <SegmentTriggerSettings
            value={worker.tree_map.children}
            onSubmit={handleSubmit}
            errors={errors}
            key={WorkflowNodes.RESOURCE}
            onClearError={onClearError}
            onDiscard={handleDiscardTrigger}
            onChange={handleTriggerSettingsChange}
            loading={loading}
            worker={worker}
          />
        )}
        {triggerType === WorkflowNodes.EVENT && (
          <EventTriggerSettings
            value={worker.tree_map.children}
            onSubmit={handleSubmit}
            errors={errors}
            key={WorkflowNodes.EVENT}
            onClearError={onClearError}
            onDiscard={handleDiscardTrigger}
            onChange={handleTriggerSettingsChange}
            loading={loading}
            worker={worker}
          />
        )}
        {triggerType === WorkflowNodes.ENTRY_ANOTHER_WF && (
          <EntryFromWorkflowTriggerSettings
            value={worker.tree_map.children}
            onSubmit={handleSubmit}
            errors={errors}
            key={WorkflowNodes.ENTRY_ANOTHER_WF}
            onClearError={onClearError}
            onDiscard={handleDiscardTrigger}
            onChange={handleTriggerSettingsChange}
            loading={loading}
            worker={worker}
          />
        )}
        {triggerType === WorkflowNodes.CONTACT_CREATED && (
          <CustomerAdditionTriggerSettings
            value={worker.tree_map.children}
            errors={errors}
            key={WorkflowNodes.CONTACT_CREATED}
            onClearError={onClearError}
            onSubmit={handleSubmit}
            onDiscard={handleDiscardTrigger}
            onChange={handleTriggerSettingsChange}
            loading={loading}
            worker={worker}
          />
        )}
      </AnimatePresence>
    </Container>
  );
};

export default TriggerStep;
