import styled from 'styled-components';

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 7px;
  border: 1px solid var(--select-select-border, #d3dbe7);
  background: var(--input-input-bg, #fff);

  /* dropdown-shadow */
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
`;

export const ListItem = styled.li`
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  &:hover {
    background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
  }
`;
