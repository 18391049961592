import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { WorkflowActionTypes } from '@store/actions/types';

import { useTranslation, useNavigate } from '@hooks';

import { AppRedirects, Paths } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { by, diffFields, removeAppFromPath, testId, update, getDefaultLang } from '@utils';

import { Input, Textarea } from '@components';
import { DynamicVariablesSettings } from '@components/lib/WorkflowEditor/components';
import {
  NodeSettingsTemplatesPreview
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/NodeSettingsTemplatesPreview';

import { Container } from './styled';

import { WorkflowEditorContext } from '../../../../../../../contexts';
import { NodeSettingsAccordion } from "../../../NodeSettingsAccordion";

const WebpushNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const { t } = useTranslation('workflow_page');
  const webpush = options?.[TemplateTypes.WEB_PUSH]?.find?.(by(value.webpush_id));
  const webpushOptions = (options?.[TemplateTypes.WEB_PUSH] || []).filter((item) => item?.status !== 3).map(({ id, name, languages, app_integration_id }) => {
    const data = getDefaultLang(languages);
    return {
      value: id,
      label: name,
      app_integration_id,
      previewData: {
        image: data.image || data?.image_url,
        pushTitle: data.title,
        pushText: data.body
      }
    };
  });

  const handleTemplateChange = (webpush_id) => {
    onClearError('webpush_id');
    onChange(s => ({ ...s, webpush_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.WEB_PUSH_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'webpush_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_WEBPUSH}/new`);
  };

  const handleEditTemplate = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.WEB_PUSH_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'webpush_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_WEBPUSH}/${value.webpush_id}`);
  };

  const handleCustomChange = (updater) => {
    onChange(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <NodeSettingsTemplatesPreview
              value={value.webpush_id}
              nodeValue={value}
              onNodeValueChange={onChange}
              onChange={handleTemplateChange}
              onCreateNew={handleCreateNew}
              onEditTemplate={handleEditTemplate}
              onClearError={onClearError}
              type={value.name}
              options={webpushOptions}
            />
            <DynamicVariablesSettings
              value={value.dynamic || {}}
              onChange={handleCustomChange}
              variables={webpush?.dynamicVariables || []}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('webpush-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              maxLength={40}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('webpush-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              style={{ height: '150px' }}
              wrapperStyles={{ height: '150px' }}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default WebpushNodeSettings;
