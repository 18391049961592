import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  mobilePushSelector, templateSelector,
} from '@store/selectors';

import {
  Badge,
  DateRangeField,
  MultiSelectField,
  RowCell,
  SingleSelectDataField
} from '@velitech/ui';
import { Dropdown } from 'antd';

import { useModalState, useTranslation } from '@hooks';

import { MobilePushStatuses, Paths, UserPermissions } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { identity, moment, tryHumanizeResponse, languageStructureByCode, languageOptions } from '@utils';

import {
  DateCell, Icon,
  Link,
  WithPermissions,
  ViewMobilePushTemplateModal, TableLayout
} from '@components';
import { Overlay } from '@components/lib/styled';

import { TableRowText } from '../../../CustomersScene/pages/FieldSettingsPage/components/AggregateTable/styled';
import { TableActions } from '../../../CustomersScene/pages/FieldSettingsPage/components/TableActions';
import { LanguageItem } from '../LanguageItem';

const MobilePushTable = ({ table, mobilePushTemplates, statusOptions, total }) => {
  const mobilePush = useSelector(mobilePushSelector);
  const templates = useSelector(templateSelector);
  const languages = useMemo(() => languageStructureByCode(), []) 

  const [selectedMobilePush, selectMobilePush] = useState({
    image: '',
    mobile_push_name: '',
  });
  const mobilePushPreviewModal = useModalState(false);
  const { t, p } = useTranslation('notification_history')

  const getStatusColor = (status) => {
    switch (status) {
      case MobilePushStatuses.DELIVERED:
        return Badge.COLORS.POSITIVE
      case MobilePushStatuses.SENT:
        return Badge.COLORS.NEUTRAL_500
      case MobilePushStatuses.FAILED:
        return Badge.COLORS.NEGATIVE_500
      default:
        return Badge.COLORS.NEUTRAL_500
    }
  };

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const openPreview = (data) => {
    selectMobilePush({
      name: data.mobile_push_name,
      title: data.title || '',
      subtitle: data.sub_title || '',
      message: data.message || '',
      image: data.image,
      actions: data.act,
      customerId: data.customer_id,
    });
    mobilePushPreviewModal.open();
  }

  const columns = [
    {
      header: {
        label: t('labels.customer_id'),
        className: 'w-25',
      },
      name: 'customer_id',

      render: (data) => {
        return(
          <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
            <Link type={'secondary'} to={`${Paths.CONTACT}/${data.customer_id}`}>{data.customer_id}</Link>

          </WithPermissions>
        )
      },
    },
    {
      header: {
        label: t('labels.template'),
        className: 'w-25',
      },
      name: 'template_id',
      sort: true,
      render: (data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_MOBILE_PUSH}><Link type={'secondary'} to={`${Paths.TEMPLATES_MOBILE_PUSH}/${data.template_id}?view=1`}>{templates?.[TemplateTypes.MOBILE_PUSH]?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      header: {
        label: t('labels.date'),
        className: 'w-10',
        sort: true,
      },
      name: 'created_at',
      render: (data) => {
        return (
          <TableRowText>
            <DateCell date={data.created_at} />
          </TableRowText>
        );
      },
    },
    {
      header: {
        label: t('labels.status'),
        className: 'w-10',
      },
      name: 'status',
      render: (data) => {
        return (
          <Badge size={Badge.SIZES.SMALL} variant={Badge.VARIANT.GHOST} color={getStatusColor(data.status)}>
            {data.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: {
        label: t('labels.response'),
        className: 'w-25',
      },
      name: 'response',
      render: (data) => {
        if (!data.response) {
          return null;
        }
        return (
          <Dropdown
            trigger={['hover']}
            overlay={(
              <Overlay>
                <pre>
                  {tryHumanizeResponse(data.response)}
                </pre>
              </Overlay>
            )}
          >
            <div>
              <Icon name="Info-icon" size={16} />
            </div>
          </Dropdown>
        )
      },
    },
    {
      header: {
        label: t('labels.language_code'),
        className: 'w-10',
      },
      name: 'language_code',
      render: (data) => {
        const languageItem = languages[data?.language_code];
        if(!languageItem) return <></>
        return (
          <LanguageItem icon={languageItem.emoji} code={languageItem.languageCode} name={languageItem.languageName} />
        );
      },
    },
    {
      header: {
        label: t('labels.actions'),
        className: 'w-25',
      },
      name: 'actions',
      render: (data) => {
        return (
          <TableActions onPreview={() => openPreview(data)} />
        )
      },
    },
  ];

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  };

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_MOBILE_PUSHES} hidden>
        <TableLayout
          withTabs
          fullPage
          name="notification_history_mobile_push"
          loading={mobilePush.loading}
          columns={columns}
          data={mobilePush.data || []}
          table={table}
          total={total}
          localizer={tableLocalizer}
          renderFilter={(
            <>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  placeholder={'Select template'}
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  label={t('labels.template')}
                  options={mobilePushTemplates || []}
                  onChange={table.filters.onChange('template_id', identity)}
                  value={ table.filters.filters['template_id']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  searchHidden
                  placeholder={'Select status'}
                  label={t('labels.status')}
                  options={statusOptions || []}
                  onChange={table.filters.onChange('status', identity)}
                  value={ table.filters.filters['status']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <SingleSelectDataField
                  disableErrorSpaceReserve
                  className="status-select"
                  placeholder={'Select language code'}
                  label={t('labels.language_code')}
                  options={languageOptions() || []}
                  mapOptionLabel={(data) => <LanguageItem icon={data.emoji} code={data.value} name={data.label} />}
                  mapOptionValue={(data) => data.value}
                  onChange={table.filters.onChange('language_code', identity)}
                  value={ table.filters.filters['language_code']}
                />
              </RowCell>
              <RowCell>
                <DateRangeField
                  label={'Date'}
                  disableErrorSpaceReserve
                  dateFormat={'yyyy/MM/dd'}
                  from={{
                    selected: getStartDate(table.filters.filters.start_date),
                    onChange: (date) => handleChangeDate('start_date', date),
                  }}
                  to={{
                    selected: getEndDate(table.filters.filters.end_date),
                    onChange: (date) => handleChangeDate('end_date', date),
                  }}
                />
              </RowCell>
            </>
          )}
        />
      </WithPermissions>

      <ViewMobilePushTemplateModal
        opened={mobilePushPreviewModal.opened}
        onClose={mobilePushPreviewModal.close}
        onActionClick={() => {}}
        data={selectedMobilePush}
      />
    </>
  )
}

export default MobilePushTable;

