import { useTranslation } from '@hooks';

import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const PageViewNodeSettingsInfo = ({ node: outerNode }) => {
  const node = { data: createMultinodeUpdater(outerNode.data).values[0] };
  const { t } = useTranslation();

  if (node.data?.linkType === undefined) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      <InfoRow>
        <InfoTitle>
          {t('labels.page')}:
        </InfoTitle>
        <InfoText>
          {node.data?.url || t('any_url')}
        </InfoText>
      </InfoRow>
      {node.data?.filter_builder?.filters?.length && (
        <InfoRow>
          <InfoTitle>
            {t('labels.filters_count')}:
          </InfoTitle>
          <InfoText>
            {node.data?.filter_builder?.filters?.length}
          </InfoText>
        </InfoRow>
      )}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default PageViewNodeSettingsInfo;

