import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { WorkflowNodes } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { moment } from '@utils';

import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container, TitleContainer } from './styled';

import { keyTemplateconfig } from '../../../NodeSettingsModal/components/BestChannelToSendSettings/module/useBestChannelToSend';
import { AiBestTimeInfo } from '../AiBestTimeInfo';

const StarIconSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='15'
      viewBox='0 0 16 15'
      fill='none'
    >
      <path
        d='M7.4385 0.99085C7.63361 0.472817 8.36639 0.472818 8.5615 0.990851L10.0932 5.05765C10.1777 5.28199 10.3876 5.4345 10.627 5.44554L14.9681 5.64553C15.5211 5.67101 15.7475 6.36792 15.3151 6.71356L11.9207 9.42697C11.7334 9.57666 11.6533 9.82343 11.7168 10.0546L12.868 14.245C13.0147 14.7788 12.4218 15.2095 11.9595 14.9051L8.32996 12.5153C8.12974 12.3834 7.87026 12.3834 7.67004 12.5153L4.04049 14.9051C3.57816 15.2095 2.98532 14.7788 3.13197 14.245L4.28323 10.0546C4.34674 9.82343 4.26655 9.57666 4.0793 9.42697L0.684862 6.71356C0.252475 6.36792 0.478917 5.67101 1.03189 5.64553L5.37295 5.44554C5.61242 5.4345 5.82234 5.28199 5.90683 5.05765L7.4385 0.99085Z'
        fill='#31C447'
      />
    </svg>
  );
};

export const BestChanneltoSendInfo = ({ node, options }) => {
  const { p, t } = useTranslation('workflow_page');
  const configOptions = {
    [WorkflowNodes.SEND_EMAIL]: options?.[TemplateTypes.EMAIL],
    [WorkflowNodes.VIBER]: options?.[TemplateTypes.VIBER],
    [WorkflowNodes.SEND_SMS]: options?.[TemplateTypes.SMS],
    [WorkflowNodes.MOBILE_PUSH]: options?.[TemplateTypes.MOBILE_PUSH],
    [WorkflowNodes.API_REQUEST]: options?.[TemplateTypes.WEBHOOK],
    [WorkflowNodes.WEBPUSH]: options?.[TemplateTypes.WEB_PUSH],
    [WorkflowNodes.SEND_CARD]: options?.[TemplateTypes.MESSAGE_FEED],
  };

  const renderTemplates = node.data?.channels?.map((channel) => {
    const channelOption = configOptions[channel.type];
    const key = keyTemplateconfig[channel.type];

    const getLabel = (templateId) => {
      const data = channelOption?.find((data) => data.id === templateId);
      return data?.name;
    };

    return (
      <Container key={channel?.data?.label}>
        <TitleContainer>
          <InfoTitle>
            {channel.data?.label} {t('labels.template').toLowerCase()}:
          </InfoTitle>
          {channel.default && (
            <Tooltip
              title={p('channel_selected_as_default')}
              placement='right'
            >
              <div>
                <StarIconSvg />
              </div>
            </Tooltip>
          )}
        </TitleContainer>
        <InfoText>{getLabel(channel?.data?.[key]) || ''}</InfoText>
      </Container>
    );
  });

  const renderSendSettings = () => {

    if(!node.data.best_time_enabled) {
      return 'Upon node entrance'
    }

    const sendAt = node.data?.best_time_fallback;
    const sendAtTime = moment(node.data?.best_time_fallback).format('HH:mm');

    return (
      <AiBestTimeInfo
        title={p('ai_best_time')}
        option={sendAt ? `${p('at')} ${sendAtTime}` : p('upon_node_entrance')}
      />

    )
  }
  return (
    <>
      {renderTemplates}
      <Container>
        <TitleContainer>
          <InfoTitle>{t('Send')}:</InfoTitle>
        </TitleContainer>
        <InfoText>
          {renderSendSettings()}
        </InfoText>
      </Container>
    </>
  );
};
