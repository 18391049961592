import { createNode } from '@utils/workflows/refactored/creators';

export const createEmptyEntryPoint = (type, translate) => {
  return createNode({
    type,
    actionType: 'entry_point',
    translate: s => translate('workflow_page.' + s),
    children: [],
    data: {
      validated: true,
      children: [],
      validation: { valid: true, errors: {} }
    },
  });
};
