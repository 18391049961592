import styled from 'styled-components';

import { TextVariants } from "@constants";

import { Text } from "@components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Optioncontainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 80px;
`;

export const Title = styled(Text).attrs({ variant: TextVariants.HEADING_2 })`
    display: flex;
    justify-content: center;

`;

