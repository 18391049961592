import { Button } from '@velitech/ui';

import MobilePushPreview from "@res/images/Template previes.png";

import { Container, ContentContainer, ImageContainer, Title, Message, Link, Footer, Row, IconContainer } from './styled';

const ContentCardTypes = {
  CLASSIC: 'classic',
  IMAGE: 'image',
  CAPTIONED_IMAGE: 'captioned_image',
};

const PlaceholderValues = {
  title: 'Title',
  message: 'Message text',
  link_type: 'link',
  link_text: 'LINK',
};

const ContentCardPreview = ({ value: v, image: i, style, previewType }) => {

  const resolveValue = () => {
    if (!v.title && !v.message && !v.link_text && !i) {
      return {
        ...v,
        ...PlaceholderValues,
      }
    }

    return v;
  };

  const resolveImage = () => {
    if (!v.title && !v.message && !v.link_text && !i) {
      return MobilePushPreview;
    }

    return i;
  }

  const value = resolveValue();
  const image = resolveImage();

  return (
    <Container $previewType={previewType} style={style}>
      {(image && value.type !== ContentCardTypes.CLASSIC) && (
        <ImageContainer $image={image} />
      )}
      {value.type !== ContentCardTypes.IMAGE && (
        <>
          {!!(value.title || value.message || (image && value.type === ContentCardTypes.CLASSIC)) && (
            <Row>
              {(image && value.type === ContentCardTypes.CLASSIC) && <IconContainer $image={image}/>}
              <ContentContainer>
                {value.title && <Title>{value.title}</Title>}
                {value.message && <Message>{value.message}</Message>}
              </ContentContainer>
            </Row>
          )}
          {(value.link_type === 'link' && value.link_text) && (
            <Footer>
              <Link>{value.link_text}</Link>
            </Footer>
          )}
          {(value.link_type === 'button' && value.link_text) && (
            <Footer>
              <Button>{value.link_text}</Button>
            </Footer>
          )}
        </>
      )}
    </Container>
  );
};

export default ContentCardPreview;
