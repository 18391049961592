import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 18px;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 10px;
  line-height: 100%;
  color: #909399;
  margin-right: 2px;
  color: #909399;
`;

export const Value = styled.div`
  font-size: 10px;
  line-height: 100%;
`;

export const Percentage = styled.div`
  font-size: 10px;
  margin-right: 4px;
  line-height: 100%;
`;
