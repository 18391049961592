import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const Title = styled.div`
  width: 100%;
`;
