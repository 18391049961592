import styled from 'styled-components';

import { Text } from '@components';

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  gap: 8px;
  align-items: center;
  max-width: 380px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const TooltipContainer = styled.div`
  position: absolute;
  right: -18px;
  top: 0px;
`;
export const Title = styled(Text)`
  color: #7C94B1;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 24px;
  position: relative;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: -80px;
    width: 50px;
    height: 1px;
    background: #D3DBE7;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -80px;
    width: 50px;
    height: 1px;
    background: #D3DBE7;
  }
`;
export const TitleRow = styled(Text)`
  display: inline-flex;
  align-items: center;
  color: #303133;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
`;
