import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGoalsAnalytics } from '@store/actions/creators';
import { goalsAnalyticsSelector, nodeWebpopupElementAnalyticsSelector } from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { Dropdown as ANTDDropdown } from 'antd';
import capitalize from 'lodash.capitalize';

import { useTranslation } from '@hooks';

import { WITHOUT_VALUE_OPERATORS , WorkflowNodes, ChartColors } from '@constants';

import {
  by,
  formatNumberByK,
  formatNumberString,
  zeroIfNaN,
} from '@utils';
import { clickhouseFields2Events } from '@utils/fields';

import { Dropdown, Icon ,
  PieChart,
  RateCard,
  LegendItem,
  DownloadWorkflowContactsModal,
  SliderTabs,
  ColumnChart } from '@components';
import { formatDate } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/utils';

import {
  Container,
  Header,
  SendingContainer,
  StatusesContainer,
  StatusesLegendContainer,
  PieChartTitle,
  PieChartTotal,
  PieChartTotalCaption,
  StatusesLegendRow,
  StatusesLegendValue,
  Column,
  TabContainer,
  Row,
  TrendLegendContainer,
  TabTitle,
  PieContainer,
  GoalConvertionRates,
  RatesContainer,
  RateItem,
  RateLeft,
  RateRight,
  GoalName,
  GoalRule,
  Divider,
  FiltersContainer,
  NoFilters,
  InfoContainer,
  LegendElementContainer,
  TotalClicks,
  TotalAmount,
  Total,
  StatuselementsContainer,
  AnalyticsCardContainer,
  AnalyticNodeList,
  WebPopupContainer
} from './styled';

import { LineChart } from '../../../LineChart';
import { AnalyticCard } from '../AnalyticCard';
import { AnalyticElement } from '../AnalyticElement';

const SendingAnalytics = ({
  opened,
  statuses,
  commonAnalytic,
  total,
  defaultStatuses,
  downloadModal,
  selected,
  worker,
  channelType,
  onDownloadByChannel,
  onDownloadAll,
  series,
}) => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState('total');
  const { t, p } = useTranslation('campaigns_page');
  const goalsAnalytics = useSelector(goalsAnalyticsSelector);
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const events = clickhouseFields2Events(clickhouseFields.data);
  const goals = goalsAnalytics[worker.id]?.goals || [];
  const analytics = goalsAnalytics[worker.id]?.goals_analytics || [];
  const currentGoalAnalytics = analytics.filter(({ node_id }) => node_id === selected.id || node_id === selected.data?.serverId || node_id === selected.data?.cloneOf)
  const elementAnalyticData = useSelector(nodeWebpopupElementAnalyticsSelector);
  const elementAnalytic = elementAnalyticData.data[selected?.id] || [];

  useEffect(() => {
    dispatch(getGoalsAnalytics({ id: worker.id }));
  }, [worker.id]);

  const resolveCompletionPeriod = (goal) => {
    if (!goal.completion_period) {
      return 'Been performed';
    }

    return `Within ${goal.completion_period.quantity} ${t(`labels.${goal.completion_period.type}`)}`;
  };

  const goalsData = goals.map(({ id, ...goal }) => {
    const info = currentGoalAnalytics.find(by('goal_id', id)) || ({
      count: 0,
      out: 0,
    });
    const conversionRate = zeroIfNaN(info.count / info.out * 100).toFixed(2);

    return {
      title: events.data?.find(by('name', goal.event_field))?.label || capitalize(goal.event_field),
      name: goal.event_field,
      completion_period: resolveCompletionPeriod(goal),
      count: info.count,
      out: info.out,
      conversion_rate: formatNumberString(conversionRate)
    }
  })

  const isSplitter = selected?.data?.name === WorkflowNodes.AB_SPLITTER;
  const isFilterNode = [WorkflowNodes.EXCLUDE_FILTER, WorkflowNodes.QUICK_FILTER].includes(selected?.data?.name);
  const isWaitNode = selected?.data?.name === WorkflowNodes.WAIT;
  const isGroupDSplit = selected?.data?.name === WorkflowNodes.SWITCH_FILTER;
  const isWebpopup = selected?.data?.name === WorkflowNodes.WEB_POPUP;

  const isOneOffWorkflow = worker.schedule?.type === 0 || !worker.schedule;

  const shouldHideTrendChart = isOneOffWorkflow || isGroupDSplit || isFilterNode || [WorkflowNodes.GIVE_REWARD, WorkflowNodes.INCLUDE_CAMPAIGN].includes(selected?.data?.name);
  const ratesConfig = [
    {
      title: p('delivery_rate'),
      tooltip: p('delivery_rate_description'),
      icon: 'Delivered-rate',
      color: '#24B277',
      total: total,
      name: 'delivery',
      actual: [
        'delivered',
        'open',
        'unsubscribed',
        'resubscribed',
        'click',
        'spam_report',
      ]
        .map((s) => zeroIfNaN(statuses[s]))
        .reduce((a, b) => a + b, 0),
      status: 'delivered',
      small: true,
    },
    {
      title: p('click_through_rate'),
      tooltip: p('click_through_rate_description'),
      icon: 'CLick-rate',
      color: '#24B277',
      total: total,
      name: 'click',
      actual: statuses['click'],
      status: 'click',
      small: true,
    },
    {
      title: p('opened_rate'),
      tooltip: p('opened_rate_description'),
      icon: 'Opened-rate',
      name: 'opened',
      color: '#24B277',
      total: total,
      actual: ['open', 'unsubscribed', 'resubscribed', 'click', 'spam_report']
        .map((s) => zeroIfNaN(statuses[s]))
        .reduce((a, b) => a + b, 0),
      status: 'open',
      small: true,
    },
    {
      title: p('bounce_rate'),
      tooltip: p('bounce_rate_description'),
      icon: 'Failed-rate',
      color: '#F04438',
      name: 'failed',
      total: total,
      actual: statuses['bounce'],
      status: 'bounce',
      small: true,
    },
    {
      title: p('unsub_rate'),
      tooltip: p('unsub_rate_description'),
      icon: 'Failed-rate',
      name: 'failed',
      color: '#F04438',
      total: total,
      actual: statuses['unsubscribed'],
      status: 'unsubscribed',
      small: true,
    },
  ];

  const typeTabs = [
    {
      name: 'total',
      label: 'total',
    },
    {
      name: 'trend',
      label: 'trend',
    },
  ];

  const isDefault = (status) => {
    return !!~defaultStatuses.indexOf(status);
  };

  const getColor = (status, index, { isSplitter }) => {
    if(isSplitter) {
      return ChartColors[index]
    }

    return ChartColors[index]
  }

  const statusSegments = Object.entries(statuses).map(([status, count], index) => ({
    id: status,
    color: getColor(status, index, { isSplitter }),
    value: count,
    name: isDefault(status) ? t(`statuses.${status}`) : capitalize(status),
  }));

  const statusElements = elementAnalytic.map((el) => {
    const statusSegment = statusSegments.find(({ id }) => id === el.status);
    return {
      id: el.element,
      color: statusSegment.color,
      value: el.count,
      name: el.element,
    }
  });

  const translateTab = (t) => ({ ...t, label: p(t.label) });
  const ratesItems = ratesConfig.filter(({ status }) => Object.keys(statuses).includes(status));

  const getTabTitle = () => {
    if(isGroupDSplit || isFilterNode || isSplitter || isWaitNode) {
      return t('workflow_page.node_exits')
    }

    return t('workflow_page.delivery_statuses')
  }

  const trendChartRender = () => {
    const replaceColorForTrend = series?.map((dataItem) => {
      const findedStatus = statusSegments.find((status) => status?.id === dataItem?.name);

      return {
        ...dataItem,
        color: findedStatus?.color || dataItem?.color,
      }
    });
    const filteredCards = commonAnalytic?.filter(({ count }) => +count);
    return (
      <div style={{ width: '100%' }}>
        <AnalyticsCardContainer>
          {filteredCards?.map((data, index) => (
            <AnalyticCard 
              key={index}
              name={data.label} 
              description={data.count}
            />
          ))}
        </AnalyticsCardContainer>
        {/*<TabTitle>{getTabTitle()}</TabTitle>*/}
        <TrendLegendContainer $l={replaceColorForTrend?.length}>
          {replaceColorForTrend.map(({ id, color, name }) => (
            <StatusesLegendRow
              key={id}
              style={{ marginBottom: 0, minWidth: 'unset' }}
            >
              <LegendItem name={name} color={color} />
            </StatusesLegendRow>
          ))}
        </TrendLegendContainer>
        <LineChart series={replaceColorForTrend} worker={worker}/>
      </div>
    )
  }

  const getLabel = (event, field) => {
    const source = event?.payload?.find?.((source) => source.field === field);
    return source?.label;
  };

  const resolveTitle = (event) => {
    return event?.label;
  };

  const resolveValue = (v, operator) => {
    if (!v || !!~WITHOUT_VALUE_OPERATORS.indexOf(operator)) {
      return;
    }

    if (Array.isArray(v.value)) {
      return v.value.join(!!~operator?.indexOf?.('between') ? ' - ' : ', ');
    }

    if (typeof v.value === 'object' && v.value !== null) {
      const title = resolveTitle(v.value, false, t);

      const isDate = title.includes('at');

      if (isDate) return formatDate(title);
      return title;
    }

    return v.value;
  };

  const totalElements = elementAnalytic?.reduce((prev, curr) => prev + curr.count, 0);

  const pieChartRender = () => {
    return (
      <PieContainer style={{ marginTop: isGroupDSplit ? '45px' : '0' }}>
        <PieChart
          total={total}
          showZeroValues={false}
          tooltipPlacement='right'
          k='qweq'
          title={
            <PieChartTitle>
              <PieChartTotal>
                {formatNumberByK(total, 1, 999)}
              </PieChartTotal>
              <PieChartTotalCaption>
                {t('labels.total')}
              </PieChartTotalCaption>
            </PieChartTitle>
          }
          appearance={{
            size: 300,
            segmentRadiusInner: 67,
            segmentRadiusOuter: 150,
          }}
          segments={statusSegments || []}
        />
        <StatusesLegendContainer $l={statusSegments?.length}>
          <AnalyticNodeList>
            {commonAnalytic?.filter(({ count }) => +count).map((data, index) => (
              <AnalyticElement
                key={index}
                name={data.label}
                count={data.count}
              />
            ))}
          </AnalyticNodeList>
          {statusSegments?.map(({ id, color, name, value }) => (
            <StatusesLegendRow key={id} style={{ marginBottom: 0 }}>
              <LegendItem name={name} color={color} />
              <StatusesLegendValue data-testid={`status-${name}-count`}>
                {formatNumberString(value)}
              </StatusesLegendValue>
            </StatusesLegendRow>
          ))}
        </StatusesLegendContainer>
      </PieContainer>
    )
  }

  return (
    <Container $opened={opened}>
      {ratesItems.length > 0 && (
        <>
          <Header>{p('delivery_rates')}</Header>
          <SendingContainer>
            {ratesItems.map(({ ...props }, index) => (
              <RateCard {...props} key={index} />
            ))}
          </SendingContainer>
        </>
      )}

      <StatusesContainer>
        <Column>
          <TabContainer>
            <Row>
              <TabTitle>{getTabTitle()}</TabTitle>

              <SliderTabs 
                tabs={typeTabs.map(translateTab)}
                tab={tab}
                onTabChange={(type) => setTab(type)}
                style={{
                  display: shouldHideTrendChart ? 'none' : 'inline-flex',
                  alignSelf: 'flex-start',
                  alignItems: 'center',
                }}
                tabStyle={{
                  padding: '6px 12px',
                }}
              />
            </Row>
          </TabContainer>
          <StatusesContainer>
            {tab === 'total' && pieChartRender()}
            {tab === 'trend' && trendChartRender()}
          </StatusesContainer>
        </Column>
      </StatusesContainer>
      {isWebpopup && (
        <WebPopupContainer>
          <LegendElementContainer>
            <div>
              <TotalClicks>{t('workflow_page.clicks_analytics')}:</TotalClicks> <TotalAmount>{totalElements} </TotalAmount>
              <Total>{p('total')}</Total>
            </div>
            <StatuselementsContainer>
              {statusElements?.map(({ id, color, name }) => (
                <StatusesLegendRow key={id} style={{ marginBottom: 0, minWidth: 'unset' }}>
                  <LegendItem name={name} color={color} />
                </StatusesLegendRow>
              ))}
            </StatuselementsContainer>
          </LegendElementContainer>
          <ColumnChart data={elementAnalytic} colors={statusElements.map(({ color }) => color)}/>
        </WebPopupContainer>
      )}
      <GoalConvertionRates>
        {goalsData.length !== 0 && (
          <>
            <Header>{p('convertion_rates')}</Header>
            <RatesContainer>
              {goalsData.map((goal, index) => {
                const event = events.data.find(by('name', goal.event_field));
                const filters = goal?.filter_builder?.filters || [];
                return (
                  <>
                    <RateItem key={goal.id}>
                      <RateLeft>
                        <GoalName>
                          {goal.title}
                          <ANTDDropdown
                            trigger={['hover']}
                            overlay={
                              <FiltersContainer>
                                {filters.length > 0 ? (
                                  filters?.map((filter, index) => {
                                    return (
                                      <Dropdown.Item
                                        key={index}
                                        title={getLabel(event, filter.field)}
                                        stretch
                                        subTitle={`${t(`segments_page.${filter.operator}`)}:`}
                                      >
                                        <div>{resolveValue(filter.value, filter.operator)}</div>
                                      </Dropdown.Item>
                                    );
                                  })
                                ) : (
                                  <NoFilters>No event filters, just perform</NoFilters>
                                )}
                              </FiltersContainer>
                            }
                          >
                            <InfoContainer>
                              <Icon name="Info-icon" size={16} color="#31C447" />
                            </InfoContainer>
                          </ANTDDropdown>
                        </GoalName>
                        <GoalRule>{goal.completion_period}</GoalRule>
                      </RateLeft>
                      <RateRight>
                        <GoalName>{`${goal.conversion_rate} %`}</GoalName>
                        <GoalRule>{`(${goal.count} of ${goal.out})`}</GoalRule>
                      </RateRight>
                    </RateItem>
                    {index !== goalsData.length - 1 && <Divider />}
                  </>
                );
              })}
            </RatesContainer>
          </>
        )}
      </GoalConvertionRates>

      <DownloadWorkflowContactsModal
        onClose={downloadModal.close}
        opened={downloadModal.opened}
        channelType={channelType}
        onDownload={onDownloadByChannel}
        onDownloadAll={onDownloadAll}
      />
    </Container>
  );
};

export default SendingAnalytics;
