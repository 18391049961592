import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: var(--Colors-Additional-White, #FFF);
  overflow: hidden;
`;

export const Section = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
