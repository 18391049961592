export const API_URL = 'https://local.riup.app'; //'http://192.168.88.89:30301/';
// export const PROXY_URL = 'http://localhost:9090';
// @TODO remove ?contact

export const Endpoints = {
  LOGIN: '/api/auth/login',
  RESET_PASSWORD: '/api/auth/reset-password',
  FORGOT_PASSWORD: '/api/auth/reset-password',
  UPDATE_INVITE_USER: '/api/auth/register/invited',
  LOGOUT: '/api/logout',

  CONTACTS: '/api/seg/customer/examples',
  CONTACT_INFO: (id) => `/api/seg/customer/${id}/info`,
  CONTACT_EVENTS: (id) => `/api/seg/customer/${id}/events`,
  CONTACT_EVENTS_TOTAL: (id) => `/api/customers/${id}/events/total`,
  FIELD_INFO: (id, fieldId) => `/api/seg/customer/${id}/info/${fieldId}`,
  CONTACT_EMAIL_INFO: `/api/notificator/email?customer_id=`,
  CONTACT_SMS_INFO: `/api/notificator/sms?customer_id=`,
  CONTACT_PUSH_INFO: '/api/notificator/web_push?customer_id=',
  CONTACT_EMAIL_SUMMARY: '/api/notificator/email/summary?',
  CONTACT_SMS_SUMMARY: '/api/notificator/sms/summary',
  CONTACT_PUSH_SUMMARY: '/api/notificator/web_push/summary',
  // CONTACT_PUSH_INFO: `/api/notificator/push?customer_id=`,
  CONTACT_INVOLVEMENT: '/api/seg/customer/involvement',
  CONTACT_AUDITS: '/api/customers/audits/?id=',
  DELETE_CONTACT: '/api/cus/edge/customers',
  DELETE_MULTIPLE_CONTACTS: '/api/cus/edge/customers/multiple-delete',
  CREATE_CONTACT_COMMENT: '/api/cus/customers/comment',
  DELETE_CONTACT_COMMENT: '/api/cus/customers/comment',
  CREATE_CONTACT: '/api/cus/edge/customers',
  CREATE_NEW_CONTACT_FIELDS: '/api/cus/settings/fields/by-label',
  IMPORT_HISTORY: '/api/settings/data-import/history',
  UPLOAD_CONTACTS: '/api/settings/data-import/upload-file',
  START_CONTACTS_IMPORT: '/api/settings/data-import/start-import',
  TRACK_CONTACTS_IMPORT_PROGRESS: '/api/settings/data-import/track-progress',

  CREATE_EXPRESSION: '/api/settings/expressions/',

  CREATE_AGGREGATE: '/api/seg/settings/aggregate',
  GET_AGGREGATES: '/api/seg/settings/aggregate',
  DELETE_AGGREGATE: `/api/seg/settings/aggregate`,
  EDIT_AGGREGATE: '/api/seg/settings/aggregate',

  ROLES: '/api/auth/settings/roles',
  ROLES_LIST: '/api/auth/settings/roles/list',
  ROLE: '/api/auth/settings/roles',
  PERMISSIONS: '/api/auth/settings/roles/permissions',

  USER: '/api/auth/user',
  USERS_LIST: '/api/auth/settings/users/list',
  ALL_USERS: '/api/auth/settings/users/all',
  USERS: '/api/auth/settings/users',
  CREATE_USER: '/api/auth/settings/users',
  DELETE_USER: (id) => `/api/auth/settings/users/${id}`,
  DELETE_APP_USER: (id) => `/api/auth/settings/users/${id}/app`,
  RE_INVITE_USER: '/api/auth/settings/users/invite',
  EDIT_USER: (id) => `/api/auth/settings/users/${id}`,
  ACTIVATE_USER: (id) => `/api/auth/settings/users/${id}/active`,
  DEACTIVATE_USER: (id) => `/api/auth/settings/users/${id}/inactive`,
  CHANGE_PASSWORD: '/api/auth/user/password',
  CHANGE_EMAIL: '/api/auth/user/email',
  TWO_FA_CONFIRMED_PASSWORD_STATUS: '/api/auth/user/password-confirmed',
  TWO_FA_CONFIRM_PASSWORD: '/api/auth/user/password-confirmed',
  TWO_FA_ENABLE: '/api/auth/user/two-factor/authentication',
  TWO_FA_CONFIRMATION: '/api/auth/two-factor/confirmation',
  TWO_FA_QR_CODE: '/api/auth/user/two-factor/qr-code',
  TWO_FA_DISABLE: '/api/auth/user/two-factor/authentication',
  TWO_FA_VERIFY_CODE: '/api/auth/two-factor/challenge',
  TWO_FA_RECOVERY_CODES: '/api/auth/user/two-factor/recovery-codes',

  SEGMENTS: '/api/segment',
  SEGMENTS_CONTACTS_EXAMPLE: (type) => {
    switch (type) {
      case "analytic_funnel":
        return '/api/seg/customer/funnel/examples';
      default:
        return '/api/seg/customer/examples';
    }
  },
  SEGMENTS_FILTER_RESULTS: '/api/segment/filter-result',
  SEGMENTS_OPTIONS: '/api/app/options',
  DELETE_SEGMENT: '/api/segment/delete',
  PUBLISH_SEGMENT: '/api/segment/publish',
  UN_PUBLISH_SEGMENT: '/api/segment/un_publish',

  PROMO_CODES: '/api/promo_code',
  PUBLISH_PROMO_CODE: '/api/promo_code/publish',
  DELETE_PROMO_CODE: '/api/promo_code/delete',

  WORKFLOW: '/api/workflow',
  WORKFLOW_PARTICIPATION: '/api/workflow/participation',
  WORKFLOW_OPTIONS: '/api/workflow/options',
  WORKFLOW_PUBLISH: '/api/workflow/publish',
  WORKFLOW_UN_PUBLISH: '/api/workflow/un_publish',
  WORKFLOW_DELETE_VERSION: '/api/workflow/version/delete',
  WORKFLOW_DELETE: '/api/workflow/delete',
  WORKFLOW_ARCHIVE: '/api/workflow/archive',
  WORKFLOW_UN_ARCHIVE: '/api/workflow/un_archive',
  WORKFLOW_EXIT_CRITERION: '/api/workflow/exit/criterion',
  WORKFLOW_NODE_ANALYTICS: '/api/workflow/node/analytic',
  WORKFLOW_NODE_DOWNLOAD: '/api/workflow/node/download',
  DOWNLOAD_FILE: '/api/file/download',

  TEMPLATES_RENDER: '/api/templates/render',

  TEMPLATES_SMS: '/api/sms',
  CREATE_TEMPLATE_SMS: '/api/sms',
  DELETE_TEMPLATE_SMS: '/api/sms/delete',
  SINGLE_TEMPLATE_SMS: (id) => `/api/sms/${id}/edit`,
  VALIDATE_SMS_TEMPLATE_NAME: '/api/sms/name-validation',

  TEMPLATES_VIBER: '/api/viber',
  DELETE_VIBER: '/api/viber/delete',
  SINGLE_VIBER: (id) => `/api/viber/${id}/edit`,

  TEMPLATES_MOBILE_PUSH: '/api/mobile_push',
  DELETE_MOBILE_PUSH: '/api/mobile_push/delete',
  SINGLE_MOBILE_PUSH: (id) => `/api/mobile_push/${id}/edit`,

  TEMPLATES_EMAIL: '/api/email',
  CREATE_TEMPLATE_EMAIL: '/api/email',
  VALIDATE_EMAIL_TEMPLATE_NAME: '/api/email/name-validation',
  GET_EMAIL_SAMPLE_DATA: '/api/seg/customer/sampleData',
  DELETE_TEMPLATE_EMAIL: '/api/email',
  SINGLE_TEMPLATE_EMAIL: (id) => `/api/email/${id}/edit`,
  VALIDATE_LINKS_TEMPLATE_EMAIL: '/api/email/links-validation',

  TEMPLATES_WEBPUSH: '/api/web_push',
  VALIDATE_WEBPUSH_TEMPLATE_NAME: '/api/web_push/name-validation',
  SINGLE_WEBPUSH_TEMPLATE: (id) => `/api/web_push/${id}/edit`,
  CREATE_TEMPLATE_WEBPUSH: '/api/web_push',
  EDIT_TEMPLATE_WEBPUSH: 'api/web_push',

  TEMPLATES_API_REQUEST: '/api/webhook',
  VALIDATE_WEBHOOK_TEMPLATE_NAME: '/api/webhook/name-validation',
  SINGLE_API_REQUEST_TEMPLATE: (id) => `/api/webhook/${id}/edit`,

  EVENTS: '/api/cus/event',
  EVENTS_LIST: '/api/cus/event/list',
  DELETE_EVENT: '/api/cus/event',

  INTEGRATIONS: '/api/integrations',
  MY_INTEGRATIONS: '/api/my-integrations',

  WORKSPACES: '/api/apps',
  WORKSPACES_ONBOARDING: (id) => `/api/apps/${id}/on_boarding`,

  NOTIFICATIONS: '/api/notifications',
  NOTIFICATIONS_MARK_AS_READ: '/api/notifications/mark-as-read',
  NOTIFICATIONS_MARK_AS_IMPORTANT: '/api/notifications/mark-as-important',
  NOTIFICATIONS_MARK_NOT_IMPORTANT: '/api/notifications/mark-not-important',
  // NOTIFICATIONS_GET_IMPORTANT: '/api/notifications?important=true',

  NOTIFICATION_HISTORY_EMAIL: '/api/notificator/email',
  NOTIFICATION_HISTORY_FACEBOOK: '/api/notificator/facebook-audiences',
  NOTIFICATION_HISTORY_SMS: '/api/notificator/sms',
  NOTIFICATION_HISTORY_VIBER: '/api/notificator/viber',
  NOTIFICATION_HISTORY_MOBILE_PUSHES: '/api/notificator/mobile_push',
  NOTIFICATION_HISTORY_WEBHOOK: '/api/notificator/webhook',
  NOTIFICATION_HISTORY_WEBPUSH: '/api/notificator/web_push',
  NOTIFICATION_HISTORY_TEMPLATES: '/api/templates',
  NOTIFICATION_HISTORY_INCLUDE_CAMPAIGN: '/api/notificator/include-campaigns',
  NOTIFICATION_HISTORY_GIVE_REWARD: '/api/notificator/rewards',
  NOTIFICATION_HISTORY_CONTENT_CARDS: '/api/notificator/message_feed',
  NOTIFICATION_HISTORY_WEB_POPUPS: '/api/notificator/web_popup',

  MOBILE_PUSH_SUMMARY: '/api/notificator/mobile_push/summary',
  VIBER_SUMMARY: '/api/notificator/viber/summary',
  DASHBOARD_EMAIL_SUMMARY: '/api/notificator/email/summary',
  DASHBOARD_VIBER_SUMMARY: '/api/notificator/viber/summary',
  DASHBOARD_SMS_SUMMARY: '/api/notificator/sms/summary',
  DASHBOARD_MOBILE_PUSH_SUMMARY: '/api/notificator/mobile_push/summary',
  DASHBOARD_WEBPUSH_SUMMARY: '/api/notificator/web_push/summary',
  DASHBOARD_CARD_SUMMARY: '/api/notificator/message_feed/summary',
  NOTIFICATION_HISTORY_INCLUDE_CAMPAIGN_SUMMARY: '/api/notificator/include-campaigns/summary',
  NOTIFICATION_HISTORY_GIVE_REWARD_SUMMARY: '/api/notificator/rewards/summary',
  GENERAL_ACTIVITY_DAILY: '/api/analytics/general-activity-daily',
  GENERAL_ACTIVITY_HOURLY: '/api/analytics/general-activity-hourly',

  SEND_TEST_EMAIL: '/api/email/test',
  SEND_TEST_SMS: '/api/sms/test',
  SEND_TEST_WEB_PUSH: '/api/web_push/test',

  DEFAULT_PAYMENT_METHOD: '/api/stripe/default-payment-method',
  SUBSCRIPTION: '/api/stripe/subscription',

  ONBOARDING: '/api/auth/user/on-boarding',

  HEALTH_CHECK_SDK: '/api/seg/analytics/base/track-events'
};
