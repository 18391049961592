import styled from 'styled-components';

export const Container = styled.div`
  width: 16px;
  height: 18px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Caption = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #909399;
`;
