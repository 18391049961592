import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 10px 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #303133;
  margin-bottom: 30px;
`;

export const Text = styled.div`
  font-size: 13px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #303133;
  margin-bottom: 25px;
  margin-left: 6px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const CheckCircle = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: #24993E;
`;

export const Row = styled.div`
  display: flex;
`;
