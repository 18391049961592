import { OPTIONS_KEYS } from '@constants/options';

import { OptionsActionTypes } from '../actions/types';

const initialState = {
  loading: false,
  lastUpdate: null,
  workflow: {
    loading: false,
  },
  options: {
    fields: {},
    aggregates: [],
    funnels: [],
    segments: [],
    query_values: [],
  },
};

const optionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OptionsActionTypes.GET_OPTIONS:
      return {
        ...state,
        loading: true,
        options: {
          ...state.options,
        },
      };
    case OptionsActionTypes.GET_OPTIONS_SUCCESS:
      if (action.meta?.part === 'aggregate') {
        return {
          ...state,
          loading: false,
          options: {
            ...state.options,
            [OPTIONS_KEYS.AGGREGATES]: action.payload?.data,
          },
        };
      }
      if (action.meta?.part === 'workflow') {
        return {
          ...state,
          loading: false,
          options: {
            ...state.options,
            [OPTIONS_KEYS.FIELDS]: {
              ...state.options[OPTIONS_KEYS.FIELDS],
              [OPTIONS_KEYS.FIELDS_ATTRIBUTES]: action.payload,
            },
          },
        };
      }
      if (action.meta?.part === 'app') {
        return {
          ...state,
          loading: false,
          options: {
            ...state.options,
            [OPTIONS_KEYS.FIELDS]: {
              ...state.options[OPTIONS_KEYS.FIELDS],
              ...(action.payload?.fields || {}),
            },
          },
        };
      }

      if (action.meta?.part === 'events') {
        return state;
      }

      return {
        ...state,
        loading: false,
        options: {
          ...state.options,
          ...action.payload,
        },
      };

    case OptionsActionTypes.GET_OPTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case OptionsActionTypes.GET_WORKFLOW_OPTIONS:
      return {
        ...state,
        loading: true,
        [OPTIONS_KEYS.WORKFLOW]: {
          ...(state[OPTIONS_KEYS.WORKFLOW] || {}),
          loading: true,
        },
      };
    case OptionsActionTypes.GET_WORKFLOW_OPTIONS_SUCCESS:
      if (action.meta?.part === 'allWorkflowOptions') {
        return {
          ...state,
          loading: false,
          options: {
            ...state.options,
            [OPTIONS_KEYS.ALL_WORKFLOWS]: action.payload,
          },
        };
      }

      return {
        ...state,
        loading: false,
        [OPTIONS_KEYS.WORKFLOW]: {
          ...action.payload,
          loading: false,
        },
      };

    case OptionsActionTypes.GET_WORKFLOW_OPTIONS_ERROR:
      return {
        ...state,
        loading: false,
        [OPTIONS_KEYS.WORKFLOW]: {
          ...action.payload,
          loading: false,
        },
      };

    case OptionsActionTypes.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        [OPTIONS_KEYS.CAMPAIGN]: {
          ...action.payload,
          loading: false,
        },
      }
    case OptionsActionTypes.SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdate: action.payload,
      };

    default:
      return state;
  }
};

export default optionsReducer;
