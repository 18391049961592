import { useSelector } from 'react-redux';

import { workflowOptionsSelector } from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { Button } from '@velitech/ui';
import { v4 } from 'uuid';

import { useModalState, useTranslation } from '@hooks';

import { SegmentNodeTypes, SegmentsRuleTypes, WorkflowNodes } from '@constants';

import { by, update } from '@utils';
import { clickhouseFields2Events } from '@utils/fields';
import { createNode } from '@utils/workflows/refactored/creators';
import { iterate } from '@utils/workflows/refactored/structureParser';
import { insertAfter, removeChild } from '@utils/workflows/refactored/updaters';

import { ControlGroupSettings } from './components';
import { Container, Title, ContentContainer } from './styled';

import { AddEntityButton } from '../AddEntityButton';
import { AddEventModal } from '../AddEventModal';
import { Actions } from '../ExitCriteriaStep/styled';
import { WorkflowFilter } from '../WorkflowFilter';

const GoalIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <circle cx="18.4526" cy="18.8119" r="13.7143" fill="#87E2BA"/>
    <mask id="path-2-inside-1_3765_45523" fill="white">
      <path d="M32.1669 18.8119C32.1669 26.3861 26.0268 32.5262 18.4526 32.5262C10.8784 32.5262 4.73828 26.3861 4.73828 18.8119C4.73828 11.2378 10.8784 5.09766 18.4526 5.09766C26.0268 5.09766 32.1669 11.2378 32.1669 18.8119ZM5.83542 18.8119C5.83542 25.7802 11.4843 31.4291 18.4526 31.4291C25.4208 31.4291 31.0697 25.7802 31.0697 18.8119C31.0697 11.8437 25.4208 6.1948 18.4526 6.1948C11.4843 6.1948 5.83542 11.8437 5.83542 18.8119Z"/>
    </mask>
    <path d="M32.1669 18.8119C32.1669 26.3861 26.0268 32.5262 18.4526 32.5262C10.8784 32.5262 4.73828 26.3861 4.73828 18.8119C4.73828 11.2378 10.8784 5.09766 18.4526 5.09766C26.0268 5.09766 32.1669 11.2378 32.1669 18.8119ZM5.83542 18.8119C5.83542 25.7802 11.4843 31.4291 18.4526 31.4291C25.4208 31.4291 31.0697 25.7802 31.0697 18.8119C31.0697 11.8437 25.4208 6.1948 18.4526 6.1948C11.4843 6.1948 5.83542 11.8437 5.83542 18.8119Z" fill="#0C1015" stroke="#0C1015" strokeWidth="2" mask="url(#path-2-inside-1_3765_45523)"/>
    <mask id="path-3-inside-2_3765_45523" fill="white">
      <path d="M27.1124 18.814C27.1124 23.5977 23.2344 27.4756 18.4507 27.4756C13.667 27.4756 9.78906 23.5977 9.78906 18.814C9.78906 14.0303 13.667 10.1523 18.4507 10.1523C23.2344 10.1523 27.1124 14.0303 27.1124 18.814ZM10.8285 18.814C10.8285 23.0237 14.2411 26.4363 18.4507 26.4363C22.6604 26.4363 26.073 23.0237 26.073 18.814C26.073 14.6043 22.6604 11.1917 18.4507 11.1917C14.2411 11.1917 10.8285 14.6043 10.8285 18.814Z"/>
    </mask>
    <path d="M27.1124 18.814C27.1124 23.5977 23.2344 27.4756 18.4507 27.4756C13.667 27.4756 9.78906 23.5977 9.78906 18.814C9.78906 14.0303 13.667 10.1523 18.4507 10.1523C23.2344 10.1523 27.1124 14.0303 27.1124 18.814ZM10.8285 18.814C10.8285 23.0237 14.2411 26.4363 18.4507 26.4363C22.6604 26.4363 26.073 23.0237 26.073 18.814C26.073 14.6043 22.6604 11.1917 18.4507 11.1917C14.2411 11.1917 10.8285 14.6043 10.8285 18.814Z" fill="#0C1015" stroke="#0C1015" strokeWidth="2" mask="url(#path-3-inside-2_3765_45523)"/>
    <circle cx="18.4506" cy="18.8119" r="1.66541" fill="#0C1015" stroke="#0C1015"/>
    <path d="M22.2422 7.95759V14.8464H29.3115C29.8667 14.8464 30.3969 14.6156 30.7753 14.2093L33.4888 11.2948C34.0842 10.6552 33.6307 9.61334 32.7569 9.61334H28.4753C27.923 9.61334 27.4753 9.16563 27.4753 8.61334V4.5122C27.4753 3.6384 26.4334 3.18488 25.7939 3.7803L22.8794 6.4938C22.473 6.87214 22.2422 7.40237 22.2422 7.95759Z" fill="white"/>
    <path d="M22.2426 14.8464V7.95759C22.2426 7.40237 22.4734 6.87214 22.8798 6.4938L25.7943 3.7803C26.4338 3.18488 27.4757 3.6384 27.4757 4.5122V8.61334C27.4757 9.16563 27.9234 9.61334 28.4757 9.61334H32.7573C33.6311 9.61334 34.0846 10.6552 33.4892 11.2948L30.7757 14.2093C30.3973 14.6156 29.8671 14.8464 29.3119 14.8464H22.2426ZM22.2426 14.8464L18.4531 18.6359" stroke="#0C1015"/>
  </svg>
);

const GoalsStep = ({ worker, onWorkerChange, onComplete, direction, loading, errors, onClearError }) => {
  const { p } = useTranslation('workflow_page');
  const options = useSelector(workflowOptionsSelector);
  const addEventModal = useModalState();
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const events = clickhouseFields2Events(clickhouseFields.data);
  options.events = events.data;

  const handleGoalsChange = (updater) => {
    onWorkerChange(w => ({ ...w, goals: update(w.goals, updater) }));
  };

  const resolveField = (type, entity) => {
    if (type === SegmentsRuleTypes.EVENT) {
      return (options.events || []).find(by(entity))?.name;
    }

    return entity;
  };

  const getEventId = (event) => {
    return (options.events || []).find(by('name', event))?.id;
  };

  const createFilter = (type, entity) => {
    const query = {
      name: 'root',
      root: true,
      children: [],
      logicalOperator: "and"
    };

    const rule = {
      type: SegmentNodeTypes.QUERY_RULE,
      query: {
        ruleType: type,
        negation: false,
        name: 1,
        value: {
          type: 'scalar',
          value: '',
        },
        field: resolveField(type, entity),
        filters: [],
      },
    };

    if (type === SegmentsRuleTypes.ARRAY || type === SegmentsRuleTypes.EVENT) {
      rule.query.logicalOperator = 'and';
      rule.query.type = 'common';
      if (type === SegmentsRuleTypes.EVENT) {
        rule.query.wasPerformed = true;
      }
    }

    if (type === SegmentsRuleTypes.AGGREGATE) {
      rule.aggregateIds = [entity.id];
    }

    query.children.push(rule);

    return query;
  };

  const handleSelectFilter = (v) => {
    handleGoalsChange(gs => [
      ...(gs || []),
      {
        completion_period: null,
        id: v4(),
        ruleType: 'event',
        type: gs?.length ? gs[0].type : 1,
        event_field: resolveField('event', v.id),
        filter_builder: {
          logicalOperator: 'and',
          filters: [],
        },
      },
    ]);
  };

  const handleRemoveFilter = (index) => () => {
    handleGoalsChange(ta => ta.filter((_, i) => i !== index));
  };

  const handleTrackingMethodChange = (method) => {
    handleGoalsChange(gs => gs.map((g) => ({ ...g, type: method })));
  };

  const resolveFilterValue = (filter) => {
    const eventId = getEventId(filter.event_field);
    const filterBuilder = createFilter('event', eventId);
    filterBuilder.children[0].query = filter.filter_builder;

    return {
      ...filterBuilder.children[0]?.query,
      filter_builder: filterBuilder.children[0].query,
      event_id: eventId,
      completion_period: filter.completion_period,
    };
  };

  const handleFilterChange = (type, index) => (updater) => {
    const { filter_builder, completion_period } = update(resolveFilterValue(worker.goals[index]), updater);

    handleGoalsChange(gs => gs.map((f, i) => i === index ? {
      ...f,
      completion_period,
      filter_builder: {
        logicalOperator: 'and',
        ...filter_builder,
      },
    } : f))
  };

  const controlGroupEnabled = worker.tree_map?.children?.[0]?.children?.[0]?.data?.internalControlGroup;

  const handleControlGroupEnabledChange = (enabled) => {
    let newTreeMap = worker.tree_map;
    let styles = worker.styles?.styles || {};

    if (!enabled) {
      let id = null;

      iterate(newTreeMap, ({ id: nid, data, type, }) => {
        if (type === WorkflowNodes.AB_TEST && data?.internalControlGroup) {
          id = nid;
        }
      });

      if (!id) {
        return;
      }

      newTreeMap = removeChild(newTreeMap, id);
    } else {
      const [node, style, otherStyles] = createNode({
        type: WorkflowNodes.AB_TEST,
        actionType: 'actions',
        children: [],
        data: { internalControlGroup: true, hide: true },
        translate: p,
        abSegments: [{ value: 95, internalControlGroup: true, hide: true }, { value: 5, internalControlGroup: true, is_control_group: true }],
      });

      newTreeMap = insertAfter(newTreeMap, newTreeMap.children[0].id, node);
      styles = {
        ...styles,
        [node.id]: style,
        ...otherStyles,
      }
    }

    onWorkerChange(w => ({
      ...w,
      tree_map: newTreeMap,
      styles: {
        ...w.styles,
        styles,
      }
    }));
  };

  const changeNodePercentageValue = (root, value) => {
    if (root.data?.internalControlGroup && !root.data?.hide) {
      return {
        ...root,
        data: {
          ...root.data,
          value,
        },
        children: (root.children || []).map(child => changeNodePercentageValue(child, value)),
      };
    }

    if (root.data?.internalControlGroup && root.data.hide) {
      return {
        ...root,
        data: {
          ...root.data,
          value: 100 - value,
        },
        children: (root.children || []).map(child => changeNodePercentageValue(child, value)),
      }
    }

    return {
      ...root,
      children: (root.children || []).map(child => changeNodePercentageValue(child, value)),
    }
  };

  const controlGroupPercentage = worker.tree_map?.children?.[0]?.children?.[0]?.children?.[1]?.data?.internalControlGroup ? worker.tree_map?.children?.[0]?.children?.[0]?.children?.[1]?.data?.value : '';
  const handleControlGroupPercentageChange = ({ target: { value } }) => {
    onWorkerChange(w => ({
      ...w,
      tree_map: changeNodePercentageValue(w.tree_map, value),
      styles: {
        ...(w.styles || {}),
        styles: Object.fromEntries(Object.entries(w.styles.styles || {}).map(([id, style]) => [id, style.data?.segments?.length ? {
          ...style,
          data: {
            ...style.data,
            segments: style.data.segments.map(segment => segment.internalControlGroup && !segment.hide ? {
              ...segment,
              value,
            } : {
              ...segment,
              value: 100 - value,
            }),
          },
        } : style]))
      }
    }));
  };

  const controlGroupPercentageError = controlGroupEnabled && (controlGroupPercentage < 1 || controlGroupPercentage > 15);

  const filterEvents = ({ name }) => {
    return ['bonus', 'bet', 'deposit', 'withdrawal', 'freespin'].includes(name);
  };

  return (
    <>
      <ControlGroupSettings
        direction={direction}
        disabled={worker.status !== 0}
        enabled={controlGroupEnabled}
        error={controlGroupPercentageError}
        onEnabledChange={handleControlGroupEnabledChange}
        onPercentageChange={handleControlGroupPercentageChange}
        percentage={controlGroupPercentage}
        trackingMethod={worker.goals?.[0]?.type === undefined ? 1 : worker.goals?.[0]?.type}
        onTrackingMethodChange={handleTrackingMethodChange}
      />
      <Container custom={direction}>
        <Title data-testid="goals-step-title">
          {p('workflow_goals_optional')}
        </Title>
        <AddEventModal
          opened={addEventModal.opened}
          onClose={addEventModal.close}
          filterOptions={filterEvents}
          onSelect={handleSelectFilter}
        />
        <ContentContainer $center={!(worker.goals || []).length}>
          {!worker.goals?.length && worker.status === 0 && (
            <AddEntityButton
              testId={'choose-goal-btn'}
              actionTitle={p('choose_goal')}
              title={p('no_goals_select')}
              containerStyle={{ paddingLeft: 16, width: 530 }}
              onClick={addEventModal.open}
              icon={GoalIcon}
            />
          )}
          {(worker.goals || []).map((filter, index) => (
            <WorkflowFilter
              disabled={worker.status !== 0}
              key={index}
              type={SegmentsRuleTypes.EVENT}
              onDelete={handleRemoveFilter(index)}
              options={options}
              customIcon={GoalIcon}
              errors={errors[index]}
              onClearError={onClearError(index)}
              onChange={handleFilterChange('event', index)}
              value={resolveFilterValue(filter)}
            />
          ))}
        </ContentContainer>
        {(!!worker.goals?.length && ((worker.goals || []).length < 5) && (worker.status === 0 || worker.status === undefined)) && (
          <AddEntityButton
            testId={'goals-choose-event'}
            actionTitle={p('choose_goal')}
            title={p('add_additional_goal')}
            containerStyle={{ paddingLeft: 16, width: 530 }}
            onClick={addEventModal.open}
            icon={GoalIcon}
          />
        )}
        <Actions>
          <Button
            width={200}
            onClick={onComplete}
            disabled={controlGroupPercentageError}
            loading={loading}
            data-testid={'goals-step-next-step-btn'}
          >
            {p('save_proceed')}
          </Button>
        </Actions>
      </Container>
    </>
  );
};

export default GoalsStep;
