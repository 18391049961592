import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { getShortenerDomains, loadTemplatesLinks } from "@store/actions/creators";
import { shortenerDomainsSelector } from '@store/selectors';

import { Accordion, Button, ICONS_NAMES, Icon, SingleSelectField } from '@velitech/ui';
import { Checkbox, Tooltip } from 'antd';

import { useErrors, useTranslation } from "@hooks";

import { by, extract } from '@utils';

import { Modal } from '@components';

import {
  Container,
  ActionsRow,
  LinkStatus,
  LinksContainer,
  LinkRow,
  LinkText,
  IconContainer,
  TrackingOptions,
  TrackingTitle,
  DomainRow,
  LinkExample
} from './styled';

const EmailTemplateLinksModal = ({ opened, onClose, onSave, links, onChange }) => {
  const { p, t } = useTranslation('templates');
  const [validatedLinks, setValidatedLinks] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const errors = useErrors();
  const dispatch = useDispatch();
  const [linkOptions, setLinkOptions] = useState({
    auth: false,
    track: true,
    domain_id: undefined,
  });
  const domains = useSelector(shortenerDomainsSelector);
  const preferredDomain = localStorage.getItem('preferredDomain');

  useEffect(() => {
    if (preferredDomain && !linkOptions.domain_id) {
      setLinkOptions(lo => ({ ...lo, domain_id: +preferredDomain }));
    }
  }, [preferredDomain, linkOptions.domain_id]);

  useEffect(() => {
    if (domains?.data?.length && !linkOptions.domain_id && !preferredDomain) {
      setLinkOptions(lo => ({ ...lo, domain_id: domains.data[0]?.id }));
    }
  }, [domains?.data, preferredDomain]);

  useEffect(() => {
    dispatch(getShortenerDomains());
    dispatch(loadTemplatesLinks({
      links,
      onSuccess: (data) => {
        setValidatedLinks(data.validatedLinks);
      },
      onError: errors.handleRequestError,
    }));
  }, []);

  useEffect(() => {
    const validate = async () => {
      setLoading(true);

      dispatch(loadTemplatesLinks({
        links: links.map(({ url }) => url),
        onSuccess: (data) => {
          const validated = data.validatedLinks;
          const linksMap = validated.reduce((acc, { url, status }) => ({ ...acc, [url]: status }), {});
          setValidatedLinks(links.map((link) => ({ ...link, status: linksMap[link.url] })));
          setError(false);
        },
        onError: (e) => {
          errors.handleRequestError(e);
          setError(true);
        }
      }))

      setLoading(false);
    };

    if (opened && links.length) {
      validate();
    }
  }, [opened]);

  useEffect(() => {
    onChange(ls => ls.map(l => ({ ...l, auth: linkOptions.auth, track: linkOptions.track, domain: linkOptions.domain_id })));
  }, [(links || []).map(extract('url')).join(''), linkOptions]);

  const handleAuthorisationChange = ({ target: { checked } }) => {
    setLinkOptions(lo => ({ ...lo, auth: checked }));
    onChange(ls => ls.map(l => ({ ...l, auth: checked })));
  };

  const handleTrackChange = ({ target: { checked } }) => {
    setLinkOptions(lo => ({ ...lo, track: checked }));
    onChange(ls => ls.map(l => ({ ...l, track: checked })));
  };

  const handleDomainChange = (domain) => {
    setLinkOptions(lo => ({ ...lo, domain_id: domain }));
    onChange(ls => ls.map(l => ({ ...l, domain })));
    localStorage.setItem('preferredDomain', domain);
  }

  const disabled = validatedLinks.length ? validatedLinks.filter(vf => !vf.status && vf.track).length : true;

  const selectedDomain = (domains?.data || []).find(by(linkOptions.domain_id)) || { domain: 'https://shortener.retainly.app' };

  const unsuccessful = links.filter((link) => {
    const status = validatedLinks.find(by('url', link.url))?.status;

    return !status;
  }).length

  return (
    <Modal
      appearance="default"
      title={p('links_checking')}
      opened={opened}
      bigger
      onClose={onClose}
      actions={
        <ActionsRow>
          <Button onClick={onClose} variant="secondary" style={{ width: '140px' }}>
            {t('actions.cancel')}
          </Button>
          <Button onClick={onSave} style={{ width: '140px' }} disabled={isError || disabled}>
            {isLoading ? `${p('validating')}...` : t('actions.save')}
          </Button>
        </ActionsRow>
      }
    >
      <Container>
        <LinksContainer>
          <Accordion
            content={
              <LinksContainer $withoutBorder>
                {links.map((link, index, self) => {
                  const status = validatedLinks.find(by('url', link.url))?.status;

                  return (
                    <LinkRow key={link.url} $isLast={index === self.length - 1}>
                      <LinkText>{link.url}</LinkText>
                      <div>
                        <LinkStatus $isSuccess={status}>
                          <LinkText>{status ? p('successful') : p('unsuccessful')}</LinkText>
                        </LinkStatus>
                      </div>
                      <IconContainer>
                        <Icon name={ICONS_NAMES.CopyContent} />
                      </IconContainer>
                    </LinkRow>
                  )
                })}
              </LinksContainer>
            }
            open={false}
            unmountOnCollapse={true}
          >
            {(onToggle, { open }) => (
              <LinksContainer $withoutBorder>
                <LinkRow $backgroundType={1}>
                  <LinkText $>Link</LinkText>
                  <LinkText>Status</LinkText>
                </LinkRow>
                <LinkRow $backgroundType={open && 2} $isLast={!open}>
                  <LinkText>{links.length} Links</LinkText>
                  <div>
                    <LinkStatus $isSuccess={true}>
                      <LinkText>{links.filter((link) => {
                        const status = validatedLinks.find(by('url', link.url))?.status;

                        return status;
                      }).length} {p('successful')}</LinkText>
                    </LinkStatus>
                    {!!unsuccessful && (<LinkStatus $isSuccess={false}>
                      <LinkText>{unsuccessful} {p('unsuccessful')}</LinkText>
                    </LinkStatus>)}
                  </div>
                  <IconContainer>
                    <Icon inherit onClick={onToggle} name={ICONS_NAMES.ChevronUp} />
                  </IconContainer>
                </LinkRow>
              </LinksContainer>
            )}
          </Accordion>
        </LinksContainer>
        <TrackingOptions>
          <TrackingTitle>
            Select needed options for hanging links
          </TrackingTitle>
          <Checkbox checked={linkOptions.auth} onChange={handleAuthorisationChange}>Link authorisation
            <Tooltip title="Enabling this option adds a magic token to your link, allowing users to automatically log in to your website when redirected">
              <Icon name={Icon.NAMES.Information} />
            </Tooltip>
          </Checkbox>
          <Checkbox checked={linkOptions.track} onChange={handleTrackChange}>Link tracking
            <Tooltip title="Enabling this option modifies your link for the selected domain to enable click tracking and analytics">
              <Icon name={Icon.NAMES.Information} />
            </Tooltip>
          </Checkbox>
          <DomainRow>
            <SingleSelectField
              label="Domain"
              options={[...(domains?.data || []).map(({ name, id }) => ({ value: id, label: name })), { value: ' ', label: 'EDGE domain' }]}
              value={linkOptions.domain_id}
              onChange={handleDomainChange}
              hideResetButton
              disabled={!linkOptions.track}
              disableErrorSpaceReserve
              searchHidden
            />
            <LinkExample>
              <span>Link example</span>
              {selectedDomain.domain}/b83y7ne-lvY
            </LinkExample>
          </DomainRow>
        </TrackingOptions>
      </Container>
    </Modal>
  );
};

export default EmailTemplateLinksModal;
