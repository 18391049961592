import styled from 'styled-components';

export const AnalyticNodeName = styled.p`
  font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;

`

export const AnalyticNodeCount = styled.p`
  font-family: Manrope-SemiBold;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`

export const AnalyticNodeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AnalyticNodeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`