import { IntegrationsActionTypes, UserActionTypes, WorkspacesActionTypes } from '../actions/types';

const initialState = {
  integrations: [],
  categories: [],
  sub_categories: [],
  loading: false,
  my_integrations: {
    loading: false,
    data: [],
  },
  connectionsMeta: {
    data: [],
  },
  jsMeta: {},
  dbRequestPreview: [],
  postgresDatasyncLog: {
    loading: false,
  },
  facebookAudiences: {
    loading: false,
  },
  facebookUserInfo: {
  },
  mySqlDatasyncLog: {
    loading: false,
  },
  shortenerDomains: {
    loading: false,
  }
};

const integrationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case IntegrationsActionTypes.GET_SHORTENER_DOMAINS:
      return {
        ...state,
        shortenerDomains: {
          ...state.shortenerDomains,
          loading: true,
        },
      };
    case IntegrationsActionTypes.GET_SHORTENER_DOMAINS_SUCCESS:
      return {
        ...state,
        shortenerDomains: {
          ...state.shortenerDomains,
          ...action.payload,
          loading: false,
        },
      };
    case IntegrationsActionTypes.GET_SHORTENER_DOMAINS_ERROR:
      return {
        ...state,
        shortenerDomains: {
          ...state.shortenerDomains,
          loading: false,
        },
      };
    case IntegrationsActionTypes.LIST:
      return {
        ...state,
        loading: true,
      };
    case IntegrationsActionTypes.LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case IntegrationsActionTypes.LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    case IntegrationsActionTypes.GET_FACEBOOK_AUDIENCES:
      return {
        ...state,
        facebookAudiences: {
          ...state.facebookAudiences,
          loading: true,
        },
      };
    case IntegrationsActionTypes.GET_FACEBOOK_AUDIENCES_WITH_CLEAR_SUCCESS:
      return {
        ...state,
        facebookAudiences: {
          data: action.payload,
          loading: false,
        },
      };
    case IntegrationsActionTypes.GET_FACEBOOK_AUDIENCES_SUCCESS:
      return {
        ...state,
        facebookAudiences: {
          data: {
            audiences: {
              data: [
                ...state.facebookAudiences.data.audiences.data,
                ...action.payload?.audiences?.data || [],
              ],
              paging: {
                ...action.payload.audiences.paging,
              }
            }
          },
          loading: false,
        },
      };
    case IntegrationsActionTypes.REMOVE_CONNECTION_FROM_STORE:
      const newMyIntegrationsData = state.my_integrations?.data?.filter?.(i => i.id !== action.payload.id) || [];
      return {
        ...state,
        my_integrations: {
          ...state.my_integrations,
          data: [...newMyIntegrationsData],
        }
      }
    case IntegrationsActionTypes.MY_LIST:
      return {
        ...state,
        my_integrations: {
          ...state.my_integrations,
          loading: true,
        },
      };
    case IntegrationsActionTypes.MY_LIST_SUCCESS:
      return {
        ...state,
        my_integrations: {
          data: [...action.payload.data],
          loading: false,
        },
      };

    case IntegrationsActionTypes.UPDATE_DB_INTEGRATION_CONNECTIONS_META_SUCCESS:
      return {
        ...state,
        connectionsMeta: {
          data: action.payload,
        },
      };
    case IntegrationsActionTypes.MY_LIST_ERROR:
      return {
        ...state,
        my_integrations: {
          data: [],
          loading: true,
        },
      };
    case IntegrationsActionTypes.GET_JS_INTEGRATION_META:
      return {
        ...state,
        jsMeta: {
          ...state.jsMeta,
          loading: true,
        },
      };
    case IntegrationsActionTypes.GET_JS_INTEGRATION_META_SUCCESS:
      return {
        ...state,
        jsMeta: {
          ...state.jsMeta,
          ...action.payload,
          loading: false,
        },
      };
    case IntegrationsActionTypes.GET_JS_INTEGRATION_META_ERROR:
      return {
        ...state,
        jsMeta: {
          loading: false,
        },
      };
    case IntegrationsActionTypes.PREVIEW_MYSQL_CONNECTION_SUCCESS:
    case IntegrationsActionTypes.PREVIEW_POSTGRES_CONNECTION_SUCCESS:
      return {
        ...state,
        dbRequestPreview: action.payload,
      };
    case IntegrationsActionTypes.CLEAR_DB_REQUEST_PREVIEW:
      return {
        ...state,
        dbRequestPreview: [],
      };
    case IntegrationsActionTypes.GET_POSTGRES_DATASYNC_LOG:
      if (action.meta.live) {
        return state;
      }

      return {
        ...state,
        postgresDatasyncLog: {
          loading: true,
        },
      };
    case IntegrationsActionTypes.GET_POSTGRES_DATASYNC_LOG_SUCCESS:
      return {
        ...state,
        postgresDatasyncLog: {
          ...action.payload,
          loading: false,
        },
      };
    case IntegrationsActionTypes.GET_POSTGRES_DATASYNC_LOG_ERROR:
      return {
        ...state,
        postgresDatasyncLog: {
          loading: false,
        },
      };
    case IntegrationsActionTypes.GET_MYSQL_DATASYNC_LOG:
      if (action.meta.live) {
        return state;
      }

      return {
        ...state,
        mySqlDatasyncLog: {
          loading: true,
        },
      };
    case IntegrationsActionTypes.GET_MYSQL_DATASYNC_LOG_SUCCESS:
      return {
        ...state,
        mySqlDatasyncLog: {
          ...action.payload,
          loading: false,
        },
      };
    case IntegrationsActionTypes.GET_MYSQL_DATASYNC_LOG_ERROR:
      return {
        ...state,
        mySqlDatasyncLog: {
          loading: false,
        },
      };
    case IntegrationsActionTypes.GET_FACEBOOK_USER_INFO:
      return {
        ...state,
        facebookUserInfo: {
          ...state.facebookUserInfo,
          [action.meta.entity_id]: {
            ...state.facebookUserInfo[action.meta.entity_id],
            loading: true,
          },
        },
      };
    case IntegrationsActionTypes.GET_FACEBOOK_USER_INFO_SUCCESS:
      return {
        ...state,
        facebookUserInfo: {
          ...state.facebookUserInfo,
          [action.meta.entity_id]: {
            ...state.facebookUserInfo[action.meta.entity_id],
            ...action.payload,
            loading: false,
          },
        },
      };
    case IntegrationsActionTypes.GET_FACEBOOK_USER_INFO_ERROR:
      return {
        ...state,
        facebookUserInfo: {
          ...state.facebookUserInfo,
          [action.meta.entity_id]: {
            // ...state.facebookUserInfo[action.meta.entity_id],
            loading: false,
          },
        },
      };
    case IntegrationsActionTypes.LOGOUT_FROM_FACEBOOK_SUCCESS:
      return {
        ...state,
        facebookUserInfo: {
          ...state.facebookUserInfo,
          [action.meta.entity_id]: {
            // ...state.facebookUserInfo[action.meta.entity_id],
            loading: false,
          },
        },
      }
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

export default integrationsReducer;
