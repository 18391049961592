import styled from 'styled-components';

export const Container = styled.div``;

export const StyledInput = styled.input`
  font-family: Manrope-Medium;
  font-size: 30px;
  min-width: 600px;
  background: transparent;
  transition: border-bottom-color 330ms ease;
  
  &::placeholder {
    color: #8B8E9F;
    transition: color 0.28s ease;
  }

  &:focus::placeholder {
    color: transparent;
    transition: color 0.28s ease;
  }

  text-align: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #DCDFE6;
  
  &:focus {
    border-bottom-color: #303133;
  }
  
  &, &:focus, &:active {
    outline: none;
  }
  &:disabled{
    color: #8B8E9F !important;
    cursor: not-allowed;
  }
`;
