import styled from 'styled-components';

export const Container = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: var(--Colors-Additional-White, #FFF);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
`;
