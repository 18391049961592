import styled from 'styled-components';

export const Container = styled.div`
  margin: 12px 0;
  width: 100%;
  display: flex;
  background: var(--border-divider, #D3DBE7);
  height: 1px;
  justify-content: center;
  align-items: center;
  overflow: visible;
`;

export const Label = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  font-feature-settings: 'clig' off, 'liga' off;
  background: #EDF0FF;
  padding: 4px 10px;

  font-family: Manrope-SemiBold;
  font-size: 10px;
  font-style: normal;
  text-transform: uppercase;
`;
