import styled from 'styled-components';

export const SiderStyled = styled.div`
  background: #f4f5fd;
  overflow-y: scroll;
  min-width: 252px;
  max-width: 252px;
  width: 100%;
  padding: 0 30px;

  &::before {
    content: '';
    width: 1px;
    height: 98%;
    position: absolute;
    left: 252px;
    background: #d3dbe7;
  }
`;

export const LayoutStyled = styled.div`
  padding-top: 40px;
  background: #f4f5fd;
  position: relative;
  display: flex;
  height: 634px;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
`;

export const Title = styled.h2`
  color: #1a232e;
  text-align: center;

  font-family: Manrope-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const SubTitle = styled.h4`
  color: #7c94b1;
  text-align: center;
  font-family: Manrope-Semibold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
