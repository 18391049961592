import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { Input, RadioButton, RadioGroup, Switch } from '@components';

import { Container, Description, Header, Info, InputRow, Title, Divider, HeaderTitle, RadioContainer, ConversionMethod } from './styled';

const TrackingMethodNames = {
  SMART: ['smart', 1],
  FUNNEL: ['funnel', 0],
};

const ControlGroupSettings = ({ enabled, direction, onEnabledChange, error, percentage, onPercentageChange, trackingMethod, onTrackingMethodChange, disabled }) => {
  const { p } = useTranslation('workflow_page');

  const resolveTrackingMethodValue = () => {
    return Object.values(TrackingMethodNames).find(([_, method]) => method === trackingMethod)[0];
  };

  const handleTrackingMethodChange = name => {
    onTrackingMethodChange(Object.values(TrackingMethodNames).find(([method]) => method === name)[1]);
  }

  return (
    <Container
      $disabled={disabled}
      custom={direction}
      transition={direction === -1 ? { duration: 0.1, easing: 'ease' } : { duration: 0.27, easing: 'ease', delay: 0.55 }}
    >
      <HeaderTitle>
        {p('additional_settings')}
      </HeaderTitle>
      <Header>
        <Title>
          {p('control_group')}
          <Tooltip title="Players in the control group will not receive any communications; however, their analytics will still be tracked">
            <Info>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.5 11.5V7.5H6.5V8.5H7.5V11.5H6V12.5H10V11.5H8.5Z" fill="#1A232E"/>
                <path d="M8 4.5C7.85167 4.5 7.70666 4.54399 7.58333 4.6264C7.45999 4.70881 7.36386 4.82595 7.30709 4.96299C7.25033 5.10004 7.23547 5.25084 7.26441 5.39632C7.29335 5.54181 7.36478 5.67544 7.46967 5.78033C7.57456 5.88522 7.7082 5.95665 7.85369 5.98559C7.99917 6.01453 8.14997 5.99968 8.28701 5.94291C8.42406 5.88615 8.54119 5.79002 8.6236 5.66668C8.70602 5.54334 8.75 5.39834 8.75 5.25C8.75 5.05109 8.67098 4.86033 8.53033 4.71967C8.38968 4.57902 8.19892 4.5 8 4.5Z" fill="#1A232E"/>
                <path d="M8 15.5C6.61553 15.5 5.26216 15.0895 4.11101 14.3203C2.95987 13.5511 2.06266 12.4579 1.53285 11.1788C1.00303 9.8997 0.86441 8.49224 1.13451 7.13437C1.4046 5.7765 2.07129 4.52922 3.05026 3.55026C4.02922 2.57129 5.2765 1.9046 6.63437 1.63451C7.99224 1.36441 9.3997 1.50303 10.6788 2.03285C11.9579 2.56266 13.0511 3.45987 13.8203 4.61101C14.5895 5.76216 15 7.11553 15 8.5C15 10.3565 14.2625 12.137 12.9497 13.4497C11.637 14.7625 9.85652 15.5 8 15.5ZM8 2.5C6.81332 2.5 5.65328 2.8519 4.66658 3.51119C3.67989 4.17047 2.91085 5.10755 2.45673 6.2039C2.0026 7.30026 1.88378 8.50666 2.11529 9.67054C2.3468 10.8344 2.91825 11.9035 3.75736 12.7426C4.59648 13.5818 5.66558 14.1532 6.82946 14.3847C7.99335 14.6162 9.19975 14.4974 10.2961 14.0433C11.3925 13.5892 12.3295 12.8201 12.9888 11.8334C13.6481 10.8467 14 9.68669 14 8.5C14 6.9087 13.3679 5.38258 12.2426 4.25736C11.1174 3.13214 9.5913 2.5 8 2.5Z" fill="#1A232E"/>
              </svg>
            </Info>
          </Tooltip>
        </Title>
        <Switch large disabled={disabled} testId="control-group-switch-btn" checked={!!enabled} onChange={onEnabledChange} />
      </Header>
      <InputRow>
        <Input
          data-testid="goals-cg-percentage"
          upProps={{ 'data-testid': 'control-group-up-button' }}
          downProps={{ 'data-testid': 'control-group-down-button' }}
          disabled={!enabled || disabled}
          style={{ marginBottom: 0 }}
          // min={1}
          // max={15}
          error={error}
          hideErrorText
          value={percentage}
          onChange={onPercentageChange}
          type="number"
        />
        <Description>
          {p('control_group_description')}
        </Description>
      </InputRow>
      <Divider />
      <RadioGroup disabled={disabled} value={resolveTrackingMethodValue()} onChange={handleTrackingMethodChange}>
        <Header>
          <Title>
            {p('conversion_tracking_method')}
            <Tooltip
              placement="bottom"
              title={(
                <ConversionMethod>
                  <span>Smart conversion tracking</span>  counts an event as a conversion only if the related communication from the workflow is opened or a link is clicked, assigning it to the most recently opened workflow
                  <br/><br/>In contrast, <span>Funnel conversion tracking</span> considers any specified event as a conversion based solely on the Send status, attributing the conversion to the last sent notification, regardless of whether the communication was engaged with.
                </ConversionMethod>
              )}
            >
              <Info>
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.5 11.5V7.5H6.5V8.5H7.5V11.5H6V12.5H10V11.5H8.5Z" fill="#1A232E"/>
                  <path d="M8 4.5C7.85167 4.5 7.70666 4.54399 7.58333 4.6264C7.45999 4.70881 7.36386 4.82595 7.30709 4.96299C7.25033 5.10004 7.23547 5.25084 7.26441 5.39632C7.29335 5.54181 7.36478 5.67544 7.46967 5.78033C7.57456 5.88522 7.7082 5.95665 7.85369 5.98559C7.99917 6.01453 8.14997 5.99968 8.28701 5.94291C8.42406 5.88615 8.54119 5.79002 8.6236 5.66668C8.70602 5.54334 8.75 5.39834 8.75 5.25C8.75 5.05109 8.67098 4.86033 8.53033 4.71967C8.38968 4.57902 8.19892 4.5 8 4.5Z" fill="#1A232E"/>
                  <path d="M8 15.5C6.61553 15.5 5.26216 15.0895 4.11101 14.3203C2.95987 13.5511 2.06266 12.4579 1.53285 11.1788C1.00303 9.8997 0.86441 8.49224 1.13451 7.13437C1.4046 5.7765 2.07129 4.52922 3.05026 3.55026C4.02922 2.57129 5.2765 1.9046 6.63437 1.63451C7.99224 1.36441 9.3997 1.50303 10.6788 2.03285C11.9579 2.56266 13.0511 3.45987 13.8203 4.61101C14.5895 5.76216 15 7.11553 15 8.5C15 10.3565 14.2625 12.137 12.9497 13.4497C11.637 14.7625 9.85652 15.5 8 15.5ZM8 2.5C6.81332 2.5 5.65328 2.8519 4.66658 3.51119C3.67989 4.17047 2.91085 5.10755 2.45673 6.2039C2.0026 7.30026 1.88378 8.50666 2.11529 9.67054C2.3468 10.8344 2.91825 11.9035 3.75736 12.7426C4.59648 13.5818 5.66558 14.1532 6.82946 14.3847C7.99335 14.6162 9.19975 14.4974 10.2961 14.0433C11.3925 13.5892 12.3295 12.8201 12.9888 11.8334C13.6481 10.8467 14 9.68669 14 8.5C14 6.9087 13.3679 5.38258 12.2426 4.25736C11.1174 3.13214 9.5913 2.5 8 2.5Z" fill="#1A232E"/>
                </svg>
              </Info>
            </Tooltip>
          </Title>
        </Header>
        <RadioContainer>
          <RadioButton disabled={disabled} name={TrackingMethodNames.SMART[0]} title="Smart tracking (recommended)" />
          <RadioButton disabled={disabled} name={TrackingMethodNames.FUNNEL[0]} title="Funnel tracking" />
        </RadioContainer>
      </RadioGroup>
    </Container>
  );
};

export default ControlGroupSettings;
