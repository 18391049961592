import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-top: 172px;
`;

export const Title = styled.div`
  text-align: center;

  font-family: Manrope-SemiBold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
  width: 400px;
`;

export const ClaimableOption = styled.div`
    color: var(--controller-cntr-text, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 12px;
    line-height: 18px;
`;

