import styled from 'styled-components';

import { Text } from '@components';

export const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;
  justify-content: center;
  align-items: center;
`;

export const TitleRow = styled(Text)`
  color: #303133;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
