import { useSelector } from 'react-redux';

import { webhookSelector } from '@store/selectors';

import {
  Badge,
  DateRangeField,
  Icon,
  MultiSelectField,
  RowCell
} from '@velitech/ui';
import { Dropdown } from 'antd';
import * as clipboard from 'clipboard-polyfill';

import { useTranslation } from '@hooks';

import { Paths, UserPermissions, WebhookStatuses, } from '@constants';

import {
  notify,
  tryHumanizeResponse,
  generateCurlCommand,
  moment,
  identity
} from '@utils';

import { DateCell, Link, TableLayout, WithPermissions } from '@components';
import { Overlay } from '@components/lib/styled';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';

import { BodyContainer, CopyButton, ButtonContainer } from './styled';

import { TableRowText } from '../../../CustomersScene/pages/FieldSettingsPage/components/AggregateTable/styled';
import TextDetails from '../TextDetails/TextDetails';

const WebhookTable = ({ table, webhooksTemplates, statusOptions, total }) => {
  const webhook = useSelector(webhookSelector);
  const { t, p } = useTranslation('notification_history');

  const getStatusColor = (status) => {
    switch (status) {
      case WebhookStatuses.DELIVERED:
        return Badge.COLORS.POSITIVE
      case WebhookStatuses.SENT:
        return Badge.COLORS.NEUTRAL_500
      case WebhookStatuses.FAILED:
        return Badge.COLORS.NEGATIVE_500
      default:
        return Badge.COLORS.NEUTRAL_500
    }
  };

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const handleCopyBody = (body) => async () => {
    await (new clipboard.writeText(body));
    notify('success', 'Copied', 'Request body');
  }

  const handleCopyCurl = (data) => async () => {

    const curl = generateCurlCommand({
      method: data.method,
      url: data.url,
      headers: data.headers,
      data: JSON.parse(data.body),
    });
    await (new clipboard.writeText(curl));
    notify('success', 'Copied', 'Request cURL');
  }

  const columns = [
    {
      header: {
        label: t('labels.customer_id'),
        className: 'w-25',
      },
      name: 'customer_id',

      render: (data) => (
        <div>
          <FlexibleText text={data.customer_id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link type={'secondary'} to={`${Paths.CONTACT}/${data.customer_id}`}>{data.customer_id}</Link>
            </WithPermissions>
          </FlexibleText>
        </div>
      ),
    },
    {
      header: {
        label: t('labels.url'),
        className: 'w-25',
      },
      name: 'url',
      render: (data) => <FlexibleText text={data.url} maxWidth={130}>{data.url}</FlexibleText>
    },
    {
      header: {
        label: t('labels.body'),
        className: 'w-25',
      },
      name: 'body',
      render: (data) => <WithPermissions name={UserPermissions.REVIEW_API_REQUESTS}><TextDetails text={(
        <BodyContainer>
          <ButtonContainer>
            <CopyButton onClick={handleCopyBody(data.body)}>Copy</CopyButton>
            <CopyButton onClick={handleCopyCurl(data)}>Copy cURL</CopyButton>
          </ButtonContainer>
          <code>
            {tryHumanizeResponse(data)}
          </code>
        </BodyContainer>
      )} /></WithPermissions>,
    },
    {
      header: {
        label: t('labels.date'),
        className: 'w-10',
        sort: true,
      },
      
      name: 'created_at',
      render: (data) => {
        return (
          <TableRowText>
            <DateCell date={data.created_at}/>
          </TableRowText>
        );
      },
    },
    {
      header: {
        label: t('labels.status'),
        className: 'w-10',
      },
      name: 'status',
      render: (data) => {
        return (
          <Badge size={Badge.SIZES.SMALL} variant={Badge.VARIANT.GHOST} color={getStatusColor(data.status)}>
            {data.status.toUpperCase()}
          </Badge>
        );
      },
    },
    // {
    //   header: {
    //     label: t('labels.language_code'),
    //     className: 'w-10',
    //   },
    //   name: 'language_code',
    //   render: (data) => {
    //     const languageItem = languages[data?.language_code] || languages['uk'];
    //     return (
    //       <LanguageItem icon={languageItem.emoji} code={languageItem.languageCode} name={languageItem.languageName} />
    //     );
    //   },
    // },
    {
      header: {
        label: t('labels.response'),
        className: 'w-25',
      },
      name: 'response',
      render: (data) => {
        if (!data.response || data.response === '[]') {
          return null;
        }

        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Dropdown
              trigger={['hover']}
              overlay={(
                <Overlay>
                  <pre>
                    {tryHumanizeResponse(data.response)}
                  </pre>
                </Overlay>
              )}
            >
              <div style={{ padding: 10 }}>
                <Icon size={Icon.SIZES.S16} name={Icon.NAMES.Information} />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ];

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  };

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_API_REQUESTS} hidden>
        <TableLayout
          withTabs
          fullPage
          name="notification_history_webhook"
          loading={webhook.loading}
          columns={columns}
          data={webhook.data || []}
          table={table}
          total={total}
          localizer={tableLocalizer}
          renderFilter={(
            <>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select template'}
                  label={t('labels.template')}
                  options={webhooksTemplates || []}
                  onChange={table.filters.onChange('template_id', identity)}
                  value={ table.filters.filters['template_id']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select status'}
                  label={t('labels.status')}
                  options={statusOptions || []}
                  onChange={table.filters.onChange('status', identity)}
                  value={ table.filters.filters['status']}
                />
              </RowCell>
              {/* <RowCell className='minWidth-250'>
                <SingleSelectDataField
                  disableErrorSpaceReserve
                  className="status-select"
                  placeholder={'Select language code'}
                  label={t('labels.language_code')}
                  options={languageOptions() || []}
                  mapOptionLabel={(data) => <LanguageItem icon={data.emoji} code={data.value} name={data.label} />}
                  mapOptionValue={(data) => data.value}
                  onChange={table.filters.onChange('language_code', identity)}
                  value={ table.filters.filters['language_code']}
                />
              </RowCell> */}
              <RowCell>
                <DateRangeField
                  label={'Date'}
                  disableErrorSpaceReserve
                  dateFormat={'yyyy/MM/dd'}
                  from={{
                    selected: getStartDate(table.filters.filters.start_date),
                    onChange: (date) => handleChangeDate('start_date', date),
                  }}
                  to={{
                    selected: getEndDate(table.filters.filters.end_date),
                    onChange: (date) => handleChangeDate('end_date', date),
                  }}
                />
              </RowCell>
            </>
          )}
        />
      </WithPermissions>
    </>
  )
}

export default WebhookTable;

