import { v4 as uuidv4 } from 'uuid';

export const copyState = (root, styles, newOrigin = false) => {
  const idMap = {};
  let newStyles = {};

  const copyRoot = (root) => {
    const [nodes, nodesStyles] = root.data?.nodes ? copyState(root.data?.nodes, styles, newOrigin) : [undefined, {}];
    const children = root.data?.children ? root.data.children.map(child => copyState(child, styles, newOrigin)[0]) : []

    newStyles = { ...newStyles, ...nodesStyles, ...children.map((_, s) => s).reduce((a, b) => ({ ...a, ...b }), {}) };

    if (idMap[root.id]) {
      return {
        ...root,
        id: idMap[root.id],
        data: {
          ...root.data,
          id: idMap[root.id],
          originId: newOrigin ? idMap[root.id] : root.data?.originId,
          serverId: uuidv4(),
          cloneOf: idMap[root.data?.cloneOf],
          children,
          nodes,
        },
        children: (root.children || []).map(child => copyRoot(child)),
      };
    }

    const newId = uuidv4();
    idMap[root.id] = newId;

    return {
      ...root,
      id: newId,
      data: {
        ...root.data,
        id: newId,
        originId: newOrigin ? idMap[root.id] : root.data?.originId,
        serverId: uuidv4(),
        nodes,
        children,
      },
      children: (root.children || []).map(child => copyRoot(child)),
    };
  };

  const newRoot = copyRoot(root);

  newStyles = {
    ...newStyles,
    ...styles,
    ...Object.fromEntries(
      Object.entries(styles)
        .filter(([, s]) => !!s)
        .map(([id, style]) => [
          idMap[id],
          { ...style, id: idMap[id] }
        ])
    )
  };

  return [newRoot, newStyles];
};
