import { useSelector } from 'react-redux';

import { myIntegrationsSelector } from '@store/selectors';

import { Button } from '@velitech/ui';
import { Tooltip } from 'antd';

import { useNavigate, useTranslation, useHealthCheck } from '@hooks';

import { Paths, WorkflowNodes } from '@constants';
import { TEST_INTEGRATIONS } from '@constants/testIntegrations';

import { by } from '@utils';

import templateIcon from './res/template.svg';
import {
  SelectedContainer,
  TemplateContainer,
  TemplateContainerInner,
  InnerTitle,
  InnerDescription,
  LeftLine,
  InfoContainer,
  IconImg, EditTemplateButton, Row, TestIntegrationDisclaimer,
  WebSDKDisclaimer
} from './styled'

import { TemplateCard } from '../../components/NodeSettingsTemplatesPreview/components/TemplateCard';

const SelectedTemplate = ({ previewType , selectedTemplate, type, handleChooseAnother, onEditTemplate }) => {
  const { p } = useTranslation('workflow_page');
  const { value, label, previewData } = selectedTemplate || {};
  const navigate = useNavigate();
  const { status, getHealthCheckAlert } = useHealthCheck();
  const integrations = useSelector(myIntegrationsSelector);
  const integration = integrations?.data?.find?.(by(selectedTemplate?.app_integration_id))

  const isTestIntegration = TEST_INTEGRATIONS.includes(integration?.integration_key);

  const NodeOptionsMap = {
    [WorkflowNodes.WEB_POPUP]: 'web_popup',
    [WorkflowNodes.SEND_CARD]: 'card',
  };
  const textType = NodeOptionsMap[type];

  const handleSetupIntegrationClick = () => {
    navigate(Paths.INTEGRATIONS);
  };

  return (
    <SelectedContainer>
      <TemplateContainer $previewType={previewType}>
        <Row>
          <LeftLine />
          <TemplateContainerInner>
            <InfoContainer>
              <InnerTitle>
                <IconImg src={templateIcon} />
                {p(`selected-${type}`)}
              </InnerTitle>
              <InnerDescription $previewType={previewType}>
                {p(`selected-${type}-description`)}
              </InnerDescription>
            </InfoContainer>
            <Button data-testid='node-settings-selected-template-change-btn' onClick={handleChooseAnother} style={{ width: '140px' }} variant='secondary'>{p('change_template')}</Button>
          </TemplateContainerInner>
          <div style={{ position: 'relative' }}>
            <TemplateCard
              testId='node-settings-template-card-selected'
              type={type}
              isSelected={true}
              value={value}
              label={label}
              previewData={previewData || {}}
            />
            <Tooltip title="Edit template">
              {onEditTemplate && <EditTemplateButton onClick={onEditTemplate}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M13 11.2857H1V12.1429H13V11.2857Z" fill="white"/>
                  <path
                    d="M11.0286 4C11.3714 3.65714 11.3714 3.14286 11.0286 2.8L9.48571 1.25714C9.14286 0.914286 8.62857 0.914286 8.28571 1.25714L1.85714 7.68571V10.4286H4.6L11.0286 4ZM8.88571 1.85714L10.4286 3.4L9.14286 4.68571L7.6 3.14286L8.88571 1.85714ZM2.71429 9.57143V8.02857L7 3.74286L8.54286 5.28571L4.25714 9.57143H2.71429Z"
                    fill="white"/>
                </svg>
              </EditTemplateButton>}
            </Tooltip>
          </div>
        </Row>
        {!status && textType && <WebSDKDisclaimer>{getHealthCheckAlert(textType, { withMargin: false })}</WebSDKDisclaimer>}
        {isTestIntegration && (
          <TestIntegrationDisclaimer>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.0013 3.8571H9.99876L2.90544 17.4979L2.90663 17.5H17.0934L17.0946 17.4979L10.0013 3.8571ZM9.29688 7.49998H10.7031V13.125H9.29688V7.49998ZM10 16.25C9.81458 16.25 9.63333 16.195 9.47916 16.092C9.32499 15.989 9.20483 15.8426 9.13387 15.6712C9.06291 15.4999 9.04434 15.3114 9.08052 15.1296C9.11669 14.9477 9.20598 14.7807 9.33709 14.6496C9.4682 14.5185 9.63525 14.4292 9.81711 14.393C9.99897 14.3568 10.1875 14.3754 10.3588 14.4463C10.5301 14.5173 10.6765 14.6375 10.7795 14.7916C10.8825 14.9458 10.9375 15.1271 10.9375 15.3125C10.9375 15.5611 10.8387 15.7996 10.6629 15.9754C10.4871 16.1512 10.2486 16.25 10 16.25Z"
                fill="#F79009"/>
              <path
                d="M18.125 18.75H1.875C1.76763 18.75 1.66206 18.7223 1.56848 18.6697C1.4749 18.617 1.39647 18.5411 1.34075 18.4493C1.28502 18.3575 1.25389 18.2529 1.25034 18.1456C1.2468 18.0383 1.27096 17.9319 1.3205 17.8366L9.4455 2.2116C9.49828 2.11012 9.57789 2.02507 9.67566 1.96572C9.77344 1.90636 9.88562 1.87497 10 1.87497C10.1144 1.87497 10.2266 1.90636 10.3243 1.96572C10.4221 2.02507 10.5017 2.11012 10.5545 2.2116L18.6795 17.8366C18.729 17.9319 18.7532 18.0383 18.7497 18.1456C18.7461 18.2529 18.715 18.3575 18.6593 18.4493C18.6035 18.5411 18.5251 18.617 18.4315 18.6697C18.338 18.7223 18.2324 18.75 18.125 18.75ZM2.90663 17.5H17.0934L17.0946 17.4979L10.0013 3.8571H9.99876L2.90544 17.4979L2.90663 17.5Z"
                fill="#F79009"/>
            </svg>
            {p('test_integration_disclaimer')}
            <Button style={{ height: 28, fontSize: 13 }} width={140} outline variant={'secondary'}
              onClick={handleSetupIntegrationClick}>
              {p('setup_integration')}
            </Button>
          </TestIntegrationDisclaimer>
        )}
      </TemplateContainer>
    </SelectedContainer>
  );
};

export default SelectedTemplate;
