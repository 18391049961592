import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { WorkflowActionTypes } from '@store/actions/types';

import { useTranslation, useNavigate } from '@hooks';

import { AppRedirects, Paths } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { by, diffFields, removeAppFromPath, testId, update } from '@utils';

import { Input, Textarea } from '@components';
import { DynamicVariablesSettings } from '@components/lib/WorkflowEditor/components';
import {
  NodeSettingsTemplatesPreview
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/NodeSettingsTemplatesPreview';

import { Container } from './styled';

import { WorkflowEditorContext } from '../../../../../../../contexts';
import { NodeSettingsAccordion } from "../../../NodeSettingsAccordion";

const ApiRequestNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const { t } = useTranslation('workflow_page');
  const api_request = options?.[TemplateTypes.WEBHOOK]?.find?.(by(value.api_request_id));
  const apiOptions = (options?.[TemplateTypes.WEBHOOK] || []).filter((apiRequest) => apiRequest?.status !==3).map(({ id, name, body }) => ({
    value: id,
    label: name,
    previewData: body,
  }));

  const handleTemplateChange = (api_request_id) => {
    onClearError('api_request_id');
    onChange(s => ({ ...s, api_request_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.API_REQUEST_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'api_request_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_API_REQUEST}/new`);
  };

  const handleEditTemplate = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.API_REQUEST_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'api_request_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_API_REQUEST}/${value.api_request_id}`);
  };

  const handleCustomChange = (updater) => {
    onChange(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <NodeSettingsTemplatesPreview
              value={value.api_request_id}
              nodeValue={value}
              onNodeValueChange={onChange}
              onChange={handleTemplateChange}
              onCreateNew={handleCreateNew}
              onClearError={onClearError}
              onEditTemplate={handleEditTemplate}
              type={value.name}
              options={apiOptions}
            />
            <DynamicVariablesSettings
              value={value.dynamic || {}}
              onChange={handleCustomChange}
              variables={api_request?.dynamicVariables || []}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('api-request-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              {...testId('api-request-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default ApiRequestNodeSettings;
