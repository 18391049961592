import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { Icon, Select } from '@components';

import { TopContentContainer, SubTitle, TooltipContainer } from './styled';

export const TopContent = ({
  prepareOptionsForSelect,
  defaultChannel,
  setDefaultchannel,
  error,
}) => {
  const { p } = useTranslation('workflow_page');

  return (
    <TopContentContainer>
      <SubTitle>
        {p('default_channel')}
        <TooltipContainer>
          <Tooltip
            title={p('default_channel_tooltip')}
            placement='right'
          >
            <Icon color={'#1A232E'} name='Info-icon' size={14} />
          </Tooltip>
        </TooltipContainer>
      </SubTitle>
      <Select
        testId={'default-channel'}
        options={prepareOptionsForSelect}
        value={defaultChannel}
        onChange={(_first, _second, data) => setDefaultchannel(data.type)}
        style={{ width: '100%' }}
        errorStyles={{ marginBottom: '0px' }}
        overlayStyles={{ padding: '0' }}
        error={
          error &&
          p(`${error}`)
        }
      />
    </TopContentContainer>
  );
};
