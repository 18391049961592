import { useSelector } from 'react-redux';

import { workflowOptionsSelector } from '@store/selectors';

import uniqBy from 'lodash.uniqby';

import { useTranslation } from '@hooks';

import { by, extract } from '@utils';
import { getExitPoints } from '@utils/workflows/refactored/getters';

import trash from '@res/images/trash-can.svg';

import { CheckboxGroup } from '@components';

import { ExitPointCheckbox } from './components';
import { Container } from './styled';

import {
  DeleteIcon,
  ExitItemDisclaimer,
  WorkflowCard,
  WorkflowCardInnerBottom,
  WorkflowCardInnerTop,
  ExitPoints
} from '../../styled';

const WorkflowPanel = ({ id, exitPoints, onExitPointsChange, onDelete }) => {
  const { p } = useTranslation('workflow_page');
  const options = useSelector(workflowOptionsSelector);
  const workflow = (options.workflows || []).find(by(id));
  const exitPointsOptions = uniqBy(getExitPoints(workflow?.tree_map), extract('id')).map(({ id, data }) => ({
    id,
    label: data.label || id,
  }));
  const workflowOptions = (options.workflows || []).map(({ name, id, version }) => ({
    value: id,
    label: `${name} (${version})`,
  }));

  const selected = workflowOptions.find(by('value', id));

  return (
    <Container>
      <WorkflowCard>
        <WorkflowCardInnerTop>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="12.5" y="3.5" width="7" height="7" rx="0.5" fill="white" stroke="#0C1015"/>
            <path d="M16 11V15.5M16 23V15.5M16 15.5H23C25.2091 15.5 27 17.2909 27 19.5V22M16 15.5H9C6.79086 15.5 5 17.2909 5 19.5V22" stroke="black"/>
            <rect x="12.5" y="21.5" width="7" height="7" rx="3.5" fill="#87E2BA" stroke="#0C1015"/>
            <rect x="23.5" y="21.5" width="7" height="7" rx="3.5" fill="#87E2BA" stroke="#0C1015"/>
            <rect x="1.5" y="21.5" width="7" height="7" rx="3.5" fill="#87E2BA" stroke="#0C1015"/>
          </svg>
          {selected?.label}
          <div onClick={onDelete}>
            <DeleteIcon src={trash} />
          </div>
        </WorkflowCardInnerTop>
        <WorkflowCardInnerBottom>
          <ExitPoints>
            <CheckboxGroup value={exitPoints} onChange={onExitPointsChange}>
              {exitPointsOptions.map(({ id, label }, idx) => (
                <ExitPointCheckbox
                  key={idx}
                  id={id}
                  selected={exitPoints.includes(id)}
                  title={label}
                />
              ))}
            </CheckboxGroup>
          </ExitPoints>
          {!exitPoints.length && (
            <ExitItemDisclaimer>
              {p('you_need_to_choose_at_least_one_exit_point')}
            </ExitItemDisclaimer>
          )}
        </WorkflowCardInnerBottom>
      </WorkflowCard>
    </Container>
  );
};

export default WorkflowPanel;
