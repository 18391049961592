import styled from 'styled-components';

export const Container = styled.div``;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: 60px;
  padding: 7px 0;

  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  max-height: 70vh;
  overflow-y: scroll;
`;

export const Option = styled.div`
  padding: 7px 14px;
  cursor: pointer;
  transition: 280ms ease all;

  &:hover {
    background: rgba(208, 207, 205, 0.43);
  }
`;
