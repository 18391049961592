export const CONTACTS_IMPORT = `Id,First Name,Last name,Birth date,Email,Email verified,Phone,Phone number verified,City,Country,Currency,Language,Registration date,Status,Gender,Created at,Creating date
887274,Anna,Smith,10.12.1980,test@gmail.com,1,12345678,1,Vienna,Austria,EUR,de,18.03.2024,ACTIVE,FEMALE,18.03.2024 10:15:53,18.03.2024
887345,John,Dalin,10.12.1981,test1@gmail.com,1,12345679,1,Washington,USA,USD,en,19.03.2024,ACTIVE,MALE,19.03.2024 10:15:53,19.03.2024
887483,Amily,Linsen,10.12.1982,test2@gmail.com,1,12345680,1,Ottawa,Canada,CAD,en,20.03.2024,BLOCKED,FEMALE,20.03.2024 10:15:53,20.03.2024
887627,Jason,Gilbert,10.12.1983,test3@gmail.com,0,12345681,1,Paris,France,EUR,fr,21.03.2024,INACTIVE,MALE,21.03.2024 10:15:53,21.03.2024
887403,Anna,Heath,10.12.1984,test4@gmail.com,1,12345682,0,Helsinki,Finland,EUR,fi,22.03.2024,SUSPENDED,FEMALE,22.03.2024 10:15:53,22.03.2024
887563,Davie,Osborne,10.12.1985,test5@gmail.com,1,12345683,0,Berlin,Germany,EUR,de,23.03.2024,COOLOFF,MALE,23.03.2024 10:15:53,23.03.2024
887697,Helen,Sharp,10.12.1986,test6@gmail.com,1,12345684,1,Athens,Greece,EUR,el,24.03.2024,ACTIVE,FEMALE,24.03.2024 10:15:53,24.03.2024
887753,Jeremy,Walters,10.12.1987,test7@gmail.com,1,12345685,1,Warsaw,Poland,PLN,pl,25.03.2024,ACTIVE,MALE,25.03.2024 10:15:53,25.03.2024
887807,Jack,Quinn,10.12.1988,test8@gmail.com,0,12345686,1,Kyiv,Ukraine,UAH,uk,26.03.2024,ACTIVE,MALE,26.03.2024 10:15:53,26.03.2024
887879,Alexandra,Gibbs,10.12.1989,test9@gmail.com,1,12345687,0,Rome,Italy,EUR,it,27.03.2024,,FEMALE,27.03.2024 10:15:53,27.03.2024
`;
