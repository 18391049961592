import { useTranslation } from '@hooks';

import { Container, Label } from './styled';

const FiltersDivider = () => {
  const { p } = useTranslation('workflow_page');
  return (
    <Container>
      <Label>
        {p('and')}
      </Label>
    </Container>
  );
};

export default FiltersDivider;
