import { useEffect } from 'react';
import 'dayjs/locale/ka';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { I18nextProvider } from 'react-i18next';
import { Provider as StoreProvider , useSelector } from 'react-redux';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';

import { persistor, store } from '@store';
import { userSelector } from '@store/selectors/user';

import Hotjar from '@hotjar/browser';
import { Theme } from '@velitech/ui';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { useANTDLocale, useCurrentWorkspace, useTranslation } from '@hooks';

import { removeLeadingSlash, resolveAB, setTimezone } from '@utils';

import i18n from '@res/languages';

import { ErrorBoundary } from '@components';

import { Paths } from './constants';
import {
  HomeScene,
  LoginPage,
  ResetPasswordPage,
  ResetPasswordFormPage,
  UnderConstructionPage,
  InviteUserFormPage,
  WhiteRegistrationPage,
  WhiteCreatePasswordPage,
  CreatePasswordPage,
  WhiteEmailConfirmationSuccessPage,
  EmailConfirmationSuccessPage,
  VerifyEmailPage,
  RegistrationPage,
} from './pages';
import AppRedirect from './pages/AppRedirect/AppRedirect';
import { LayoutWithSidebar } from './pages/HomeScene/layouts/LayoutWithSidebar';
import { LoggerService, RioService, WebSocketService, WebSocketContext } from './services';

import "@carbon/styles/css/styles.css";
import "@carbon/charts/styles.css";
import 'reactflow/dist/style.css';

import './index.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const AB_VERSION = resolveAB();

const ABEmailConfirmationSuccessPage = AB_VERSION === 'registration.white' ? WhiteEmailConfirmationSuccessPage : EmailConfirmationSuccessPage;
const ABCreatePasswordPage = AB_VERSION === 'registration.white' ? WhiteCreatePasswordPage : CreatePasswordPage;
const ABRegistrationPage = AB_VERSION === 'registration.white' ? WhiteRegistrationPage : RegistrationPage;

const App = () => {
  const workspace = useCurrentWorkspace();
  const user = useSelector(userSelector);

  const socketService = WebSocketService({
    userId: user?.id,
    workspaceId: workspace?.id
  });
  const { i18n: i18nInstance } = useTranslation('app');
  const locale = useANTDLocale();

  useEffect(() => {
    if(user?.id) {
      socketService.init();
      LoggerService.identifyUser(user?.id);
    }
  }, [user?.id]);
  
  useEffect(() => {
    if (!user?.language || !i18nInstance.isInitialized) {
      return;
    }

    i18nInstance.changeLanguage({ ge: 'ka-GE', en: 'en-US', [undefined]: 'en-US' }[user.language]);
  }, [user?.language, i18nInstance.isInitialized]);

  useEffect(() => {
    // if (user?.email && !user?.email?.includes?.('velitech') && !user?.email?.includes?.('veliedge') && !window.location.href.includes('stage') && process.env.NODE_ENV !== 'development') {
    //   Hotjar.init(5134184, 6);
    // }
  }, [user?.email]);

  useEffect(() => {
    // RioService.subscribe();
  }, []);

  useEffect(() => {
    if (workspace?.timezone) {
      setTimezone(workspace.timezone);
    }
  }, [workspace?.timezone]);

  return (
    <ConfigProvider locale={locale}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <WebSocketContext.Provider value={socketService}>
          <Routes>
            <Route path={removeLeadingSlash(Paths.LOGIN)} element={<LoginPage />} />
            <Route path={removeLeadingSlash(Paths.RESET_PASSWORD)} element={<ResetPasswordPage />} />
            <Route path={removeLeadingSlash(Paths.REGISTRATION)} element={<ABRegistrationPage />} />
            <Route path={removeLeadingSlash(Paths.REGISTRATION_SECOND_STEP)} element={<ABCreatePasswordPage />} />
            <Route path={removeLeadingSlash(Paths.EMAIL_VERIFICATION_SUCCESS_PAGE)} element={<ABEmailConfirmationSuccessPage />} />
            <Route path={removeLeadingSlash(Paths.VERIFY_EMAIL)} element={<VerifyEmailPage />} />
            <Route path={removeLeadingSlash(Paths.RESET_PASSWORD_FORM)} element={<ResetPasswordFormPage />} />
            <Route path={removeLeadingSlash(Paths.INVITE_USER_FORM)} element={<InviteUserFormPage />} />
            <Route path={removeLeadingSlash(Paths.UNDER_CONSTRUCTION)} element={<UnderConstructionPage />} />
            <Route element={<LayoutWithSidebar />}>
              <Route path={Paths.HOME} element={<HomeScene />} />
            </Route>
            <Route path="*" element={<AppRedirect />}/>
          </Routes>
        </WebSocketContext.Provider>
      </QueryParamProvider>
    </ConfigProvider>
  );
};

const ErrorBoundaryHOC = process.env.NODE_ENV === 'development' ? ({ children }) => children : ErrorBoundary;

const Wrapped = ({ ...props }) => {
  return (
    <ErrorBoundaryHOC>
      <I18nextProvider i18n={i18n}>
        <StoreProvider store={store}>
          <PersistGate persistor={persistor}>
            <GoogleReCaptchaProvider reCaptchaKey="6LehNk4gAAAAAAVC23VO81cGT0K8p-OygZjJ5CJO" language="en">
              <Theme mode='light'>
                <App {...props} />
              </Theme>
            </GoogleReCaptchaProvider>
          </PersistGate>
        </StoreProvider>
      </I18nextProvider>
    </ErrorBoundaryHOC>
  );
}

export default Wrapped;
