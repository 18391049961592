import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
`;

export const TextContainer = styled.div`
  padding: 60px 40px 50px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
