import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div).attrs({
  initial: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '-30%' : '30%',
    scale: 0.98
  }),
  animate: { opacity: 1, x: '0%', scale: 1 },
  exit: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '30%' : '-30%',
    scale: 0.98
  }),
  transition: { duration: 0.27, easing: 'ease' },
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin-bottom: 70px;
`;
export const QuiteHoursSettingsContainer = styled.div`
  
`;
export const SettingsTitle = styled.div`
  font-family: 'Manrope-SemiBold';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #7C94B1;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-bottom: 24px;
`;
export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  position: absolute;
  top: 119px;
`;

export const Image = styled.img`
  width: 380px;
  height: 332px;
`;

export const SettingsOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const PickersContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  align-items: center;
`;

export const Actions = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  padding-bottom: 20px;
`;

export const Divider = styled.div`
  width: 12px;
  height: 1px;
  background: #DCDFE6;
  margin: 0 4px;
`;
