import { Container, StyledInput } from './styled';

const Input = ({ ...props }) => {
  return (
    <Container>
      <StyledInput {...props} />
    </Container>
  );
};

export default Input;
