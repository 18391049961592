import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getWorkflowVersionAnalytics } from '@store/actions/creators';
import { workflowAnalyticsSelector } from '@store/selectors';

import { useCurrentWorkspace } from '@hooks';

import { moment } from '@utils';

import { WebSocketContext } from '@services';
let timout = null;
export const useWorkflowAnalytics = ({
  workerStatus,
  workerId,
  range,
  programid,
  start_date
}) => {
  const workspace = useCurrentWorkspace();
  const channel = `dashboard#${workspace?.id}/worker_${workerId}`;
  const dispatch = useDispatch();
  const webSocketService = useContext(WebSocketContext);
  const analytics = useSelector(workflowAnalyticsSelector);
  const [socketAnalytic, setSocketanalytic] = useState([]);

  const minutesAgoFromStart = (startDate) => {
    const date = moment(startDate);
    const now = moment();
    const minutesAgo = now.diff(date, 'minutes');
    return minutesAgo;
  }

  const getAnalytic = () => {
    dispatch(
      getWorkflowVersionAnalytics({
        id: programid,
        withLoader: false,
        workflow_id: workerId,
        range: range,
      })
    );
  };
  
  useEffect(() => {
    if (!range.every((d) => !!d) || !programid || !workerId) {
      return;
    }
    const minuntesAgo = minutesAgoFromStart(start_date);

    if(minuntesAgo >= 2 || workerStatus !== 1) {
      getAnalytic();
    }

  }, [
    range.map((d) => moment.unix(+d)).join(),
    workerStatus,
    programid,
    workerId,
  ]);

  useEffect(() => {
    if(workerStatus !== 1 && analytics?.node?.length > 0) {
      setSocketanalytic([]);
    } 
  }, [analytics, workerStatus]);

  const isBetweenRange = (socketData, rangeDates) => {
    if(socketData?.created_at) {
      return moment(socketData.created_at).isBetween(rangeDates[0].startOf('day'), range[1].endOf('day'));
    }

    return false;
  }

  const handleSocketData = (data) => {
    if (workerId === data.worker_id) {
      setSocketanalytic((prevState) => {
        return [...prevState, { ...data }];
      });
    }
  };

  const requestHistory = (channel, limit = 5000) => {
    webSocketService?.getHistory(channel, limit).then((data) => {
      const history = data.publications
        .filter((pub) => pub.data.worker_id === workerId)
        .map((pub) => {
          return pub.data;
        });
      setSocketanalytic(history);
    });
  };

  useEffect(() => {
    let subscribe = null;

    if (workerStatus === 1) {
      timout && clearTimeout(timout);
      subscribe = webSocketService?.subscribe(channel, handleSocketData);
      
      const minuntesAgo = minutesAgoFromStart(start_date);
      minuntesAgo < 2 && requestHistory(channel);
    }
     
    return () => {

      timout = setTimeout(() => {
        subscribe?.unsubscribe();
      }, 10000)
    }
  }, [workerStatus, channel]);

  const margeAnalytic = (analyticStore, socketAnalytic) => {
    const combinedMap = new Map();

    const updateCombinedMap = (arr) => {
      arr?.forEach((obj) => {
        const nodeId = obj.node_id || obj.node;
        if (combinedMap.has(nodeId)) {
          const combinedObj = combinedMap.get(nodeId);
          combinedObj.in += +obj.in;
          combinedObj.out += +obj.out;
          combinedObj.exit += +obj.exit;
          combinedObj.insufficient += +obj.insufficient;
          combinedObj.unsubscribed += +obj.unsubscribed;

        } else {
          combinedMap.set(nodeId, 
            { 
              ...obj, 
              in: +obj.in,
              out: +obj.out, 
              exit: +obj.exit, 
              insufficient: +obj.insufficient, 
              unsubscribed: +obj.unsubscribed 
            });
        }
      });
    };

    updateCombinedMap(socketAnalytic);
    updateCombinedMap(analyticStore);

    const combinedArray = Array.from(combinedMap.values()).map((node) => ({
      ...node,
      node: node.node_id || node.node
    }));

    return combinedArray;
  };

  const margedAnalytics = useMemo(() => {
    if (workerStatus === 1 && socketAnalytic.length > 0) {
      const socketFilteredByRange = socketAnalytic.filter((socketData) => isBetweenRange(socketData, range));
      return margeAnalytic(analytics?.nodes, socketFilteredByRange);
    }

    if(analytics?.nodes?.length === 0 && socketAnalytic?.length > 0) {
      return margeAnalytic(analytics?.nodes, socketAnalytic);
    }

    return analytics?.nodes;
  }, [analytics?.nodes, socketAnalytic.length, workerStatus, range.map((d) => moment.unix(+d)).join()]);

  return {
    ...analytics,
    nodes: margedAnalytics,
  };
};
