import styled from 'styled-components';

import { Text } from '@components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  max-width: 375px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const TitleRow = styled(Text)`
  display: inline-flex;
  color: #303133;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
`;
