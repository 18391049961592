import { FitViewButton, FlowDirectionSwitch, UndoRedoButtons } from './components';
import { Container, Row } from './styled';

import { ZoomSlider } from '../ZoomSlider';

const ActionsBar = ({
  reactFlowInstance,
  onFitView,
  onUndo,
  onRedo,
  canUndo,
  canRedo,
  directionMode,
  onDirectionModeChange,
}) => {
  const handleFitView = () => {
    onFitView({ duration: 500 });
  };

  return (
    <Container>
      {(!!onUndo && canUndo) && (
        <Row>
          <UndoRedoButtons
            onUndo={onUndo}
            undoDisabled={!canUndo}
            onRedo={onRedo}
            redoDisabled={!canRedo}
          />
        </Row>
      )}
      <Row>
        <ZoomSlider
          vertical
          instance={reactFlowInstance}
        />
        <FitViewButton
          testId={'workflow-actionsbar-fit-view'}
          onClick={handleFitView}
        />
      </Row>
      {!!onDirectionModeChange && (
        <Row>
          <FlowDirectionSwitch
            value={directionMode}
            onChange={onDirectionModeChange}
          />
        </Row>
      )}
    </Container>
  );
};

export default ActionsBar;
