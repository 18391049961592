import { useTranslation } from '@hooks';

import { StyledCreateTemplateCard, Container, Title } from './styled'

const CreateTemplateCard = ({ handleCreateNew, testId }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container data-testid={testId}>
      <StyledCreateTemplateCard onClick={handleCreateNew}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.75 11.25V3H11.25V11.25H3V12.75H11.25V21H12.75V12.75H21V11.25H12.75Z" fill="#1A232E"/>
        </svg>
      </StyledCreateTemplateCard>
      <Title>
        {p('template_title_create_from_scratch')}
      </Title>
    </Container>
  );
}

export default CreateTemplateCard;

