import styled from 'styled-components';

export const Container = styled.div`
  height: 254px;
  max-height: 254px;
  padding-top: 12px;
  overflow-y: auto;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  padding-bottom:  ${props => props.$hasSelected ? '55px' : '0px'};
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  align-items: center;
  background: #fff;
  transition: background 290ms ease;
  cursor: pointer;
  background: ${props => props.$selected ? 'rgba(124, 148, 177, 0.12)' : '#fff'};
  &:hover {
    background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
  }
`;

export const OptionTitle = styled.div`
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;

export const OptionCaption = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;

export const OptionLeft = styled.div`
  display: flex;
  gap: 10px;
`

export const ApplySelected = styled.div`
  width: calc(100% + 10px);
  bottom: 0;
  position: absolute;
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--border-divider, #D3DBE7);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`