import { isValidElement } from 'react';

import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { Icon } from '@components';

import { Select, Option, Title, TitleContainer, Error, Container, LabelRow, InfoIconContainer } from './styled';

const QuerySelect = ({
  options: opt,
  value: val,
  onChange,
  testId,
  error,
  title,
  label,
  highlight = false,
  renderOptions = false,
  footerOption,
  renderRightTitle,
  tooltip,
  tooltipColor,
  smaller = false,
  tooltipError,
  withoutLabel = false,
  showInfoIcon = false,
  noData,
  bordered = true,
  customValue = '',
  errorStyle = {},
  getPopupContainer = () => document.body,
  absoluteError = true,
  containerStyle = {},
  wrapperStyles = {},
  darkLabel,
  onSearch,
  onScroll,
  ...props
}) => {
  const { t, e } = useTranslation();
  const selectTitle = title || label;

  // search select cant take input values correct, if find good option refactor next logic
  const value = (val !== undefined && val !== null && val !== 'undefined' && val !== 'null') ? val.toString() : undefined;
  const options = opt?.map(o => ({ value: o?.value?.toString(), label: isValidElement(o.label) ? o.label : o?.label?.toString() })) || [];

  const handleChange = (v) => {
    const initialValue = opt?.find(o => o.value.toString() === v.toString())?.value;
    onChange(initialValue);
  };

  return (
    <Container data-testid={`${testId}-container`} style={{ ...containerStyle, ...wrapperStyles }}>
      {selectTitle && !withoutLabel && (tooltip ? (
        <Tooltip title={tooltip} placement="topLeft">
          <TitleContainer $darkLabel={darkLabel}>
            <Title $darkLabel={darkLabel}>{selectTitle}</Title>
            {!!renderRightTitle && renderRightTitle}
          </TitleContainer>
        </Tooltip>
      ) : (
        <LabelRow>
          <TitleContainer $darkLabel={darkLabel}>
            <Title $darkLabel={darkLabel}>{selectTitle}</Title>
          </TitleContainer>
          {showInfoIcon && <InfoIconContainer><Icon color={tooltipColor || '#909399'} name="Info-icon" size={10} /></InfoIconContainer>}
          {!!renderRightTitle && renderRightTitle}
        </LabelRow>
      ))}
      <Select
        hasError={!!error || !!tooltipError}
        showSearch
        popupClassName={`${testId}-dropdown`}
        data-testid={`${testId}-select`}
        value={value || customValue || (withoutLabel ? selectTitle : t('actions.select'))}
        getPopupContainer={getPopupContainer}
        defaultActiveFirstOption={false}
        autoComplete="off"
        bordered={bordered}
        highlight={highlight}
        smaller={smaller}
        {...props}
        style={{ ...(props.style || {}), color: (!value && !customValue) ? '#909399' : '#303133' }}
        optionFilterProp="children"
        clearIcon={(
          <Icon size={10} name="Search-icon" color="#909399"/>
        )}
        suffixIcon={(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="Dropdown-arrow" style={{ background: '#fff', display: 'block', padding: '2px', height: 14, width: 14 }} size={10} color="#909399"/>
            {tooltipError && (
              <Tooltip title={e(tooltipError)} placement="bottom">
                <svg style={{ marginLeft: '6px', pointerEvents: 'all' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="7" cy="7" r="7" fill="#C8402F"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.78412 7.79622C7.78412 8.24339 7.4203 8.60721 6.97313 8.60721C6.52596 8.60721 6.16214 8.24339 6.16214 7.79622V4.00002C6.16214 3.55284 6.52596 3.18903 6.97313 3.18903C7.4203 3.18903 7.78412 3.55284 7.78412 4.00002V7.79622ZM7.78213 9.959C7.78213 10.4062 7.41832 10.77 6.97115 10.77C6.52398 10.77 6.16016 10.4062 6.16016 9.959C6.16016 9.51183 6.52398 9.14801 6.97115 9.14801C7.41832 9.14801 7.78213 9.51183 7.78213 9.959Z" fill="white"/>
                </svg>
              </Tooltip>
            )}
          </div>
        )}
        filterOption={false}
        filterSort={false}
        onChange={handleChange}
        onSearch={onSearch}
        dropdownRender={(!options?.length && noData) ? () => <div>{noData}</div> : (footerOption ? menu => (
          <div onScroll={onScroll}>
            {menu}
            {footerOption}
          </div>
        ) : undefined)}
      >
        {options.map(({ label, value }) => (
          <Option key={label} data-testid={`select-value-${value}`} value={value}>{renderOptions ? label : t(label)}</Option>
        ))}
      </Select>
      {error && <Error style={errorStyle} $a={absoluteError}>{e(error)}</Error>}
    </Container>
  );
};

export default QuerySelect;
