import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

export const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  border-top: 1px solid #DCDFE6;
  width: 100%;
  padding: 12px 20px;
  background: #fff;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
`;
