import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { WorkflowActionTypes } from '@store/actions/types';

import { useNavigate } from '@hooks';

import { AppRedirects, Paths } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { by, removeAppFromPath, update, getDefaultLang } from '@utils';

import { DynamicVariablesSettings } from '@components/lib/WorkflowEditor/components';

import { Container, NodeSettingsContent } from './styled';

import { WorkflowEditorContext } from '../../../../../../../contexts';
import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';
import { NodeSettingsTemplatesPreview } from '../NodeSettingsTemplatesPreview';

export const WebPopupNodeSettings = ({
  value,
  onChange,
  options,
  onClearError,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');

  const web_popups = options?.[TemplateTypes.WEB_POPUP]?.find?.(by(value.web_popup_id));
  // eslint-disable-next-line no-unused-vars
  const web_popups_options = (options?.[TemplateTypes.WEB_POPUP] || []).filter((item) => item?.status !== 3).map(({ id, name, data, languages }) => {
    const defaultData = getDefaultLang(languages);
    return {
      value: id,
      label: name,
      previewData: defaultData,
    }
  });

  const handleTemplateChange = (web_popup_id) => {
    onClearError('web_popup_id');
    onChange(s => ({ ...s, web_popup_id }));
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.WEB_POPUP_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'web_popup_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_WEB_POPUP}/new`);
  };

  const handleEditTemplate = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.WEB_POPUP_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'web_popup_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_WEB_POPUP}/${value.web_popup_id}`);
  };

  const handleCustomChange = (updater) => {
    onChange?.(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <NodeSettingsContent>
              <NodeSettingsTemplatesPreview
                value={value.web_popup_id}
                nodeValue={value}
                onChange={handleTemplateChange}
                onNodeValueChange={onChange}
                onCreateNew={handleCreateNew}
                onClearError={onClearError}
                onEditTemplate={handleEditTemplate}
                type={value.name}
                options={web_popups_options || []}
              />
            </NodeSettingsContent>
            <DynamicVariablesSettings
              value={value.dynamic || {}}
              onChange={handleCustomChange}
              variables={web_popups?.dynamicVariables || []}
            />
          </>
        )}
      />
    </Container>
  );
};
