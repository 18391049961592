import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { WorkflowActionTypes } from '@store/actions/types';

import { useTranslation, useNavigate } from '@hooks';

import { AppRedirects, Paths } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { by, diffFields, removeAppFromPath, testId, update, getDefaultLang } from '@utils';

import { WorkflowEditorContext } from '@contexts';

import { Input, Textarea } from '@components';
import { DynamicVariablesSettings, NodeSettingsAccordion } from '@components/lib/WorkflowEditor/components';
import {
  NodeSettingsTemplatesPreview
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/NodeSettingsTemplatesPreview';
import {
  NodeSettingsContent
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/ViberNodeSettings/styled';

import { Container } from './styled.js';

const getDefaultLangData = (languagesTemplates) => {
  const data = getDefaultLang(languagesTemplates)
  return {
    image: data.image_url || data.image?.url,
    pushTitle: data.title,
    pushText: data.message
  }
}

const MobilePushNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;

  const worker = new URLSearchParams(search).get('w');
  const { t } = useTranslation('workflow_page');
  const mobile_push = options?.[TemplateTypes.MOBILE_PUSH]?.find?.(by(value.mobile_push_id));
  const mobilePushOptions = (options?.[TemplateTypes.MOBILE_PUSH] || []).filter((mobile) => mobile?.status !== 3).map(({ id, name, data, languages, app_integration_id }) => ({
    value: id,
    label: name,
    app_integration_id,
    previewData: (languages && getDefaultLangData(languages)) || data,
  }));

  const handleTemplateChange = (mobile_push_id) => {
    onClearError('mobile_push_id');
    onChange(s => ({ ...s, mobile_push_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.MOBILE_PUSH_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'mobile_push_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/new`);
  };

  const handleEditTemplate = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.MOBILE_PUSH_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'mobile_push_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/${value.mobile_push_id}`);
  };

  const handleCustomChange = (updater) => {
    onChange(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <NodeSettingsContent>
              <NodeSettingsTemplatesPreview
                value={value.mobile_push_id}
                nodeValue={value}
                onNodeValueChange={onChange}
                onChange={handleTemplateChange}
                onCreateNew={handleCreateNew}
                onClearError={onClearError}
                onEditTemplate={handleEditTemplate}
                type={value.name}
                options={mobilePushOptions}
              />
            </NodeSettingsContent>
            <DynamicVariablesSettings
              value={value.dynamic || {}}
              onChange={handleCustomChange}
              variables={mobile_push?.dynamicVariables || []}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('mobile-push-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              maxLength={40}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('mobile-push-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              style={{ height: '150px' }}
              wrapperStyles={{ height: '150px' }}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default MobilePushNodeSettings;

