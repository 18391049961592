import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { diffFields, testId } from '@utils';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

import { Input, Multiselect, SearchSelect, Textarea } from '@components';

import { Container } from './styled';

import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';

const DataChangeNodeSettings = ({
  value: outerValue,
  onChange: outerOnChange,
  label,
  description,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const { t, p } = useTranslation('workflow_page');
  const multinode = createMultinodeUpdater(outerValue, outerOnChange, errors, onClearError);
  const value = multinode.values[0];
  const onChange = multinode.changeNode(0);

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const segmentOptions = useSelector(optionsSelector);
  const fieldsOptions = segmentOptions.fields?.attributes?.map(({ label, field }) => ({ value: field, label }));

  const handleFieldsChange = (message_fields) => {
    onChange(s => ({ ...s, meta: message_fields }));
  };

  const handleFieldChange = (field_id) => {
    multinode.clearError(0)('field_id');
    onChange(s => ({ ...s, field_id }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <SearchSelect
              {...testId('data-change-node-settings-required-changes')()}
              highlight={diff['field_id'] === false}
              disabled={!editable}
              style={{ width: '100%', marginBottom: '16px' }}
              value={value.field_id}
              error={multinode.getError(0)('field_id')}
              placeholder={t('labels.select')}
              getPopupContainer={t => t.parentElement.parentElement.parentElement}
              onChange={handleFieldChange}
              options={fieldsOptions || []}
              title={p('changes_in_field')}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('data-change-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={multinode.getError(0)('label')}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              {...testId('data-change-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
        additionalSettings={(
          <Multiselect
            highlight={diff['meta'] === false}
            disabled={!editable}
            title={t('labels.message_fields')}
            getParentElement={t => t.parentElement.parentElement.parentElement.parentElement.parentElement}
            style={{ width: '100%' }}
            options={fieldsOptions}
            value={value.meta || []}
            onChange={handleFieldsChange}
          />
        )}
      />
    </Container>
  );
};

export default DataChangeNodeSettings;
