import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  clearFilterResult,
  getAggregates,
  getSubscriptionGroups,
} from '@store/actions/creators';
import { getClickhouseFields } from '@store/actions/creators/fields';

import { useTranslation, useSystemOptions } from '@hooks';

import { SegmentsRuleTypes } from '@constants';

import { Modal, SliderTabs } from '@components';

import {
  LayoutGroupSplit,
  DeleteGroupModalContent,
  MainContent,
  ExcludeStep,
  AsideContent,
} from './components';
import { useGroupSplit, GroupSplitContext } from './module/useGroupSplit';
import { listAttributeOptions, viewSteps } from './module/utils';

import SegmentFilter from '../../../../../../../pages/HomeScene/pages/WorkflowScene/pages/WorkflowSetupPage/components/SegmentFilter/SegmentFilter';
import { WorkflowFilter } from '../../../../../../../pages/HomeScene/pages/WorkflowScene/pages/WorkflowSetupPage/components/WorkflowFilter';
import { RFMList } from '../DefaultFilterNodeSettings/components/RFMList/RFMList';
import { SelectFilter } from '../DefaultFilterNodeSettings/components/SelectFilter/SelectFilter';
import { List } from '../ui/List';

export const GroupSplitNodeSettings = ({
  value,
  styles,
  onStylesChange,
  errors,
  options,
  onClearError,
}) => {
  const dispatch = useDispatch();
  const { p } = useTranslation('workflow_page');
  const { requestOptions } = useSystemOptions();
  const [openedModal, setOpenedModal] = useState({
    open: false,
    type: null,
    id: null,
  });

  const groupSplitCtx = useGroupSplit({
    value,
    styles,
    onStylesChange,
    errors,
    onClearError,
  });

  useEffect(() => {
    requestOptions();
    dispatch(clearFilterResult());
    dispatch(getClickhouseFields());
    dispatch(getSubscriptionGroups({ entity: 'EMAIL' }));
    dispatch(getAggregates());
  }, []);

  const handleCloseModal = () => {
    setOpenedModal({
      id: null,
      action: null,
      open: false,
    });
  };

  const handleRemoveGroup = () => {
    const id = openedModal.id;
    groupSplitCtx.manageGroup.onRemoveGroup(id, handleCloseModal)
  };

  const getListLabel = (optionItem) => {
    const isSegment = groupSplitCtx.selectedFilterType === 'segment';
    return isSegment ? optionItem.name : optionItem.label;
  };

  const { segment_id, segmentType, parentId } = groupSplitCtx.step === viewSteps.SHOW_SELECTED_SEGMENT && groupSplitCtx.getSelectedGroupData();

  const subTitle = useMemo(() => {
    return groupSplitCtx.manageGroup.subTitleLogic(p)
  }, [groupSplitCtx.selectedGroup?.label]);

  const renderError = (id) => {
    const error = Array.isArray(errors) && errors?.find((error) => !error.valid && error.id === id);

    return error ? p('error_one_filter_is_required') : '';
  }

  return (
    <GroupSplitContext.Provider value={groupSplitCtx}>
      <LayoutGroupSplit
        title={groupSplitCtx.selectedGroup?.label}
        subTitle={subTitle}
        siderContent={<AsideContent renderError={renderError} setOpenedModal={setOpenedModal} />}
        content={
          <MainContent>
            {groupSplitCtx.step === viewSteps.EMPTY && (<></>)}
            {groupSplitCtx.step === viewSteps.INITIAL && (
              <SelectFilter
                testId={'group-split-node-settings'}
                containerStyles={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
                options={listAttributeOptions}
                onChangeType={groupSplitCtx.onChangeFilterType}
              />
            )}
            {groupSplitCtx.step === viewSteps.SHOW_LIST && (
              <>
                {groupSplitCtx.selectedFilterType !==
                  SegmentsRuleTypes.RFM_SEGMENT && (
                  <List
                    testId={'group-split'}
                    title={p(groupSplitCtx.listTitle)}
                    filterType={groupSplitCtx.selectedFilterType}
                    options={groupSplitCtx.listBySelectedType}
                    renderLabel={(optionItem) => getListLabel(optionItem)}
                    onSelect={groupSplitCtx.onSelectAttributeField}
                    onClose={groupSplitCtx.onCloseList}
                    headerComponent={ groupSplitCtx.selectedFilterType === 'segment' && 
                      <div style={{ padding: '0 20px', margin: '14px 0' }}>
                        <SliderTabs
                          style={{ height: '30px' }}
                          tabs={groupSplitCtx.segmentTabs}
                          tabStyle={{ width: '100%', padding: '5px 20px', fontSize: '11px' }}
                          tab={groupSplitCtx.segmentTab}
                          onTabChange={groupSplitCtx.setSegmentTab}
                        />
                      </div>
                    }
                  />
                )}
                {groupSplitCtx.selectedFilterType ===
                  SegmentsRuleTypes.RFM_SEGMENT && (
                  <RFMList
                    testId={'group-split'}
                    title={p(groupSplitCtx.listTitle)}
                    onSelect={groupSplitCtx.onSelectRFMSegment}
                    options={options}
                    onClose={groupSplitCtx.onCloseList}
                    isWorkflow
                  />
                )}
              </>
            )}
            {groupSplitCtx.step === viewSteps.SHOW_SELECTED_FILTER && (
              <div style={{ width: '100%' }}>
                <WorkflowFilter
                  isFilterSplit={true}
                  smaller
                  type={groupSplitCtx.getRuleType()}
                  onDelete={groupSplitCtx.onDeleteSelectedFilter}
                  options={options}
                  errors={groupSplitCtx.getWorkflowError()}
                  errorType={'query'}
                  onClearError={groupSplitCtx.handleClearError}
                  onChange={groupSplitCtx.onFilterChange()}
                  value={groupSplitCtx.getWorkflowValue()}
                />
              </div>
            )}
            {groupSplitCtx.step === viewSteps.SHOW_SELECTED_SEGMENT && (
              <div style={{ alignItems: 'flex-end' }}>
                <div style={{ width: '100%' }}>
                  <SegmentFilter
                    key={segment_id}
                    type={segmentType}
                    id={segment_id}
                    parentId={parentId}
                    onDelete={groupSplitCtx.onDeleteSelectedFilter}
                    getSegmentType={groupSplitCtx.getSegmentType}
                  />
                </div>
              </div>
            )}
            {groupSplitCtx.step === viewSteps.EXCLUDE && <ExcludeStep />}

          </MainContent>
        }
      />
      <Modal
        title={
          openedModal.action === 'remove' ? p('delete_group') : p('rename_group')
        }
        opened={openedModal.open}
        onClose={handleCloseModal}
      >
        {openedModal.action === 'remove' && (
          <DeleteGroupModalContent
            onDeleteGroup={handleRemoveGroup}
            onClose={handleCloseModal}
          />
        )}
      </Modal>
    </GroupSplitContext.Provider>
  );
};
