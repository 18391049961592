import styled from 'styled-components';

export const Container = styled.div`
  height: 254px;
  max-height: 254px;
  padding-top: 12px;
  overflow-y: auto;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  align-items: center;
  background: #fff;
  transition: background 290ms ease;
  cursor: pointer;
  
  &:hover {
    background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
  }
`;

export const OptionTitle = styled.div`
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;

export const OptionCaption = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;
