import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { RFMColorsConfig } from '@constants';

import { by, extract } from '@utils';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

import { Dropdown } from '@components';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const resolveType = type => type?.toLowerCase?.();

const tryParse = monetaryAggregate => {
  try {
    return JSON.parse(monetaryAggregate);
  } catch (e) {
    return monetaryAggregate;
  }
};

const sortOption = (a, b) => a.cfg?.order - b.cfg?.order;

const sortSub = (a, b) => a.rank = b.rank;

const ResourceNodeSettingsInfo = ({ node: outerNode, options }) => {
  const node = { data: createMultinodeUpdater(outerNode.data).values[0] };
  const type = resolveType(node.data?.resource_type);
  const segmentOptions = useSelector(optionsSelector);
  const defaultSegmentOptions = segmentOptions.segments?.map?.(({ id, name }) => ({ value: id, label: name })) || [];
  const { t } = useTranslation();

  const rfmAnalyticsOptions = (options.rfmResources || []).map(({ id, name, resources }) => ({
    value: id,
    label: name,
    options: (resources || [])
      .filter(({ parent_segment_id }) => !parent_segment_id)
      .flatMap(({ segment_id, name, monetary_aggregate }) => [
        {
          value: segment_id,
          label: t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`),
          cfg: RFMColorsConfig.find(by('name', name)),
        },
        ...(resources || [])
          .filter(by('parent_segment_id', segment_id))
          .map(({ segment_id, name: subName }) => {
            const monetaryValues = (tryParse(monetary_aggregate) || []).find(by('monetary_ranks', +subName[subName.length - 1]));

            return {
              value: segment_id,
              label: `${t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`)}.${(+monetaryValues.min).toFixed(2)} - ${(+monetaryValues.max).toFixed(2)} revenue`,
              cfg: RFMColorsConfig.find(by('name', name)),
              rank: +subName[subName.length - 1],
            };
          })
          .sort(sortSub)
      ])
      .sort(sortOption)
  })).flatMap(extract('options'));

  const allSegmentOptions = defaultSegmentOptions.concat(rfmAnalyticsOptions);
  const resources = (node.data?.segment_ids || []).map(id => allSegmentOptions.find(by('value', id)) || {});

  if (!type && !resources.length) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      <Container>
        {resources.map((res) => (
          <Dropdown.Main key={res?.label} title={res?.label}/>
        ))}
      </Container>
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default ResourceNodeSettingsInfo;

