import styled, { css } from 'styled-components';

import { extract, styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 50px;
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${styledProp('$opn', css`
      overflow-y: auto;
      max-height: 2000px;
  `)}
`;

export const OptionTitle = styled.div`
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;

export const OptionCaption = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;

export const OptionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  align-items: center;
  background: #fff;
  transition: background 290ms ease;
  cursor: pointer;

  &:hover {
    background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
  }
`;

export const SubOptions = styled.div`
    transition: max-height 330ms ease;
    max-height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;

    ${styledProp('$opn', css`
        overflow-y: auto;
        height: auto;
        max-height: 2000px;
    `)}
`;

export const SubOption = styled.div`
  padding: 8px 32px;
  transition: background 290ms ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${props => props.$selected ? 'rgba(124, 148, 177, 0.12)' : '#fff'};

  &:hover {
    background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
  }
`;

export const ColorIndicator = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
  background: ${extract('$color')};
  border-radius: 4px;
`;

export const ApplySelected = styled.div`
  width: 100%;
  top: 405px;
  position: absolute;
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--border-divider, #D3DBE7);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`