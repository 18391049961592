import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  getSubscriptionGroups,
  listIntegrations,
  myListIntegrations,
  setNewEmail,
  updateRedirects
} from '@store/actions/creators';
import { getClickhouseFields } from '@store/actions/creators/fields';
import { TemplatesActionTypes } from '@store/actions/types';
import {
  emailTemplateIntegrationsSelector, integrationsSelector,
  subscriptionGroupsSelector
} from '@store/selectors';

import { SingleSelectDataField, InputField } from '@velitech/ui';

import { useModalState, useTranslation, useNavigate } from '@hooks';

import { AppRedirects, Paths } from '@constants';

import { by, sequence } from '@utils';

import { Link, TagAutoComplete } from '@components';

import { Container, NoIntegrations, Row, OpenButton, Title } from './styled';

import {
  Image,
  Option
} from '../../../../../pages/HomeScene/pages/TemplatesScene/pages/CreateSmsTemplatePage/components/SmsMetaForm/styled';

const EmailMetaForm = ({ subject, languageCode, value, disabled, onChange, onChangeSubject, errors }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const state = useModalState(true);
  const allIntegrations = useSelector(integrationsSelector);
  const integrations = useSelector(emailTemplateIntegrationsSelector);
  const { p, t } = useTranslation('templates');

  const sendersOptions = useMemo(() => integrations.map(({ id, name, integration_key }) => {
    const imageUrl = allIntegrations.find(by('key', integration_key))?.image_url;

    return {
      value: id,
      label: name,
      icon: imageUrl

    };
  }), [integrations]);

  const subscriptionGroups = useSelector(subscriptionGroupsSelector);

  useEffect(() => {
    dispatch(listIntegrations());
    dispatch(myListIntegrations());
    dispatch(getSubscriptionGroups({ entity: 'EMAIL ' }));
    dispatch(getClickhouseFields());
  }, []);
  
  useEffect(() => {
    const hasError = Object.keys(errors?.errors).some((key) => errors?.errors[key] !== '');
    if(hasError && !state.opened) {
      state.toggle()
    }
  }, [errors?.errors]);

  const handleSenderChange = (app_integration_id) => {
    errors.clearError('app_integration_id');
    onChange({ ...value, app_integration_id });
  };

  const handleBCCChange = (bcc) => {
    errors.clearError('bcc');
    onChange({ 
      ...value, 
      meta: {
        ...value.meta,
        bcc: bcc
      }
    });
  }

  const handleSubjectChange = ({ target: { value: subject } }) => {
    errors.clearError(`subject-${languageCode}`);
    onChangeSubject(subject);
  };

  const handleEmailGroupChange = (group_id) => {
    errors.clearError('group_id');
    onChange({ ...value, group_id });
  };

  const handleAddIntegration = () => {
    dispatch(setNewEmail({ data: value }));
    dispatch(updateRedirects({
      [AppRedirects.INTEGRATION_CONNECTION_CREATION + '/message_channels_email']: {
        to: `${Paths.TEMPLATES_EMAIL}/${type}/${id}?loadCached`,
        updateAction: TemplatesActionTypes.UPDATE_NEW_EMAIL,
        mapConfig: [['id', 'app_integration_id']],
      }
    }));
    setTimeout(() => {
      navigate(`${Paths.INTEGRATIONS}?name=message_channels_email&type=sub-category`);
    }, 100);
  };

  const groupsOptions = useMemo(() => subscriptionGroups.data?.map(({ name, id }) => ({ value: id, label: name })), [subscriptionGroups]);

  return (
    <Container $opened={state.opened} onClick={!state.opened ? state.toggle : undefined}>
      <OpenButton $opened={state.opened} onClick={sequence(e => { e.stopPropagation() }, state.toggle)}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.3855 9.29245C11.2624 9.35399 11.2009 9.41553 11.0778 9.41553C10.9547 9.41553 10.8317 9.35399 10.7701 9.29245L8.61627 7.20014L7.93935 6.58476L7.26242 7.20014L5.17012 9.29245C5.10858 9.35399 4.9855 9.41553 4.86242 9.41553C4.73935 9.41553 4.61627 9.35399 4.55473 9.29245C4.43166 9.16937 4.37012 9.0463 4.37012 8.92322C4.37012 8.86168 4.43166 8.7386 4.55473 8.61553L8.00089 5.35399L11.3855 8.61553C11.447 8.67707 11.447 8.7386 11.5086 8.80014C11.5086 8.86168 11.5701 8.92322 11.5701 8.98476C11.5701 9.0463 11.5701 9.10783 11.5086 9.16937C11.447 9.16937 11.447 9.23091 11.3855 9.29245Z" fill="#303133"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M8 0.861538C11.9425 0.861538 15.1385 4.05754 15.1385 8C15.1385 11.9425 11.9425 15.1385 8 15.1385C4.05754 15.1385 0.861538 11.9425 0.861538 8C0.861538 4.05754 4.05754 0.861538 8 0.861538ZM8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0Z" fill="#303133"/>
        </svg>
      </OpenButton>
      <Title $opened={state.opened}>
        {p('sending_settings')}
      </Title>
      <Row $opened={state.opened}>
        <div style={{ marginBottom: '16px' }}>
          <SingleSelectDataField
            disabled={disabled}
            scrollToActiveItem={false}
            disableErrorSpaceReserve
            placeholder={'Select sender'}
            label={t('labels.sender')}
            options={sendersOptions || []}
            mapOptionLabel={(data) => (
              <Option>
                <Image $url={data.icon} />
                {data.label}
              </Option>
            )}
            mapOptionValue={(data) => data.value}
            noOptionsPlaceholder={(
              <NoIntegrations>
                {p('no_integrations_found')}
                <Link to="#" onClick={handleAddIntegration}>{p('add_one')}</Link>
              </NoIntegrations>
            )}
            onChange={handleSenderChange}
            value={ value.app_integration_id}
            error={errors?.errors?.['app_integration_id'] || false}
          />
        </div>
        {/*<div style={{ }}>*/}
        {/*    <Multiselect*/}
        {/*      containerStyle={{ marginRight: '21px' }}*/}
        {/*      title={p('send_to')}*/}
        {/*      data-testid="email-meta-receiver"*/}
        {/*      disabled={!!disabled}*/}
        {/*      placement="bottomLeft"*/}
        {/*      getParentElement={trigger => trigger.parentElement}*/}
        {/*      onChange={handleReceiverChange}*/}
        {/*      value={value.email_fields || []}*/}
        {/*      options={receivers ? [*/}
        {/*        ...receivers.filter(({ value }) => ~value.toLowerCase().indexOf('email')),*/}
        {/*        ...receivers.filter(({ value }) => !~value.toLowerCase().indexOf('email')),*/}
        {/*      ] : []}*/}
        {/*    />*/}
        {/*</div>*/}
        <div>
          <TagAutoComplete
            options={[]}
            title={p('bcc')}
            // arrowStyles={{ right: '-5px' }}
            testId="email-bbc-input"
            containerStyle={{ width: 160 }}
            style={{ width: '100%' }}
            disabled={!!disabled}
            error={errors.errors['bcc']}
            value={value?.meta?.bcc || []}
            onChange={handleBCCChange}
          />
        </div>
        <InputField 
          data-testid="email-subject-input"
          disabled={!!disabled}
          label={p('subject')}
          value={subject || ''}
          onChange={handleSubjectChange}
          error={errors.errors[`subject-${languageCode}`]}
        />
        <SingleSelectDataField
          disabled={!!disabled}
          scrollToActiveItem={false}
          disableErrorSpaceReserve
          placeholder={'Select group'}
          label={p('email_group')}
          options={groupsOptions || []}
          mapOptionValue={(data) => data.value}
          mapOptionLabel={(data) => data.label}
          onChange={handleEmailGroupChange}
          value={value.group_id}
          error={errors.errors['group_id']}
        />
      </Row>
    </Container>
  );
};

export default EmailMetaForm;
