import { useTranslation } from '@hooks';

import { testId } from '@utils';

import { Input } from '@components';

import { Container, Row, TitleRow } from './styled';

export const ForDuration = ({
  highlight,
  errors,
  editable,
  onDaysChange,
  onHoursChange,
  onMinutesChange,
  value,
}) => {
  const { t } = useTranslation('workflow_page');

  return (
    <Container>
      <TitleRow>{t('workflow_page.wait_for')}</TitleRow>
      <Row style={{ gap: '10px', maxWidth: '300px' }}>
        <Input
          {...testId('wait-node-settings-select-time-days')()}
          $highlight={highlight}
          error={errors['duration']}
          disabled={!editable}
          type='number'
          style={{ marginBottom: '0' }}
          min={0}
          placeholder={t('labels.days')}
          absoluteError
          onChange={onDaysChange}
          value={value.days}
          errorStyle={{
            position: 'absolute',
            width: 'max-content',
          }}
        />
        <Input
          {...testId('wait-node-settings-select-time-hours')()}
          $highlight={highlight}
          error={errors['duration'] ? ' ' : ''}
          disabled={!editable}
          min={0}
          style={{ marginBottom: '0' }}
          type='number'
          placeholder={t('labels.hours')}
          absoluteError
          onChange={onHoursChange}
          value={value.hours}
        />
        <Input
          {...testId('wait-node-settings-select-time-minutes')()}
          $highlight={highlight}
          disabled={!editable}
          min={0}
          style={{ marginBottom: '0' }}
          type='number'
          error={errors['duration'] ? ' ' : ''}
          placeholder={t('labels.minutes')}
          absoluteError
          onChange={onMinutesChange}
          value={value.minutes}
        />
      </Row>
    </Container>
  );
};
