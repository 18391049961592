import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Modal } from '@components';

import { Container } from './styled';

const CancelCreationModal = ({ opened, onClose, onSave, onDelete, saveLoading, deleteLoading }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Modal
      title={p('cancel_creation')}
      opened={opened}
      onClose={onClose}
      actions={(
        <>
          <Button loading={deleteLoading} onClick={onDelete} variant="secondary" style={{ width: '140px' }}>{p('discard_changes')}</Button>
          <Button loading={saveLoading} style={{ marginLeft: 10, width: '140px' }} onClick={onSave}>{p('save_exit')}</Button>
        </>
      )}
    >
      <Container>
        {p('cancel_creation_hint')}
      </Container>
    </Modal>
  );
};

export default CancelCreationModal;
