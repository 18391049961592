import { useSelector } from 'react-redux';

import {
  optionsSelector,
} from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import {
  resolveAggregateType
} from '@constants';

import { by , decomposeFilters } from '@utils';
import { clickhouseFields2Customer, clickhouseFields2Events } from '@utils/fields';

import { Input, OperatorSelect, SearchSelect , ActionButton } from '@components';
import { InfoIconContainer } from '@components/inputs/Input/styled';
import { AggregateSettings } from "@components/ui/AggregateSettings";

import { Container, Row, Title, Divider, FooterOption, FooterOptionLabel } from './styled';

import { Icon } from '../../../../../../ui';

const AggregateSelect = ({ query, onChange, aggregates, aggregateCreationDisabled = false, addFilter, aggregateSaved, onDeleteFilter, onAggregateChange, showErrors, appearance }) => {
  const { p, t } = useTranslation('segments_page');
  const options = useSelector(optionsSelector);
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data)
  const events = clickhouseFields2Events(clickhouseFields.data);
  const loadedAggregatesOptions = options?.aggregates?.map?.(({ label, name }) => ({ value: name, label }));
  const created = aggregates.find(by('name', query.field));
  const loaded = options?.aggregates?.find?.(by('name', query.field));
  const isLoaded = !!loaded;
  const aggregate = created ? { ...created, filters: decomposeFilters(created.filters) } : (loaded ? { ...loaded, filters: decomposeFilters(loaded.filters) } : {});

  const handleFieldChange = (field) => {
    const aggregate = options?.aggregates?.find(by('name', field));
    onAggregateChange(query.field, aggregate);
    onChange(q => ({ ...q, query: { ...q.query, field, operator: '', value: { value: '', type: 'scalar' }, aggregateId: aggregate.id, errors: { ...(q.query.errors || {}), label: undefined } } }));
  };

  const handleCreateFieldChange = ({ target: { value } }) => {
    onChange(q => ({ ...q, query: { ...q.query, errors: { ...(q.query.errors || {}), label: undefined } } }));
    onAggregateChange(query.field, ({ ...aggregate, label: value }));
  };

  const handleTypeChange = (type) => {
    onChange(q => ({ ...q, query: { ...q.query, type: type === 'common' ? null : type } }));
  };

  const handleValueChange = (value) => {
    onChange(q => ({ ...q, query: { ...q.query, value: { ...q.query.value, value } } }));
  };

  const handleOperatorChange = (operator, type) => {
    onChange(q => ({ ...q, query: { ...q.query, operator, value: { ...q.query.value, value: '' }, type: type === 'common' ? null : type } }));
  };

  const handleCreateNew = () => {
    addFilter(null, 'aggregate');
    onDeleteFilter();
  }

  const handleClearAggregateConditions = () => {
    onChange(q => ({ ...q, query: { ...q.query, value: {}, operator: "" } }));
  }

  return (
    <Container $appearance={appearance}>
      {appearance !== 'column' && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title disabled={isLoaded || aggregateSaved}>
            {p('aggregate_condition')}:
          </Title>
          <Tooltip title={p('segments_filter_description')}>
            <InfoIconContainer style={{ marginBottom: 14, marginLeft: 5, backgroundColor: '#FFFFFF' }}>
              <Icon color="#31C447" name="Info-icon" size={14} />
            </InfoIconContainer>
          </Tooltip>
        </div>
      )}
      <Row $appearance={appearance}>
        {isLoaded ? (
          <SearchSelect
            label={t('labels.aggregate_name')}
            error={query.errors?.label}
            style={{ width: appearance !== 'column' ? 186 : 'auto', marginBottom: appearance === 'column' ? 16 : 0 }}
            title={t('labels.aggregate_name')}
            options={loadedAggregatesOptions}
            value={query.field}
            onChange={handleFieldChange}
            footerOption={aggregateCreationDisabled ? null : (
              <FooterOption onClick={handleCreateNew}>
                <ActionButton size={22} icon="Plus-icon" />
                <FooterOptionLabel>
                  {t('actions.create')}
                </FooterOptionLabel>
              </FooterOption>
            )}
          />
        ) : (
          <Input
            title={t('labels.aggregate_name')}
            wrapperStyles={{ width: 186, maxWidth: 186 }}
            error={query.errors?.label}
            value={aggregate.label}
            maxLength={25}
            onChange={handleCreateFieldChange}
          />
        )}
        <OperatorSelect
          wrapperStyle={{ marginLeft: appearance !== 'column' ? 6 : 0 }}
          style={{ width: appearance !== 'column' ? 186 : 'auto', background: '#F9FBFF' }}
          value={{ ...query, value: query?.value?.value, errors: showErrors && query.errors }}
          type={query.type || resolveAggregateType(aggregate, customerFields, events.data)}
          initialType={query.type || resolveAggregateType(aggregate, customerFields, events.data)}
          labeled
          isAggregate
          appearance={appearance}
          fullWidth={appearance === 'column'}
          onTypeChange={handleTypeChange}
          onValueChange={handleValueChange}
          onOperatorChange={handleOperatorChange}
        />
      </Row>
      {appearance !== 'column' && (
        <>
          <Divider />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title disabled={isLoaded || aggregateSaved}>
              {loadedAggregatesOptions?.find(a => a.value === query.field)?.label || 'Aggregate'} {p('settings')}:
            </Title>
            <Tooltip title={p('aggregate_value_description')}>
              <InfoIconContainer style={{ marginBottom: 14, marginLeft: 5, backgroundColor: '#FFFFFF' }}>
                <Icon color="#31C447" name="Info-icon" size={14} />
              </InfoIconContainer>
            </Tooltip>
          </div>
          <div>
            <AggregateSettings
              isLoaded={isLoaded}
              aggregateSaved={aggregateSaved}
              state={aggregate}
              showErrors={showErrors}
              errors={query.errors}
              onClear={handleClearAggregateConditions}
              setState={(params) => {
                onAggregateChange(query.field, params);
              }}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default AggregateSelect;
