import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 250ms ease;
  border-radius: 0 0 10px 10px;
  padding: 0 20px;
  overflow: scroll;
`;

export const Header = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
  margin-top: 30px;
`;

export const SendingContainer = styled.div`
  border-bottom: 1px solid #DCDFE6;
  padding-bottom: 40px;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  align-content: start;
`;

export const StatusesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
  width: 100%;
  position: relative;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding-bottom: 50px; */
`

export const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 25px 0 30px 0;
`

export const StatusesLegendRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  min-width: 170px;
  gap: 10px;
`;

export const StatusesLegendValue = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  text-align: right;
  color: #303133;
`;

export const PieChartTotalCaption = styled.div`
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #8B8E9F;
`;

export const PieChartTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PieChartTitle = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 28px;
  line-height: 33px;
  color: #303133;
`;

export const StatusesLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const TrendLegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 15px;
  gap: 10px;
  margin-bottom: 30px;
`

export const TabTitle = styled.h4`
  color: var(--text-secondary-body, #7C94B1);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`
export const PieContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
`

export const GoalConvertionRates = styled.div`
  margin-bottom: 20px;
`

export const RatesContainer = styled.div`
  background: #E4F5EC;
  border-radius: 10px;
  border: 1px solid #D3DBE7;
  padding: 20px;
`

export const RateItem = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RateLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`
export const RateRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const GoalName = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 21px;
  color: #1A232E;
  display: inline-flex;
  align-items: center;
  gap: 5px;
`

export const GoalRule = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 14px;
  color: #7C94B1;
`
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: #D3DBE7;
`

export const FiltersContainer = styled.div`
    display: flex;
    width: 216px;
    padding: 10px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 7px;
    border: 1px solid var(--cards-border, #D3DBE7);
    background: #FFF;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
`;

export const NoFilters = styled.div`
    color: var(--text-secondary-body, #7C94B1);
    font-family: Manrope-Medium;
    font-size: 12px;
    line-height: 18px;
`;

export const InfoContainer = styled.div`
    cursor: help;
`;

export const LegendElementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 10px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #D3DBE7;
  }
`

export const TotalClicks = styled.span`
  font-family: Manrope-SemiBold;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #7C94B1;
`

export const TotalAmount = styled.span`
  font-family: Manrope-SemiBold;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: #1A232E;
`

export const Total = styled.span`
  font-family: Manrope-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #1A232E;
`

export const StatuselementsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
`

export const AnalyticsCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 12px;
`

export const AnalyticNodeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`

export const WebPopupContainer = styled.div`
  margin-top: 65px
`