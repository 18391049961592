import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const FooterOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 14px;
  cursor: pointer;
  border-top: 1px solid #DCDFE6;;
`;

export const FooterOptionLabel = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  margin-left: 6px;
`;

