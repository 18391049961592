import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div).attrs({
  initial: { opacity: 0, y: 60, scale: 0.98 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: 60, scale: 0.98 },
  transition: { duration: 0.27, easing: 'ease' }
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  flex: 1;
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;

  font-family: Manrope-SemiBold;
  font-size: 24px;
  line-height: 28px;
  position: absolute;
  top: 119px;
`;

export const Actions = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

export const ActionsTitle = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 40px;
`;

export const CustomerAdditionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 43px;
  border-radius: 10px;
  border: 2px solid var(--colors-additional-white, #FFF);
  background: #F4F5FD;

  box-shadow: 0px 12px 24px 0px rgba(153, 163, 194, 0.30);
  padding: 12px 25px 20px 25px;
  position: relative;
`;

export const ImageContainer = styled.div`
  margin-bottom: 3px;
`;

export const CardTitle = styled.div`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
  transition: color 330ms ease;
  color: var(--text-primary-body, #1A232E);
`;

export const CheckIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
