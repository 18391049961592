import styled from 'styled-components';

export const TopContentContainer = styled.div`
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
`
export const TooltipContainer = styled.p`
    display: flex;
    position: relative;
    top: -5px;
    right: -5px;
    margin-bottom: 0px;
`

export const SubTitle = styled.p`
    display: flex;
    align-items: center;
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 14px;
`
