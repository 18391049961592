import { useTranslation } from '@hooks';

import { SegmentsRuleTypes } from '@constants';

import { by } from '@utils';

import { FiltersDropdown } from '@components/lib/SegmentEditor/components/FiltersDropdown';

import { Container } from './styled';

const AddEventButton = ({ options, onSelect }) => {
  const { p } = useTranslation('workflow_page');

  const mapOptions = ({ label, field, system, payload }) => {
    const e = options.events?.find?.(by('name', label));

    return {
      label: e?.label,
      value: field,
      system,
      payload,
      field,
      name: label
    }
  }

  const customEvents = options.fields?.events?.filter?.(({ system }) => !system)?.map?.(mapOptions) || [];
  const systemEvents = options.fields?.events?.filter?.(({ system }) => !!system)?.map?.(mapOptions) || [];

  const tabs = [
    {
      name: 'system-events',
      description: 'system-events-description',
      options: systemEvents,
      ruleType: SegmentsRuleTypes.EVENT,
    },
    {
      name: 'custom-events',
      description: 'custom-events-description',
      options: customEvents,
      ruleType: SegmentsRuleTypes.EVENT,
    },
  ];

  return (
    <FiltersDropdown
      style={{ width: '100%' }}
      maxWidth={480}
      hiddenTypes={Object.values(SegmentsRuleTypes)}
      customTabs={tabs}
      onSelect={onSelect}
      defaultTab="system-events"
    >
      <Container>
        {p('add_event')}
      </Container>
    </FiltersDropdown>
  );
};

export default AddEventButton;
