import { useTranslation } from '@hooks';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container, Column } from './styled.js';

const ABTestingNodeSettingsInfo = ({ node }) => {
  const segments = node.data?.segments;
  const { t } = useTranslation();

  if (!segments?.length) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      {segments.map(({ value, label }, index) => (
        <Column key={index}>
          <InfoTitle>
            {t('labels.path')} {index + 1}:
          </InfoTitle>
          <InfoText>
            {label} {value} %
          </InfoText>
        </Column>
      ))}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default ABTestingNodeSettingsInfo;

