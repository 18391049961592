import { Container, Option, OptionCaption, OptionTitle } from './styled';

import { ListNoData } from '../ListNoData';

const WorkflowList = ({ options, onSelect, omitValues = [] }) => {
  const handleSelect = (v) => () => {
    onSelect({ id: v });
  };

  return (
    <Container>
      {!options.length && (
        <ListNoData />
      )}
      {options.filter(({ value }) => !omitValues.includes(value)).map(({ value, label, status }, index) => (
        <Option
          key={value}
          onClick={handleSelect(value)}
          data-testid={'entry-from-workflow-trigger-settings-entry-option-' + index}
        >
          <OptionTitle>
            {label}
          </OptionTitle>
          {status && (
            <OptionCaption>
              {status}
            </OptionCaption>
          )}
        </Option>
      ))}
    </Container>
  );
};

export default WorkflowList;
