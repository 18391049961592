import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const WorkflowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px 14px;
  border-radius: 10px;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
`;

export const Workflow = styled.div`
  flex: 0;
`;

export const DraftStatus = styled.div`
  font-size: 13px;
  line-height: 120%;
  font-family: Manrope-Medium;
  color: #909399;
`;
