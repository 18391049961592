import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 9px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding-left: 13px;
  margin-bottom: 16px;
  box-shadow: none !important;
  min-height: 36px;
  background: #FFFFFF;
  height: auto;
  overflow-x: scroll;
  justify-content: space-between;

  width: 100%;    
  min-width: 50px;
  max-width: 100%;
  display: flex;
  
  ${styledProp('$area', css`
    min-height: 90px;
  `)}


  ${styledProp('hasError', `
    margin-bottom: 0px;
    color: #C8402F;
    border-color: #C8402F !important;
  `)}

  ${styledProp('withSuffix', `
    padding-right: 5px;
  `)}

  ${styledProp('withPrefix', `
    padding-left: 6px;
  `)}

  ${styledProp('highlight', `
    border-color: #f3cc50 !important;
    color: #f3cc50 !important;
  `)}

  &:focus-within {
    box-shadow: none !important;
    border-color: #31C447;
  }
`;

export const CopyIcon = styled.div`
    width: 34px;
    max-width: 34px;
    align-self: stretch;
    border-left: 1px solid #D3DBE7;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
