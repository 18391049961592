import { ListContainer, ListItem } from './styled';

export const AddChannelOverlay = ({ options, onClick }) => {
  return (
    <ListContainer>
      {options?.map((option) => (
        <ListItem key={option?.label} data-testid={option.label} onClick={() => onClick(option)}>
          {option.label}
        </ListItem>
      ))}
    </ListContainer>
  );
};
