import { Dropdown } from 'antd';

import {
  Container,
  Title,
  List,
  Item,
  InfoItem,
  ButtonContainer,
  ItemContentWrapper,
  IconContainer,
  Error,
  Divider,
  SubContainer,
  FixedContainer,
} from './styled';

const Main = ({ title, children, renderButton, subContent, topContent }) => {
  return (
    <Container>
      <div>
        {title && <Title>{title}</Title>}
        {topContent && (
          <>
            <SubContainer>{topContent}</SubContainer>
          </>
        )}
        <List>{children}</List>
        <ButtonContainer>{renderButton}</ButtonContainer>
      </div>

      {subContent && (
        <FixedContainer>
          <Divider></Divider>
          <SubContainer>{subContent}</SubContainer>
        </FixedContainer>
      )}
    </Container>
  );
};

const ItemContent = ({ label, onClick, iconRight, iconLeft }) => {
  return (
    <>
      <ItemContentWrapper>
        {iconLeft && <IconContainer>{iconLeft}</IconContainer>}
        {label}
      </ItemContentWrapper>
      <IconContainer onClick={onClick}>{iconRight}</IconContainer>
    </>
  );
};

const ItemListDropdown = ({
  open,
  active,
  highlight,
  renderContent,
  menu,
  onClick,
  testId,
}) => {
  return (
    <div data-testid={testId}>
      <Dropdown open={open} overlay={menu || <></>} trigger={['click']}>
        <Item onClick={onClick} $active={active} $error={highlight}>
          {renderContent}
        </Item>
      </Dropdown>
    </div>
  );
};

const ItemList = ({ testId, onClick, disabled, active, children }) => {
  return (
    <div data-testid={testId}>
      <Item $disabled={disabled} onClick={onClick} $active={active}>
        {children}
      </Item>
    </div>
  );
};

const InfoItemList = ({ error, testId, children }) => {
  return (
    <div data-testid={testId}>
      <InfoItem $error={error}>{children}</InfoItem>
    </div>
  );
};

const ErrorContainer = ({ children }) => {
  return <Error>{children}</Error>;
};

export const AsideTabs = {
  Main,
  ItemList,
  InfoItemList,
  ItemListDropdown,
  ItemContent,
  ErrorContainer,
};
