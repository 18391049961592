import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  
  & > span {
    opacity: 0;
    transition: 280ms ease all;
    margin-left: 6px;
  }
  
  &:hover > span {
    opacity: 1;
  }
`;

export const InputContainer = styled.div`
  padding: 11px 10px;
  display: flex;
  align-items: center;
  background: #fff;
`;
