import NodeActionsContext from './NodeActionsContext';

const NodeActionsProvider = ({ children, onDelete, onCopy, onChangeTrigger, workerStatus, canDelete, canCopy, ...rest }) => {

  return (
    <NodeActionsContext.Provider
      value={{
        onDelete,
        onCopy,
        onChangeTrigger,
        workerStatus,
        canDelete,
        canCopy,
        ...rest
      }}
    >
      {children}
    </NodeActionsContext.Provider>
  );
};

export default NodeActionsProvider;
