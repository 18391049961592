import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Container, Title, ImageContainer, CustomerAdditionCard, CardTitle, CheckIcon, Actions, ActionsTitle } from './styled';

import { BackToTriggerTypeButton } from '../BackToTriggerTypeButton';

const CustomerAdditionTriggerSettings = ({ onDiscard, onSubmit, loading, worker }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container>
      <BackToTriggerTypeButton disabled={worker.status !== 0} testId={'customer-addition'} onClick={onDiscard} />
      <Title data-testid="step-title">{p('customer_trigger_settings_title')}</Title>
      <CustomerAdditionCard>
        <CheckIcon>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="22" height="22" rx="11" fill="#31C447"/>
            <path d="M9.5 15L5 10.5L5.707 9.79297L9.5 13.5855L16.293 6.79297L17 7.49997L9.5 15Z" fill="white"/>
          </svg>
        </CheckIcon>
        <ImageContainer>
          <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_624_28074)">
              <g filter="url(#filter0_di_624_28074)">
                <path fillRule="evenodd" clipRule="evenodd" d="M37.2865 72.6211C39.7521 71.1307 42.3446 69.8377 45.041 68.7594C42.774 66.9791 40.7737 64.9107 39.0839 62.6188C36.8958 59.6509 35.2283 56.3081 34.1765 52.7305C32.3125 46.3902 32.4747 39.6276 34.6406 33.3836C36.8065 27.1395 40.8685 21.7246 46.2613 17.8921C51.6541 14.0595 58.1096 12 64.7297 12C71.3498 12 77.8054 14.0595 83.1982 17.8921C88.591 21.7246 92.6529 27.1395 94.8188 33.3836C96.9847 39.6276 97.147 46.3902 95.2829 52.7305C94.232 56.3051 92.5665 59.6452 90.3811 62.6112C88.6902 64.9062 86.688 66.9771 84.4184 68.7594C87.1215 69.8302 89.7211 71.1165 92.1941 72.6011C95.3441 74.4921 98.2887 76.7046 100.98 79.2026C104.409 82.1126 98.5308 91.4026 93.1312 86.3918C85.3484 79.4474 75.2751 75.6085 64.8359 75.6085C54.3966 75.6085 44.3233 79.4474 36.5405 86.3918C28.7578 93.3362 23.8112 102.899 22.6458 113.254C22.491 114.659 21.7834 115.945 20.6787 116.829C19.574 117.713 18.1627 118.123 16.7552 117.968C15.3477 117.813 14.0594 117.107 13.1736 116.005C12.735 115.459 12.4085 114.832 12.2126 114.16C12.0167 113.488 11.9553 112.784 12.032 112.088C13.0911 102.561 16.7207 93.4987 22.5338 85.8681C26.5888 80.5454 31.6002 76.0584 37.2865 72.6211ZM52.9363 61.4278C56.4271 63.7559 60.5313 64.9986 64.7297 64.9986C70.3597 64.9986 75.759 62.7663 79.74 58.7928C83.7209 54.8193 85.9574 49.4301 85.9574 43.8108C85.9574 39.6202 84.7124 35.5238 82.3799 32.0395C80.0474 28.5552 76.7321 25.8395 72.8532 24.2358C68.9743 22.6322 64.7062 22.2126 60.5884 23.0301C56.4706 23.8477 52.6882 25.8656 49.7195 28.8288C46.7507 31.7919 44.729 35.5672 43.9099 39.6773C43.0908 43.7873 43.5112 48.0474 45.1179 51.919C46.7246 55.7906 49.4454 59.0996 52.9363 61.4278Z" fill="url(#paint0_linear_624_28074)"/>
              </g>
              <g filter="url(#filter1_di_624_28074)">
                <path fillRule="evenodd" clipRule="evenodd" d="M113.019 98.2488H123.776C125.396 98.2488 126.71 99.5623 126.71 101.183C126.71 102.803 125.396 104.116 123.776 104.116H113.019V114.384C113.019 116.005 111.705 117.318 110.085 117.318C108.465 117.318 107.151 116.005 107.151 114.384V104.116H97.3724C95.7521 104.116 94.4387 102.803 94.4387 101.183C94.4387 99.5623 95.7521 98.2488 97.3724 98.2488H107.151V87.4918C107.151 85.8716 108.465 84.5581 110.085 84.5581C111.705 84.5581 113.019 85.8716 113.019 87.4918V98.2488Z" fill="url(#paint1_linear_624_28074)"/>
              </g>
            </g>
            <defs>
              <filter id="filter0_di_624_28074" x="8" y="8" width="106" height="122" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="4" dy="4"/>
                <feGaussianBlur stdDeviation="4"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.392157 0 0 0 0 0.854902 0 0 0 0 0.65098 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_624_28074"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_624_28074" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="3"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_624_28074"/>
              </filter>
              <filter id="filter1_di_624_28074" x="90.4387" y="80.5581" width="48.271" height="48.76" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="4" dy="4"/>
                <feGaussianBlur stdDeviation="4"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.392157 0 0 0 0 0.854902 0 0 0 0 0.65098 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_624_28074"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_624_28074" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_624_28074"/>
              </filter>
              <linearGradient id="paint0_linear_624_28074" x1="64.8084" y1="12" x2="64.8084" y2="118" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6EDEAD"/>
                <stop offset="1" stopColor="#5DD8A2"/>
              </linearGradient>
              <linearGradient id="paint1_linear_624_28074" x1="113.374" y1="84.5581" x2="113.374" y2="117.318" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6EDEAD"/>
                <stop offset="1" stopColor="#5DD8A2"/>
              </linearGradient>
              <clipPath id="clip0_624_28074">
                <rect width="130" height="130" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </ImageContainer>
        <CardTitle>
          {p('setup_trigger_customer')}
        </CardTitle>
      </CustomerAdditionCard>

      <Actions>
        {worker.status === 0 && (
          <ActionsTitle>
            {p('customer_actions_title').split('\n').flatMap((part, index) =>
              index === 0 ? (
                <span key={index}>{part}</span>
              ) : (
                [<br key={index + '-br'} />, <span key={index}>{part}</span>]
              )
            )}
          </ActionsTitle>
        )}

        <Button
          width={130}
          onClick={onSubmit}
          data-testid="customer-addition-trigger-next-button"
          loading={loading}
        >
          {p('next_step')}
        </Button>
      </Actions>
    </Container>
  );
};

export default CustomerAdditionTriggerSettings;
