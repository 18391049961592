import styled, { keyframes } from 'styled-components';

export const Container = styled.div``;

const animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Rect = styled.rect`
  opacity: 0;
  fill: #ECEBEB;
  animation: ${animation} 1000ms forwards;
`;

