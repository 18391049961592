import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border: 1px solid #fff;
  background: #A9A7A7;
  border-radius: 50%;
  transition: border-width 280ms ease, transform 280ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  
  & > * {
    opacity: 0;
    transition: all 280ms ease;
  }

  :hover {
    transform: scale(1.5);
    border-width: 0px;
    & > * {
      opacity: 1;
    }
  }
`;
