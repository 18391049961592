import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Modal } from '@components';

import { Container, Description, Title } from './styled';

const ChangeTriggerModal = ({ opened, onClose, onConfirm }) => {
  const { t, p } = useTranslation("workflow_page");

  return (
    <Modal
      title={p("change_trigger")}
      {...{ opened, onClose }}
      actions={(
        <>
          <Button onClick={onClose} variant="secondary" style={{ width: '140px' }}>{t('actions.cancel')}</Button>
          <Button variant="danger" style={{ marginLeft: 10, width: '140px' }} onClick={onConfirm}>{t('actions.change_trigger')}</Button>
        </>
      )}
    >
      <Container>
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23 2.875C11.9313 2.875 2.875 11.9313 2.875 23C2.875 34.0687 11.9313 43.125 23 43.125C34.0687 43.125 43.125 34.0687 43.125 23C43.125 11.9313 34.0687 2.875 23 2.875ZM21.4187 11.5H24.5813V27.3125H21.4187V11.5V11.5ZM23 35.9375C21.85 35.9375 20.8438 34.9313 20.8438 33.7812C20.8438 32.6312 21.85 31.625 23 31.625C24.15 31.625 25.1562 32.6312 25.1562 33.7812C25.1562 34.9313 24.15 35.9375 23 35.9375Z"
            fill="#F04438"/>
        </svg>
        <Title>
          {p('change_trigger_disclaimer_title')}
        </Title>
        <Description>
          {p('change_trigger_disclaimer_description')}
        </Description>
      </Container>

    </Modal>
  );
};

export default ChangeTriggerModal;
