import { useTranslation } from '@hooks';

import { Container, Label } from './styled';

const CopyModeBadge = ({ onCancel }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container onClick={onCancel} data-testid={'action-cancel-copy'}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M9 1.125C4.6125 1.125 1.125 4.6125 1.125 9C1.125 13.3875 4.6125 16.875 9 16.875C13.3875 16.875 16.875 13.3875 16.875 9C16.875 4.6125 13.3875 1.125 9 1.125ZM9 15.75C5.2875 15.75 2.25 12.7125 2.25 9C2.25 5.2875 5.2875 2.25 9 2.25C12.7125 2.25 15.75 5.2875 15.75 9C15.75 12.7125 12.7125 15.75 9 15.75Z" fill="white"/>
        <path d="M12.0375 12.9375L9 9.9L5.9625 12.9375L5.0625 12.0375L8.1 9L5.0625 5.9625L5.9625 5.0625L9 8.1L12.0375 5.0625L12.9375 5.9625L9.9 9L12.9375 12.0375L12.0375 12.9375Z" fill="white"/>
      </svg>
      <Label>
        {p('cancel_copy')}
      </Label>
    </Container>
  );
};

export default CopyModeBadge;
