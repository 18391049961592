import { useState } from 'react';

import { Button } from '@velitech/ui';
import { Dropdown, Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { WorkflowNodes } from '@constants';

import { AsideTabs , IconSvg } from '@components';

import { AddChannelOverlay } from './components/AddChannelOverlay';
import { SubContent } from './components/SubContent';
import { TopContent } from './components/TopContent';
import { StarIconSvg } from './icons';
import {
  ListWrapper,
  SubTitle,
  IconContainer,
} from './styled';

const getOptions = (p) => [
  {
    type: WorkflowNodes.SEND_EMAIL,
    label: p(WorkflowNodes.SEND_EMAIL),
  },
  {
    type: WorkflowNodes.SEND_SMS,
    label: p(WorkflowNodes.SEND_SMS),
  },
  {
    type: WorkflowNodes.VIBER,
    label: p(WorkflowNodes.VIBER),
  },
  {
    type: WorkflowNodes.MOBILE_PUSH,
    label: p(WorkflowNodes.MOBILE_PUSH),
  },
  {
    type: WorkflowNodes.WEBPUSH,
    label: p(WorkflowNodes.WEBPUSH),
  },
  {
    type: WorkflowNodes.SEND_CARD,
    label: p(WorkflowNodes.SEND_CARD),
  },
];

export const AsideContent = ({
  enabledAiBestTime,
  handleChangeEnabledIdBestTime,
  currentChannel,
  handleSelectChannel,
  selectedChannels,
  onAddChannel,
  defaultChannel,
  setDefaultchannel,
  onRemoveChannel,
  errors,
  renderChannelError,
}) => {
  const [open, setOpen] = useState(false);
  const { p, t } = useTranslation('sidebar');
  const options = getOptions(p);

  const filteredOptions = options.filter(({ type }) => {
    if (selectedChannels.length === 0) {
      return true;
    }
    const typeIsSelected = selectedChannels.some(
      (channel) => channel.type === type
    );
    return !typeIsSelected;
  });

  const handleAddChannel = (option) => {
    setOpen(false);
    onAddChannel(option);
  };

  const handleRemoveChannel = (channel, event) => {
    event.stopPropagation();
    onRemoveChannel(channel);
  };

  const prepareOptionsForSelect = selectedChannels.map((channel) => {
    return {
      ...channel,
      value: channel.type,
      label: channel.data?.label,
    };
  });

  const defaultchannelError =
    Array.isArray(errors) &&
    errors?.find((error) => !error.valid && error.errors?.default_channel);

  return (
    <AsideTabs.Main
      title={t('workflow_page.channels')}
      renderButton={
        <Dropdown
          open={open}
          placement='bottom'
          dropdownRender={() => (
            <AddChannelOverlay
              options={filteredOptions}
              onClick={handleAddChannel}
            />
          )}
        >
          <Tooltip
            trigger={'hover'}
            title={
              filteredOptions.length === 0
                ? t('workflow_page.no_channels_left_to_add')
                : ''
            }
            placement='right'
          >
            <div style={{ marginBottom: '20px' }}>
              <Button
                data-testid={'btn-add-channel'}
                disabled={!filteredOptions.length}
                variant='secondary'
                onClick={() => setOpen(!open)}
                style={{ width: '100%' }}
              >
                {t('workflow_page.add_channel')}
              </Button>
            </div>
          </Tooltip>
        </Dropdown>
      }
      topContent={
        <TopContent
          prepareOptionsForSelect={prepareOptionsForSelect}
          defaultChannel={defaultChannel}
          setDefaultchannel={setDefaultchannel}
          error={
            defaultchannelError?.errors?.default_channel }
        />
      }
      subContent={
        <SubContent
          enabledAiBestTime={enabledAiBestTime}
          handleChangeEnabledIdBestTime={handleChangeEnabledIdBestTime}
          currentChannel={currentChannel}
          handleSelectChannel={handleSelectChannel}
        />
      }
    >
      <>
        <SubTitle>{t('workflow_page.added_channels')}</SubTitle>
        <ListWrapper>
          {selectedChannels.map((channel, index) => {
            const error = renderChannelError(index, channel.type);
            return (
              <>
                <AsideTabs.ItemList
                  onClick={() => handleSelectChannel(channel.type)}
                  active={currentChannel === channel.type}
                >
                  <AsideTabs.ItemContent
                    label={channel.data?.label}
                    onClick={(event) => handleRemoveChannel(channel, event)}
                    iconLeft={
                      channel.type === defaultChannel && (
                        <Tooltip
                          title={t('workflow_page.channel_selected_as_default')}
                          placement='right'
                        >
                          <div>
                            <StarIconSvg />
                          </div>
                        </Tooltip>
                      )
                    }
                    iconRight={
                      <IconContainer>
                        <IconSvg
                          name='Delete-icon'
                          color={'#7C94B1'}
                          size={16}
                        />
                      </IconContainer>
                    }
                  />
                </AsideTabs.ItemList>
                {!!error && (
                  <AsideTabs.ErrorContainer>{error}</AsideTabs.ErrorContainer>
                )}
              </>
            );
          })}
          {selectedChannels.length <= 1 && (
            <AsideTabs.InfoItemList error={Object.entries(errors)?.length > 0}>
              {t('workflow_page.need_to_configure_at_least_two_channels')}
            </AsideTabs.InfoItemList>
          )}
        </ListWrapper>
      </>
    </AsideTabs.Main>
  );
};
