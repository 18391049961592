import { UserPermissions, WorkflowNodes } from '@constants';

import { WithPermissions } from '@components';
import {
  EmailNodeSettings,
  PageViewNodeSettings
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components';
import {
  AnotherWorkflowNodeSettings
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/AnotherWorkflowNodeSettings';
import { ApiRequestNodeSettings } from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/ApiRequestNodeSettings';
import CustomerChangeNodeSettings
  from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/CustomerChangeNodeSettings/CustomerChangeNodeSettings';
import {
  GiveRewardNodeSettings
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/GiveRewardNodeSettings';
import {
  IncludeCampaignNodeSettings
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/IncludeCampaignNodeSettings';
import { MobilePushNodeSettings } from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/MobilePushNodeSettings';
import {
  RetargetingNodeSettings
} from "@components/lib/WorkflowEditor/components/NodeSettingsModal/components/RetargetingNodeSettings";
import { WebpushNodeSettings } from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/WebpushNodeSettings';

import { ABTestingNodeSettings } from '../ABTestingNodeSettings';
import { BestChannelToSendSettings } from '../BestChannelToSendSettings';
import { DataChangeNodeSettings } from '../DataChangeNodeSettings';
import { DefaultFilterNodeSettings } from '../DefaultFilterNodeSettings';
import { EventNodeSettings } from '../EventNodeSettings';
import { GroupSplitNodeSettings } from "../GroupSplitNodeSettings";
import { NewContactNodeSettings } from '../NewContactNodeSettings';
import { PushDiscountNodeSettings } from '../PushDiscoundNodeSettings';
import { ResourceNodeSettings } from '../ResourceNodeSettings';
import { SendCardNodeSettings } from '../SendCardNodeSettings';
import { SendEmailNodeSettings } from '../SendEmailNodeSettings';
import { SendSmsNodeSettings } from '../SendSmsNodeSettings';
import { TeamMessageNodeSettings } from '../TeamMessageNodeSettings';
import { ViberNodeSettings } from '../ViberNodeSettings';
import { WaitNodeSettings } from '../WaitNodeSettings';
import { WebPopupNodeSettings } from '../WebPopupNodeSettings';

const NodeSettings = ({ type, ...props }) => {
  switch (type) {
    case WorkflowNodes.RESOURCE:
      return <ResourceNodeSettings {...props} />;
    case WorkflowNodes.AB_SPLITTER:
      return <ABTestingNodeSettings {...props} />;
    case WorkflowNodes.RETARGETING:
      return <RetargetingNodeSettings {...props} />;
    case WorkflowNodes.WAIT:
      return <WaitNodeSettings {...props} />;
    case WorkflowNodes.PUSH_DISCOUNT:
      return <WithPermissions name={UserPermissions.REVIEW_DISCOUNT_NODES} hidden><PushDiscountNodeSettings {...props} /></WithPermissions>;
    case WorkflowNodes.EVENT:
      return <EventNodeSettings {...props} />;
    case WorkflowNodes.SWITCH_FILTER:
      return <GroupSplitNodeSettings {...props} />;
    case WorkflowNodes.EXCLUDE_FILTER:
    case WorkflowNodes.QUICK_FILTER:
      return <DefaultFilterNodeSettings {...props} />;
    // case WorkflowNodes.MOBILE_PUSH:
    //   return <MobilePu></MobilePu>
    case WorkflowNodes.SEND_EMAIL:
      return <WithPermissions name={UserPermissions.REVIEW_EMAIL_NODES} hidden><SendEmailNodeSettings type={type} {...props} /></WithPermissions>;
    case WorkflowNodes.SEND_SMS:
      return <WithPermissions name={UserPermissions.REVIEW_SMS_NODES} hidden><SendSmsNodeSettings type={type} {...props} /></WithPermissions>;
    case WorkflowNodes.SEND_CARD:
      return <WithPermissions name={UserPermissions.REVIEW_SMS_NODES} hidden><SendCardNodeSettings type={type} {...props} /></WithPermissions>;
    case WorkflowNodes.API_REQUEST:
      return <WithPermissions name={UserPermissions.REVIEW_API_REQUEST_NODES} hidden><ApiRequestNodeSettings {...props} /></WithPermissions>;
    case WorkflowNodes.WEBPUSH:
      return <WithPermissions name={UserPermissions.REVIEW_WEBPUSH_NODES} hidden><WebpushNodeSettings {...props} /></WithPermissions>;
    case WorkflowNodes.MOBILE_PUSH:
      return <WithPermissions name={UserPermissions.REVIEW_WEBPUSH_NODES} hidden><MobilePushNodeSettings {...props} /></WithPermissions>;
    case WorkflowNodes.VIBER:
      return <WithPermissions name={UserPermissions.REVIEW_VIBER_NODES} hidden><ViberNodeSettings type={type} {...props} /></WithPermissions>;
    case WorkflowNodes.ENTRY_ANOTHER_WF:
      return <AnotherWorkflowNodeSettings {...props} />;
    case WorkflowNodes.CONTACT_CREATED:
      return <NewContactNodeSettings {...props} />;
    case WorkflowNodes.DATA_CHANGE:
      return <DataChangeNodeSettings {...props} />;
    case WorkflowNodes.EMAIL:
      return <EmailNodeSettings {...props} />;
    case WorkflowNodes.PAGE_VIEW:
      return <PageViewNodeSettings {...props} />;
    case WorkflowNodes.CUSTOMER_CHANGE:
      return <CustomerChangeNodeSettings {...props} />
    case WorkflowNodes.INCLUDE_CAMPAIGN:
      return <IncludeCampaignNodeSettings {...props} />
    case WorkflowNodes.GIVE_REWARD:
      return <GiveRewardNodeSettings {...props} />;
    case WorkflowNodes.BEST_CHANNEL_TO_SEND:
      return <BestChannelToSendSettings {...props} />;
    case WorkflowNodes.TEAM_MESSAGE:
      return <TeamMessageNodeSettings {...props} />
    case WorkflowNodes.WEB_POPUP:
      return <WebPopupNodeSettings {...props} />
    default:
      return null;
  }
};

export default NodeSettings;
