import { useNavigate, useTranslation } from '@hooks';

import { Button } from '@components';

import { Container, Title, StepsContainer, StepsIndicator, StepContainer, StepTitle } from './styled';

import { DiscardButton } from '../DiscardButton';

const Header = ({ steps, step, onStepChange, completedUntilStep, onDiscard, saved, onSaveChanges, worker, program }) => {
  const { p } = useTranslation('workflow_page');
  const navigate = useNavigate();

  const isStepCompleted = (s) => steps.indexOf(s) < completedUntilStep;
  const isStepDisabled = (s) => completedUntilStep < steps.indexOf(s);

  const isStepCurrent = (s) => s === step;

  const resolveStepLabel = (s) => {
    if (isStepCompleted(s)) {
      return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.278 2.4223C12.8808 2.04692 12.2579 2.05262 11.8677 2.4352L4.56114 9.59802L2.21905 7.30503C1.83157 6.92567 1.21299 6.9222 0.82128 7.29719C0.404848 7.69585 0.408522 8.3624 0.829323 8.75644L3.86979 11.6036C4.25753 11.9667 4.86149 11.9632 5.24508 11.5958L13.283 3.89564C13.7028 3.49342 13.7006 2.82167 13.278 2.4223Z" fill="white"/>
        </svg>
      );
    }

    return steps.indexOf(s) + 1;
  };

  const handleStepClick = (s) => () => {
    if (isStepDisabled(s)) {
      return;
    }

    onStepChange(s);
  };

  const onBackToAnalytics = () => {
    navigate(`/workflows/edit/${program.id}/editor?w=${worker.id}`);
  }

  const controlGroupEnabled = worker.tree_map?.children?.[0]?.children?.[0]?.data?.internalControlGroup;
  const controlGroupPercentage = worker.tree_map?.children?.[0]?.children?.[0]?.children?.[1]?.data?.value;
  const controlGroupPercentageError = controlGroupEnabled && (controlGroupPercentage < 1 || controlGroupPercentage > 15);
  const quietHoursError = worker.quite_hours && (!worker.quite_hours.start || !worker.quite_hours.end) && !Array.isArray(worker.quite_hours);

  return (
    <Container>
      <Title>{worker.status === 0 ? p('setup_header_title') : p('view_header_title')}</Title>
      <StepsContainer>
        <StepsIndicator>
          {steps.map((s, index) => (
            <StepContainer
              key={index}
              data-testid={'workflow-header-step-' + s + '-' + (isStepCurrent(s) ? 'current' : isStepCompleted(s) ? 'completed' : 'disabled')}
              onClick={handleStepClick(s)}
              $disabled={isStepDisabled(s) || isStepCurrent(s)}
              $current={isStepCurrent(s)}
              $completed={isStepCompleted(s)}
            >
              {resolveStepLabel(s)}
              <StepTitle $darker={isStepCurrent(s) || isStepCompleted(s)}>
                {p(`setup_steps_${s}`)}
              </StepTitle>
            </StepContainer>
          ))}
        </StepsIndicator>
      </StepsContainer>
      {worker.status === 0 || worker.status === undefined ? (
        <>
          {saved ? (
            <Button data-testid={'workflow-setup-save-settings-btn'} onClick={onSaveChanges} disabled={controlGroupPercentageError || quietHoursError}>
              {p('save_settings')}
            </Button>
          ) : (
            <DiscardButton data-testid={'workflow-setup-discard-btn'} onClick={onDiscard} />
          )}
        </>
      ) : (
        <Button data-testid={'workflow-setup-back-to-analytics-btn'} onClick={onBackToAnalytics}>
          {p('back_to_analytics')}
        </Button>
      )}
    </Container>
  );
};

export default Header;
