import { v4 as uuidv4 } from 'uuid';

import { by, identity } from '@utils';

const node2Treemap = (node, children) => {
  if(['event', 'resource', "worker", "contactcreated"].includes(node?.data?.name)) {
    return {
      data: null,
      dummy: true,
      id: null,
      multinode: false,
      type: null,
      children: [...node.data.children]
    }
  }
  return {
    ...node,
    children: [...children]
  }
};

export const generateTreemapFrom = (node, nodes, edges) => {
  return {
    ...node2Treemap(node, edges
      .filter(by('source', node?.id))
      .map(({ target }) => nodes.find(by(target)))
      .filter(identity)
      .map(node => generateTreemapFrom(node, nodes, edges))),
  }
}

export const syncEventBranches = (tree_map, entryNode) => {
  const mainEvent = tree_map.children.find(({ id }) => id === entryNode.id);

  const cloned_event = {
    ...tree_map,
    children: tree_map.children.map((triggerNode) => {
      if(triggerNode.id !== mainEvent?.id) {
        return {
          ...triggerNode,
          children: mainEvent.children
        }
      }
      return triggerNode;
    })
  }

  return cloned_event;
}

export const modifyIdsForBranches = (tree_map, mainEvent) => {

  const updateIdsForBestChannel = (child) => {
    const updatedChannelsId = child.data?.channels?.map((channel) => {
      const newId = uuidv4();
  
      return {
        ...channel,
        id: newId,
        data: {
          ...channel.data,
          id: newId
        },
        cloneOf: channel.id,
      }
    });

    return {
      ...child,
      id: uuidv4(),
      data: {
        ...child.data,
        channels: updatedChannelsId
      },
      children: child.children.map((child) => doModifyIds(child))
    }
  }
  
  const doModifyIds = (child) => {
    if(child.type === "bestchannel") {
      return updateIdsForBestChannel(child);
    }
    const newwOrOldId = child.type === 'event' ? child.id: uuidv4();

    return {
      ...child,
      id: newwOrOldId,
      children: child.children.map((child) => doModifyIds(child))
    }
  }

  return {
    ...tree_map,
    children: tree_map.children.map((child) => {
      if(child.id === mainEvent.id) {
        return child;
      }
      return doModifyIds(child, mainEvent)
    })
  }
}

export const graph2Treemap = (nodes, edges) => {
  if(!nodes.length) {
    return null;
  }

  const entry = nodes.find(({ id }) => !edges.some(by('target', id)));
  const tree_map_dirty = generateTreemapFrom(entry, nodes, edges);

  const tree_map_synced = syncEventBranches(tree_map_dirty, entry);
  const tree_map_modified_ids = modifyIdsForBranches(tree_map_synced, entry);

  return tree_map_modified_ids;
};
