import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  templateSelector,
  webpushSelector
} from '@store/selectors';

import {
  Badge,
  DateRangeField,
  MultiSelectField,
  RowCell,
  SingleSelectDataField
} from '@velitech/ui';

import { useModalState, useTranslation } from '@hooks';

import { Paths, UserPermissions, WebpushStatuses } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { identity, moment, languageStructureByCode, languageOptions } from '@utils';

import {
  DateCell,
  Link,
  Modal,
  TableLayout,
  WithPermissions
} from '@components';
import { MacOSPushPreview } from '@components/lib/WebPushPreview/components';

import { TableRowText } from '../../../CustomersScene/pages/FieldSettingsPage/components/AggregateTable/styled';
import { TableActions } from '../../../CustomersScene/pages/FieldSettingsPage/components/TableActions';
import { LanguageItem } from '../LanguageItem';

const WebpushTable = ({ table, webpushTemplates, statusOptions, total }) => {
  const webpush = useSelector(webpushSelector);
  const templates = useSelector(templateSelector);
  const [selectedWebpush, selectWebpush] = useState({
    actions: [],
    icon: '',
    image: '',
    web_push_name: '',
  });
  const previewState = useModalState(true);
  const webpushPreivewModal = useModalState(false);
  const languages = useMemo(() => languageStructureByCode(), []) 

  const { t, p } = useTranslation('notification_history')

  const getStatusColor = (status) => {
    switch (status) {
      case WebpushStatuses.DELIVERED:
        return Badge.COLORS.POSITIVE
      case WebpushStatuses.SENT:
        return Badge.COLORS.NEUTRAL_500
      case WebpushStatuses.FAILED:
        return Badge.COLORS.NEGATIVE_500
      default:
        return Badge.COLORS.NEUTRAL_500
    }
  };

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const openPreview = (data) => {
    selectWebpush(data);
    webpushPreivewModal.open();
  }

  const columns = [
    {
      header: {
        label: t('labels.customer_id'),
        className: 'w-25',
      },
      name: 'customer_id',

      render: (data) => {
        return(
          <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
            <Link type={'secondary'} to={`${Paths.CONTACT}/${data.customer_id}`}>{data.customer_id}</Link>
          </WithPermissions>
        )
      },
    },
    {
      header: {
        label: t('labels.template'),
        className: 'w-25',
      },
      name: 'template_id',
      render: (data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_WEBPUSHES}><Link type={'secondary'} to={`${Paths.TEMPLATES_WEBPUSH}/${data.template_id}?view=1`}>{templates?.[TemplateTypes.WEB_PUSH]?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      header: {
        label: t('labels.date'),
        className: 'w-10',
        sort: true,
      },
      name: 'created_at',
      render: (data) => {
        return (
          <TableRowText>
            <DateCell date={data.created_at} />
          </TableRowText>
        );
      },
    },
    {
      header: {
        label: t('labels.status'),
        className: 'w-10',
      },
      name: 'status',
      render: (data) => {
        return (
          <Badge size={Badge.SIZES.SMALL} variant={Badge.VARIANT.GHOST} color={getStatusColor(data.status)}>
            {data.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: {
        label: t('labels.language_code'),
        className: 'w-10',
      },
      name: 'language_code',
      render: (data) => {
        const languageItem = languages[data?.language_code];
        if(!languageItem) return <></>
        return (
          <LanguageItem icon={languageItem.emoji} code={languageItem.languageCode} name={languageItem.languageName} />
        );
      },
    },
    {
      header: {
        label: t('labels.actions'),
        className: 'w-25',
      },
      name: 'actions',
      fixed: 'right',
      render: (data) => {
        return (
          <TableActions
            onPreview={() => openPreview(data)}
          />
        )
      },
    },
  ];

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  };

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_WEBPUSHES} hidden>
        <TableLayout
          withTabs
          fullPage
          name="notification_history_webpush"
          loading={webpush.loading}
          columns={columns}
          data={webpush.data || []}
          table={table}
          total={total}
          localizer={tableLocalizer}
          renderFilter={(
            <>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select template'}
                  label={t('labels.template')}
                  options={webpushTemplates || []}
                  onChange={table.filters.onChange('template_id', identity)}
                  value={ table.filters.filters['template_id']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select status'}
                  searchHidden
                  label={t('labels.status')}
                  options={statusOptions || []}
                  onChange={table.filters.onChange('status', identity)}
                  value={ table.filters.filters['status']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <SingleSelectDataField
                  disableErrorSpaceReserve
                  className="status-select"
                  placeholder={'Select language code'}
                  label={t('labels.language_code')}
                  options={languageOptions() || []}
                  mapOptionLabel={(data) => <LanguageItem icon={data.emoji} code={data.value} name={data.label} />}
                  mapOptionValue={(data) => data.value}
                  onChange={table.filters.onChange('language_code', identity)}
                  value={ table.filters.filters['language_code']}
                />
              </RowCell>
              <RowCell>
                <DateRangeField
                  label={'Date'}
                  disableErrorSpaceReserve
                  dateFormat={'yyyy/MM/dd'}
                  from={{
                    selected: getStartDate(table.filters.filters.start_date),
                    onChange: (date) => handleChangeDate('start_date', date),
                  }}
                  to={{
                    selected: getEndDate(table.filters.filters.end_date),
                    onChange: (date) => handleChangeDate('end_date', date),
                  }}
                />
              </RowCell>
            </>
          )}
        />
      </WithPermissions>

      <Modal
        appearance="default"
        bigger
        title={p('preview', { name: selectedWebpush.web_push_name || '' })}
        opened={webpushPreivewModal.opened}
        onClose={webpushPreivewModal.close}
        contentStyles={{ padding: '30px 20px 30px 20px', maxHeight: '55vh', minHeight: '55vh' }}
      >
        <div style={{ position: 'relative', maxHeight: '46vh', minHeight: '45vh', display: 'flex' }}>
          <MacOSPushPreview
            style={{
              width: '60%',
              position: 'inherit',
              margin: 'auto',
              top: 'auto',
              left: 'auto',
              right: 'auto',
              bottom: 'auto',
            }}
            title={selectedWebpush.title}
            text={selectedWebpush.b}
            icon={selectedWebpush.icon}
            image={selectedWebpush.image}
            company={p('chrome')}
            buttons={selectedWebpush.actions ? selectedWebpush.actions.map(a => ({ title: a.text })) : []}
            onToggle={previewState.toggle}
            opened={previewState.opened}
          />
        </div>
      </Modal>
    </>
  )
}

export default WebpushTable;

