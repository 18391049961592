import styled from 'styled-components';

export const Container = styled.div`
`;

export const Disclaimer = styled.div`
  border-radius: 10px;
  border: 1px solid #FFDC7B;
  background: #FFFAED;
  padding: 12px 10px;
  margin-bottom: 20px;
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-bottom: 16px;
  margin-top: 5px;
  line-height: 120%;
  color: #C8402F;
`;
