import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 50px;
`;

export const TabsContainer = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid #DCDFE6;
  margin-bottom: 30px;
`;

export const Tab = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 12px;
  font-size: 14px;
  color: #303133;
  cursor: pointer;
`;

export const TabIndicator = styled.div`
  width: 33.3%;
  height: 3px;
  background: #31C447;
  position: absolute;
  bottom: -1px;
  transition: all 280ms ease;
  transform: translateX(${({ tab }) => tab * 100}%);
`;

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 6px;
`;

export const RepeatSettingsContainer = styled.div`
  margin-top: 30px;
`;

export const RepeatSettingsTitle = styled.div`
  font-size: 14px;
  line-height: 100%;
  color: #000000;
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid #DCDFE6;
`;

export const InputCaption = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #909399;
  margin-bottom: 10px;
  margin-left: 16px;
`;

export const DisabledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px 40px;
`;

export const DisabledCaption = styled.div`
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #909399;
`;

export const Title = styled.div`
  width: 337px;
  height: 32px;
  left: 82px;
  top: 470px;
  font-family: Manrope-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #909399;
`;
