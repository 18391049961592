import { useTranslation } from '@hooks';

import { Container, IconContainer, Caption } from './styled';

import { AddEventButton } from '../AddEventButton';

const SelectEventPanel = ({ options, onSelect }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container>
      <IconContainer>
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_21539_632238)">
            <path d="M19.0003 23.8698V9.37842C18.5895 9.41642 18.1834 9.48054 17.7701 9.49954H5.9386C5.28314 9.49954 4.75117 10.0315 4.75117 10.687V11.8744H1.18889C0.533432 11.8744 0.00146484 12.4064 0.00146484 13.0618V20.1864C0.00146484 20.8418 0.533432 21.3738 1.18889 21.3738H4.75117V22.5612C4.75117 23.2167 5.28314 23.7486 5.9386 23.7486H6.65818L11.4316 36.4731C11.7237 37.2354 12.3768 37.7888 13.1795 37.9503C13.3387 37.9835 13.4978 38.0001 13.6545 38.0001C14.2886 38.0001 14.8918 37.7484 15.324 37.2972L18.1762 34.4687C18.9053 33.7396 19.0787 32.6021 18.6132 31.721L14.6282 23.7486H17.782C18.1905 23.7676 18.5942 23.8318 19.0003 23.8698Z" fill="#D7ECFF"/>
            <path d="M37.8042 0.574714C37.7805 0.534342 37.7591 0.501094 37.7282 0.463096C37.5976 0.296856 37.4338 0.16149 37.2319 0.0831198C37.2248 0.080745 37.22 0.0712456 37.2129 0.0688707C37.1891 0.0617462 37.1678 0.0736204 37.144 0.0664959C37.0372 0.0356228 36.9303 0 36.8115 0C36.7308 0 36.6596 0.0308731 36.5812 0.047497C36.5361 0.0569964 36.4933 0.0593713 36.4482 0.0736204C36.244 0.140116 36.0682 0.254109 35.9281 0.410849C35.9186 0.420349 35.9044 0.422724 35.8949 0.432223C32.1925 4.92782 27.0295 7.93438 21.375 9.03631V24.214C27.0295 25.3159 32.1925 28.3201 35.8949 32.8157C35.9044 32.8276 35.921 32.8323 35.9329 32.8466C36.016 32.9392 36.1181 33.0128 36.2273 33.0746C36.263 33.0959 36.2915 33.1268 36.3271 33.1434C36.4767 33.2076 36.6382 33.2479 36.8115 33.2479C36.9469 33.2479 37.0799 33.2242 37.2129 33.1791C37.22 33.1767 37.2248 33.1672 37.2319 33.1648C37.4338 33.0864 37.5976 32.9534 37.7282 32.7848C37.7567 32.7468 37.7781 32.7136 37.8042 32.6732C37.9182 32.4903 37.999 32.2885 37.999 32.0605V1.18743C37.999 0.95944 37.9182 0.757578 37.8042 0.574714Z" fill="#D7ECFF"/>
          </g>
          <defs>
            <clipPath id="clip0_21539_632238">
              <rect width="38" height="38" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </IconContainer>
      <Caption>{p('no_events')}</Caption>
      <AddEventButton options={options} onSelect={onSelect} />
    </Container>
  );
};

export default SelectEventPanel;
