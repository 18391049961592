import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { WorkflowActionTypes } from '@store/actions/types';

import { useTranslation, useNavigate } from '@hooks';

import { AppRedirects, Paths } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { by, diffFields, removeAppFromPath, testId, update } from '@utils';

import { Input, Textarea } from '@components';
import { DynamicVariablesSettings } from '@components/lib/WorkflowEditor/components';

import { Container, NodeSettingsContent } from './styled';

import { WorkflowEditorContext } from '../../../../../../../contexts';
import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';
import { NodeSettingsTemplatesPreview } from '../NodeSettingsTemplatesPreview';

const SendEmailNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const { t } = useTranslation('workflow_page');
  const email = options?.[TemplateTypes.EMAIL]?.find?.(by(value.email_id));

  const getDefaultLangData = (languagesTemplates) => {
    const defaultLang = languagesTemplates.find((langTemplate) => langTemplate.is_default);
    return defaultLang?.data || languagesTemplates[0]?.data;
  }
  
  const emailOptions = (options?.[TemplateTypes.EMAIL] || []).filter(({ status }) => status !== 3).map(({ id, name, data, languages, app_integration_id }) => ({
    value: id,
    label: name,
    previewData: getDefaultLangData(languages) || data,
    app_integration_id,
  }));

  const handleTemplateChange = (email_id) => {
    onClearError('email_id');
    onChange(s => ({ ...s, email_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.EMAIL_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'email_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_EMAIL}/list/default?defaultCreate`);
  };

  const handleEditTemplate = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.EMAIL_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'email_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_EMAIL}/vce/${value.email_id}`);
  };

  const handleCustomChange = (updater) => {
    onChange?.(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <NodeSettingsContent>
              <NodeSettingsTemplatesPreview
                value={value.email_id}
                nodeValue={value}
                onChange={handleTemplateChange}
                onNodeValueChange={onChange}
                onClearError={onClearError}
                onCreateNew={handleCreateNew}
                onEditTemplate={handleEditTemplate}
                type={value.name}
                options={emailOptions}
              />
            </NodeSettingsContent>
            <DynamicVariablesSettings
              value={value.dynamic || {}}
              onChange={handleCustomChange}
              variables={email?.dynamicVariables || []}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('default-filter-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              autoComplete="off"
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('default-filter-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              autoComplete="off"
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default SendEmailNodeSettings;
