import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { WorkflowActionTypes } from '@store/actions/types';

import { useTranslation, useNavigate } from '@hooks';

import { AppRedirects, Paths } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { by, diffFields, removeAppFromPath, testId, update, getDefaultLang } from '@utils';

import { Input, Textarea } from '@components';
import { DynamicVariablesSettings } from '@components/lib/WorkflowEditor/components';

import { Container } from './styled';

import { WorkflowEditorContext } from '../../../../../../../contexts';
import { NodeSettingsAccordion } from "../../../NodeSettingsAccordion";
import NodeSettingsTemplatesPreview from '../NodeSettingsTemplatesPreview/NodeSettingsTemplatesPreview';
import { NodeSettingsContent } from "../SendEmailNodeSettings/styled";

const SendCardNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const { t } = useTranslation('workflow_page');
  const contentCard = (options?.[TemplateTypes.MESSAGE_FEED] || []).find(by(value.content_card_id));
  const cardOptions = (options?.[TemplateTypes.MESSAGE_FEED] || []).filter((card) => card?.status !== 3).map(({ id, name, languages, ...data }) => {
    const defaultData = getDefaultLang(languages);
    return ({
      value: id,
      label: name,
      previewData: { ...defaultData, id, name, image: defaultData?.image?.url },
    })
  });

  const handleTemplateChange = (content_card_id) => {
    onClearError('content_card_id');
    onChange(s => ({ ...s, content_card_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.CONTENT_CARD_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'content_card_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_CONTENT_CARDS}/new`);
  };

  const handleEditTemplate = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.CONTENT_CARD_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'content_card_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_CONTENT_CARDS}/${value.content_card_id}`);
  };

  const handleCustomChange = (updater) => {
    onChange(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <NodeSettingsContent>
              <NodeSettingsTemplatesPreview
                worker={worker}
                value={value.content_card_id}
                nodeValue={value}
                onChange={handleTemplateChange}
                onEditTemplate={handleEditTemplate}
                onNodeValueChange={onChange}
                errors={errors}
                onCreateNew={handleCreateNew}
                onClearError={onClearError}
                type={value.name}
                options={cardOptions}
              />
              <DynamicVariablesSettings
                value={value.dynamic || {}}
                onChange={handleCustomChange}
                variables={contentCard?.dynamicVariables || []}
              />
            </NodeSettingsContent>
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('send-card-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('send-card-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default SendCardNodeSettings;
