import styled from 'styled-components';

export const Container = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
  padding-bottom: 32px;
`;
