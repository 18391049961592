import { useTranslation } from '@hooks';

import { StyledTimePicker, Container, Error } from './styled';

const TimePicker = ({ style, error, format, ...props }) => {
  const { e } = useTranslation();

  return (
    <Container style={style}>
      <StyledTimePicker style={{ width: '120px' }} format={format} $error={!!error} {...props} />
      {error && (<Error>{e(error)}</Error>)}
    </Container>
  );
};

export default TimePicker;
