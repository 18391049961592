import styled from 'styled-components';

export const Container = styled.div``;

export const Overlay = styled.div`
  padding: 16px 14px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;

export const InfoTitle = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #909399;
  margin-bottom: 4px;
`;

