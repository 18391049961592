import { InGroupCheckbox } from '@components';

import { ExitItem, ExistItemLabel } from '../../../../styled';

const checkboxStyles = { fontFamily: 'Manrope-SemiBold', color: '#7C94B1', fontSize: '14px' };

const ExitPointCheckbox = ({ title, id, selected }) => {

  return (
    <ExitItem >
      <InGroupCheckbox style={checkboxStyles} name={id}>
        <ExistItemLabel $selected={selected}>{title}</ExistItemLabel>
      </InGroupCheckbox>
    </ExitItem>
  );
};

export default ExitPointCheckbox;
