import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div).attrs({
  initial: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '-30%' : '30%',
    scale: 0.98
  }),
  animate: { opacity: 1, x: '0%', scale: 1 },
  exit: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '30%' : '-30%',
    scale: 0.98
  }),
  transition: { duration: 0.27, easing: 'ease' }
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: calc(100% - 73px);
  justify-content: center;
  position: relative;
  gap: 5px;
  padding-top: 128px;
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 70px;
  flex: 1;
`;

export const ContentItemRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

export const ContentItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
`;

export const ParticipationContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-top: 70px;
`

export const TargetAuiduenceContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-top: 90px;
`

export const Image = styled.img`
  width: 380px;
  min-height: 300px;
`;

export const PickersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -6px;
`;

export const SettingsTitle = styled.div`
  font-family: Manrope-Medium;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #7C94B1;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-bottom: 24px;
`;

export const SettingsOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const Actions = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  padding-bottom: 20px;
`;

export const SpecificSettingsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 55px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 480px;
  gap: 22px;
`