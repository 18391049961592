import { useEffect, useState, createContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  optionsAggregatesSelector,
  optionsSelector,
  workflowOptionsSelector,
} from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { v4 as uuid } from 'uuid';

import { useTranslation } from '@hooks';

import { SegmentsRuleTypes, WorkflowNodes } from '@constants';

import { generateRandomName, validateSegment, sequence } from '@utils';
import {
  clickhouseFields2Customer,
  clickhouseFields2Events,
} from '@utils/fields';

import { useManageGroups } from "./useManageGroups";
import {
  viewSteps,
  titleListConfig,
  SegmentType,
  resolveFilterValue,
  getCurentGroupData,
} from './utils';

const getTabs = (p) => [
  { name: 'dynamic', label: p('dynamic_segment') },
  { name: 'static', label: p('static_segment') },
];

export const useGroupSplit = ({ value, errors, styles, onStylesChange, onClearError }) => {
  const groupsList = styles.segments || [];
  const { p } = useTranslation('workflow_page')
  const { onAddGroup, onRemoveGroup, onChangeGroupName } = useManageGroups({ id: value.id, groupsList, onStylesChange });

  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const aggregatesList = useSelector(optionsAggregatesSelector);
  const eventsList = clickhouseFields2Events(clickhouseFields.data);
  const segmentOptions = useSelector(optionsSelector);
  const options = useSelector(workflowOptionsSelector);

  const segmentTabs = getTabs(p);

  const [segmentTab, setSegmentTab] = useState(segmentTabs[0].name);
  const [step, setStep] = useState();
  const [selectedFilterType, setSelectedFilterType] = useState();
  const [displayErrors, setDisplayErrors] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState();

  const getGroupError = (id) => {
    return Array.isArray(errors) && errors.some((error) => error.id === id && !error.valid);
  };

  const linearList = useMemo(() => {
    return customerFields?.filter((f) => f.type !== 'array');
  }, [clickhouseFields]);

  useEffect(() => {
    const inValid = Array.isArray(errors) && errors.some((error) => !error.valid)
    if (inValid) {
      setDisplayErrors(true);
    }
  }, [errors]);

  const segmentOption = segmentOptions.segments.filter((segment) => segment.type === segmentTab);

  const configListBySelectedAttr = {
    [SegmentsRuleTypes.LINEAR]: linearList,
    [SegmentsRuleTypes.AGGREGATE]: aggregatesList,
    [SegmentsRuleTypes.EVENT]: eventsList.data,
    [SegmentsRuleTypes.RFM_SEGMENT]: options.rfmResources,
    [SegmentType.SEGMENT]: segmentOption,
  };

  const getSegmentType = (segmentId) => {
    const currentSegment = segmentOptions.segments.find((segment) => segment.id === segmentId);
    if(!currentSegment) {
      return null;
    }

    return currentSegment.type;
  }

  const onUpdateSegments = (updatedSegments) => {
    onStylesChange((prevState) => ({
      ...prevState,
      segments: updatedSegments,
    }));
  };

  const getStep = (selectedGroup) => {
    if(!selectedGroup) return setStep(viewSteps.EMPTY);

    if(selectedGroup.actionType === WorkflowNodes.EXCLUDE) {
      return setStep(viewSteps.EXCLUDE);
    }
    const isEmptySegment = selectedGroup.filter_by === 'segment' && !selectedGroup.segment_id;
    const isEmptyRule = selectedGroup.filter_by === 'rule' && ((selectedGroup?.query && Object.keys(selectedGroup?.query).length === 0) || selectedGroup?.query === undefined) ;

    if((isEmptySegment || isEmptyRule) && !selectedFilterType) {
      return setStep(viewSteps.INITIAL);
    }

    if(isEmptyRule || isEmptySegment) {
      return setStep(viewSteps.SHOW_LIST);
    }

    if(!selectedGroup?.filter_by) return setStep(viewSteps.INITIAL);

    if(selectedGroup.filter_by === 'segment') {
      return setStep(viewSteps.SHOW_SELECTED_SEGMENT);
    } else {
      return setStep(viewSteps.SHOW_SELECTED_FILTER);
    }
  };

  const onSelectGroup = (groupId) => {
    const selectedGroup = groupsList.find((group) => group.id === groupId);

    if (!selectedGroup) return;

    getStep(selectedGroup);
    setSelectedGroup(selectedGroup);
  };

  const onChangeFilterType = (filterType) => {
    setSelectedFilterType(filterType);
    const currentIndex = groupsList.findIndex((group) => group.id === selectedGroup.id);
    onClearError('filter_by', currentIndex)
    const filter_by = filterType.includes('segment') ? 'segment' : 'rule';

    const updatedSegment = groupsList.map((group) => {
      if (group.id === selectedGroup.id) {
        return {
          ...group,
          filter_by,
          rule_segment_id: filter_by === 'rule' ? (group.rule_segment_id || uuid()) : undefined,
        };
      }
      return group;
    });

    onUpdateSegments(updatedSegment);

    setStep(viewSteps.SHOW_LIST);
  };

  const onUpdateSegmentFilter = (segmentId) => {
    const currentIndex = groupsList.findIndex((group) => group.id === selectedGroup.id);

    onClearError('segment_id', currentIndex);
    const updatedSegment = groupsList.map((group) => {
      if (group.id === selectedGroup.id) {
        return {
          ...group,
          segment_id: segmentId,
        };
      }
      return group;
    });

    onUpdateSegments(updatedSegment);
  };

  const handleSelectFilter = (field) => {
    const currentIndex = groupsList.findIndex((group) => group.id === selectedGroup.id);
    onClearError('query', currentIndex);
    setDisplayErrors(false);

    const curentGroupData = getCurentGroupData(groupsList, selectedGroup.id);
    const fieldType = field.special ? SegmentsRuleTypes.ARRAY : selectedFilterType;

    if (fieldType === SegmentType.SEGMENT) {
      onUpdateSegmentFilter(field.id);
      setStep(viewSteps.SHOW_SELECTED_SEGMENT);
      return;
    }

    const newValue = { ...curentGroupData };

    const query = {
      ruleType: fieldType,
      negation: false,
      name: 1,
      value: {
        type: 'scalar',
        value: '',
      },
      field:
        fieldType === SegmentsRuleTypes.EVENT
          ? field.name
          : field?.field,
      filters: [],
    };

    if (fieldType === SegmentsRuleTypes.ARRAY || fieldType === SegmentsRuleTypes.EVENT) {
      query.logicalOperator = 'and';
      query.type = 'common';

      if (fieldType === SegmentsRuleTypes.ARRAY) {
        query.type = 'array';
        query.field = field?.field;
        query.value.value = [];
      }
      if (fieldType === SegmentsRuleTypes.EVENT) {
        query.wasPerformed = true;
      }
    }

    if (fieldType === SegmentsRuleTypes.AGGREGATE && field) {
      query.aggregateId = field.id;
      query.field = field.name;
      newValue.aggregates = [...(newValue.aggregates || []), field];
    }

    if (fieldType === SegmentsRuleTypes.AGGREGATE && !field) {
      const id = uuid();
      const name = generateRandomName();
      const label = `Quick aggregate`;

      query.field = name;
      query.aggregateId = id;

      newValue.aggregates = [
        ...(newValue.aggregates || []), {
          createdId: id,
          label,
          name,
          logicalOperator: 'and',
          type: '',
          filters: [],
          attribute: null,
          aggregate: 'count',
          aggregateMeta: {},
        }];
    }

    const updatedSegment = groupsList.map((group) => {
      if (group.id === selectedGroup.id) {
        return {
          ...curentGroupData,
          ...newValue,
          query: validateSegment({
            type: 'query-builder-group',
            funnels: [],
            name: 'New segment',
            aggregates: [],
            ...newValue,
            query: {
              name: 'root',
              root: true,
              children: [{ type: 'query-builder-rule', query }],
              logicalOperator: 'and',
            },
          }).query,
        }
      }
      return group;
    });

    onUpdateSegments(updatedSegment);

    setStep(viewSteps.SHOW_SELECTED_FILTER);
  };

  const getRuleType = () => {
    const curentGroupData = getCurentGroupData(groupsList, selectedGroup.id);
    return curentGroupData.query?.children[0]?.query?.ruleType
  }

  const getWorkflowValue = () => {
    const curentGroupData = getCurentGroupData(groupsList, selectedGroup.id);
    return resolveFilterValue(curentGroupData, eventsList.data)
  }

  const updateFilterValue = (updatedQuery, currentGroupData) => {
    const currentIndex = groupsList.findIndex((group) => group.id === selectedGroup.id);
    onClearError('query', currentIndex);
    setDisplayErrors(false);

    const validatedQuery = validateSegment({
      ...currentGroupData,
      query: {
        ...currentGroupData.query,
        children: [{
          type: 'query-builder-rule',
          query: updatedQuery,
        }],
      }
    })
    const updatedSegment = groupsList.map((group) => {
      if (group.id === selectedGroup.id) {
        return { ...validatedQuery }
      }
      return group;
    });

    onUpdateSegments(updatedSegment);
  }

  const onDeleteSelectedFilter = () => {
    onClearError('query');
    setDisplayErrors(false);
    const currentIndex = groupsList.findIndex((group) => group.id === selectedGroup.id);

    onClearError('segment_id', currentIndex);

    const updatedSegment = groupsList.map((group) => {
      if (group.id === selectedGroup.id) {
        return {
          ...group,
          filter_by: undefined,
          aggregates: [],
          segment_id: null,
          query: {},
        };
      }
      return group;
    });

    onUpdateSegments(updatedSegment);

    setStep(viewSteps.INITIAL);
  };

  const onFilterChange = () => {
    const currentGroupData = getCurentGroupData(styles.segments, selectedGroup.id);
    const queryRuleType = currentGroupData.query?.children?.[0]?.query?.ruleType;
    switch (queryRuleType) {
      case SegmentsRuleTypes.EVENT:
        return (updater) => {
          const result = updater(resolveFilterValue(currentGroupData, eventsList.data));
          //removed fuilder build from result
          // eslint-disable-next-line no-unused-vars
          const { filter_builder, ...rest } = result.filter_builder;
          updateFilterValue(rest, currentGroupData);
        };
      default:
        return (updater) => {
          const updatedQuery = updater(resolveFilterValue(currentGroupData, eventsList.data));
          updateFilterValue(updatedQuery, currentGroupData);
        };
    }
  };

  const onSelectRFMSegment = sequence((data) => {

    const updatedSegment = groupsList.map((group) => {
      if (group.id === selectedGroup.id) {
        return {
          ...group,
          parentId: data.parentId,
          segmentType: data.type,
          segment_id: data.id,
          query: data.segment_query,
        };
      }
      return group;
    });

    onUpdateSegments(updatedSegment);
    setStep(viewSteps.SHOW_SELECTED_SEGMENT)
  });

  const getSelectedGroupData = () => {
    return getCurentGroupData(styles.segments, selectedGroup.id);
  };

  const getWorkflowError = () => {
    const currentGroupData = getCurentGroupData(styles.segments, selectedGroup.id);
    return displayErrors && currentGroupData.query?.children?.[0]?.query;
  }

  const handleRemoveGroup = (removeId, callback) => {

    if(selectedGroup.id === removeId) {
      const newList = styles.segments.filter((segment) => segment.id !== removeId);

      setSelectedGroup(newList[0]);
      getStep(newList[0]);
    }

    onRemoveGroup(removeId, callback);
  };

  const onCloseList = () => {
    setSelectedFilterType();
    setStep(viewSteps.INITIAL);
  };

  const subTitleLogic = (translate) => {
    if(!selectedGroup) {
      return;
    }

    const index = groupsList.findIndex((group) => group.id === selectedGroup?.id);
    const isLast = groupsList.length - 1 === index;
    const selectedGroupTitle = selectedGroup?.label;

    if(!selectedGroupTitle || isLast) return '';

    if(index === 0) {
      return `${selectedGroupTitle} ${translate('includes_all_eligible_players')}`;
    }

    if(index) {
      const groupsLabels = groupsList.slice(0, index).map((group) => group.label).join(', ');
      return `${selectedGroupTitle} ${translate('includes_all_eligible_players_except')} ${groupsLabels}`
    }
  };

  const handleClearError = (field) => {
    const fieldToClear = field === 'filter_builder' ? 'query' : field;

    const currentIndex = groupsList.findIndex((group) => group.id === selectedGroup.id);
    onClearError(fieldToClear, currentIndex)
  }

  return {
    selectedGroup,
    groupsList,
    step,
    displayErrors,
    listBySelectedType: configListBySelectedAttr[selectedFilterType],
    listTitle: titleListConfig[selectedFilterType],
    onSelectGroup,
    selectedFilterType,
    onDeleteSelectedFilter,
    onChangeFilterType,
    onSelectAttributeField: handleSelectFilter,
    onFilterChange,
    onSelectRFMSegment,
    onCloseList,
    getRuleType,
    getWorkflowValue,
    getSelectedGroupData,
    getGroupError,
    getWorkflowError,
    handleClearError,
    manageGroup: {
      onAddGroup,
      onRemoveGroup: handleRemoveGroup,
      onChangeGroupName,
      subTitleLogic,
    },
    segmentTabs,
    segmentTab, 
    setSegmentTab,
    getSegmentType
  };
};

export const GroupSplitContext = createContext();
