import ELK from 'elkjs/lib/elk.bundled.js';

import { DirectionModes, WorkflowNodes } from '@constants';

import { zeroIfNaN } from '@utils';
import { getConnectors, hideNodes, iterate } from '@utils/workflows/refactored/structureParser';

import { createMatrix, modifyHeight } from './layoutUtils';

const elkOptions = {
  'algorithm': 'elk.mrtree',
  'elk.spacing.nodeNode': '110',
  'elk.layered.spacing.nodeNodeBetweenLayers': '100',
  'elk.direction': 'RIGHT',
  'elk.alignment': 'CENTER',
  'elk.padding': '0',
  // 'elk.edgeRouting': 'POLYLINE', // Edge routing style
  // 'elk.layered.nodePlacement.strategy': 'BRANDES_KOEPF', // Node placement strategy
  // 'elk.layered.highDegreeNodes.treatment': 'SEPARATE', // Treatment for high-degree nodes
  // Prevent nodes from changing layers
  // 'elk.layered.crossingMinimization.semiInteractive': 'true',
  // 'elk.layered.nodePlacement.bk.fixedAlignment': 'RIGHTDOWN',
  'elk.layered.nodeOrdering': 'INPUT',
};

const getNodeWidth = (node, directionMode) => {
  if (directionMode === DirectionModes.VERTICAL) {
    return 260;
  }

  if ([WorkflowNodes.EVENT, WorkflowNodes.RESOURCE, WorkflowNodes.CONTACT_CREATED, WorkflowNodes.ENTRY_ANOTHER_WF].includes(node.type)) {
    return 300;
  }

  if (node.type === 'splitter') {
    return 240;
  }

  if (node.type === 'ab') {
    if (node.data.internalControlGroup) {
      return 260;
    }

    return 190;
  }

  return 260;
};

const getNodeHeight = (node, directionMode) => {
  if (directionMode === DirectionModes.HORIZONTAL) {
    return 200;
  }

  if (node.type === 'splitter') {
    return 120;
  }

  if (node.type === 'ab') {
    return 200;
  }

  if ([WorkflowNodes.CONTACT_CREATED, WorkflowNodes.ENTRY_ANOTHER_WF, WorkflowNodes.RESOURCE, WorkflowNodes.DATA_CHANGE].includes(node.type)) {
    return 240;
  }

  return 200;
}

export const layoutFlow = async (root, styles, directionMode = DirectionModes.HORIZONTAL) => {
  const elk = new ELK();

  const isHorizontal = directionMode === DirectionModes.HORIZONTAL;

  const processedRoot = hideNodes(root);
  const nodes = [];

  iterate(processedRoot, (node) => {
    if (!node.id) {
      return;
    }

    nodes.push({
      id: node.id,
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
      width: getNodeWidth(node, directionMode),
      height: getNodeHeight(node, directionMode),
      name: node.type,
      data: node.data,
    });
  });

  const edges = getConnectors(processedRoot).filter(({ source, target }) => source && target);
  const nodeStructure = createMatrix(processedRoot);
  const newNodes = modifyHeight(nodes, directionMode, processedRoot, nodeStructure);

  const graph = {
    id: 'root',
    layoutOptions: {
      ...elkOptions,
      'elk.direction': isHorizontal ? 'RIGHT' : 'DOWN',
    },
    children: newNodes,
    edges,
  };

  const { nodes: ns } = await (elk
    .layout(graph)
    .then((layoutGraph) => ({
      nodes: layoutGraph.children.map((node) => {

        return ({
          ...node,
          position: { x: node.x, y: node.y },
        })
      }),

      edges: layoutGraph.edges,
    }))
    .catch(console.error))

  return {
    ...styles,
    ...Object.fromEntries(ns.map(({ id, ...rest }) => {
      const style = styles[id] || {};
      // const node = nodes.find(by(id));

      return [
        id,
        {
          ...style,
          targetPosition: isHorizontal ? 'left' : 'top',
          sourcePosition: isHorizontal ? 'right' : 'bottom',
          position: {
            x: zeroIfNaN(rest?.x) ,
            y: zeroIfNaN(rest?.y),
          },
        }
      ];
    }))
  }
}
