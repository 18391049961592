import { Tooltip } from 'antd';

import { Button } from '@components';

import { Container, TitleContainer, Image } from './styled';

const DefaultIcon = (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_1686_17742" fill="white">
      <path d="M28 19C29.1046 19 30.0157 19.9006 29.8465 20.9922C29.5575 22.8553 28.8654 24.6415 27.8091 26.2224C26.3806 28.3603 24.3503 30.0265 21.9749 31.0104C19.5994 31.9944 16.9856 32.2518 14.4638 31.7502C11.9421 31.2486 9.62569 30.0105 7.80761 28.1924C5.98953 26.3743 4.7514 24.0579 4.24979 21.5362C3.74818 19.0144 4.00563 16.4006 4.98957 14.0251C5.97351 11.6497 7.63975 9.61935 9.77759 8.1909C11.3585 7.13458 13.1447 6.44247 15.0078 6.15354C16.0994 5.98427 17 6.89543 17 8V17C17 18.1046 17.8954 19 19 19H28Z"/>
    </mask>
    <path d="M28 19C29.1046 19 30.0157 19.9006 29.8465 20.9922C29.5575 22.8553 28.8654 24.6415 27.8091 26.2224C26.3806 28.3603 24.3503 30.0265 21.9749 31.0104C19.5994 31.9944 16.9856 32.2518 14.4638 31.7502C11.9421 31.2486 9.62569 30.0105 7.80761 28.1924C5.98953 26.3743 4.7514 24.0579 4.24979 21.5362C3.74818 19.0144 4.00563 16.4006 4.98957 14.0251C5.97351 11.6497 7.63975 9.61935 9.77759 8.1909C11.3585 7.13458 13.1447 6.44247 15.0078 6.15354C16.0994 5.98427 17 6.89543 17 8V17C17 18.1046 17.8954 19 19 19H28Z" fill="#87E2BA" stroke="#0C1015" strokeWidth="2" mask="url(#path-1-inside-1_1686_17742)"/>
    <mask id="path-2-inside-2_1686_17742" fill="white">
      <path d="M18.5 6C18.5 4.89543 19.4002 3.98484 20.4927 4.14788C21.58 4.31015 22.6459 4.60501 23.6662 5.02763C25.3041 5.70607 26.7924 6.70047 28.0459 7.95406C29.2995 9.20765 30.2939 10.6959 30.9724 12.3338C31.395 13.3541 31.6898 14.42 31.8521 15.5073C32.0152 16.5998 31.1046 17.5 30 17.5L20.5 17.5C19.3954 17.5 18.5 16.6046 18.5 15.5V6Z"/>
    </mask>
    <path d="M18.5 6C18.5 4.89543 19.4002 3.98484 20.4927 4.14788C21.58 4.31015 22.6459 4.60501 23.6662 5.02763C25.3041 5.70607 26.7924 6.70047 28.0459 7.95406C29.2995 9.20765 30.2939 10.6959 30.9724 12.3338C31.395 13.3541 31.6898 14.42 31.8521 15.5073C32.0152 16.5998 31.1046 17.5 30 17.5L20.5 17.5C19.3954 17.5 18.5 16.6046 18.5 15.5V6Z" fill="white" stroke="#0C1015" strokeWidth="2" mask="url(#path-2-inside-2_1686_17742)"/>
  </svg>
);

const AddEntityButton = ({ icon = DefaultIcon, title, actionTitle, containerStyle, testId, tooltip, ...props }) => {
  return (
    <Container data-testid={testId} style={containerStyle}>
      <TitleContainer>
        {typeof icon === "string" ? <Image src={icon} /> : icon}
        {title}
      </TitleContainer>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <div>
            <Button appearance="add-entity" width="auto" style={{ flex: 0 }} {...props}>
              {actionTitle}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button appearance="add-entity" width="auto" style={{ flex: 0 }} {...props}>
          {actionTitle}
        </Button>
      )}
    </Container>
  );
};

export default AddEntityButton;
