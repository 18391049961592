import { useTranslation } from '@hooks';

import { LayoutSplit } from '@components';

import { AiBestChannelToSend } from './AiBestChannelToSend';
import { AsideContent } from './AsideContent';
import {
  useBestChannelToSend,
  viewSteps,
} from './module/useBestChannelToSend';

import { NodeSettingsTemplatesPreview } from '../NodeSettingsTemplatesPreview';

export const BestChannelToSendSettings = ({
  value,
  onChange,
  errors,
  onClearError,
}) => {
  const { p } = useTranslation('workflow_page');
  const {
    step,
    currentChannelData,
    currentChannel,
    selectedChannels,
    defaultChannel,
    options,
    enabledAiBestTime,
    templateKey,
    handleSelectChannel,
    handleAddChannel,
    handleRemoveChannel,
    handleChangegDefaultchannel,
    handleChangeTemplate,
    handleNodeValueChange,
    handleChangeEnabledIdBestTime,
    handleChangeAiSendSettingsTime,
    handleChangeAiSendSettings,
    getTemplateKey,
    getSelectedChannelIndex
  } = useBestChannelToSend({
    value: value,
    onChange: onChange,
    onClearError
  });

  const renderChannelError = (index, channelType) => {
    const key = getTemplateKey(channelType);

    if(errors[index] && errors[index].errors?.[key]) {
      return p(errors[index] && errors[index].errors[key])
    }
    return '';
  }

  const getCurrentError = () => {
    const index = getSelectedChannelIndex();
    return errors?.[index]?.errors
  };
  const validationAIBestTime = errors[errors.length - 1];

  return (
    <LayoutSplit
      siderContent={
        <AsideContent
          enabledAiBestTime={enabledAiBestTime}
          handleChangeEnabledIdBestTime={handleChangeEnabledIdBestTime}
          currentChannel={currentChannel}
          handleSelectChannel={handleSelectChannel}
          selectedChannels={selectedChannels}
          onAddChannel={handleAddChannel}
          defaultChannel={defaultChannel}
          setDefaultchannel={handleChangegDefaultchannel}
          onRemoveChannel={handleRemoveChannel}
          errors={errors}
          renderChannelError={renderChannelError}
        />
      }
      content={
        <>
          {step === viewSteps.SHOW_AI_BEST_CHANNEL_TO_SEND && (
            <AiBestChannelToSend
              value={value}
              onChangeAiSendSettings={handleChangeAiSendSettings}
              onChangeAiSendSettingsTime={handleChangeAiSendSettingsTime}
              error={validationAIBestTime?.errors}
            />
          )}
          {step === viewSteps.SHOW_EMPTY && (
            <div></div>
          )}
          {step === viewSteps.SHOW_TEMPLATES && (
            <div style={{ width: '100%', padding: '0 30px' }}>
              <NodeSettingsTemplatesPreview
                value={currentChannelData?.[templateKey] || ''}
                nodeValue={currentChannelData}
                onChange={handleChangeTemplate}
                onNodeValueChange={handleNodeValueChange}
                type={currentChannel}
                options={options}
                previewType={'preview-3-column'}
                showAiSend={false}
                errors={getCurrentError()}
                onClearError={onClearError}
              />
            </div>
          )}
        </>
      }
    />
  );
};
