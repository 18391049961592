import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router';

import {
  addNotification,
  getDemoWorkspaces, getNotifications,
  getUser,
  listWorkspaces,
  setViewDemoWorkspacesModalOpened,
  switchToDemoWorkspace, switchWorkspaces
} from '@store/actions/creators';
import {
  accessTokenSelector,
  demoWorkspacesSelector,
  loggedInSelector, sidebarOpenedSelector,
  viewDemoWorkspacesModalOpenedSelector
} from '@store/selectors';
import { notificationsSelector } from '@store/selectors/notifications';

import { useModalState, useNavigate, useTranslation, useCurrentWorkspace } from '@hooks';

import { Paths } from '@constants';

import { notify, testId, hashAppId } from '@utils';
import initPusher from '@utils/initPusher';

import { CreateDemoWorkspaceModal, NotificationsList, Sidebar } from '@components';
import { Topbar } from '@components/layout/Topbar';
import { translateNotification } from '@components/lib/NotificationsList/components/NotificationItem/NotificationItem';
import { SystemNotificationBody } from '@components/lib/NotificationsList/components/SystemNotificationBody';

import { PageContent, Container } from './styled';

import { sidebarRoutes } from '../../sidebarRoutes';

const LayoutWithSidebar = () => {
  const dispatch = useDispatch();
  const app = useCurrentWorkspace();
  const opened = useSelector(sidebarOpenedSelector);
  const viewDemoWorkspacesModalOpened = useSelector(viewDemoWorkspacesModalOpenedSelector);
  const welcome = window.location.search.indexOf('welcome') !== -1;
  const notificationsState = useModalState(false);
  const navigate = useNavigate();
  const demoWorkspaces = useSelector(demoWorkspacesSelector);
  const accessToken = useSelector(accessTokenSelector);
  const { p } = useTranslation('system_notifications');
  const user = useSelector(loggedInSelector);
  const location = useLocation();
  const hideSidebar = !!~location.pathname.indexOf('/workflows/templates') || !!~location.pathname.indexOf('/workflows/configure');

  useEffect(() => {
    if (user?.id) {
      dispatch(getNotifications());
      initPusher({
        accessToken,
        onReceiveNotification: (notification) => {
          const composedNotification = { ...notification, ...notification.data, data: notification.data };
          dispatch(addNotification(composedNotification));

          const handleNavigate = (path) => {
            if (user?.app?.id === composedNotification.data.app.app_id) {
              navigate(path);
            } else {
              dispatch(switchWorkspaces({
                id: composedNotification.data.app.app_id,
                onSuccess: () => {
                  navigate(path);
                }
              }))
            }
          }

          const event_workflow_finished = new CustomEvent("workflow_finished", {
            detail: composedNotification,
          });

          composedNotification.type === "workflow_finished" && document.dispatchEvent(event_workflow_finished);

          const { body, title } = translateNotification(composedNotification, p, handleNavigate);
          if (!body || !title) return;
          notify(composedNotification.status === 'success' ? 'success' : 'error', title, <SystemNotificationBody tid={testId('websocket-push')} style={{ marginLeft: -5 }} body={body} />);
        },
        user,
      })
    }
  }, []);

  const handleViewDemoWorkspace = ({ app_id }) => {
    dispatch(switchToDemoWorkspace({
      app_id,
      onSuccess: () => {
        dispatch(getUser());
        dispatch(listWorkspaces());
        dispatch(getDemoWorkspaces());
        navigate(Paths.DASHBOARD, { replace: true });
        dispatch(setViewDemoWorkspacesModalOpened({ opened: false }));
      }
    }));
  };
  const notifications = useSelector(notificationsSelector);

  const sidebarRoutesWithAppId = sidebarRoutes(hashAppId(String(app?.id)));

  return (
    <Container $hideSidebar={hideSidebar}>
      {!hideSidebar && (
        <>
          <Topbar
            notificationsOpened={notificationsState.opened}
            toggleNotifications={notificationsState.toggle}
            routes={sidebarRoutesWithAppId}
            notifications={notifications}
          />
          <Sidebar
            routes={sidebarRoutesWithAppId}
          />
          <NotificationsList
            close={notificationsState.close}
            opened={notificationsState.opened}
            notifications={notifications}
          />
          <CreateDemoWorkspaceModal
            opened={viewDemoWorkspacesModalOpened}
            onClose={() => {
              // navigate(Routes.DASHBOARD, { replace: true });
              dispatch(setViewDemoWorkspacesModalOpened({ opened: false }));
            }}
            options={demoWorkspaces?.data || []}
            welcome={welcome}
            onSelect={handleViewDemoWorkspace}
          />
        </>
      )}
      <PageContent sidebarOpened={opened} $hideSidebar={hideSidebar}>
        <Outlet />
      </PageContent>
    </Container>
  )
};

export default LayoutWithSidebar;
