import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { by } from '@utils';
import { clickhouseFields2Customer } from '@utils/fields';

import { OperatorSelect } from '@components';

import {
  Container, Content,
  DeleteButtonContainer,
  EventName,
  EventPanel,
  FiltersContainer,
} from './styled';

const gamePreferenceOptions = [
  {
    value: 'Sport',
    label: 'Sport',
  },
  {
    value: 'Casino',
    label: 'Casino',
  },
];

const LinearFilter = ({ value, onChange, onDelete, errors, onClearError, disabled }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const fields = clickhouseFields2Customer(clickhouseFields.data);
  const field = (fields || []).find(by('field', value.field));
  const title = field?.label;

  const handleDelete = () => {
    if(!disabled){
      onDelete();
    }
  };

  const handleValueChange = (value) => {
    onClearError('errors');
    onChange(s => ({ ...s, value: { type: 'scalar', value } }));
  };

  const handleOperatorChange = (operator) => {
    onClearError('errors');
    onChange(s => ({
      ...s,
      operator,
      value: { type: 'scalar', value: '' },
    }));
  };

  return (
    <Container>
      <EventPanel>
        <Content>
          <EventName>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.72717 24.0794L4.55973 24.3027C4.48546 24.4017 4.44531 24.5222 4.44531 24.646V24.7326C4.44531 25.8814 5.37659 26.8127 6.52537 26.8127H25.7898C26.7663 26.8127 27.5579 26.0211 27.5579 25.0446V24.925C27.5579 24.6201 27.459 24.3234 27.276 24.0794C21.6388 16.5632 10.3644 16.5632 4.72717 24.0794Z" fill="#87E2BA" stroke="#0C1015" strokeWidth="1.2" strokeLinecap="round"/>
              <circle cx="16.3233" cy="11.2589" r="7.05419" fill="white" stroke="#0C1015" strokeWidth="1.3"/>
            </svg>
            {title}
          </EventName>
          <DeleteButtonContainer $disabled={disabled} onClick={handleDelete}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.75 7.5H7.5V15H8.75V7.5Z" fill="#F04438"/>
              <path d="M12.5 7.5H11.25V15H12.5V7.5Z" fill="#F04438"/>
              <path d="M2.5 3.75V5H3.75V17.5C3.75 17.8315 3.8817 18.1495 4.11612 18.3839C4.35054 18.6183 4.66848 18.75 5 18.75H15C15.3315 18.75 15.6495 18.6183 15.8839 18.3839C16.1183 18.1495 16.25 17.8315 16.25 17.5V5H17.5V3.75H2.5ZM5 17.5V5H15V17.5H5Z" fill="#F04438"/>
              <path d="M12.5 1.25H7.5V2.5H12.5V1.25Z" fill="#F04438"/>
            </svg>
          </DeleteButtonContainer>
        </Content>
        <FiltersContainer>
          <OperatorSelect
            changeDisabled={disabled}
            appearance="row"
            smaller
            wrapperStyle={{ width: '100%' }}
            initialType={field?.type}
            type={field?.type}
            value={{
              operator: value.operator,
              value: value.value?.value || '',
              type: value.type,
              errors: errors?.errors || {}
            }}
            strictOptions={field?.field === "game_preferences" && gamePreferenceOptions}
            onValueChange={handleValueChange}
            onOperatorChange={handleOperatorChange}
            field={value.field}
            autocomplete={value.type === 'numeric' ? undefined : {
              fields: [value.field],
            }}
          />
        </FiltersContainer>
      </EventPanel>
    </Container>
  );
};

export default LinearFilter;
