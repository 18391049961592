import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { Container, Section } from './styled';

const UndoRedoButtons = ({ onUndo, onRedo, undoDisabled, redoDisabled }) => {
  const { p } = useTranslation('workflow_page');

  const handleUndo = () => {
    if (undoDisabled) {
      return;
    }

    onUndo();
  };

  const handleRedo = () => {
    if (redoDisabled) {
      return;
    }

    onRedo();
  };

  return (
    <Container>
      <Tooltip title={p('undo') + ' ⌘Z'} placement="left">
        <Section onClick={handleUndo} data-testid={'action-undo'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M11.25 5.625H4.39588L6.41379 3.60793L5.625 2.8125L2.25 6.1875L5.625 9.5625L6.41379 8.76679L4.39757 6.75H11.25C12.1451 6.75 13.0036 7.10558 13.6365 7.73851C14.2694 8.37145 14.625 9.22989 14.625 10.125C14.625 11.0201 14.2694 11.8786 13.6365 12.5115C13.0036 13.1444 12.1451 13.5 11.25 13.5H6.75V14.625H11.25C12.4435 14.625 13.5881 14.1509 14.432 13.307C15.2759 12.4631 15.75 11.3185 15.75 10.125C15.75 8.93153 15.2759 7.78693 14.432 6.94302C13.5881 6.09911 12.4435 5.625 11.25 5.625Z" fill="#1A232E"/>
          </svg>
        </Section>
      </Tooltip>
      <Tooltip title={p('redo') + ' ⌘⇧Z'} placement="left">
        <Section onClick={handleRedo} data-testid={'action-redo'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M6.75 5.625H13.6041L11.5862 3.60793L12.375 2.8125L15.75 6.1875L12.375 9.5625L11.5862 8.76679L13.6024 6.75H6.75C5.85489 6.75 4.99645 7.10558 4.36351 7.73851C3.73058 8.37145 3.375 9.22989 3.375 10.125C3.375 11.0201 3.73058 11.8786 4.36351 12.5115C4.99645 13.1444 5.85489 13.5 6.75 13.5H11.25V14.625H6.75C5.55653 14.625 4.41193 14.1509 3.56802 13.307C2.72411 12.4631 2.25 11.3185 2.25 10.125C2.25 8.93153 2.72411 7.78693 3.56802 6.94302C4.41193 6.09911 5.55653 5.625 6.75 5.625Z" fill="#1A232E"/>
          </svg>
        </Section>
      </Tooltip>
    </Container>
  );
};

export default UndoRedoButtons;
