import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: transparent;
  border-radius: 8px;
  margin-bottom: 3px;
  flex-direction: column;
  position: absolute;
  width: 42px;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  gap: 50px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  opacity: .7;
  transition: opacity 290ms ease;
  
  &:hover {
    opacity: 1;
  }
`;

export const InfoIcon = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: var(--Colors-Additional-White, #FFF);
  cursor: pointer;
`;
