import { Card } from "@velitech/ui"
import styled from 'styled-components';

export const Cardstyled = styled(Card)`
  flex: 1;
  min-width: 153px;
`
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const CardName = styled.h4`
 font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #1A232E;

`;

export const CardDescription = styled.p`
  font-family: Manrope-Medium;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #1A232E;
`;