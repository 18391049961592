import React from 'react';

import { NumberInputField } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { by } from '@utils';

import { Switch } from '@components';

import { Container, Title, CurrencyOptionsContainer, OptionRow, TitleContainer, OptionCol, OptionLabel, OptionPanel, Error } from './styled';

const MoneyRewardSettings = ({ currencyOptions, value, errors, onClearError, onChange }) => {
  const { t, p } = useTranslation('workflow_page');

  // const sortOptions = (a, b) => {
  //   const aIndex = (value || []).findIndex(by('currency', a));
  //   const bIndex = (value || []).findIndex(by('currency', b));
  //
  //   return ~aIndex - ~bIndex || currencyOptions.indexOf(a) - currencyOptions.indexOf(b);
  // };

  const isSelected = (option) => {
    return !!value.find(by('currency', option));
  };

  const getAmount = (option) => {
    return value.find(by('currency', option))?.amount;
  }

  const handleSelectionChange = (option) => () => {
    onClearError(`reward_money_mapping_${option}`);

    if (isSelected(option)) {
      return onChange(os => os.filter(o => o.currency !== option));
    }

    return onChange(os => [...os, { currency: option, amount: 0 }]);
  };

  const handleAmountChange = (option) => ({ target: { value: amount } }) => {
    onClearError(`reward_money_mapping_${option}`);

    return onChange(os => os.map(o => o.currency === option ? {
      ...o,
      amount,
    } : o));
  };

  return (
    <Container>
      <TitleContainer>
        <Title>
          {p('money_reward_title')}
        </Title>
      </TitleContainer>
      <CurrencyOptionsContainer>
        {currencyOptions.map((option) => (
          <OptionRow layout key={option}>
            <OptionCol>
              <OptionLabel>
                {t('labels.currency')}
                <Switch checked={isSelected(option)} onChange={handleSelectionChange(option)} />
              </OptionLabel>
              <OptionPanel $disabled={!isSelected(option)}>
                {option}
              </OptionPanel>
            </OptionCol>
            <NumberInputField
              style={{ width: 100 }}
              label="Amount"
              onChange={handleAmountChange(option)}
              error={errors[`reward_money_mapping_${option}`] && ' '}
              value={getAmount(option)}
              disabled={!isSelected(option)}
            />
            {errors[`reward_money_mapping_${option}`] && (
              <Error>
                {errors[`reward_money_mapping_${option}`]}
              </Error>
            )}
          </OptionRow>
        ))}
      </CurrencyOptionsContainer>
    </Container>
  );
};

export default MoneyRewardSettings;
