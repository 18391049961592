import { DirectionModes } from '@constants';

import { getParent } from '@utils/workflows/refactored/getters';

import { iterate } from './structureParser';

export const createMatrix = (root) => {
  const result = [];
  let tempArray = [];

  iterate(root, (node) => {
    if (!node.id) {
      return;
    }
    if (node.children?.length > 1) {
      tempArray.push(node);
      result.push(tempArray);
      tempArray = [];
    }
    if (node.children?.length === 1) {
      tempArray.push(node);
    }

    if (node.children?.length === 0) {
      tempArray.push(node);
      result.push(tempArray);
      tempArray = [];
    }
  });

  return result;
};

export const findArrayWithId = (matrix, targetId) => {
  for (let i = 0; i < matrix.length; i++) {
    for (let j = 0; j < matrix[i].length; j++) {
      if (matrix[i][j].id === targetId) {
        return matrix[i].map(({ id }) => id);
      }
    }
  }
  return null;
};

const getFromSegments = (data) => {
  let totalCount = 0;

  data?.forEach(segment => {
    if (segment?.query) {
      if (segment.query.children?.[0].query?.field === '_rfm') {
        totalCount += 1;
      } else {
        totalCount += segment.query.children?.[0].query?.filters?.length || 1;
      }
    }
  });

  return totalCount;
};

const getFromChannels = () => {
  return 1;
};

const getFromQuery = (data) => {
  const query = data.children?.[0].query;
  const valueIsArray = Array.isArray(query?.value?.value);

  if(query?.ruleType === 'linear' && valueIsArray) {

    return query.value?.value.length / 3;
  }

  const filters = data.children?.[0].query?.filters?.length || 1;
  const container = 1;

  if(data.children?.[0].query?.filters?.length === 0 || !data.children) {
    return 1;
  }

  return container + filters;
};

const getFromBuilder = (data) => {
  const filters = data.filters?.length;
  const container = 2;

  if(data.filters?.length === 0 || !data?.filters) {
    return container + 1;
  }

  return container + filters;
};

const getChildCount = (data, type) => {
  const propertyConfig = {
    segments: getFromSegments,
    filter_builder: getFromBuilder,
    query: getFromQuery,
    channels: getFromChannels,
  };

  const result = propertyConfig[type](data);

  return {
    filters: result,
    parents: data?.length || 1,
  };
};

const verticalLogic = (nodes, root) => {
  let maxHeight = {};

  return nodes.reduce((nodesResult, currentNode) => {
    const structureConfig = {
      filter: 'segments',
      wait: 'filter_builder',
      quick: 'query',
      filterexclude: 'query',
      bestchannel: 'channels'
    };

    const parent = getParent(root, currentNode.id);
    const childrensIds = parent?.children?.map((child) => child.id);
   
    const property = structureConfig[currentNode.name];
    if (maxHeight[parent?.id] && !currentNode.data?.[property]) {
      // set height for siblings
      nodesResult.push({
        ...currentNode,
        height: maxHeight[parent.id],
      });
      return nodesResult;
    }

    if (!currentNode.data?.[property]) {
      nodesResult.push(currentNode);
      return nodesResult;
    }

    const childCount = getChildCount(currentNode.data?.[property], property);

    const currentHeight = 120 * childCount.parents + 120 * childCount.filters;

    if (!maxHeight[parent.id] || maxHeight[parent.id] < currentHeight) {
      maxHeight = {
        ...maxHeight,
        [parent.id]: currentHeight,
      };
    }

    nodesResult = nodesResult.map((node) => {
      //re-set height for previos siblings node
      if (!childrensIds.includes(node.id)) {
        return node;
      }

      return {
        ...node,
        height: maxHeight[parent.id],
      };
    });

    return [
      ...nodesResult,
      {
        ...currentNode,
        height: maxHeight[parent.id] || currentNode.height,
      },
    ];
  }, []);
};

const horizontalLogic = (nodes, nodesStructure) => {
  let heightConfig = {};
  
  return nodes.reduce((nodesResult, currentNode) => {
    const structureConfig = {
      filter: 'segments',
      wait: 'filter_builder',
      quick: 'query',
      filterexclude: 'query',
      bestchannel: 'channels'
    };

    const property = structureConfig[currentNode.name];

    if (!currentNode.data?.[property]) {
      const height = heightConfig[currentNode.id] || 200;

      nodesResult.push({
        ...currentNode,
        height: height,
      });
      return nodesResult;
    }

    const childCount = getChildCount(currentNode.data?.[property], property);

    const currentHeight = 100 * childCount.parents + 120 * childCount.filters;

    const allSiblings = findArrayWithId(nodesStructure, currentNode.id);

    allSiblings?.forEach((id) => {
      const prevHeightBigger = heightConfig[id] > currentHeight;
      const height = prevHeightBigger ? heightConfig[id] : currentHeight;

      heightConfig = {
        ...heightConfig,
        [id]: height,
      };
    });

    const updatePrevNodes = nodesResult.map((node) => {
      if (!allSiblings?.includes(node.id)) {
        return node;
      }

      return {
        ...node,
        height: heightConfig[node.id],
      };
    });

    updatePrevNodes.push({
      ...currentNode,
      height: heightConfig[currentNode.id],
    });

    return updatePrevNodes;
  }, []);
};

export const modifyHeight = (nodes, directionMode, root, matrix) => {
  if (directionMode === DirectionModes.VERTICAL) {
    return verticalLogic(nodes, root);
  } else {
    return horizontalLogic(nodes, matrix);
  }
};
