import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';
import useSystemAutocomplete from '@hooks/useSystemAutocomplete';

import { moment , by } from '@utils';
import { clickhouseFields2Events } from '@utils/fields';

import { Dropdown } from '@components';
import { resolveTitle } from '@components/lib/SegmentEditor/components/DateFilterPicker/DateFilterPicker';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container, Row } from './styled.js';

import { formatDate, logicOperatortextconfig } from '../../utils.js';

const actions = {
  0: 'wait_day',
  1: 'wait_duration',
  2: 'wait_untill_action',
};

const days = {
  0: 'labels.sunday',
  1: 'labels.monday',
  2: 'labels.tuesday',
  3: 'labels.wednesday',
  4: 'labels.thursday',
  5: 'labels.friday',
  6: 'labels.saturday',
};

const optionsAndthen = {
  0: 'exit_workflow',
  1: 'proceed_workflow'
}

const durationUnits = {
  minutes: 'labels.minutes',
  hours: 'labels.hours',
  days: 'labels.day'
};

const WaitNodeSettingsInfo = ({ node }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const eventsList = clickhouseFields2Events(clickhouseFields.data);

  const durationUnit = durationUnits[node.data?.duration_unit];
  const duration = node.data?.duration;
  const type = actions[node.data?.type];
  const day = days[node.data?.day];
  const send_at = node.data?.send_at;
  const { t, p } = useTranslation('workflow_page');
  const systemAutocomplete = useSystemAutocomplete();

  const event = eventsList.data?.find?.(by(node.data?.event_id));

  const getLabel = (event, field) => {
    const OverrideLabels = {
      "nf_notification_id": 'Template',
      "nf_entity_id": 'Workflow version',
      "nf_sub_entity_id": 'Workflow node',
      "nf_event": 'Status',
    };

    const source = event?.payload?.find?.((source) => source.field === field);

    return OverrideLabels[field] || source?.label;
  }

  const resolveValue = (value, node, filter, event) => {
    const systemOptions = systemAutocomplete?.[filter?.field]?.['event']?.[event];
    const foundFromOptions = (systemOptions || []).find(({ value: v }) => String(v) === String(value))?.label;

    if (foundFromOptions) {
      return foundFromOptions;
    }

    if (Array.isArray(value)) {
      return value.join(
        !!~node.data?.query?.children?.[0]?.query?.operator?.indexOf?.(
          'between'
        )
          ? ' - '
          : ', '
      );
    }

    if (typeof value === 'object' && value !== null) {
      const title = resolveTitle(value, false, p);
      const isDate = title.includes('at');
      if(isDate) return formatDate(title);
      return title
    }

    return value;
  };

  if (!durationUnit && !duration && !type && !day && !send_at) {
    return (
      <Container>
        <InfoTitle>{t('labels.option')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  const renderDuration = () => {
    return (
      <InfoRow>
        <InfoText>
          {!!+node.data.days && node.data.days + ' day' + (node.data.days > 1 ? 's ' : '')}&nbsp;
        </InfoText>
        <InfoText>
          {!!+node.data.hours && node.data.hours + ' hour' + (node.data.hours > 1 ? 's ' : '')}&nbsp;
        </InfoText>
        <InfoText>
          {!!+node.data.minutes && node.data.minutes + ' minute' + (node.data.minutes > 1 ? 's ' : '')}
        </InfoText>
      </InfoRow>
    )
  };

  const getFilterOperationText = (node) => {
    const hasFilters = node.data?.filter_builder.filters?.length > 0;
    const logicalOperator = node.data?.filter_builder.logicalOperator;

    const text = hasFilters
      ? logicOperatortextconfig[logicalOperator]
      : 'workflow_page.logical_operator_was_performed';
    return t(text);
  };

  return (
    <Container>
      <Row padded condition={type !== undefined}>
        <InfoTitle>
          {t('labels.option')}:
        </InfoTitle>
        <InfoText>
          {p(type)}
        </InfoText>
      </Row>
      {node.data?.type === 0 && (
        <>
          <Row padded={send_at !== undefined} condition={day !== undefined}>
            <InfoTitle>
              {t('labels.day')}:
            </InfoTitle>
            <InfoText>
              {t(day)}
            </InfoText>
          </Row>
          <Row padded={send_at !== undefined} condition={day !== undefined}>
            <InfoTitle>
              {t('workflow_page.and_then_sendAt')}:
            </InfoTitle>
            <InfoText>
              {moment(send_at).format('hh:mm A')}
            </InfoText>
          </Row>
        </>
      )}

      {node.data?.type === 1 && (
        <>
          <Row padded={send_at !== undefined} condition={duration !== undefined}>
            <InfoTitle>
              {t('labels.duration')}:
            </InfoTitle>
            {renderDuration()}
          </Row>
          <Row padded={send_at !== undefined} condition={day !== undefined}>
            <InfoTitle>
              {send_at ? t('workflow_page.and_then_sendAt') : t('workflow_page.and_then')}:
            </InfoTitle>
            <InfoText>
              {send_at ? moment(send_at).format('hh:mm A') : 'Send immediately' }
            </InfoText>
          </Row>
        </>
      )}
      {node.data?.type === 2 && (
        <Row padded={send_at !== undefined} condition={duration !== undefined}>
          <Dropdown.Main title={event?.label} description={getFilterOperationText(node)}>
            {node.data?.filter_builder?.filters.length > 0 && node.data?.filter_builder?.filters?.map((filter, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  title={getLabel(event, filter.field)}
                  subTitle={`${t(`segments_page.${filter.operator}`)}:`}
                >
                  {resolveValue(filter.value.value, node, filter, event?.name)}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Main>
          <Row style={{ marginTop: '10px' }}>
            <InfoTitle>
              {t('workflow_page.max_wait_time')}:
            </InfoTitle>
            {renderDuration()}
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <InfoTitle>
              {t('workflow_page.and_then')}:
            </InfoTitle>
            <InfoText>
              {p(optionsAndthen[node.data?.expiration_action])}
            </InfoText>
          </Row>
        </Row>
      )}
      <InfoRow condition={send_at !== undefined && type === 0}>
        <InfoTitle>
          {t('labels.send_at')}:
        </InfoTitle>
        <InfoText>
          {moment(send_at).format('hh:mm A')}
        </InfoText>
      </InfoRow>
      {node.data.analytics && (
        <AnalyticsSection
          analytics={{
            ...(node.data.analytics || {}),
            // out: zeroIfNaN(node.data.analytics?.out / nextLength)
          }}
        />
      )}
    </Container>
  );
}

export default WaitNodeSettingsInfo;

