import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { workflowOptionsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { moment } from '@utils';

import { Modal } from '@components';

import { Container } from './styled';

import { ModalListHeader } from '../ModalListHeader';
import { WorkflowList } from '../WorkflowList';

const AddWorkflowModal = ({ opened, onClose, onSelect, omitValues = [] }) => {
  const { t, p } = useTranslation('workflow_page');
  const options = useSelector(workflowOptionsSelector);

  const [searchTerm, setSearchTerm] = useState('');

  const resolveStatus = (status, qh) => {
    return t(`statuses.${[
      'draft',
      'active',
      'stopped',
      'finished',
      'on_quiet_hours'
    ][status]}`, {
      start: moment(qh?.start)?.format?.('HH:mm'),
      end: moment(qh?.end)?.format?.('HH:mm')
    })
  };

  const workflowOptions = (options.workflows || []).map(({ name, id, version, status, quite_hours }) => ({
    value: id,
    label: `${name} (${version})`,
    status: resolveStatus(status, quite_hours),
  }));

  const filteredOptions = useMemo(() => {
    return workflowOptions.filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  const handleSelect = (v) => {
    onSelect(v);
    onClose();
  };

  return (
    <Modal
      {...{ opened, onClose }}
      style={{ width: 530 }}
      containerStyle={{ width: 530 }}
      contentStyles={{ padding: 0 }}
    >
      <Container>
        <ModalListHeader testId={'add-workflow-modal'} title={p('workflow_list')} onClose={onClose} triggerSettings={true} onSearchChange={setSearchTerm} />
        <WorkflowList omitValues={omitValues} options={filteredOptions} onSelect={handleSelect} />
      </Container>
    </Modal>
  );
};

export default AddWorkflowModal;
