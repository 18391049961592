import { Icon } from '@velitech/ui';

import { Paths, UserPermissions } from '../../constants';

// eslint-disable-next-line import/no-anonymous-default-export
export const sidebarRoutes = (appId) => [
  // {
  //   path: Routes.EXPRESSIONS,
  //   name: 'expressions',
  //   icon: 'Sidebar-Dashboard',
  // },
  {
    path: `/app/${appId}${Paths.DASHBOARD}`,
    name: 'dashboard',
    addonLeft: Icon.NAMES.Home,
    children: 'dashboard',
  },
  {
    name: 'analytics',
    icon: 'Analytic',
    path: `/app/${appId}/analytics`,
    fallback: `/app/${appId}${Paths.FUNNEL_ANALYTICS}`,
    children: 'analytics',
    items: [
      // {
      //    name: 'funnel',
      //    children: 'funnel',
      //    path: `/app/${appId}${Paths.FUNNEL_ANALYTICS}`,
      //    isBeta: true
      //  },
      {
        name: 'rfm',
        children: 'rfm',
        path: `/app/${appId}${Paths.RFM_ANALYTICS}`,
        permission: UserPermissions.REVIEW_RFM,
      }],
  },
  {
    name: 'campaigns',
    path: `/app/${appId}${Paths.CAMPAIGNS}`,
    children: 'campaigns',
    icon: 'Campaign',
    permission: UserPermissions.REVIEW_WORKFLOWS,
  },
  {
    name: 'workflows',
    path: `/app/${appId}${Paths.WORKFLOWS}`,
    children: 'workflows',
    icon: 'Sidebar-Workflow',
    permission: UserPermissions.REVIEW_WORKFLOWS,
  },
  {
    name: 'segments',
    icon: 'Sidebar-Resources',
    path: `/app/${appId}${Paths.RESOURCES_SEGMENTS}/dynamic`,
    permission: UserPermissions.REVIEW_SEGMENTS,
  },
  {
    name: 'contacts',
    children: 'contacts',
    icon: 'Sidebar-Contacts',
    path: `/app/${appId}${Paths.CONTACTS_ALL}`,
    items: [
      {
        name: 'all',
        children: 'all',
        path: `/app/${appId}${Paths.CONTACTS_ALL}`,
        permission: UserPermissions.REVIEW_CONTACTS,
      },
      {
        name: 'import',
        children: 'import',
        permission: UserPermissions.CSV_IMPORT,
        path: `/app/${appId}${Paths.CONTACTS_IMPORT}/import`
      },
      // {
      //   name: 'Import History',
      //   path: Routes.CONTACTS_IMPORT_HISTORY
      // },
      {
        name: 'field_settings',
        children: 'field_settings',
        permission: UserPermissions.REVIEW_CONTACT_FIELDS,
        path: `/app/${appId}${Paths.CONTACTS_FIELDS_SETTINGS}`
      },
      {
        name: 'subscription_groups',
        children: 'subscription_groups',
        permission: UserPermissions.REVIEW_UNSUBSCRIBE_GROUPS,
        path: `/app/${appId}${Paths.SUBSCRIPTION_GROUPS}`,
      },
    ],
  },
  {
    name: 'templates',
    children: 'templates',
    icon: 'Sidebar-Templates',
    path: `/app/${appId}/templates`,
    items: [
      {
        name: 'email',
        children: 'email',
        path: `/app/${appId}${Paths.TEMPLATES_EMAIL}/list/default`,
        permission: UserPermissions.REVIEW_EMAILS,
      },
      {
        name: 'sms',
        children: 'sms',
        path: `/app/${appId}${Paths.TEMPLATES_SMSES}/default`,
        permission: UserPermissions.REVIEW_SMS,
      },
      {
        name: 'viber',
        children: 'viber',
        path: `/app/${appId}${Paths.TEMPLATES_VIBERS}/default`,
      },
      {
        name: 'webpush',
        children: 'webpush',
        path: `/app/${appId}${Paths.TEMPLATES_WEBPUSHES}/default`,
        permission: UserPermissions.REVIEW_WEBPUSHES,
      },
      {
        name: 'mobile_push',
        children: 'mobile_push',
        path: `/app/${appId}${Paths.TEMPLATES_MOBILE_PUSHES}/default`,
        permission: UserPermissions.REVIEW_MOBILE_PUSH,
      },
      {
        name: 'api_request',
        children: 'api_request',
        path: `/app/${appId}${Paths.TEMPLATES_API_REQUESTS}/default`,
        permission: UserPermissions.REVIEW_API_REQUESTS,
      },
      {
        name: 'content_cards',
        children: 'content_cards',
        path: `/app/${appId}/templates/content-card/list/default`,
        permission: UserPermissions.REVIEW_API_REQUESTS, //@TODO
      },
      {
        name: 'web_popups',
        children: 'web_popup',
        path: `/app/${appId}/templates/web-popups/list/default`,
        permission: UserPermissions.REVIEW_API_REQUESTS, //@TODO
      },
    ],
  },
  // {
  //   path: Routes.EVENTS,
  //   name: 'events',
  //   icon: 'Sidebar-Events',
  //   permission: UserPermissions.REVIEW_EVENTS,
  // },
  // {
  //   path: Paths.PROMO_CODES,
  //   name: 'promo_codes',
  //   icon: 'Sidebar-Promo-codes',
  // },
  {
    name: 'notification_history',
    icon: 'Sidebar-Notifications-history',
    path: `/app/${appId}${Paths.NOTIFICATIONS}`,
    permission: UserPermissions.NOTIFICATION_HISTORY,
    // pages: [
    //   {
    //     name: 'Sms',
    //     path: Routes.NOTIFICATIONS_SMS
    //   },
    //   {
    //     name: 'Email',
    //     path: Routes.NOTIFICATIONS_EMAIL
    //   },
    // {
    //   name: 'Mobile pushes',
    //   path: Routes.NOTIFICATIONS_MOBILE_PUSHES
    // },
    // {
    //   name: 'Browser pushes',
    //   path: Routes.NOTIFICATIONS_BROWSER_PUSHES
    // },
    // ],
  },
  // {
  //   path: Routes.UPDATES_HISTORY,
  //   name: 'Updates history',
  //   icon: 'Sidebar-Update-history',
  // },
  // {
  //   path: Routes.APPLICATIONS,
  //   name: 'Applications',
  //   icon: 'Sidebar-Applications',
  // },
]
