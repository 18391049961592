import { Container, Option, OptionTitle, OptionCaption } from './styled';

import { ListNoData } from '../ListNoData';

const EventsList = ({ options, onSelect }) => {
  const handleSelect = (v) => () => {
    onSelect({ id: v });
  };

  return (
    <Container>
      {!options.length && (
        <ListNoData />
      )}
      {options.map(({ value, label }, index) => (
        <Option
          key={value}
          onClick={handleSelect(value)}
          data-testid={'event-trigger-settings-option-' + index}
        >
          <OptionTitle>
            {label}
          </OptionTitle>
          <OptionCaption>
            {/* @TODO: customers count */}
          </OptionCaption>
        </Option>
      ))}
    </Container>
  );
};

export default EventsList;
