import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { emailsSelector, templateSelector } from '@store/selectors';

import {
  RowCell,
  MultiSelectField,
  DateRangeField,
  Badge,
  SingleSelectDataField
} from '@velitech/ui';

import { useModalState, useTranslation, useNavigate } from '@hooks';

import { EmailStatuses, Paths, UserPermissions } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { identity, moment, languageStructureByCode, languageOptions } from '@utils';

import {
  DateCell,
  Link,
  WithPermissions,
  ViewEmailTemplateModal,
  TableLayout
} from '@components';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';

import { TableRowText } from '../../../CustomersScene/pages/FieldSettingsPage/components/AggregateTable/styled';
import { TableActions } from '../../../CustomersScene/pages/FieldSettingsPage/components/TableActions';
import { LanguageItem } from '../LanguageItem';
import { EmailInfoModal } from '../Modals';

const EmailTable = ({ total, table, statusOptions, emailTemplates }) => {
  const emails = useSelector(emailsSelector);
  const emailInfoModal = useModalState();
  const templates = useSelector(templateSelector);
  const emailViewModal = useModalState();
  const navigate = useNavigate();
  const [currentEmail, setCurrentEmail] = useState({});
  const { t, p } = useTranslation('notification_history');
  const languages = useMemo(() => languageStructureByCode(), []) 

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const getStatusColor = (status) => {
    switch (status) {
      case EmailStatuses.DELIVERED:
        return Badge.COLORS.POSITIVE
      case EmailStatuses.SENT:
        return Badge.COLORS.NEUTRAL_500
      case EmailStatuses.FAILED:
        return Badge.COLORS.NEGATIVE_500
      default:
        return Badge.COLORS.NEUTRAL_500
    }
  };

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const handleEmailInfoClick = (data) => {
    setCurrentEmail(data);
    emailInfoModal.open();
  }

  const handleEmailViewClick = (data) => {
    setCurrentEmail(data);
    emailViewModal.open();
  }

  const columns = [
    {
      header: {
        label: t('labels.customer_id'),
        className: 'w-25',
      },
      fixedIndex: 0,
      alwaysVisible: true,
      name: 'customer_id',
      render: (data) => {
        return(
          <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
            <Link type={'secondary'} to={`${Paths.CONTACT}/${data.customer_id}`}>{data.customer_id}</Link>
          </WithPermissions>
        )
      },
    },
    {
      header: {
        label: t('labels.email'),
        className: 'w-25',
      },
      fixedIndex: 1,
      alwaysVisible: true,
      name: 'email',
      sort: true,
      render: (data) => {
        return <TableRowText>{data.email}</TableRowText>;
      },
    },
    {
      header: {
        label: t('labels.template'),
      },
      fixedIndex: 2,
      alwaysVisible: true,
      name: 'template_id',
      render: (data) => {
        const currentTemplateInstance = templates?.email?.find((emailTemp) => emailTemp.id === data.template_id);

        const findLangTemplate = Array.isArray(currentTemplateInstance?.languages) && currentTemplateInstance?.languages?.find(({ language_code }) => language_code === data.language_code);
        const templateDriver = findLangTemplate?.template_driver || 'code';
        return (
          (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_EMAILS}><Link type={'secondary'} to={`${Paths.TEMPLATES_EMAIL}/${templateDriver}/${data.template_id}?view=1`}>{templates?.[TemplateTypes.EMAIL]?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
        )
      }
    },
    {
      header: {
        label: t('labels.date'),
        className: 'w-10',
        sort: true,
      },
      fixedIndex: 3,
      alwaysVisible: true,
      name: 'created_at',
      render: (data) => {
        return (
          <TableRowText>
            <DateCell date={data.created_at} />
          </TableRowText>
        );
      },
    },
    {
      header: {
        label: t('labels.status'),
        className: 'w-10',
      },
      fixedIndex: 4,
      alwaysVisible: true,
      name: 'status',
      render: (data) => {
        return (
          <Badge size={Badge.SIZES.SMALL} variant={Badge.VARIANT.GHOST} color={getStatusColor(data.status)}>
            {data.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: {
        label: t('labels.language_code'),
        className: 'w-10',
      },
      name: 'language_code',
      render: (data) => {
        const languageItem = languages[data?.language_code];
        if(!languageItem) return <></>
        return (
          <LanguageItem icon={languageItem.emoji} code={languageItem.languageCode} name={languageItem.languageName} />
        );
      },
    },
    {
      header: {
        label: t('labels.updated_at'),
      },
      defaultVisible: false,
      name: 'updated_at',
      className: `notification-history-cell-updated_at`,
      render: ({ updated_at: date }) => <DateCell date={date}/>
    },
    {
      header: {
        label: t('labels.group'),
      },
      name: 'group',
      defaultVisible: false,
      className: `notification-history-cell-group`,
      key: 'group',
      render: ({ gn, group }) => <FlexibleText text={gn} maxWidth={200}><WithPermissions name={UserPermissions.REVIEW_UNSUBSCRIBE_GROUPS}><Link type="secondary" to={`${Paths.SUBSCRIPTION_GROUPS}/${group}`}>{gn}</Link></WithPermissions></FlexibleText>
    },
    {
      header: {
        label: t('labels.actions'),
        className: 'w-5',
      },
      fixed: 'right',
      name: 'actions',
      hideFromColumnSettings: true,
      render: (data) => {
        return (
          <TableActions
            onPreview={() => handleEmailViewClick(data)}
            onInfo={() => handleEmailInfoClick(data)}
          />
        );
      },
    },
  ];

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  };

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_EMAIL} hidden>
        <TableLayout
          withTabs
          fullPage
          name="notification_history_emails"
          loading={emails.loading}
          columns={columns}
          data={emails.data || []}
          table={table}
          total={total}
          localizer={tableLocalizer}
          columnSettings={true}
          renderFilter={(
            <>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select template'}
                  label={t('labels.template')}
                  options={(emailTemplates || []).map(({ value, ...rest }) => ({ value: String(value), ...rest }))}
                  onChange={table.filters.onChange('template_id', identity)}
                  value={table.filters.filters['template_id']}
                />
              </RowCell>
             
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  searchHidden
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select status'}
                  label={t('labels.status')}
                  options={statusOptions || []}
                  onChange={table.filters.onChange('status', identity)}
                  value={ table.filters.filters['status']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <SingleSelectDataField
                  disableErrorSpaceReserve
                  className="status-select"
                  placeholder={'Select language code'}
                  label={t('labels.language_code')}
                  options={languageOptions() || []}
                  mapOptionLabel={(data) => <LanguageItem icon={data.emoji} code={data.value} name={data.label} />}
                  mapOptionValue={(data) => data.value}
                  onChange={table.filters.onChange('language_code', identity)}
                  value={ table.filters.filters['language_code']}
                />
              </RowCell>
              <RowCell>
                <DateRangeField
                  label={'Date'}
                  disableErrorSpaceReserve
                  dateFormat={'yyyy/MM/dd'}
                  from={{
                    selected: getStartDate(table.filters.filters.start_date),
                    onChange: (date) => handleChangeDate('start_date', date),
                  }}
                  to={{
                    selected: getEndDate(table.filters.filters.end_date),
                    onChange: (date) => handleChangeDate('end_date', date),
                  }}
                />
              </RowCell>
             
            </>
          )}
        />
      </WithPermissions>
      <EmailInfoModal
        opened={emailInfoModal.opened}
        onClose={emailInfoModal.close}
        data={currentEmail || {}}
      />
      <ViewEmailTemplateModal
        opened={emailViewModal.opened}
        name={templates?.[TemplateTypes.EMAIL]?.find(e => e.id === currentEmail?.template_id)?.name}
        html={currentEmail.html}
        onActionClick={() => navigate(`${Paths.TEMPLATES_EMAIL}/code/${currentEmail.template_id}`)}
        onClose={emailViewModal.close}
      />
    </>
  )
}

export default EmailTable;

