import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { templateSelector, vibersSelector } from '@store/selectors';

import {
  Badge,
  DateRangeField,
  Icon,
  MultiSelectField,
  RowCell,
  SingleSelectDataField
} from '@velitech/ui';
import { Dropdown } from 'antd';

import { useModalState, useTranslation } from '@hooks';

import { Paths, UserPermissions, WebhookStatuses } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { identity, moment, tryHumanizeResponse, languageOptions, languageStructureByCode } from '@utils';

import {
  DateCell,
  ViewViberTemplateModal,
  Link,
  WithPermissions,
  TableLayout
} from '@components';
import { IdType, Overlay } from '@components/lib/styled';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';

import { TableRowText } from '../../../CustomersScene/pages/FieldSettingsPage/components/AggregateTable/styled';
import { TableActions } from '../../../CustomersScene/pages/FieldSettingsPage/components/TableActions';
import { LanguageItem } from '../LanguageItem';

const ViberTable = ({ table, viberTemplates, statusOptions, total }) => {
  const vibers = useSelector(vibersSelector);
  const templates = useSelector(templateSelector);
  const viberInfoModal = useModalState();
  const [currentViber, setCurrentViber] = useState({});
  const { t, p } = useTranslation('notification_history')
  const languages = useMemo(() => languageStructureByCode(), []) 

  const getStatusColor = (status) => {
    switch (status) {
      case WebhookStatuses.DELIVERED:
        return Badge.COLORS.POSITIVE
      case WebhookStatuses.SENT:
        return Badge.COLORS.NEUTRAL_500
      case WebhookStatuses.FAILED:
        return Badge.COLORS.NEGATIVE_500
      default:
        return Badge.COLORS.NEUTRAL_500
    }
  };

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const columns = [
    {
      header: {
        label: t('labels.customer_id'),
        className: 'w-25',
      },
      name: 'customer_id',
      fixedIndex: 0,
      alwaysVisible: true,
      render: (data) => (
        <div>
          <FlexibleText text={data.customer_id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link type={'secondary'} to={`${Paths.CONTACT}/${data.customer_id}`}>{data.customer_id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{data?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      ),
    },
    {
      header: {
        label: t('labels.phone'),
        className: 'w-25',
      },
      name: 'number',
      fixedIndex: 1,
      alwaysVisible: true,
      sort: true,
      render: (data) => {
        return <TableRowText>{data.number}</TableRowText>;
      },
    },
    {
      header: {
        label: t('labels.template'),
        className: 'w-25',
      },
      name: 'template_id',
      fixedIndex: 2,
      alwaysVisible: true,
      sort: true,
      render: (data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_VIBER}><Link type={'secondary'} to={`${Paths.TEMPLATES_SMS}/${data.template_id}?view=1`}>{templates?.[TemplateTypes.VIBER]?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      header: {
        label: t('labels.date'),
        className: 'w-10',
      },
      sort: true,
      fixedIndex: 3,
      alwaysVisible: true,
      name: 'created_at',
      render: (data) => {
        return (
          <TableRowText>
            <DateCell date={data.created_at}/>
          </TableRowText>
        );
      },
    },
    {
      header: {
        label: t('labels.status'),
        className: 'w-10',
      },
      fixedIndex: 4,
      alwaysVisible: true,
      name: 'status',
      render: (data) => {
        return (
          <Badge size={Badge.SIZES.SMALL} variant={Badge.VARIANT.GHOST} color={getStatusColor(data.status)}>
            {data.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: {
        label: t('labels.response'),
        className: 'w-25',
      },
      fixedIndex: 5,
      alwaysVisible: true,
      name: 'response',
      render: (data) => {
        if (!data) {
          return null;
        }

        return (
          <Dropdown
            trigger={['hover']}
            overlay={(
              <Overlay>
                <pre>
                  {tryHumanizeResponse(data)}
                </pre>
              </Overlay>
            )}
          >
            <div style={{ padding: 10 }}>
              <Icon name={Icon.NAMES.Information} size={Icon.SIZES.S16} />
            </div>
          </Dropdown>
        )
      },
    },
    {
      header: {
        label: t('labels.language_code'),
        className: 'w-10',
      },
      name: 'language_code',
      render: (data) => {
        const languageItem = languages[data?.language_code];
        if(!languageItem) return <></>
        return (
          <LanguageItem icon={languageItem.emoji} code={languageItem.languageCode} name={languageItem.languageName} />
        );
      },
    },
    {
      header: {
        label: t('labels.updated_at'),
      },
      defaultVisible: false,
      name: 'updated_at',
      className: `notification-history-cell-updated_at`,
      render: (date) => <DateCell date={date} />
    },
    {
      header: {
        label: t('labels.actions'),
      },
      key: 'actions',
      fixed: 'right',
      hideFromColumnSettings: true,
      render: (data) => {
        const handleViberInfoClick = () => {
          setCurrentViber({ name: data.viber_name, title: data.title, image_url: data.image, btn_name: data.button_name, body: data.message });
          viberInfoModal.open();
        }

        return <TableActions onInfo={handleViberInfoClick}/>
      }
    }
  ];

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  };

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NH_VIBER} hidden>
        <TableLayout
          withTabs
          fullPage
          name="notification_history_viber"
          loading={vibers.loading}
          columns={columns}
          data={vibers.data || []}
          table={table}
          total={total}
          localizer={tableLocalizer}
          columnSettings={true}
          renderFilter={(
            <>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select template'}
                  label={t('labels.template')}
                  options={viberTemplates || []}
                  onChange={table.filters.onChange('template_id', identity)}
                  value={ table.filters.filters['template_id']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select status'}
                  searchHidden
                  label={t('labels.status')}
                  options={statusOptions || []}
                  onChange={table.filters.onChange('status', identity)}
                  value={ table.filters.filters['status']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <SingleSelectDataField
                  disableErrorSpaceReserve
                  className="status-select"
                  placeholder={'Select language code'}
                  label={t('labels.language_code')}
                  options={languageOptions() || []}
                  mapOptionLabel={(data) => <LanguageItem icon={data.emoji} code={data.value} name={data.label} />}
                  mapOptionValue={(data) => data.value}
                  onChange={table.filters.onChange('language_code', identity)}
                  value={ table.filters.filters['language_code']}
                />
              </RowCell>
              <RowCell>
                <DateRangeField
                  label={'Date'}
                  disableErrorSpaceReserve
                  dateFormat={'yyyy/MM/dd'}
                  from={{
                    selected: getStartDate(table.filters.filters.start_date),
                    onChange: (date) => handleChangeDate('start_date', date),
                  }}
                  to={{
                    selected: getEndDate(table.filters.filters.end_date),
                    onChange: (date) => handleChangeDate('end_date', date),
                  }}
                />
              </RowCell>
            </>
          )}
        />
        <ViewViberTemplateModal
          opened={viberInfoModal.opened}
          onClose={viberInfoModal.close}
          data={currentViber || {}}
        />
      </WithPermissions>
    </>
  )
}

export default ViberTable;

