import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  webPopupsNotificationHistorySelector
} from '@store/selectors';

import {
  Badge,
  DateRangeField,
  MultiSelectField,
  RowCell,
  SingleSelectDataField
} from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Paths, UserPermissions, WebPopupStatuses, WebPopupStatusOptions } from '@constants';

import { by, identity, moment, languageStructureByCode, languageOptions } from '@utils';

import { DateCell, Link, TableLayout, WithPermissions } from '@components';
import { IdType } from '@components/lib/styled';
import { FlexibleText } from '@components/ui/Table';

import { WebPopupPreviewModal } from './components';

import { TableActions } from '../../../CustomersScene/pages/FieldSettingsPage/components/TableActions';
import { LanguageItem } from '../LanguageItem';

const WebPopupsTable = ({ table, total, templates }) => {
  const data = useSelector(webPopupsNotificationHistorySelector);
  const { p, t } = useTranslation('notification_history');
  const [openedPreview, setOpenedPreview] = useState(null);
  const languages = useMemo(() => languageStructureByCode(), [])

  const getStatusColor = (status) => {
    switch (status) {
      case WebPopupStatuses.OPENED:
      case WebPopupStatuses.OPT_IN:
        return Badge.COLORS.POSITIVE
      case WebPopupStatuses.SENT:
        return Badge.COLORS.NEUTRAL_500;
      default:
        return Badge.COLORS.NEUTRAL_500;
    }
  };

  const columns = [
    {
      header: {
        label: t('labels.customer_id'),
      },
      name: 'customer_id',
      className: `notification-history-cell-id`,
      render: ({ customer_id: id, ...d }) => (
        <div>
          <FlexibleText text={id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link type="secondary" to={`${Paths.CONTACT}/${id}`}>{id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      ),
    },
    {
      header: {
        label: 'Template name',
      },
      name: 'template_id',
      render: ({ template_id: id }) => {
        const template = templates.find(by('value', id));

        if (!(id && id !== 0)) {
          return <div>{p('test_send')}</div>;
        }

        return (
          <Link type="secondary" to={`${Paths.TEMPLATES_WEB_POPUP}/${id}?view=1`}>{template?.label}</Link>
        );
      }
    },
    {
      header: {
        label: t('labels.date')
      },
      name: 'created_at',
      className: `notification-history-cell-date`,
      render: ({ created_at: date }) => <DateCell date={date}/>
    },
    {
      header: {
        label: 'Status',
      },
      name: 'status',
      render: (data) => {
        return (
          <Badge size={Badge.SIZES.SMALL} variant={Badge.VARIANT.GHOST} color={getStatusColor(data.status)}>
            {data.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: {
        label: t('labels.language_code'),
        className: 'w-10',
      },
      name: 'language_code',
      render: (data) => {
        const languageItem = languages[data?.language_code];
        if(!languageItem) return <></>
        return (
          <LanguageItem icon={languageItem.emoji} code={languageItem.languageCode} name={languageItem.languageName} />
        );
      },
    },
    {
      header: {
        label: 'Actions'
      },
      name: 'id',
      fixed: 'right',
      render: ({ id }) => {
        return (
          <TableActions
            onPreview={() => setOpenedPreview(id)}
          />
        )
      }
    },
  ];

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  };

  return (
    <>
      <TableLayout
        withTabs
        fullPage
        name="notification_history_web_popups"
        loading={data.loading}
        columns={columns}
        data={data.data || []}
        table={table}
        total={total}
        localizer={tableLocalizer}
        renderFilter={(
          <>
            <RowCell className='minWidth-250'>
              <MultiSelectField
                disableErrorSpaceReserve
                className="status-select"
                selectedOptionsLabel="Selected options"
                availableOptionsLabel="Available options"
                placeholder={'Select status'}
                label={t('labels.status')}
                options={WebPopupStatusOptions || []}
                onChange={table.filters.onChange('status', identity)}
                value={ table.filters.filters['status']}
              />
            </RowCell>
            <RowCell className='minWidth-250'>
              <SingleSelectDataField
                disableErrorSpaceReserve
                className="status-select"
                placeholder={'Select language code'}
                label={t('labels.language_code')}
                options={languageOptions() || []}
                mapOptionLabel={(data) => <LanguageItem icon={data.emoji} code={data.value} name={data.label} />}
                mapOptionValue={(data) => data.value}
                onChange={table.filters.onChange('language_code', identity)}
                value={ table.filters.filters['language_code']}
              />
            </RowCell>
            <RowCell>
              <DateRangeField
                label={'Date'}
                disableErrorSpaceReserve
                dateFormat={'yyyy/MM/dd'}
                from={{
                  selected: getStartDate(table.filters.filters.start_date),
                  onChange: (date) => handleChangeDate('start_date', date),
                }}
                to={{
                  selected: getEndDate(table.filters.filters.end_date),
                  onChange: (date) => handleChangeDate('end_date', date),
                }}
              />
            </RowCell>
          </>
        )}
      />
      <WebPopupPreviewModal
        opened={!!openedPreview}
        onClose={() => setOpenedPreview(null)}
        data={data?.data?.find(by(openedPreview)) || {}}
      />
    </>
  );
};

export default WebPopupsTable;
