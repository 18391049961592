import { extract, validateGoalFilter, validateNode, validateSegment } from '@utils';

const validateFilterRule = (rule, _validateCompletionPeriod) => {
  if (rule.type === 'segment') {
    return true;
  }

  const { query } = validateSegment({ query: rule.query, aggregates: [], funnels: [] });

  return query.children[0].query;
};

const validateGoalRules = (filters) => {
  return filters.map((rule) => {
    if (rule.completion_period) {
      if (!rule.completion_period.quantity) {
        return {
          ...rule,
          errors: {
            completion_period: {
              quantity: 'This field is required',
            },
          },
        };
      }

      if (!rule.completion_period.type) {
        return {
          ...rule,
          errors: {
            completion_period: {
              type: 'This field is required',
            },
          },
        };
      }

      if (rule.completion_period.quantity < 1) {
        return {
          ...rule,
          errors: {
            completion_period: {
              quantity: 'Quantity should be greater than 0',
            },
          },
        };
      }
    }

    return rule;
  }).map(rule => validateGoalFilter({ ...rule, filters: rule.filter_builder || [] })).map(extract('query')).map(({ filters, ...q }) => ({ ...q, filter_builder: filters }));
};

const validateFilterRules = (filters) => {
  return filters.map(validateFilterRule)
}

export const validateWorkflowSetup = (worker) => {
  const tree_map = validateNode({ ...(worker.tree_map), label: 'dummy' });

  const participation = validateFilterRules(worker.target_audience || []);
  const exit_criteria = validateFilterRules(worker.exit_criteria || []);
  const goals = validateGoalRules(worker.goals || []);

  return {
    tree_map,
    participation,
    exit_criteria,
    goals: !!goals.length && goals,
  };
};

