import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  aggregatesListSelector,
  optionsSelector,
  workflowOptionsSelector
} from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';
import useSystemAutocomplete from '@hooks/useSystemAutocomplete';

import { WITHOUT_VALUE_OPERATORS, RFMColorsConfig } from '@constants';

import { by, tryParse } from '@utils';
import {
  clickhouseFields2Events,
  clickhouseFields2Customer,
} from '@utils/fields';

import { Dropdown } from '@components';
import { resolveTitle } from '@components/lib/SegmentEditor/components/DateFilterPicker/DateFilterPicker';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import {
  Container,
  Column,
  Divider
} from './styled.js';

import SegmentsRuleTypes from '../../../../../../../constants/segments-refactored/SegmentsRuleTypes.js';
import { formatDate, logicOperatortextconfig } from '../../utils.js';

export const GroupSplitNodeInfo = ({ node }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const events = clickhouseFields2Events(clickhouseFields.data);
  const segmentOptions = useSelector(optionsSelector);
  const workflowOptions = useSelector(workflowOptionsSelector);
  const aggregateList = useSelector(aggregatesListSelector);

  const { t } = useTranslation('date_picker');

  const getRfmOptions = () => {
    const rfmAnalyticsOptions = (workflowOptions?.rfmResources || []).map(({ id, name, resources }) => ({
      value: id,
      label: name,
      options: (resources || [])
        .filter(({ parent_segment_id }) => !parent_segment_id)
        .flatMap(({ segment_id, name, monetary_aggregate }) => [
          {
            value: segment_id,
            label: t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`),
            cfg: RFMColorsConfig.find(by('name', name)),
          },
          ...(resources || [])
            .filter(by('parent_segment_id', segment_id))
            .map(({ segment_id, name: subName }) => {
              const monetaryValues = (tryParse(monetary_aggregate) || []).find(by('monetary_ranks', +subName[subName.length - 1]));

              return {
                value: segment_id,
                label: `${t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`)}.${(+monetaryValues.min).toFixed(2)} - ${(+monetaryValues.max).toFixed(2)} revenue`,
                cfg: RFMColorsConfig.find(by('name', name)),
                rank: +subName[subName.length - 1],
              };
            })
        ])
    }));
    return rfmAnalyticsOptions;
  }

  const resolveRfmTitle = (id, parentId) => {
    const getCurrentRfm = (getRfmOptions() || []).find((option) => option.value === parentId);

    return {
      label: getCurrentRfm?.options?.find(by('value', id))?.label || '',
      rfmLabelParent: getCurrentRfm?.label || ''
    };
  };

  const linearList = useMemo(() => {
    return customerFields?.filter((f) => f.type !== 'array');
  }, [clickhouseFields]);

  const arrayList = useMemo(() => {
    return customerFields
      ?.filter((f) => f.type === 'array')
      .map((a) => ({ ...a, value: a.field }));
  }, [clickhouseFields]);

  const configRuleTypeList = {
    [SegmentsRuleTypes.LINEAR]: linearList,
    [SegmentsRuleTypes.ARRAY]: arrayList,
    [SegmentsRuleTypes.EVENT]: events.data,
  };

  const segments = node?.data.segments;

  const resolveRule = (rule, ruleType) => {
    const list = configRuleTypeList[ruleType];
    if (ruleType === SegmentsRuleTypes.LINEAR) {
      return list.find(by('field', rule))?.label;
    }
    if (
      ruleType === SegmentsRuleTypes.EVENT &&
      events.data?.find?.(by('name', rule))
    ) {
      return events.data?.find?.(by('name', rule))?.label;
    }

    if (segmentOptions.fields?.attributes?.find?.(by('field', rule))) {
      return segmentOptions.fields?.attributes?.find?.(by('field', rule))
        ?.label;
    }

    if (rule === '_c') {
      return t('labels.created_at');
    }

    if (ruleType === SegmentsRuleTypes.AGGREGATE) {
      return (aggregateList.data || []).find(by('name', rule))?.label || rule;
    }

    return rule;
  };

  const systemAutocomplete = useSystemAutocomplete();

  const resolveValue = (v, segment, filter, event) => {
    const systemOptions = systemAutocomplete?.[filter?.field]?.['event']?.[event];
    const foundFromOptions = (systemOptions || []).find(({ value }) => String(value) === String(v.value))?.label;

    if (foundFromOptions) {
      return foundFromOptions;
    }

    const operator = segment?.query?.children?.[0]?.query?.operator;
    if (!v || !!~WITHOUT_VALUE_OPERATORS.indexOf(operator)) {
      return;
    }

    if (Array.isArray(v.value)) {
      return v.value.join(!!~operator?.indexOf?.('between') ? ' - ' : ', ');
    }

    if (typeof v.value === 'object' && v.value !== null) {
      const title = resolveTitle(v.value, false, t);

      const isDate = title.includes('at');

      if (isDate) return formatDate(title);
      return title;
    }

    return v.value;
  };

  const getLabel = (event, field) => {
    const OverrideLabels = {
      "nf_notification_id": 'Template',
      "nf_entity_id": 'Workflow version',
      "nf_sub_entity_id": 'Workflow node',
      "nf_event": 'Status',
    };

    const source = event?.payload.find((source) => source.field === field);

    return OverrideLabels[field] || source?.label;
  };

  const getFilterOperationText = (segment) => {
    const hasFilters = segment?.query?.children?.[0]?.query.filters?.length > 0;
    const logicalOperator = segment?.query?.children?.[0]?.query.logicalOperator;

    const text = hasFilters
      ? logicOperatortextconfig[logicalOperator]
      : 'workflow_page.logical_operator_was_performed';

    return t(text);
  };

  return segments.map((segment, index) => {
    const { ruleType, filters, field, operator, value } = segment?.query?.children?.[0]?.query || {};
    const event = events.data?.find((event) => event.name === field);
    const isEventRuleType = ruleType === 'event';
    const resource = segmentOptions.segments?.find?.(by(segment?.segment_id));
    const isLast = segments.length - 1 === index;

    return (
      <Container key={segment.id}>
        {!isLast && <InfoTitle>{segment?.label}:</InfoTitle>}
        {!segment?.filter_by && !isLast && (
          <Column>
            <Dropdown.Main
              disabled={true}
              title={'Not setted'}
            />
          </Column>
        )}
        {segment?.filter_by === 'rule' && !isEventRuleType && (
          <Column>
            <Dropdown.Item
              testId={`workflow-split-node-${ruleType}-rule-item`}
              type={'secondary'}
              title={resolveRule(field, ruleType)}
              subTitle={`${t(`segments_page.${operator}`)}: `}
            >
              {!~WITHOUT_VALUE_OPERATORS.indexOf(operator) &&
                  value?.value !== undefined &&
                  value?.value !== '' &&
                  value?.value !== null &&
                  resolveValue(value, segment)}
            </Dropdown.Item>
          </Column>
        )}
        {isEventRuleType && segment?.filter_by === 'rule' && (
          <Column>
            <Dropdown.Main
              testId={'workflow-split-node-event-rule-item'}
              title={event?.label}
              description={getFilterOperationText(segment)}
            >
              {filters.length > 0 && filters?.map((filter, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    title={getLabel(event, filter.field)}
                    subTitle={`${t(`segments_page.${filter.operator}`)}:`}
                  >
                    <div>
                      {resolveValue(filter.value, filter.operator, filter, event?.name)}
                    </div>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Main>
          </Column>
        )}
        {segment?.filter_by === 'segment' && segment?.segmentType !== 'rfm' && (

          <Column>
            <Dropdown.Main
              testId={'workflow-split-node-segment-rule-item'}
              title={resource?.name}
            />
          </Column>
        )}
        {segment?.filter_by === 'segment' && segment?.segmentType === 'rfm' && (

          <Column>
            <Dropdown.Main
              testId={'workflow-split-node-segment-rule-item'}
              title={resolveRfmTitle(segment?.segment_id, segment.parentId)?.label}
              description={resolveRfmTitle(segment?.segment_id, segment.parentId).rfmLabelParent}
            />
          </Column>
        )}
        {segment.analytics && (
          <AnalyticsSection analytics={segment?.analytics} />
        )}
        {isLast && (
          <>
            <Divider />
            <Dropdown.Main testId={'workflow-split-node-everyone-else-item'} title={segment?.label} />
          </>
        )}
      </Container>
    );
  });
};
