import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
`;

export const InfoForm = styled.div`
  display: flex;
  padding: 14px 21px 16px 21px;
  background: #F0F2F6;
  border-radius: 2px;
  transition: 0.2s;
  overflow: hidden;
`;

export const NoIntegrations = styled.div`
  padding: 20px 14px;
  font-size: 13px;
  font-family: Manrope-Medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
