import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 0px 47px;
  position: relative;
  background: rgba(250, 250, 250, 0.8);
  border: 1px solid #DCDFE6;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  overflow: visible;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  & > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

export const Row = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: #303133;
  width: 80px;
  margin-left: 10px;
`;

export const Arrow = styled.div`
  height: 4px;
  display: flex;
  position: absolute;
  right: 10px;
  top: -3.8px;
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 20px;
  line-height: 24px;
  color: #303133;
  margin-top: 22px;
  margin-bottom: 3px;
`;

export const Description = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 130%;
  color: #909399;
`;

export const Plus = styled.div`
  font-family: Manrope-Medium;
  font-size: 10px;
  line-height: 12px;
  margin: 0 3px;
  color: #909399;
`;
