import { useEffect } from 'react';

import { useModalState } from '@hooks';

import { Icon } from '@components';

import { Container, TitleRow, Title, Category, CategoryName, Count, DropdownIcon, Option, ItemTitle, SelectedLine } from './styled';

const CategoryMenu = ({ title, name, options, count, onFilterChange, filter, hasSubCategories }) => {
  const expandable = useModalState(true);

  useEffect(() => {
    if (options?.some(o => o.name === filter.name)) {
      expandable.open();
    }
  }, [filter?.name]);

  if (!count) {
    return null;
  }

  const handleClick = (event) => {
    if (hasSubCategories) {
      expandable.toggle();
    } else {
      onFilterChange('category', name)(event);
    }
  }

  return (
    <Category
      $opened={expandable.opened}
      selected={filter?.type === 'category' && filter?.name === name}
    >
      {filter?.name === name && <SelectedLine/>}
      <CategoryName
        selected={filter?.name === name} onClick={hasSubCategories ? expandable.toggle : handleClick }
      >
        <ItemTitle>{title}</ItemTitle>
        {!hasSubCategories ? (
          <Count $selected={filter?.name === name}>
            {count}
          </Count>
        ) : (
          <DropdownIcon selected={expandable.opened}>
            <Icon size={12} name="Dropdown-arrow-icon" />
          </DropdownIcon>
        )}
      </CategoryName>
      {options?.map(({ title, name, count }) => count ? (
        <Option selected={filter?.name === name} key={name} onClick={e => { onFilterChange('sub-category', name)(e) }}>
          {filter.name === name && <SelectedLine/>}
          <ItemTitle>{title}</ItemTitle>
          <Count $selected={filter.name === name}>{count}</Count>
        </Option>
      ) : null)}
    </Category>
  );
}

const MenuFilter = ({ options, title, filter, onFilterChange }) => {
  const handleFilterChange = (type, name) => () => {
    onFilterChange({ type, name });
  };

  return (
    <Container>
      <TitleRow>
        <Title>
          {title}
        </Title>
      </TitleRow>
      {options?.map(({ title, name, options, count }) => (
        <CategoryMenu
          key={name}
          hasSubCategories={!!options?.length}
          title={title}
          name={name}
          options={options}
          count={count}
          filter={filter}
          onFilterChange={handleFilterChange}
        />
      ))}
    </Container>
  );
};

export default MenuFilter;
