import { Container } from './styled';

const TriggerTypeDescription = ({ description }) => {
  return (
    <Container>
      {description}
    </Container>
  );
};

export default TriggerTypeDescription;
