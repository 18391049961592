import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import {
  clearFilterResult, getAggregates,
  getSubscriptionGroups,
} from '@store/actions/creators';
import { getClickhouseFields } from '@store/actions/creators/fields';

import { v4 as uuid } from 'uuid';

import { useTranslation, useSystemOptions } from '@hooks';

import { SegmentsRuleTypes } from '@constants';

import { OptionCard, SliderTabs } from '@components';

import { RFMList } from './components/RFMList/RFMList';
import { SelectFilter } from './components/SelectFilter/SelectFilter';
import { FilterIconSvg, SegmentIconSvg } from './icons';
import { useDefaultFilterNodeSettings } from './module/useDefaultFilterNodeSettings';
import { viewSteps } from './module/utils';
import { Container, Row, Error, Title, Optioncontainer } from './styled';

import SegmentFilter from '../../../../../../../pages/HomeScene/pages/WorkflowScene/pages/WorkflowSetupPage/components/SegmentFilter/SegmentFilter';
import { WorkflowFilter } from '../../../../../../../pages/HomeScene/pages/WorkflowScene/pages/WorkflowSetupPage/components/WorkflowFilter';
import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';
import { List } from '../ui/List';

const filterOptions = [
  {
    key: 'rule',
    label: 'workflow_page.filter_by_attribute',
    description: 'workflow_page.filter_by_attribute_description',

    icon: <FilterIconSvg />,
  },
  {
    key: 'segment',
    label: 'workflow_page.filter_by_segment',
    description: 'workflow_page.filter_by_segment_description',
    icon: <SegmentIconSvg />,

  },
];

const DefaultFilterNodeSettings = ({
  value,
  onChange,
  options,
  errors,
  onClearError,
}) => {

  const filterNodeSettings = useDefaultFilterNodeSettings({
    filterNode: value,
    onChangeFilterNode: onChange,
    onClearFilterNodeError: onClearError,
    errors,
  });
  const { t, p, e } = useTranslation('workflow_page')
  const { id } = useParams();
  const dispatch = useDispatch();
  const { requestOptions } = useSystemOptions();

  useEffect(() => {
    requestOptions();
    dispatch(clearFilterResult());
    dispatch(getClickhouseFields());
    dispatch(getSubscriptionGroups({ entity: 'EMAIL' }));
    dispatch(getAggregates());
  }, []);

  useEffect(() => {
    if (!value?.rule_segment_id && value?.filter_by === 'rule') {
      onChange(s => ({ ...s, rule_segment_id: uuid() }));
    } else if (id === 'new' && value?.filter_by !== 'rule') {
      onChange(s => ({ ...s, rule_segment_id: void 0 }));
    }
  }, [value, id]);

  const handleClickOption = (filter_by) => {
    filterNodeSettings.onChangeFilterBy(filter_by);
  };

  const handleChangeType = (filterType) => {
    filterNodeSettings.onSelectFilterType(filterType);
  }

  const getListLabel = (optionItem) => {
    const isSegment = filterNodeSettings.selectedFilterType === 'segment';
    return isSegment ? optionItem.name : optionItem.label;
  };

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={
          <div style={{ overflowY: 'auto', maxHeight: 900 }}>
            <Title>{t('workflow_page.option_title')}</Title>
            <Optioncontainer>
              {filterOptions.map(({ key, icon, description, label }) => {
                return (
                  <OptionCard
                    testId={`quick-filter-node-settings-${label}`}
                    key={key}
                    className={filterNodeSettings.data.filter_by === key && 'active'}
                    iconNode={icon}
                    title={t(label)}
                    description={t(description)}
                    onClick={() => handleClickOption(key)}
                  />
                );
              })}
            </Optioncontainer>

            {(filterNodeSettings.step === viewSteps.ATTRIBUTE_LIST ||
              filterNodeSettings.step === viewSteps.SEGMENT_LIST) && (
              <SelectFilter
                testId={'quick-filter-node-settings'}
                options={filterNodeSettings.listTypeOptions}
                type={filterNodeSettings.selectedFilterType}
                onChangeType={handleChangeType}
                containerStyles={{
                  gap: '55px',
                }}
              />
            )}
            {filterNodeSettings.step === viewSteps.SHOW_LIST && (
              <div style={{
                maxWidth: '500px',
                margin: '0 auto',
              }}>
                {filterNodeSettings.selectedFilterType !== SegmentsRuleTypes.RFM_SEGMENT && (
                  <div>

                    <List
                      filter
                      testId={'quick-filter'}
                      filterType={filterNodeSettings.selectedFilterType}
                      title={p(filterNodeSettings.listTitle)}
                      options={filterNodeSettings.listBySelectedType}
                      renderLabel={(optionItem) => getListLabel(optionItem)}
                      onSelect={filterNodeSettings.onSelectAttributeField}
                      onClose={filterNodeSettings.onCloseList}
                      headerComponent={ filterNodeSettings.selectedFilterType === 'segment' && 
                        <div style={{ padding: '0 20px', margin: '14px 0' }}>
                          <SliderTabs
                            style={{ height: '30px' }}
                            tabs={filterNodeSettings.segmentTabs}
                            tabStyle={{ width: '100%', padding: '5px 20px', fontSize: '11px' }}
                            tab={filterNodeSettings.segmentTab}
                            onTabChange={filterNodeSettings.setSegmentTab}
                          />
                        </div>
                      }
                    />
                  </div>
                )}
                {filterNodeSettings.selectedFilterType === SegmentsRuleTypes.RFM_SEGMENT && (
                  <RFMList
                    testId={'quick-filter'}
                    title={p(filterNodeSettings.listTitle)}
                    onSelect={filterNodeSettings.onSelectRFMSegment}
                    options={options}
                    onClose={filterNodeSettings.onCloseList}
                    isWorkflow
                  />
                )}
              </div>
            )}
            {filterNodeSettings.step === viewSteps.SHOW_SELECTED_FILTER && (
              <div style={{
                maxWidth: '500px',
                margin: '0 auto'
              }}>
                <WorkflowFilter
                  isNodeSettings={true}
                  smaller
                  type={filterNodeSettings.data.query?.children?.[0]?.query?.ruleType}
                  onDelete={filterNodeSettings.onDeleteSelectedFilter}
                  options={options}
                  errors={filterNodeSettings.displayErrors && filterNodeSettings.data.query?.children?.[0]?.query}
                  onClearError={onClearError}
                  onChange={filterNodeSettings.onFilterChange(
                    filterNodeSettings.data.query?.children?.[0]?.query?.ruleType
                  )}
                  value={filterNodeSettings.getWorkflowValue()}
                />
              </div>
            )}
            {filterNodeSettings.step === viewSteps.SHOW_SELECTED_SEGMENT && (
              <Row style={{ alignItems: 'flex-end' }}>
                <div style={{
                  maxWidth: '500px',
                  width: '100%',
                  margin: '0 auto' }}>
                  <SegmentFilter
                    key={filterNodeSettings.data.segment_id}
                    type={filterNodeSettings.data.type}
                    id={filterNodeSettings.data.segment_id}
                    parentId={filterNodeSettings.data.parentId}
                    onDelete={filterNodeSettings.onDeleteSelectedFilter}
                    getSegmentType={filterNodeSettings.getSegmentType}
                  />
                </div>
              </Row>
            )}
            {errors['query'] && (
              <Error>{errors['query']?.['filters']}</Error>
            )}

            {errors['segment_id'] && (
              <Error>{e('at_least_one_filter_required')}</Error>
            )}
          </div>
        }
      />
    </Container>
  );
};

export default DefaultFilterNodeSettings;
