import { v4 as uuidv4 } from 'uuid';

import { WorkflowNodes } from '@constants';

import { alphabet, sortGroupByType } from './utils';

export const useManageGroups = ({ id, groupsList, onStylesChange }) => {
  const onUpdateSegments = (updatedSegments) => {
    onStylesChange((prevState) => ({
      ...prevState,
      segments: updatedSegments,
    }));
  };

  const getLetter = (list) => {
    const usedLetters = list.map((group) => {
      const labelGroup = group.label.replace('Group' ,'').trim();
      return labelGroup;
    });
    const letters = alphabet.filter((letter) => !usedLetters.includes(letter));

    const letter = letters[0];

    return letter;
  }

  const onAddGroup = () => {
    const letter = getLetter(groupsList);

    const newGroup = {
      id: uuidv4(),
      parentId: id,
      type: WorkflowNodes.INCLUDE,
      actionType: WorkflowNodes.INCLUDE,
      label: `Group ${letter}`,
    };
    const newGroupList = [...groupsList, newGroup].sort(sortGroupByType);

    onUpdateSegments(newGroupList);

    return newGroup;
  };

  const onRemoveGroup = (groupId, callback) => {

    const updatedGroup = groupsList.filter((group) => group.id !== groupId);
    onUpdateSegments(updatedGroup);

    callback();
  };

  const onChangeGroupName = (id, newGroupName) => {
    const updatedGroup = groupsList.map((group) => {
      if (group.id === id) {
        return {
          ...group,
          label: newGroupName,
        };
      }
      return group;
    });

    onUpdateSegments(updatedGroup);
  };

  return { onAddGroup, onRemoveGroup, onChangeGroupName }
};
