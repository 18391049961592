export const CalendarIconSvg = () => {
  return (

    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.88281" y="4.76953" width="47.1831" height="46.2384" rx="4.15116" fill="#F8F4E4" stroke="#303133"/>
      <path
        d="M2.96421 8.92069C2.96421 6.67302 4.78631 4.85093 7.03398 4.85093H45.9148C48.1625 4.85093 49.9846 6.67302 49.9846 8.92069V15.1765H2.96421V8.92069Z"
        fill="#87E2BA" stroke="#303133" strokeWidth="1.16279"/>
      <line x1="3.32812" y1="15.1094" x2="49.6217" y2="15.1094" stroke="#303133"/>
      <rect x="14" y="1.43359" width="1.33" height="5.14" rx="0.665" fill="#303133"/>
      <rect x="21.3281" y="1.43359" width="1.33" height="5.14" rx="0.665" fill="#303133"/>
      <rect x="28.6562" y="1.43359" width="1.33" height="5.14" rx="0.665" fill="#303133"/>
      <rect x="35.9922" y="1.43359" width="1.33" height="5.14" rx="0.665" fill="#303133"/>
      <path
        d="M23.5837 41.5984C23.4396 41.5984 23.3195 41.5464 23.2234 41.4424C23.1273 41.3211 23.0792 41.1824 23.0792 41.0264C23.0792 40.9571 23.0952 40.8791 23.1273 40.7924L28.9172 26.4924H21.3735C21.1973 26.4924 21.0531 26.4318 20.941 26.3104C20.8449 26.1891 20.7969 26.0418 20.7969 25.8684V24.0484C20.7969 23.8578 20.8449 23.7018 20.941 23.5804C21.0531 23.4591 21.1973 23.3984 21.3735 23.3984H31.9203C32.0965 23.3984 32.2326 23.4591 32.3287 23.5804C32.4408 23.7018 32.4969 23.8578 32.4969 24.0484V25.6604C32.4969 25.9031 32.4729 26.1111 32.4248 26.2844C32.3928 26.4404 32.3447 26.6051 32.2807 26.7784L26.5388 40.9744C26.4907 41.0958 26.4026 41.2344 26.2745 41.3904C26.1624 41.5291 25.9942 41.5984 25.77 41.5984H23.5837Z"
        fill="#303133"/>
    </svg>
  )
}