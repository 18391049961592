import { useEffect, useRef, useState } from 'react';

import { useTranslation } from '@hooks';

import {
  DATETIME_DATE_PICKER_OPERATORS, DATETIME_SELECT_UNIT_OPERATORS,
  MULTIPLE_VALUE_OPERATORS, OPERATOR_MATCHES_RANGE, OPERATOR_MATCHES_REGEXP, OPERATOR_NOT_MATCHES_REGEXP,
  SELECT_GROUPS,
  SINGLE_VALUE_OPERATORS,
  TWO_VALUE_OPERATORS,
  WITHOUT_VALUE_OPERATORS
} from "@constants";

import { testId as createTestId } from '@utils';

import { AutoComplete, Select, TagAutoComplete } from '@components';
import { DateFilterPicker } from '@components/lib/SegmentEditor/components';

import { OperatorMenu } from './components/OperatorMenu';
import { Container, Row, FieldRow, FieldLabel } from './styled';

const OperatorSelect = ({
  testId,
  align,
  labeled,
  type: outerType,
  value,
  smaller,
  disabled,
  appearance = 'default',
  field,
  autocomplete,
  className,
  validateDate,
  additionalOptions = null,
  additionalOptionsOptions,
  onValueTypeChange,
  initialIsField = false,
  initialType = 'numeric',
  style = {},
  wrapperStyle = {},
  onOperatorChange,
  strictOptions,
  onValueChange,
  hideOperator,
  isAggregate = false,
  eventSubFilter,
  isNodeSettings,
  isFilterSplit,
  isStepper,
  changeDisabled,
  ...props
}) => {
  const [tab, setTab] = useState(initialType);
  // eslint-disable-next-line no-unused-vars
  const [fieldSelected, setFieldSelected] = useState(initialIsField);
  const { p, t } = useTranslation('segments_page');
  const selectRef = useRef();
  const [type, setType] = useState(outerType);

  useEffect(() => {
    if (!outerType || outerType === 'common') {
      return;
    }

    setType(outerType);
  }, [outerType]);

  useEffect(() => {
    if (!initialType) {
      return;
    }
    setTab(initialType);
  }, [initialType]);

  const handleAutoCompleteValueChange = (v, isField, option) => {
    onValueChange(value.valueType === 'query-variable' ? option?.value : v, isField);
  };

  const handleBetweenValueChange = ([nf, ns]) => {
    const parseNum = (n) => {
      return String(n).length === String(+n).length ? +n : n;
    };

    onValueChange([(nf === undefined ? value?.value?.[0] : nf === '' ? '' : parseNum(nf)), (ns === undefined ? value?.value?.[1] : ns === '' ? '' : parseNum(ns))]);
  }

  const handleMultipleValueChange = (vs) => {
    onValueChange(vs, false);
  }

  const handleOperatorChange = (operator, type) => {
    selectRef?.current?.onClose();
    onOperatorChange(operator, type);
    setFieldSelected(false);
  };

  const fullWidth = align === 'column' || props.fullWidth;
  const inputType = (type === 'numeric' && value.operator !== OPERATOR_MATCHES_REGEXP && value.operator !== OPERATOR_NOT_MATCHES_REGEXP) ? 'number' : undefined;

  const resolveLabel = () => {
    switch (value.valueType) {
      case 'variable':
        return t('labels.field');
      case 'query-variable':
        return t('labels.query');
      case 'scalar':
      default:
        return t('labels.value');
    }
  };

  const tid = createTestId('operator-select');
  const resolveSecondInput = (operator) => {

    if (WITHOUT_VALUE_OPERATORS.includes(operator)) {
      return null;
    }

    if (strictOptions) {
      if (smaller && appearance === 'column') {
        return (
          <FieldRow>
            <FieldLabel>
              {t('labels.value')}
            </FieldLabel>
            <Select
              disabled={disabled || changeDisabled}
              onChange={onValueChange}
              title={t('labels.select')}
              absoluteError
              errorStyles={{ top: 55 }}
              containerStyle={{ width: '100%' }}
              wrapperStyles={{ width: '100%' }}
              value={value.value}
              suggestionsOptions={autocomplete}
              getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
              style={{ margin: 0, width: fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
              tooltipError={value?.errors?.value}
              options={strictOptions}
            />
          </FieldRow>
        );
      }
      return (
        <Select
          disabled={disabled || changeDisabled}
          onChange={onValueChange}
          label={labeled && t('labels.value')}
          title={t('labels.select')}
          absoluteError
          suggestionsOptions={autocomplete}
          getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
          errorStyles={{ top: 55 }}
          value={value.value}
          style={{ margin: 0, width: fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
          tooltipError={value?.errors?.value}
          options={strictOptions}
        />
      );
    }

    if (TWO_VALUE_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <>
            <FieldRow>
              <FieldLabel>
                {p('value_from')}
              </FieldLabel>
              <AutoComplete
                key="1"
                testId={`${testId}-autocomplete-two-1`}
                style={{ margin: 0, border: 'auto', borderRadius: '13px' }}
                smaller={smaller}
                fullWidth={fullWidth}
                title={labeled && resolveLabel()}
                disabled={disabled || changeDisabled}
                field={field}
                suggestionsOptions={autocomplete}
                type={inputType}
                valueType={value.valueType}
                onPlusChange={onValueTypeChange}
                options={additionalOptions?.length ? additionalOptions : void 0}
                additionalOptions={additionalOptionsOptions}
                value={(!value.value[0] && value.value[0] !== 0) ? '' : value.value[0]}
                error={value?.errors?.value?.[0] || value?.errors?.value}
                onChange={v => handleBetweenValueChange([v])}
                getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
                isTwoValues={true}
                isFilterSplit={isFilterSplit}
              />
            </FieldRow>
            <FieldRow>
              <FieldLabel>
                {p('value_to')}
              </FieldLabel>
              <AutoComplete
                key="2"
                testId={`${testId}-autocomplete-two-2`}
                style={{ margin: 0, border: 'auto', borderRadius: '13px' }}
                smaller={smaller}
                fullWidth={fullWidth}
                field={field}
                suggestionsOptions={autocomplete}
                disabled={disabled || changeDisabled}
                title={labeled && resolveLabel()}
                valueType={value.valueType}
                onPlusChange={onValueTypeChange}
                options={additionalOptions?.length ? additionalOptions : void 0}
                additionalOptions={additionalOptionsOptions}
                type={inputType}
                value={(!value.value[1] && value.value[1] !== 0) ? '' : value.value[1]}
                error={value?.errors?.value?.[1] || value?.errors?.value}
                // eslint-disable-next-line no-sparse-arrays
                onChange={v => handleBetweenValueChange([, v])}
                getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
                isTwoValues={true}
              />
            </FieldRow>
          </>
        );
      }
      return (
        <>
          <AutoComplete
            key="1"
            testId={`${testId}-autocomplete-two-1`}
            style={{ margin: 0, border: 'auto', borderRadius: '13px', marginBottom: appearance !== 'column' ? 0 : 16 }}
            smaller={smaller}
            fullWidth={fullWidth}
            title={labeled && p('value_from')}
            disabled={disabled || changeDisabled}
            suggestionsOptions={autocomplete}
            field={field}
            type={inputType}
            value={(!value.value[0] && value.value[0] !== 0) ? '' : value.value[0]}
            altSelection={!!additionalOptionsOptions?.length}
            valueType={value.valueType}
            operator={value.operator}
            operatorType={value.type}
            onPlusChange={onValueTypeChange}
            options={additionalOptions?.length ? additionalOptions : void 0}
            additionalOptions={additionalOptionsOptions}
            error={value?.errors?.value?.[0] || value?.errors?.value}
            onChange={v => handleBetweenValueChange([v])}
            getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
            twoValues={true}
          />
          <AutoComplete
            key="2"
            testId={`${testId}-autocomplete-two-2`}
            style={{ margin: 0, border: 'auto', borderRadius: '13px', marginLeft: eventSubFilter ? '-35px' : appearance !== 'column' ? 6 : 0 }}
            smaller={smaller}
            fullWidth={fullWidth}
            field={field}
            suggestionsOptions={autocomplete}
            title={labeled && p('value_to')}
            disabled={disabled || changeDisabled}
            altSelection={!!additionalOptionsOptions?.length}
            valueType={value.valueType}
            operator={value.operator}
            operatorType={value.type}
            onPlusChange={onValueTypeChange}
            options={additionalOptions?.length ? additionalOptions : void 0}
            additionalOptions={additionalOptionsOptions}
            type={inputType}
            value={(!value.value[1] && value.value[1] !== 0) ? '' : value.value[1]}
            error={value?.errors?.value?.[1] || value?.errors?.value}
            // eslint-disable-next-line no-sparse-arrays
            onChange={v => handleBetweenValueChange([, v])}
            getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
            twoValues={true}
          />
        </>
      );
    }

    if (MULTIPLE_VALUE_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <FieldRow>
            <FieldLabel>
              {p('values')}
            </FieldLabel>
            <TagAutoComplete
              testId={`${testId}-autocomplete-multiple`}
              disabled={disabled || changeDisabled}
              onChange={handleMultipleValueChange}
              field={field}
              suggestionsOptions={autocomplete}
              type={inputType}
              value={value.value || []}
              smaller={smaller}
              title={labeled && t('labels.value')}
              error={value?.errors?.value}
              containerStyle={{ width: fullWidth ? '100%' : '186px' }}
              style={{ margin: 0, width: fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
              getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
              isStepper={isStepper}
              eventSubFilter={eventSubFilter}
            />
          </FieldRow>
        );
      }
      return (
        <TagAutoComplete
          testId={`${testId}-autocomplete-multiple`}
          disabled={disabled || changeDisabled}
          className={className}
          onChange={handleMultipleValueChange}
          field={field}
          smaller={smaller}
          placeholder={t('actions.select')}
          suggestionsOptions={autocomplete}
          getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
          type={inputType}
          value={value.value || []}
          title={labeled && t('labels.value')}
          error={value?.errors?.value}
          containerStyle={{ width: fullWidth ? '100%' : isStepper ? '210px' : '186px' }}
          style={{ background: disabled || changeDisabled ? '#f5f5f5': '', display: 'flex', alignItems: 'flex-end' , margin: 0, width: isFilterSplit ? '186px' : isNodeSettings ? '220px' : isStepper ? '210px' : eventSubFilter ? '250px' : fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
          isStepper={isStepper}
          eventSubFilter={eventSubFilter}
        />
      );
    }

    if (DATETIME_DATE_PICKER_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <FieldRow>
            <FieldLabel>
              {t('labels.value')}
            </FieldLabel>
            <DateFilterPicker
              value={value.value}
              title={labeled && t('labels.value')}
              onChange={onValueChange}
              disabled={disabled || changeDisabled}
              validateDate={validateDate}
              tooltipError={value?.errors?.value}
              style={{ width: isNodeSettings ? '186px' : isStepper ? '210px' : eventSubFilter ? '250px' : '' }}
              containerStyle={{ width: '100%' }}
              disabledTabs={operator === OPERATOR_MATCHES_RANGE ? [] : ['specific']}
              large={!smaller}
            />
          </FieldRow>
        );
      }

      return (
        <DateFilterPicker
          {...tid(`date-picker-input`)}
          value={value.value}
          title={labeled && t('labels.value')}
          onChange={onValueChange}
          validateDate={validateDate}
          tooltipError={value?.errors?.value}
          disabled={disabled || changeDisabled}
          style={{ width: isNodeSettings ? '220px' : isFilterSplit ? '186px' : isStepper ? '210px' : eventSubFilter ? '250px' : fullWidth && '100%' }}
          containerStyle={{ width: fullWidth && '100%' }}
          disabledTabs={operator === OPERATOR_MATCHES_RANGE ? [] : ['specific']}
          large={!smaller}
        />
      )
    }
    if (DATETIME_SELECT_UNIT_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <FieldRow>
            <FieldLabel>
              {t('labels.value')}
            </FieldLabel>
            <Select
              disabled={disabled || changeDisabled}
              onChange={onValueChange}
              title={t('labels.select')}
              absoluteError
              errorStyles={{ top: 55 }}
              containerStyle={{ width: '100%' }}
              wrapperStyles={{ width: '100%' }}
              value={value.value}
              suggestionsOptions={autocomplete}
              getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
              style={{ margin: 0, width: fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
              tooltipError={value?.errors?.value}
              options={[
                { value: 'years', label: p('year') },
                { value: 'months', label: p('month') },
                { value: 'weeks', label: p('week') },
                { value: 'year_days', label: p('day_of_year') },
                { value: 'week_days', label: p('day_of_week') },
                { value: 'days', label: p('day_of_month') },
                { value: 'days_months', label: p('day_and_month') },
                { value: 'hours', label: p('hour') },
                { value: 'minutes', label: p('minute') }
              ]}
            />
          </FieldRow>
        );
      }
      return (
        <Select
          disabled={disabled || changeDisabled}
          onChange={onValueChange}
          label={labeled && t('labels.value')}
          title={t('labels.select')}
          absoluteError
          suggestionsOptions={autocomplete}
          getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
          errorStyles={{ top: 55 }}
          value={value.value}
          style={{ margin: 0, width: fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
          tooltipError={value?.errors?.value}
          options={[
            { value: 'years', label: p('year') },
            { value: 'months', label: p('month') },
            { value: 'weeks', label: p('week') },
            { value: 'year_days', label: p('day_of_year') },
            { value: 'week_days', label: p('day_of_week') },
            { value: 'days', label: p('day_of_month') },
            { value: 'days_months', label: p('day_and_month') },
            { value: 'hours', label: p('hour') },
            { value: 'minutes', label: p('minute') }
          ]}
        />
      );
    }

    if (SINGLE_VALUE_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <FieldRow>
            <FieldLabel>
              {t('labels.value')}
            </FieldLabel>
            <AutoComplete
              testId={`${testId}-autocomplete-one`}
              style={{ margin: 0, border: 'auto', borderRadius: '13px' }}
              smaller={smaller}
              fullWidth={fullWidth}
              field={field}
              suggestionsOptions={autocomplete}
              altSelection={!!additionalOptionsOptions?.length}
              valueType={value.valueType}
              onPlusChange={onValueTypeChange}
              options={additionalOptions?.length ? additionalOptions : void 0}
              additionalOptions={additionalOptionsOptions}
              fieldSelected={value.valueType !== 'scalar'}
              title={labeled ? resolveLabel() : null}
              type={inputType}
              disabled={disabled || changeDisabled}
              setFieldSelected={setFieldSelected}
              operator={value.operator}
              operatorType={value.type}
              value={value.value}
              error={value?.errors?.value}
              onChange={handleAutoCompleteValueChange}
              getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
              isNodeSettings={isNodeSettings}
              isFilterSplit={isFilterSplit}
            />
          </FieldRow>
        );
      }

      return (
        <AutoComplete
          testId={`${testId}-autocomplete-one`}
          style={{ margin: 0, border: 'auto', borderRadius: '13px', width: isNodeSettings ? '250px' : isStepper ? '210px' : isFilterSplit ? '186px' : eventSubFilter ? '283px' : '' }}
          smaller={smaller}
          fullWidth={fullWidth}
          field={field}
          suggestionsOptions={autocomplete}
          altSelection={!!additionalOptionsOptions?.length}
          additionalOptions={additionalOptionsOptions}
          options={additionalOptions?.length ? additionalOptions : void 0}
          fieldSelected={value.valueType !== 'scalar'}
          title={labeled ? resolveLabel() : null}
          type={inputType}
          disabled={disabled || changeDisabled}
          setFieldSelected={setFieldSelected}
          operator={value.operator}
          operatorType={value.type}
          value={value.value}
          error={value?.errors?.value}
          valueType={value.valueType}
          onChange={handleAutoCompleteValueChange}
          onPlusChange={onValueTypeChange}
          getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
          eventSubFilter={eventSubFilter}
          isNodeSettings={isNodeSettings}
          isFilterSplit={isFilterSplit}
          isStepper={isStepper}
        />
      )
    }
  };

  return (
    <Container data-testid={testId} style={wrapperStyle}>
      <Row align={align || appearance} $eventSubFilter={eventSubFilter}>
        {!hideOperator && (smaller && appearance === 'column' ? (
          <FieldRow>
            <FieldLabel>
              {t('labels.operator')}
            </FieldLabel>
            <Select
              testId={`${testId}-inner`}
              style={{
                width: '100%',
                background: '#F9FBFF',
                height: smaller ? 26 : undefined,
                ...style,
              }}
              wrapperStyles={{
                marginBottom: align === 'column' ? '16px' : 0,
                width: '100%',
                height: (smaller && !labeled) ? 26 : undefined,
              }}
              ref={selectRef}
              value={value.operator}
              tooltipError={value.errors?.operator || value?.errors?.operator}
              disabled={disabled || changeDisabled}
              label={labeled && t('labels.operator')}
              optionGroups={SELECT_GROUPS(p)}
              getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
              customOverlay={({ opened }) => (
                <OperatorMenu
                  isAggregate={isAggregate}
                  tab={tab}
                  setTab={setTab}
                  opened={opened}
                  onChange={handleOperatorChange}
                />
              )}
            />
          </FieldRow>
        ) : (
          <Select
            testId={`${testId}-inner`}
            style={{
              width: '186px',
              background: '#F9FBFF',
              height: smaller ? 26 : undefined,
              ...style,
            }}
            wrapperStyles={{
              marginBottom: align === 'column' || appearance === 'column' ? '16px' : 0,
              width: eventSubFilter ? '145px' : align === 'column' || appearance === 'column' ? 'auto' : 186,
              marginRight: '6px',
              height: (smaller && !labeled) ? 26 : undefined,
            }}
            ref={selectRef}
            value={value.operator}
            tooltipError={value.errors?.operator || value?.errors?.operator}
            disabled={disabled || changeDisabled}
            label={labeled && t('labels.operator')}
            optionGroups={SELECT_GROUPS(p)}
            getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
            customOverlay={({ opened }) => (
              <OperatorMenu
                tab={tab}
                opened={opened}
                type={type}
                isAggregate={isAggregate}
                setTab={setTab}
                onChange={handleOperatorChange}
              />
            )}
          />
        ))}
        {resolveSecondInput(value.operator)}
      </Row>
    </Container>
  );
};

export default OperatorSelect;
