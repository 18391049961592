import { useState } from 'react';
import { useSelector } from 'react-redux';

import { optionsSelector, workflowOptionsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { RFMColorsConfig } from '@constants';

import { by, sequence, tryParse } from '@utils';

import { Modal, SliderTabs } from '@components';

import { RFMSegmentsList, SegmentsList, SegmentsListHeader, SegmentTypeCard } from './components';
import { Container, Row, Description, ListContainer } from './styled';

import { CloseButton } from '../ModalListHeader/styled';

const sortOption = (a, b) => a.cfg?.order - b.cfg?.order;

const sortSub = (a, b) => a.rank = b.rank;

const getTabs = (p) => [
  { name: 'dynamic', label: p('dynamic_segment') },
  { name: 'static', label: p('static_segment') },
];

const AddSegmentModal = ({ opened, onClose, onSelect,onSelectRfm, omitSegments = [] }) => {
  const { p, t } = useTranslation('workflow_page');
  const [type, setType] = useState();
  const [hoveredType, setHoveredType] = useState();
  const segmentOptions = useSelector(optionsSelector);
  const workflowOptions = useSelector(workflowOptionsSelector);
  const [searchTerm, setSearchTerm] = useState('');
  const tabs = getTabs(p)
  const [value, setValue] = useState(tabs[0].name);

  const getSegmentOptions = (segmentOptions.segments || []).map(({ id, name, customers, type }) => ({
    value: id,
    label: name,
    customers,
    type
  }))

  const rfmAnalyticsOptions = (workflowOptions?.rfmResources || []).map(({ id, name, resources }) => ({
    value: id,
    label: name,
    options: (resources || [])
      .filter(({ parent_segment_id }) => !parent_segment_id)
      .flatMap(({ segment_id, name, monetary_aggregate }) => [
        {
          value: segment_id,
          label: t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`),
          cfg: RFMColorsConfig.find(by('name', name)),
        },
        ...(resources || [])
          .filter(by('parent_segment_id', segment_id))
          .map(({ segment_id, name: subName }) => {
            const monetaryValues = (tryParse(monetary_aggregate) || []).find(by('monetary_ranks', +subName[subName.length - 1]));

            return {
              value: segment_id,
              label: `${t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`)}.${(+monetaryValues.min).toFixed(2)} - ${(+monetaryValues.max).toFixed(2)} revenue`,
              cfg: RFMColorsConfig.find(by('name', name)),
              rank: +subName[subName.length - 1],
            };
          })
          .sort(sortSub)
      ])
      .sort(sortOption)
  }));

  const searchedOptions = (options, searchTerm) => {
    return options.filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleClose = () => {
    setType();
    onClose();
  };

  const handleSelect = sequence(onSelect, () => setType(), onClose);
  const handleSelectRfm = sequence(onSelectRfm, () => setType(), onClose);

  const resolveDescription = () => {
    if (!hoveredType) {
      return 'choose_segment_type';
    }
    if (hoveredType === 'rfm') {
      return 'choose_segment_rfm';
    }

    return 'choose_segment_default';
  };
  const staticSegment = getSegmentOptions?.filter((segment) => segment.type === 'static');
  const dynamicSegment = getSegmentOptions?.filter((segment) => segment.type === 'dynamic');
  const segmentTypeConfig = {
    ['static']: searchedOptions(staticSegment, searchTerm),
    ['dynamic']: searchedOptions(dynamicSegment, searchTerm)
  }

  return (
    <Modal
      style={{ width: 530 }}
      containerStyle={{ width: 530 }}
      contentStyles={{ padding: 0 }}
      onClose={handleClose}
      opened={opened}
    >
      {!type && (
        <>
          <CloseButton onClick={handleClose} style={{ position: 'absolute', top: 12, right: 0, zIndex: 1 }}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="26" height="26" rx="13" fill="#7C94B1" fillOpacity="0.12"/>
              <path d="M17 9.7L16.3 9L13 12.3L9.7 9L9 9.7L12.3 13L9 16.3L9.7 17L13 13.7L16.3 17L17 16.3L13.7 13L17 9.7Z" fill="#7C94B1"/>
            </svg>
          </CloseButton>
          <Container>
            <Row>
              <SegmentTypeCard
                data-testid={'segment-trigger-settings-segment-type-card-default'}
                type="default"
                onClick={setType}
                onMouseEnter={setHoveredType}
                onMouseLeave={() => setHoveredType('')}
              />
              <SegmentTypeCard
                data-testid={'segment-trigger-settings-segment-type-card-rfm'}
                type="rfm"
                onClick={setType}
                onMouseEnter={setHoveredType}
                onMouseLeave={() => setHoveredType('')}
              />
            </Row>
            <Description $lighter={!hoveredType}>
              {p(resolveDescription())}
            </Description>
          </Container>
        </>
      )}
      {!!type && (
        <ListContainer>
          <SegmentsListHeader
            onSearchChange={setSearchTerm}
            title={p(type === "rfm" ? 'rfm_segments' : 'segments')}
            onClose={handleClose}
            onBack={() => setType()}
          />
          {type !== 'rfm' && <div style={{ margin: '14px 20px 9px 20px' }}>
            <SliderTabs
              style={{ height: '30px' }}
              tabs={getTabs(p)}
              tabStyle={{ width: '100%', padding: '5px 20px', fontSize: '11px' }}
              tab={value}
              onTabChange={setValue}
            />
          </div>}
          {type === "rfm" ? (
            <RFMSegmentsList
              onSelect={handleSelectRfm}
              omitValues={omitSegments}
              options={searchedOptions(rfmAnalyticsOptions, searchTerm)}
            />
          ) : (
            <SegmentsList
              onSelect={handleSelect}
              omitValues={omitSegments}
              options={segmentTypeConfig[value]}
              multipleSelect={true}
            />
          )}
        </ListContainer>
      )}
    </Modal>
  );
};

export default AddSegmentModal;
