import uniqBy from 'lodash.uniqby';

import { useTranslation } from '@hooks';

import { by, extract } from '@utils';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';
import { getExitPoints } from '@utils/workflows/refactored/getters';

import { Dropdown } from '@components';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const AnotherWorkflowNodeSettingsInfo = ({ node: outerNode, options }) => {
  const nodes = (createMultinodeUpdater(outerNode.data)?.values || []).map(v => ({ data: v }));
  const { t } = useTranslation();

  if (!nodes?.length || !nodes?.[0]?.data?.trigger_workflow_id) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  const renderNode = (node) => {
    if (!node.data?.trigger_workflow_id) {
      return null;
    }

    const workflow = options?.workflows?.find?.(by(node.data?.trigger_workflow_id));
    const eps = uniqBy(getExitPoints(workflow?.tree_map), extract('id'));

    const analytics = outerNode.data?.analytics?.clones?.find?.((data) => data?.node === node.data?.id);
    const exitPoints = node?.data?.trigger_workflow_node_ids?.map(epId => eps?.find(e => e.id === epId)?.data?.label || 'label 123123')?.join(', ');

    return (
      <>
        <Dropdown.Main withoutDropdown title={`${workflow?.name} (${workflow?.version})`} description={exitPoints}/>
        {analytics && <AnalyticsSection analytics={analytics}/>}
      </>
    );
  }

  return (
    <Container>
      {nodes.map(renderNode)}
    </Container>
  );
}

export default AnotherWorkflowNodeSettingsInfo;

