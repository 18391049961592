import { moment } from '@utils';

export const generateMonthlyData = (start, end) => {
  const startDate = moment(start).utcOffset(0, true);
  const endDate = moment(end).utcOffset(0, true);
  const monthlyData = [];

  for (let d = startDate.toDate(); d <= endDate; d.setDate(d.getDate() + 1)) {
    const obj = {
      date: d.toISOString().split('T')[0],
      x: d.toISOString().split('T')[0],
      y: 0,
    };
    monthlyData.push(obj);
  }

  return monthlyData;
};

const getRangeDiff = (range) => {
  if (!range.every((d) => !!d)) {
    return 0;
  }

  const [dateFrom, dateTo] = range.map((d) => moment.unix(+d));

  return Math.abs(dateFrom.diff(dateTo, 'days'));
};

const defaultLineOption = ({ maxCount, range, type, isDay }) => {
  return {
    chart: {
      type: type,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    fill: type === 'area' ? {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.10,
        stops: [47, 100]
      }
    } : {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    xaxis: {
      tooltip: {
        hideEmptySeries: false,
        enabled: false,
      },
      type: 'datetime',
      width: '100%',
      tickPlacement: 'between',
      labels: {
        offsetX: 0,
        offsetY: 0,
        style: {
          width: '100%',
          colors: '#7C94B1',
          cssClass: 'custom-apexcharts-xaxis-label',
        },

        formatter: (_value, timestamp) => {
          const d = moment(timestamp)
            .add(59, 'minutes')
            .add(59, 'seconds')
            .startOf('hour');
          return d.format('DD.MM');
        },
      },
      axisBorder: {
        show: true,
        color: '#D3DBE7',
      },
      tickAmount: (() => {
        const diff = getRangeDiff(range);

        if(isDay) {
          return 1;
        }
        
        if (diff <= 10) {
          return diff;
        }

        if (diff <= 30) {
          return diff / 3;
        }
        if (diff <= 70) {
          return diff / 7;
        }

        return diff / 30;
      })(),
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    grid: {
      borderColor: '#D3DBE7',
      strokeDashArray: 5,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: { left: 5, right: 5, top: 0, bottom: 0 },
    },
    yaxis: {
      ...(maxCount ? { max: maxCount } : {}),
      type: 'numeric',
      decimalsInFloat: 0,
      floating: true,
      labels: {
        align: 'left',
        offsetX: 40,
        offsetY: -10,
        style: {
          colors: '#7C94B1',
          cssClass: 'custom-apexcharts-yaxis-label',
        },
        formatter: function (val) {
          return val?.toFixed(0) || 0;
        },
      },

      tickAmount: (() => {
        return 2
      })(),
      
      // axisBorder: {
      //   show: true,
      //   color: '#D3DBE7',
      //   offsetX: -40,
      //   offsetY: 0,
      // },
    },
    legend: {
      show: false,
    },
  };
};

export const getLinechartOptions = ({ maxCount, range, series, isDay }) => {
  const type = series.length > 1 ? 'line' : 'area';
  return defaultLineOption({ maxCount, range, type, isDay });
};

export const combineData = (originalData, generatedArray) => {
  const firstEl = generatedArray[0];
  const lastEl = generatedArray[generatedArray.length - 1];

  const theSameFirstDay = moment(firstEl.x).format('L') === moment(originalData.x).format('L');

  const theSameLastDay = moment(lastEl.x).format('L') === moment(originalData.x).format('L')

  const array = [firstEl, {
    ...originalData,
    y: +originalData.y,
  }, lastEl];
  
  if(theSameFirstDay) {
    array.shift();
  }

  if(moment(firstEl.x).isAfter(+originalData.x)) {
    array.shift();
  }

  if(theSameLastDay) {
    array.pop();
  }
  
  return array;
};

export const combineArrys = (originalArray, generatedArray) => {
  
  const replacedData = generatedArray.map((dayData) => {

    const sameDay = originalArray.find((data) => {
      return moment(dayData.date).format('L') === moment(data.date).format('L');
    });

    if(sameDay) {
      return sameDay;
    }

    return dayData;
  });

  return replacedData.filter((data, index, fullArray) => { //remove unnecessary data
    if(data.y === 0 && index === 0) {
      return true;
    }
    if(data.y === 0 && index === fullArray.length - 1) {
      return true
    }

    if(data.y !== 0) {
      return true
    }
    return false;
  })
};
