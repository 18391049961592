import { useTranslation } from '@hooks';

import { Container, ImageContainer, Label } from './styled';

const TriggerTypeCard = ({ image, label, hovered, ...props }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container $highlight={hovered} {...props}>
      <ImageContainer>
        {image}
      </ImageContainer>
      <Label $darker={hovered}>
        {p(label)}
      </Label>
    </Container>
  );
};

export default TriggerTypeCard;
