import { Container, Title, SwitchContainer, SwitchCircle } from './styled';

const Switch = ({ title, checked, testId = '', onChange, style, disabled, large, isNotifications }) => {
  const handleChange = (event) => {
    if (disabled) {
      return;
    }

    onChange(!checked, event);
  };

  return (
    <Container data-testid={testId} onClick={handleChange} style={style}>
      {title && <Title notifications={isNotifications}>{title}</Title>}
      <SwitchContainer large={large} notifications={isNotifications} disabled={disabled} checked={checked} >
        <SwitchCircle large={large} notifications={isNotifications} checked={checked} />
      </SwitchContainer>
    </Container>
  );
};

export default Switch;
