import { SiderStyled, LayoutStyled, Title, SubTitle, Content } from './styled';

export const LayoutSplit = ({ siderContent, content, title, subTitle }) => {
  return (
    <LayoutStyled>
      <SiderStyled>{siderContent}</SiderStyled>
      <Content>
        {title && <Title>{title}</Title>}
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        {content}
      </Content>
    </LayoutStyled>
  );
};
