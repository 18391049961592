import iconConfig from '@res/fonts/iconConfig.json';

import { Container } from './styled';

const glyphMap = {};

iconConfig.icons.forEach(({ properties }) => {
  glyphMap[properties.name] = properties?.code;
});

const getGlyph = (name) => {
  let glyph = glyphMap[name] || '?';
  if (typeof glyph === 'number') {
    glyph = String.fromCharCode(glyph);
  }
  return glyph;
};

const Icon = ({ name, size = 22, whiteHover = false, color, style, ...props }) => {
  return (
    <Container {...props} whiteHover={whiteHover} size={size} style={{ color, ...style }}>
      {getGlyph(name)}
    </Container>
  );
};

export default Icon;
