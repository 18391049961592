import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStore } from 'reactflow';

import { workflowEditorSetNodes } from '@store/actions/creators/workflowEditor';

import omit from 'lodash.omit';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation, useIsFirstRender } from '@hooks';

import { WorkflowNodes } from '@constants';
import DirectionModes from '@constants/workflow/directionModes';

import {
  by,
  compose,
  identity,
  sequence,
  update,
} from '@utils';
import { isConnectorsValid } from '@utils/workflows/refactored/common';
import { createNode } from '@utils/workflows/refactored/creators';
import {
  getChildren,
  getExitPointsCount,
  getNode,
  getParentsArray, isChildOf, isEnd, isInternalControlGroup, isSplitter, isStartNode,
} from '@utils/workflows/refactored/getters';
import { layoutFlow } from '@utils/workflows/refactored/layout';
import { toArray, toReactFlowNodesArray } from '@utils/workflows/refactored/structureParser';
import {
  appendChild,
  appendFinishNodes, clearStyles,
  insertAfter, insertBefore,
  removeBranchFrom,
  removeChild, removeDataFields, unwrapArrays, updateNode, updateSplitters
} from '@utils/workflows/refactored/updaters';

import { useWorkerState } from '@contexts/WorkflowEditorContext/hooks';

import { DROPDOWN_ICON_ID } from "@components/ui/Dropdown/Dropdown.js";

import WorkflowEditorContext from './WorkflowEditorContext';

import WorkflowNodeTypes from '../../constants/workflow/nodeTypes';

const WorkflowEditorProvider = ({ children, workflowEditable, defaultName = '' }) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [openedNodeSettings, setOpenedNodeSettings] = useState('');
  const [memoizedViewport, setMemoizedViewport] = useState(null);
  const [name, setName] = useState(defaultName);
  const [description, setDescription] = useState('');
  const [editable, setEditable] = useState(workflowEditable);
  const [onUpdateListeners, setOnUpdateListeners] = useState([]);
  const nodesState = useStore(state => state.getNodes(), (a, b) => a.every((n, i) => n?.__rf?.position === b[i]?.__rf?.position));
  const { root, styles, setRootAndStyles, undo: internalUndo, redo: internalRedo, canRedo, canUndo } = useWorkerState();
  const { p } = useTranslation('workflow_page');
  const [directionMode, setDirectionMode] = useState(DirectionModes.HORIZONTAL);
  const [shouldUpdateNode, setShouldUpdateNode] = useState(false);
  const [hideEdge, setHideEdge] = useState(false);
  const [hoveredNode, setHoveredNode] = useState(null);

  const isFirstRender = useIsFirstRender();
  const [pasteNodeData, setPasteNodeData] = useState(null);
  const [newConnectionSource, setNewConnectionSource] = useState(null);
  const [focusNode, setFocusNode] = useState('');

  const hideEdgesWhenUpdate = (delay = 400) => {
    setHideEdge(true);

    setTimeout(() => {
      setHideEdge(false);
    }, delay);
  }

  const addOnUpdateListener = (listener) => {
    setOnUpdateListeners(ls => [...ls, listener]);
  };

  const removeOnUpdateListener = (ref) => {
    setOnUpdateListeners(ls => ls.filter(l => l !== ref));
  };

  const undo = () => {
    const [newRoot] = internalUndo();

    sequence(...onUpdateListeners)(root, newRoot);
  };

  const redo = () => {
    const [newRoot] = internalRedo();

    sequence(...onUpdateListeners)(root, newRoot);
  };

  const updateWithLayout = (rootUpdater, styleId, stylesUpdater, layoutFn = layoutFlow, skipUndoable = false, skipMiddlewareIfUndoable = true, additionalMiddlewareParams) => {
    let s = styleId ? { ...styles, [styleId]: update(styles[styleId] || {}, stylesUpdater) } : update(styles, stylesUpdater);
    const newRoot = appendFinishNodes(update(root, rootUpdater), { translate: p });
    const rootArr = toArray(newRoot);
    const stylesPersist = !!~rootArr.findIndex(by(styleId));

    layoutFn(newRoot, s, directionMode).then(s => {
      if (!newRoot) {
        setRootAndStyles(null, {}, skipUndoable);
        return;
      }

      if (skipMiddlewareIfUndoable ? !skipUndoable : true) {
        sequence(...onUpdateListeners)(root, newRoot, additionalMiddlewareParams);
      }

      setRootAndStyles(newRoot, () => {
        if (!stylesPersist) {
          delete s[styleId];
        }
        return clearStyles(newRoot, s);
      }, skipUndoable);
    });
  };

  const updateLayout = () => {
    updateWithLayout(identity, null, identity);
  }

  useEffect(() => {
    if (!isFirstRender) {
      updateWithLayout(identity, null, identity);
      setShouldUpdateNode(true);
    }
  }, [directionMode]);

  const saveCachedNodes = (id) => {
    dispatch(workflowEditorSetNodes({ id, tree_map: unwrapArrays(root), styles }));
  };

  const handleLoad = (_reactFlowInstance) => {
    setReactFlowInstance(_reactFlowInstance);
  };

  const addNodeBefore = (target, actionType, type) => {
    const [node, style, otherStyles] = createNode({
      type,
      actionType,
      children: [],
      translate: p,
    });

    updateWithLayout(insertBefore(root, target, node), null, () => ({
      ...styles,
      [style.id]: style,
      ...otherStyles
    }));
    // setShouldUpdateNode(true);

    setTimeout(() => {
      setFocusNode(style.id);
    }, 250);
  };

  const addNewBranchAfter = (source, target, position, actionType, type) => {
    const [node, style, otherStyles] = createNode({
      type,
      actionType,
      children: [],
      translate: p,
    });

    updateWithLayout(appendChild(root, source, node, target, position), null, () => ({
      ...styles,
      [style.id]: style,
      ...otherStyles
    }));
    setShouldUpdateNode(true);

    setTimeout(() => {
      setFocusNode(style.id);
    }, 250);
  }

  const copyNode = (node) => {
    setPasteNodeData(node);
  };

  const pasteNewBranch = () => {
    setPasteNodeData(null);
  };

  const pasteBefore = (target) => {
    const source = getNode(root, pasteNodeData);

    const [node, style, otherStyles] = createNode({
      type: source.type,
      actionType: styles[source?.id]?.actionType,
      data: omit(source.data, ['originId', 'id', 'renderId', 'serverId']),
      children: [],
      translate: p,
    });

    updateWithLayout(insertBefore(root, target, node), null, () => ({
      ...styles,
      [style.id]: style,
      ...otherStyles
    }));
    setPasteNodeData(null);

    setTimeout(() => {
      setFocusNode(style.id);
    }, 250);
  };

  const changeNodeData = (id, data, { label, description, ...stylesData } = {}) => {
    let newStyles = { ...styles, [id]: { ...(styles[id] || {}), data: stylesData, label: label || styles[id]?.label, description: description || styles[id]?.description } };
    const updated = updateNode(root, id, node => ({ ...node, data: { ...update(node.data, data), serverId: node.data.serverId } }));

    const configSplitters = {
      [WorkflowNodes.AB_SPLITTER]: {
        type: 'ab',
        actionType: WorkflowNodes.AB_SPLITTER
      },
      [WorkflowNodes.SWITCH_FILTER]: {
        type: WorkflowNodes.INCLUDE,
        actionType: WorkflowNodes.INCLUDE
      },
    }

    const splittersProps = configSplitters[data.name];

    const withSplitters = splittersProps ? updateSplitters(updated, id, newStyles, (updated) => {
      newStyles = { ...updated }
    }, p, splittersProps?.type, splittersProps?.actionType) : updated;

    const withNextNodes = data.nextNodes ? compose(...getChildren(withSplitters, id).map(child => root => {
      if (child.children[0]?.type !== data.nextNodes) {
        const [node, style, otherStyles] = createNode({
          type: data.nextNodes,
          actionType: data.nextNodesType,
          children: [],
          translate: p,
        });
        newStyles = { ...newStyles, [node.id]: style, ...otherStyles };

        return insertAfter(root, child.id, node);
      }

      return root;
    }))(withSplitters) : withSplitters;
    updateWithLayout(withNextNodes, null, { ...styles, ...newStyles }, layoutFlow, true, false, { save: true });
    setShouldUpdateNode(true);
  };

  const deleteSelectedNode = ({ id }) => {
    updateWithLayout(appendFinishNodes(removeChild(root, id), { translate: p }), id, identity);
    setShouldUpdateNode(true);
    hideEdgesWhenUpdate();
  };

  const deleteBranchFromSelected = ({ id }, newRoot = root) => {
    updateWithLayout(appendFinishNodes(removeBranchFrom(newRoot, id), { translate: p }), id, styles);
  };

  const renderNodes = useMemo(() => {
    if (!root || !styles) {
      return [];
    }

    return toReactFlowNodesArray(root, styles);
  }, [styles, nodesState, root]);

  const getDecoratedRenderNodes = async (rootDecorator = identity, stylesDecorator = (_, s) => s) => {
    if (!root || !styles) {
      return [];
    }

    const decoratedRoot = rootDecorator(root, styles);
    const decoratedStyles = await stylesDecorator(decoratedRoot, styles, directionMode);

    return toReactFlowNodesArray(decoratedRoot, decoratedStyles);
  };

  const renderNodesUnwrapped = useMemo(() => {
    return toReactFlowNodesArray(unwrapArrays(root, true), unwrapArrays(root, true));
  }, [styles, nodesState, root]);

  const getRenderNodesUnwrapped = () => {
    return toReactFlowNodesArray(unwrapArrays(root, true), layoutFlow(unwrapArrays(root, true), styles));
  };

  const getAllArraysOpened = () => {
    return toReactFlowNodesArray(root, styles);
  };

  const removeEdge = (id, source) => {
    const connector = renderNodes.find(by(id));

    deleteBranchFromSelected({ id: connector.target }, updateNode(root, source, node => ({ ...node, data: { ...(node.data || {}), addedConnection: false } })));
    setShouldUpdateNode(true);
  };

  const onConnect = (source, target) => {
    const targetNode = getNode(root, target);

    let removed = updateNode(root, source, node => ({ ...node, data: { ...(node.data || {}), addedConnection: true } }));

    if (getParentsArray(root, source).some(parent => !!~parent.children.findIndex(by(target)))) {
      removed = removeBranchFrom(root, target);
    }

    const appended = appendChild(removed, source, {
      ...targetNode,
      data: {
        ...targetNode.data,
        serverId: uuidv4(),
      },
    });

    updateWithLayout(appended, null, s => ({
      ...s,
      [targetNode.id]: {
        ...(s[targetNode.id] || {}),
        connectorRemoved: (s[targetNode.id].connectorRemoved || []).filter(id => id !== source),
      }
    }));
  };

  const removeTemplatesFields = (idMap) => {
    const newStyles = { ...styles };

    const onRemove = (id) => {
      newStyles[id].data.validated = false;
      newStyles[id].data.validation = { valid: false };
    };

    updateWithLayout(removeDataFields(root, idMap, onRemove), null, newStyles, layoutFlow, true, true);
  };

  const onNodeClick = (e, node, viewport) => {
    if (e.target.id === DROPDOWN_ICON_ID) {
      return;
    }

    const isFinishNode = node.type === WorkflowNodeTypes.FINISH;
    const isEntryPoint = node.data.actionType === 'entry_point';
    const isSplitterNode = node.type === WorkflowNodeTypes.SPLITTER;

    if (isEntryPoint || isFinishNode || isSplitterNode) {
      return;
    }

    if (openedNodeSettings === node.id) {
      return setOpenedNodeSettings('');
    }

    setOpenedNodeSettings(node.id);

    if (viewport && !openedNodeSettings) {
      setMemoizedViewport(viewport);
    }
  };

  const isAdditionBeforeAllowed = (target) => {
    const splitter = isSplitter(root, target);

    return !splitter;
  };

  const isAdditionBranchAllowed = (target) => {
    const end = isEnd(root, target);

    return !end;
  };

  const cancelConnection = () => {
    setNewConnectionSource(null);
  };

  const cancelCopy = () => {
    setPasteNodeData(null);
  };

  const pasteBeforeAllowed = (target) => {
    return !isSplitter(root, target);
  };

  const isControlGroup = (source) => {
    return isInternalControlGroup(root, source);
  };

  const isConnectionSourceAllowed = (target) => {
    return isEnd(root, target);
  };

  const isConnectionTargetAllowed = (source, target) => {
    if (source === newConnectionSource) {
      return false;
    }

    return isEnd(root, target);
  };

  const hasMultipleChildren = (source) => {
    return getNode(root, source)?.children?.length > 1;
  };

  const shouldHideGroupNode = (id) => {
    if (!hoveredNode || hoveredNode === id) {
      return false;
    }

    return !isChildOf(root, hoveredNode, id);
  };

  const isFirstEdge = (source) => {
    return root.id === source;
  };

  const selectNewConnectionSource = (source) => {
    setNewConnectionSource(source);
    setShouldUpdateNode(true);
  };

  const selectNewConnectionTarget = (target) => {
    onConnect(newConnectionSource, target);
    setNewConnectionSource(null);

    setShouldUpdateNode(true);
  }

  const isOnlyTriggerSelected = (source, target) => {
    return isStartNode(root, source) && isEnd(root, target);
  };

  const handleUndo = () => {
    undo();
    setShouldUpdateNode(true);
  }

  const handleRedo = () => {
    redo();
    setShouldUpdateNode(true);
  }

  const exitPointsCount = getExitPointsCount(root);

  const validated = !Object.values(styles).some(style => style?.data?.validated === false);
  const entryPointSelected = !!root && styles[root.id]?.actionType === 'entry_point';
  const connectionsValid = isConnectorsValid(root, styles);

  return (
    <WorkflowEditorContext.Provider
      value={{
        fitView: reactFlowInstance?.fitView,
        instance: reactFlowInstance,
        state: {
          root,
        },
        memoizedViewport,
        shouldUpdateNode,
        setShouldUpdateNode,
        focusNode,
        hideEdgesWhenUpdate,
        hideEdge,
        renderNodes: renderNodes,
        renderNodesUnwrapped,
        actions: {
          undo: handleUndo,
          canUndo: canUndo && !openedNodeSettings,
          canRedo: canRedo && !openedNodeSettings,
          redo: handleRedo,
        },
        directionMode,
        setDirectionMode,
        pasteModeActive: !!pasteNodeData,
        newConnectionSource,
        isConnectionTargetAllowed,
        cancelConnection,
        cancelCopy,
        onLoad: handleLoad,
        addNodeBefore,
        isAdditionBeforeAllowed,
        isAdditionBranchAllowed,
        isOnlyTriggerSelected,
        isFirstEdge,
        addNewBranchAfter,
        updateWithLayout,
        updateLayout,
        removeEdge,
        connect: onConnect,
        containerRef,
        changeNodeData,
        addOnUpdateListener,
        removeOnUpdateListener,
        deleteSelectedNode,
        deleteBranchFromSelected,
        copyNode,
        pasteBefore,
        pasteNewBranch,
        validated,
        editable,
        setEditable,
        saveCachedNodes,
        openedNodeSettings,
        setOpenedNodeSettings,
        removeTemplatesFields,
        connectionsValid,
        entryPointSelected,
        onNodeClick,
        exitPointsCount,
        getRenderNodesUnwrapped,
        getAllArraysOpened,
        styles,
        name, setName,
        description, setDescription,
        isConnectionSourceAllowed,
        selectNewConnectionTarget,
        selectNewConnectionSource,
        pasteBeforeAllowed,
        hasMultipleChildren,
        isControlGroup,
        getDecoratedRenderNodes,
        hoveredNode,
        setHoveredNode,
        shouldHideGroupNode,
      }}
    >
      {children}
    </WorkflowEditorContext.Provider>
  );
};

export default WorkflowEditorProvider;
