import styled from 'styled-components';

export const Container = styled.div`
  padding: 2px 6px;
  margin-right: 6px;
  background: #E5E8ED;
  border-radius: 4px;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  margin-top: 6px;
  display: flex;
  flex: 0;
  white-space: nowrap;
`;
