import styled from 'styled-components';

export const Listcontainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

`;
export const CloseIconcontainer = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #1A232E;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
    padding: 14px 0px 15px 16px;
    align-items: center;
    border-bottom: 1px solid var(--border-divider, #D3DBE7);
    background: var(--cards-bg, #F8FAFE);
    border-radius: 10px 10px 0 0;
    border: 1px solid var(--cards-border, #D3DBE7);
`;
