const WorkflowSetupSteps = {
  DESCRIPTION: 'description',
  TRIGGER: 'trigger',
  PARTICIPATION: 'participation',
  QUIET_HOURS: 'quiet-hours',
  EXIT_CRITERIA: 'exit-criteria',
  GOALS: 'goals',
};

export default WorkflowSetupSteps;
