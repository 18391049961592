import styled from 'styled-components';

export const Container = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
  
  position: absolute;
  bottom: 130px;
  max-width: 762px;
`;
