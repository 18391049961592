import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHorizonCampaigns } from '@store/actions/creators';
import { WITHOUT_SEARCH } from '@store/reducers/workflow';
import { horizonCampaignsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { by } from '@utils';

import { QuerySelect, Select } from '@components';
import { NodeSettingsAccordion } from '@components/lib/WorkflowEditor/components';

import { Container, Content, Title, Description } from './styled';

const ActionOptions = [
  {
    value: 'include',
    label: 'Include in campaign',
  },
  {
    value: 'exclude',
    label: 'Remove from campaign',
  }
];

const IncludeCampaignNodeSettings = ({
  value,
  onChange,
  errors,
  onClearError,
}) => {
  const { p } = useTranslation('workflow_page');
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const campaigns = useSelector(horizonCampaignsSelector);
  const currentCampaignsOptions = campaigns[search || WITHOUT_SEARCH];
  const options = (currentCampaignsOptions?.data || []).map(({ name, id }) => ({ value: id, label: name }));
  const bottomEl = useRef();

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  const handleCampaignChange = (campaign_id) => {
    const campaign = options.find(by('value', campaign_id));

    onChange(s => ({
      ...s,
      campaign_id,
      campaign_name: campaign?.label,
    }));
    onClearError?.('campaign_id');
  };

  const handleActionChange = (action) => {
    onChange(s => ({ ...s, action }));
    onClearError?.('action');
  };

  useEffect(() => {
    dispatch(getHorizonCampaigns({ search, page: 0 }));
  }, [search]);

  const onScrollToEnd = () => {
    dispatch(getHorizonCampaigns({ search, page: (isNaN(currentCampaignsOptions.page) ? 0 : currentCampaignsOptions.page) + 1 }));
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight) {
      onScrollToEnd();
    }
  };

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <Content>
            <Title>{p('include_campaign_settings_title')}</Title>
            <Description>{p('include_campaign_settings_description')}</Description>
            <Select
              value={value.action}
              onChange={handleActionChange}
              options={ActionOptions}
              testId="include-action"
              placeholder="Select"
              label="Action"
              error={errors['action']}
              wrapperStyles={{ width: 320, marginBottom: 16 }}
              style={{ width: 320 }}
              containerStyle={{ width: 320 }}
            />
            <QuerySelect
              onChange={handleCampaignChange}
              value={value.campaign_id}
              placeholder="Select campaign"
              title="Campaign"
              onSearch={handleSearch}
              error={errors['campaign_id']}
              options={options}
              wrapperStyles={{ width: 320 }}
              style={{ width: 320 }}
              containerStyle={{ width: 320 }}
              onPopupScroll={handleScroll}
              footerOption={(
                <div ref={bottomEl} />
              )}
            />
          </Content>
        )}
      />
    </Container>
  );
};

export default IncludeCampaignNodeSettings;
