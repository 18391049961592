import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  optionsSelector
} from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';
import useSystemAutocomplete from '@hooks/useSystemAutocomplete';

import { WITHOUT_VALUE_OPERATORS } from '@constants';

import { by } from '@utils';
import {
  clickhouseFields2Events,
  clickhouseFields2Customer,
} from '@utils/fields';

import { Dropdown } from '@components';
import { resolveTitle } from '@components/lib/SegmentEditor/components/DateFilterPicker/DateFilterPicker';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import {
  Container,
  Column,
} from './styled.js';

import SegmentsRuleTypes from '../../../../../../../constants/segments-refactored/SegmentsRuleTypes.js';
import { formatDate, logicOperatortextconfig } from '../../utils.js';

const DefaultFilterNodeSettingsInfo = ({ node }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const events = clickhouseFields2Events(clickhouseFields.data);
  const segmentOptions = useSelector(optionsSelector);
  const resource = segmentOptions.segments?.find?.(by(node.data?.segment_id));
  const { p, t } = useTranslation('date_picker');
  const systemAutocomplete = useSystemAutocomplete();

  const linearList = useMemo(() => {
    return customerFields?.filter((f) => f.type !== 'array');
  }, [clickhouseFields]);

  const arrayList = useMemo(() => {
    return customerFields
      ?.filter((f) => f.type === 'array')
      .map((a) => ({ ...a, value: a.field }));
  }, [clickhouseFields]);

  const configRuleTypeList = {
    [SegmentsRuleTypes.LINEAR]: linearList,
    [SegmentsRuleTypes.ARRAY]: arrayList,
    [SegmentsRuleTypes.EVENT]: events.data,
  };

  if (!node.data?.filter_by) {
    return (
      <Container>
        <InfoTitle>{t('labels.filter_by')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  const resolveRule = (rule, ruleType) => {
    const list = configRuleTypeList[ruleType];
    if (ruleType === SegmentsRuleTypes.LINEAR) {
      return list.find(by('field', rule))?.label;
    }
    if (
      ruleType === SegmentsRuleTypes.EVENT &&
      events.data?.find?.(by('name', rule))
    ) {
      return events.data?.find?.(by('name', rule))?.label;
    }

    if (segmentOptions.fields?.attributes?.find?.(by('field', rule))) {
      return segmentOptions.fields?.attributes?.find?.(by('field', rule))
        ?.label;
    }

    if (rule === '_c') {
      return t('labels.created_at');
    }

    return rule;
  };

  const resolveValue = (v, op, filter, event) => {
    const systemOptions = systemAutocomplete?.[filter?.field]?.['event']?.[event];
    const foundFromOptions = (systemOptions || []).find(({ value }) => String(value) === String(v.value))?.label;

    if (foundFromOptions) {
      return foundFromOptions;
    }

    const operator = op || node.data?.query?.children?.[0]?.query?.operator;
    if (!v || !!~WITHOUT_VALUE_OPERATORS.indexOf(operator)) {
      return;
    }
    if (Array.isArray(v.value)) {
      return v.value.join(!!~operator?.indexOf?.('between') ? ' - ' : ', ');
    }

    if (typeof v.value === 'object' && v.value !== null) {
      const title = resolveTitle(v.value, false, p);
      const isDate = title.includes('at');

      if (isDate) return formatDate(title);
      return title;
    }

    return v.value;
  };

  const getLabel = (event, field) => {
    const OverrideLabels = {
      "nf_notification_id": 'Template',
      "nf_entity_id": 'Workflow version',
      "nf_sub_entity_id": 'Workflow node',
      "nf_event": 'Status',
    };

    const source = event?.payload?.find?.((source) => source.field === field);
    return OverrideLabels[field] || source?.label;
  };

  const getFilterOperationText = (node) => {
    const hasFilters =
      node.data?.query?.children?.[0]?.query.filters?.length > 0;
    const logicalOperator =
      node.data?.query?.children?.[0]?.query.logicalOperator;

    const text = hasFilters
      ? logicOperatortextconfig[logicalOperator]
      : 'workflow_page.logical_operator_was_performed';
    return t(text);
  };

  const { ruleType, filters, field, operator, value } = node.data?.query?.children?.[0]?.query || {};

  const event = events.data?.find((event) => event.name === field);
  const isEventRuleType = ruleType === 'event';

  return (
    <Container>
      {node.data?.filter_by === 'rule' && !isEventRuleType && (
        <Column>
          <Dropdown.Main testId={`workflow-quick-filter-node-${ruleType}-rule-item`} title={t('labels.attribute')}>
            <Dropdown.Item
              title={resolveRule(
                node.data?.query?.children?.[0]?.query?.field,
                ruleType
              )}
              subTitle={t(`segments_page.${operator}`)}
            >
              {!~WITHOUT_VALUE_OPERATORS.indexOf(operator) &&
                value?.value !== undefined &&
                value?.value !== '' &&
                value?.value !== null &&
                resolveValue(value)}
            </Dropdown.Item>
          </Dropdown.Main>
        </Column>
      )}
      {isEventRuleType && node.data?.filter_by === 'rule' && (
        <Column>
          <Dropdown.Main
            testId={'workflow-quick-filter-node-event-rule-item'}
            title={event?.label}
            description={getFilterOperationText(node)}
          >
            {filters.length > 0 && filters?.map((filter, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  title={getLabel(event, filter.field)}
                  subTitle={`${t(`segments_page.${filter.operator}`)}:`}
                >
                  {resolveValue(filter.value, filter.operator, filter, event?.name)}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Main>
        </Column>
      )}
      {node.data?.filter_by === 'segment' && (
        <Dropdown.Main
          testId={'workflow-quick-filter-node-segment-rule-item'}
          title={t('labels.segment')}
          description={resource?.name}
        />
      )}
      {node.data.analytics && (
        <AnalyticsSection analytics={node.data.analytics} />
      )}
    </Container>
  );
};

export default DefaultFilterNodeSettingsInfo;
