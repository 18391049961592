import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { testId } from '@utils';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

import { Input, Multiselect, Textarea } from '@components';

import { Container } from './styled';

import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';

const NewContactNodeSettings = ({
  value: outerValue,
  label,
  description,
  onChange: outerOnChange,
  onClearError,
  onLabelChange,
  onDescriptionChange,
  errors,
  editable = true,
}) => {
  const { t } = useTranslation('workflow_page');
  const segmentOptions = useSelector(optionsSelector);
  const multinode = createMultinodeUpdater(outerValue, outerOnChange, errors, onClearError);
  const value = multinode.values[0];
  const onChange = multinode.changeNode(0);

  const fieldsOptions = segmentOptions.fields?.attributes?.map(({ label, field }) => ({ value: field, label }));

  const handleFieldsChange = (message_fields) => {
    onChange(s => ({ ...s, meta: message_fields }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    multinode.clearError(0)('label');
    cb(value);
  };

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <Input
              {...testId('new-contact-node-settings-required-trigger')()}
              style={{ width: '100%', marginBottom: '16px' }}
              value={t('labels.customer_addition')}
              disabled
              title={t('labels.trigger')}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input {...testId('new-contact-node-settings-description-name')()} error={multinode.getError(0)('label')} disabled={!editable} value={label} onChange={handleInputChange(onLabelChange)} title={t('labels.name')} />
            <Textarea {...testId('new-contact-node-settings-description-text')()} disabled={!editable} value={description} onChange={handleInputChange(onDescriptionChange)} title={t('labels.description')} />
          </>
        )}
        additionalSettings={(
          <Multiselect
            {...testId('new-contact-node-settings-additional-message-fields')()}
            disabled={!editable}
            title={t('labels.message_fields')}
            getParentElement={t => t.parentElement.parentElement.parentElement}
            style={{ width: '100%' }}
            options={fieldsOptions}
            value={value.meta || []}
            onChange={handleFieldsChange}
          />
        )}
      />
    </Container>
  );
};

export default NewContactNodeSettings;
