import { useTranslation } from '@hooks';

import { testId } from '@utils';

import { SearchSelect } from '@components';

import { Row, TitleRow } from './styled';

const daysOptions = [
  {
    value: 1,
    label: 'labels.monday',
  },
  {
    value: 2,
    label: 'labels.tuesday',
  },
  {
    value: 3,
    label: 'labels.wednesday',
  },
  {
    value: 4,
    label: 'labels.thursday',
  },
  {
    value: 5,
    label: 'labels.friday',
  },
  {
    value: 6,
    label: 'labels.saturday',
  },
  {
    value: 0,
    label: 'labels.sunday',
  },
];

export const TillDay = ({
  highlight,
  errors,
  editable,
  value,
  handleDayChange,
}) => {
  const { t } = useTranslation('workflow_page');

  return (
    <Row>
      <TitleRow>{t('workflow_page.wait_till')}</TitleRow>
      <SearchSelect
        {...testId('wait-node-settings-select-day')()}
        highlight={highlight}
        error={errors['day']}
        disabled={!editable}
        getPopupContainer={(t) => t.parentElement.parentElement.parentElement}
        style={{ minWidth: '290px' }}
        value={value.day + ''}
        onChange={handleDayChange}
        options={daysOptions}
      />
    </Row>
  );
};
