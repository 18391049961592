import { useTranslation } from '@hooks';

import { TemplateTypes } from '@constants/templates';

import { by, moment } from '@utils';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import {
  AiSection, AiSubtitle,
  AiTitle, Sending, SubText
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendEmailNodeSettingsInfo/styled';
import { SendingSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendingSection';

import { Container } from './styled.js';

const MobilePushNodeSettingsInfo = ({ node, options }) => {
  const mobilePush = options?.[TemplateTypes.MOBILE_PUSH]?.find?.(by(node.data?.mobile_push_id));
  const { p, t } = useTranslation();

  if (!mobilePush) {
    return (
      <Container>
        <InfoTitle>{t('labels.template')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  return (
    <Container>
      <InfoTitle>
        {t('labels.template')}:
      </InfoTitle>
      <InfoText>
        {mobilePush.name}
      </InfoText>
      {node.data.sending && <SendingSection statuses={node.data.sending} out={node.data.analytics?.out} />}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
      {node.data.analytics && (
        <AnalyticsSection analytics={node.data.analytics} />
      )}
      <Sending>
        <InfoTitle>{t('labels.sending')}:</InfoTitle>
        {!node.data?.best_time_enabled ? (
          <InfoText>{p('workflow_page.upon_node_entrance')}</InfoText>
        ) : (
          <AiSection>
            <AiTitle>
              {p('workflow_page.ai_best_time')}
            </AiTitle>
            <AiSubtitle>
              {p('workflow_page.fallback_option')}
            </AiSubtitle>
            <SubText>{node.data?.best_time_fallback ? `${p('workflow_page.at')} ${moment(node.data.best_time_fallback)?.hour?.()}:${moment(node.data.best_time_fallback)?.minute?.() === 0 ? moment(node.data.best_time_fallback)?.minute?.() + '0' : moment(node.data.best_time_fallback)?.minute?.()}` : p('workflow_page.upon_node_entrance')}</SubText>
          </AiSection>
        )}
      </Sending>
    </Container>
  );
}

export default MobilePushNodeSettingsInfo;

