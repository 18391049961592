import styled from 'styled-components';

export const StyledCreateTemplateCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FAF7EF;
  border-radius: 10px;
  border: 1px solid #DCDFE6;
  width: 170px;
  height: 170px;
  &:hover{
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
`;

export const Title = styled.div`
  align-self: center;
  color: #1A232E;
  font-family: Manrope-SemiBold;
  font-size: 14px;
`;

