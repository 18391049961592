/* eslint-disable no-unused-vars */
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { smsSelector, templateSelector } from '@store/selectors';

import {
  DateRangeField,
  MultiSelectField,
  RowCell,
  Badge,
  SingleSelectDataField
} from '@velitech/ui';

import { useModalState, useTranslation } from '@hooks';

import { Paths, SmsStatuses, UserPermissions } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { identity, moment, languageStructureByCode, languageOptions } from '@utils';

import { DateCell, WithPermissions, Link, TableLayout } from '@components';

import { TableRowText } from '../../../CustomersScene/pages/FieldSettingsPage/components/AggregateTable/styled';
import { TableActions } from '../../../CustomersScene/pages/FieldSettingsPage/components/TableActions';
import { LanguageItem } from '../LanguageItem';
import SmsInfoModal from '../Modals/SmsInfoModal';
import TextDetails from '../TextDetails/TextDetails';

const SmsTable = ({ table, smsTemplates, statusOptions, total }) => {
  const sms = useSelector(smsSelector);
  const smsInfoModal = useModalState();
  const templates = useSelector(templateSelector);
  const [currentSms, setCurrentSms] = useState({});
  const { t, p } = useTranslation('notification_history')
  // eslint-disable-next-line no-unused-vars
  const languages = useMemo(() => languageStructureByCode(), []) 

  const getStatusColor = (status) => {
    switch (status) {
      case SmsStatuses.DELIVERED:
        return Badge.COLORS.POSITIVE
      case SmsStatuses.SENT:
        return Badge.COLORS.NEUTRAL_500
      case SmsStatuses.FAILED:
        return Badge.COLORS.NEGATIVE_500
      default:
        return Badge.COLORS.NEUTRAL_500
    }
  };

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const columns = [
    {
      header: {
        label: t('labels.customer_id'),
        className: 'w-25',
      },
      fixedIndex: 0,
      alwaysVisible: true,
      name: 'customer_id',
      render: (data) => {
        return(
          <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
            <Link type={'secondary'} to={`${Paths.CONTACT}/${data.customer_id}`}>{data.customer_id}</Link>
          </WithPermissions>
        )
      },
    },
    {
      header: {
        label: t('labels.phone'),
        className: 'w-25',
      },
      fixedIndex: 1,
      alwaysVisible: true,
      name: 'number',
      sort: true,
      render: (data) => {
        return <TableRowText>{data.number}</TableRowText>;
      },
    },
    {
      header: {
        label: t('labels.sms_text'),
        className: 'w-25',
      },
      fixedIndex: 2,
      alwaysVisible: true,
      name: 'message',
      sort: true,
      render: (data) => {
        return <TextDetails text={data.message} />;
      },
    },
    {
      header: {
        label: t('labels.template'),
        className: 'w-25',
      },
      fixedIndex: 3,
      alwaysVisible: true,
      name: 'template_id',
      sort: true,
      render: (data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_SMS}><Link type={'secondary'} to={`${Paths.TEMPLATES_SMS}/${data.template_id}?view=1`}>{templates?.[TemplateTypes.SMS]?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      header: {
        label: t('labels.date'),
        className: 'w-10',
        sort: true,
      },
      fixedIndex: 4,
      alwaysVisible: true,
      name: 'created_at',
      render: (data) => {
        return (
          <TableRowText>
            <DateCell date={data.created_at} />
          </TableRowText>
        );
      },
    },
    {
      header: {
        label: t('labels.status'),
        className: 'w-10',
      },
      name: 'status',
      fixedIndex: 5,
      alwaysVisible: true,
      render: (data) => {
        return (
          <Badge size={Badge.SIZES.SMALL} variant={Badge.VARIANT.GHOST} color={getStatusColor(data.status)}>
            {data.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: {
        label: t('labels.language_code'),
        className: 'w-10',
      },
      name: 'language_code',
      render: (data) => {
        const languageItem = languages[data?.language_code];
        if(!languageItem) return <></>
        
        return (
          <LanguageItem icon={languageItem.emoji} code={languageItem.languageCode} name={languageItem.languageName} />
        );
      },
    },
    {
      header: {
        label: t('labels.actions'),
        className: 'w-25',
      },
      name: 'actions',
      fixed: 'right',
      render: (data) => {
        return (
          <TableActions onInfo={() => handleSmsInfoClick(data)}/>
        )
      },
    },
  ];

  const handleSmsInfoClick = (data) => {
    setCurrentSms(data);
    smsInfoModal.open();
  }

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  };

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_SMS} hidden>
        <TableLayout
          withTabs
          fullPage
          name="notification_history_sms"
          loading={sms.loading}
          columns={columns}
          data={sms.data || []}
          table={table}
          total={total}
          localizer={tableLocalizer}
          columnSettings={true}
          renderFilter={(
            <>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select template'}
                  label={t('labels.template')}
                  options={smsTemplates || []}
                  onChange={table.filters.onChange('template_id', identity)}
                  value={ table.filters.filters['template_id']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <MultiSelectField
                  disableErrorSpaceReserve
                  className="status-select"
                  searchHidden
                  selectedOptionsLabel="Selected options"
                  availableOptionsLabel="Available options"
                  placeholder={'Select status'}
                  label={t('labels.status')}
                  options={statusOptions || []}
                  onChange={table.filters.onChange('status', identity)}
                  value={ table.filters.filters['status']}
                />
              </RowCell>
              <RowCell className='minWidth-250'>
                <SingleSelectDataField
                  disableErrorSpaceReserve
                  className="status-select"
                  placeholder={'Select language code'}
                  label={t('labels.language_code')}
                  options={languageOptions() || []}
                  mapOptionLabel={(data) => <LanguageItem icon={data.emoji} code={data.value} name={data.label} />}
                  mapOptionValue={(data) => data.value}
                  onChange={table.filters.onChange('language_code', identity)}
                  value={ table.filters.filters['language_code']}
                />
              </RowCell>
              <RowCell>
                <DateRangeField
                  label={'Date'}
                  disableErrorSpaceReserve
                  dateFormat={'yyyy/MM/dd'}
                  from={{
                    selected: getStartDate(table.filters.filters.start_date),
                    onChange: (date) => handleChangeDate('start_date', date),
                  }}
                  to={{
                    selected: getEndDate(table.filters.filters.end_date),
                    onChange: (date) => handleChangeDate('end_date', date),
                  }}
                />
              </RowCell>
            </>
          )}
        />
      </WithPermissions>
      <SmsInfoModal
        opened={smsInfoModal.opened}
        onClose={smsInfoModal.close}
        data={currentSms || {}}
      />
    </>
  )
}

export default SmsTable;

