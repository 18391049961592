import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldCol = styled.div`
  flex: 1;
`;

export const AddButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RemoveFieldButton = styled.div`
  margin-top: 32px;
  margin-left: 16px;
`;

export const FieldRow = styled.div`
  display: flex;
  width: 100%;
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  line-height: 120%;
  color: #C8402F;
`;

export const SettingsContainer = styled.div`
  overflow-y: auto;
  max-height: 1000px;
  padding-right: 5px;
  
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(240, 242, 246, 1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #F0F2F6;
    border-radius: 10px;
  }
`;
