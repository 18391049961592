import { useTranslation } from '@hooks';

import { moment } from '@utils';

import {
  RadioButton,
  RadioGroup,
  TimePicker,
} from '@components';

import {
  Container,
  Title,
  SettingsContainer,
  SettingsTitle,
  PickerContainer,
} from './styled';

export const AiBestChannelToSend = ({
  value,
  onChangeAiSendSettings,
  onChangeAiSendSettingsTime,
  error
}) => {
  const { p, t } = useTranslation('workflow_page');
  return (
    <Container>
      <Title>{p('ai_best_time_to_send_settings')}</Title>

      <SettingsContainer>
        <SettingsTitle>
          {p('ai_best_time_to_send_title')}
        </SettingsTitle>
        <RadioGroup
          data-testid={'ai_best_time_radio'}
          value={value?.send_settigs_type || 'immediately'}
          onChange={onChangeAiSendSettings}
        >
          <RadioButton
            data-testid={'ai_best_time_option_send_immediately'}
            style={{
              fontFamily: 'Manrope-Medium',
              marginBottom: '15px',
            }}
            name='immediately'
            title={p('send_immediately_upon_node_activation')}
          />
          <RadioButton
            data-testid={'ai_best_time_option_send_specific_time'}
            style={{
              fontFamily: 'Manrope-Medium',
              marginBottom: '12px',
            }}
            name='specific_time'
            title={p('set_specific_send_time')}
          />
          <PickerContainer>
            <TimePicker
              data-testid={'time_picker'}
              value={
                value?.best_time_fallback ? moment(value?.best_time_fallback) : ''
              }
              disabled={value?.send_settigs_type === 'immediately'}
              format={'HH:mm'}
              onChange={onChangeAiSendSettingsTime}
              error={value?.send_settigs_type !== 'immediately' && t(error?.best_time_fallback)}
            />
          </PickerContainer>
        </RadioGroup>
      </SettingsContainer>
    </Container>
  );
};
