import { useEffect, useRef, useState } from 'react';

import { Button, Theme } from '@velitech/ui';
import { Checkbox, Tooltip } from 'antd';
import { v4 as uuidv4 } from "uuid";

import { useTranslation } from '@hooks';

import { testId, zeroIfNaN } from '@utils';

import { IconButton, Input, Icon } from '@components';

import { ABPercentageIndicator } from './components';
import { Container, Row, WrapperContent, Title, ColumnLarge, ColumnSmall, Text } from './styled';

import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';
import { alphabet } from '../GroupSplitNodeSettings/module/utils';

const ABTestingNodeSettings = ({
  styles,
  onStylesChange,
  errors,
  editable = true
}) => {
  const { p } = useTranslation('workflow_page')
  const [pathsEvenly, setPathsEvenly] = useState(true);
  const [indicateInvalid, setIndicateInvalid] = useState(false);
  const divRef = useRef();
  useEffect(() => {
    const sum = (styles?.segments || []).reduce((acc, { value: v }) => acc + v, 0);
    if (errors?.segments && sum !== 100) {
      setIndicateInvalid(true);
    } else {
      setIndicateInvalid(false);
    }
  }, [errors, styles?.segments]);

  const reCalculatePathsEvenly = (segments) => {
    const currentSegments = segments || styles?.segments;

    const updatedSegments = currentSegments.map((segment, index) => {
      const baseValue = Math.floor(100 / currentSegments.length);
      const rest = 100 % currentSegments.length;
      const isLast = index === currentSegments.length - 1;
      const value = isLast ? baseValue + rest : baseValue

      return {
        ...segment,
        value: value
      }
    });

    onStylesChange(prevState => ({
      ...prevState,
      segments: updatedSegments,
    }));
  }

  const updateSegments = (segments) => {
    if (pathsEvenly) {
      reCalculatePathsEvenly(segments);
    } else {
      onStylesChange((prevState) => ({ ...prevState, segments }));
    }
  };

  const getLetter = (list) => {
    const usedLetters = list.map((group) => {
      const labelGroup = group.label.replace('Path' ,'').trim();
      return labelGroup;
    });
    const letters = alphabet.filter((letter) => !usedLetters.includes(letter));

    const letter = letters[0];

    return letter;
  }

  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth", block: "end" })
  }

  const handleAddSegment = () => {
    const pathName = getLetter(styles?.segments);

    const updatedSegments = (styles?.segments || []).concat({
      id: uuidv4(),
      label: `Path ${pathName}`,
      value: 0,
    }).sort((a, b) => (a.isControlGroup === b.isControlGroup) ? 0 : a.isControlGroup ? 1 : -1);

    updateSegments(updatedSegments);
    scrollToBottom();
  };

  const handleRemoveSegment = (id) => () => {
    const updatedSegments = (styles?.segments || []).filter(ss => ss.id !== id);

    updateSegments(updatedSegments);
  };

  const handleSegmentChange = id => ({ target: { value } }) => {
    setPathsEvenly(false);
    onStylesChange(prevState => ({ ...prevState, segments: (prevState?.segments || []).map(segment => segment.id === id ? { ...segment, value: zeroIfNaN(+value) } : segment) }));
  };

  const handleSegmentNameChange = (id) => ({ target: { value } }) => {
    onStylesChange(prevState => ({ ...prevState, segments: (prevState?.segments || []).map(segment => segment.id === id ? { ...segment, label: value } : segment) }));
  };

  const handlePathsEvenly = () => {
    setPathsEvenly(!pathsEvenly);
    !pathsEvenly && reCalculatePathsEvenly();
  }

  const manageControlGroup = (haveControlGroup) => {
    if(haveControlGroup) {
      const updatedSegments = (styles?.segments || []).filter(segment => !segment.isControlGroup);
      updateSegments(updatedSegments);
    } else {
      const updatedSegments = (styles?.segments || []).concat({
        id: uuidv4(),
        label: 'Control Group',
        isControlGroup: true,
        value: 0,
      });
      updateSegments(updatedSegments);
    }
  }

  const segments = styles?.segments || [];
  const haveControlGroup = segments.some((segment) => segment.isControlGroup);

  return (
    <Theme>
      <Container ref={divRef}>
        <NodeSettingsAccordion
          requiredSettings={
            <>
              <Title>{p('ab_testing_title')}</Title>
              <WrapperContent>
                <Row>
                  <ColumnLarge>
                    <Row>
                      <Text> {p('path_name')}</Text>
                    </Row>
                  </ColumnLarge>
                  <ColumnSmall>
                    <Row>
                      <Text> {p('percentage')}</Text>
                    </Row>
                  </ColumnSmall>
                </Row>
                {segments.map(({ id, label, value, isControlGroup }, index) => (
                  <Row key={index}>
                    <ColumnLarge>
                      <Row>
                        <Input
                          {...testId('ab-testing-required-settings-name')()}
                          disabled={!editable || isControlGroup}
                          wrapperStyles={{ width: '100%' }}
                          style={{ marginBottom: '0' }}
                          maxLength={40}
                          value={label}
                          onChange={handleSegmentNameChange(id)}
                        />
                      </Row>
                    </ColumnLarge>
                    <ColumnSmall>
                      <Row>
                        <Input
                          {...testId(
                            'ab-testing-required-settings-percentage-value'
                          )()}
                          disabled={!editable}
                          wrapperStyles={{ width: '100%', maxWidth: '80px' }}
                          style={{ marginBottom: '0' }}
                          // title={t('labels.value_percentage')}
                          type='number'
                          value={value}
                          min={0}
                          showArrowButtons={false}
                          iconRightNode={<Text>%</Text>}
                          onChange={handleSegmentChange(id)}
                        />
                        <div>
                          {!isControlGroup && <IconButton
                            {...testId(
                              'ab-testing-required-settings-remove-segment'
                            )()}
                            disabled={index < 2 || !editable}
                            appearance='common'
                            onClick={handleRemoveSegment(id)}
                            icon='Delete-icon'
                          />}
                        </div>
                      </Row>
                    </ColumnSmall>
                  </Row>
                ))}

                <ABPercentageIndicator
                  invalid={indicateInvalid}
                  segments={styles.segments || []}
                />
                <Row style={{ marginTop: '20px' }}>
                  <Checkbox
                    data-testid={'control-group'}
                    checked={haveControlGroup}
                    onChange={() => manageControlGroup(haveControlGroup)}
                    disabled={!editable}
                  >
                    {p('control_group')}
                  </Checkbox>
                  <Tooltip title={p('control_group')}>
                    <Icon color="#909399" name="Info-icon" size={14} />
                  </Tooltip>
                </Row>
                <Row>
                  <Checkbox
                    data-testid={'path-evently'}
                    style={{ paddingRight: 0 }}
                    checked={pathsEvenly}
                    onChange={handlePathsEvenly}
                    disabled={!editable}
                  >
                    {p('distribute_paths_evenly')}
                  </Checkbox>
                </Row>
                <Row>
                  {editable && (
                    <Button
                      variant='secondary'
                      outline={true}
                      disabled={!editable}
                      onClick={handleAddSegment}
                      style={{
                        width: '100%',
                        marginTop: '14px'
                      }}
                      {...testId('ab-testing-required-settings-add-segment')()}
                    >
                      {p('add_path')}
                    </Button>
                  )}
                </Row>
              </WrapperContent>
            </>
          }
        />
      </Container>
    </Theme>
  );
};

export default ABTestingNodeSettings;
