import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 160px;
  padding: var(--buttons-big-padding-TB, 8px) var(--buttons-big-padding-RL, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--buttons-small-space, 4px);
  border-radius: var(--buttons-big-radius, 7px);
  border: 1px solid var(--buttons-outline-border, #1A232E);
  cursor: pointer;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;
