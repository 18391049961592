import { Dropdown } from 'antd';

import { useTranslation } from '@hooks';

import { NoData } from '@components';

import { Container, Option, Overlay } from './styled';

const AddWorkflowButton = ({ options, onSelect, editable }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomCenter"
      disabled={!editable}
      overlayStyle={{ minWidth: 480 }}
      overlay={(
        <Overlay>
          {options?.length ? options?.map(({ value, label }) => (
            <Option key={value} onClick={() => onSelect({ value })}>{label}</Option>
          )) : <NoData />}
        </Overlay>
      )}
    >
      <Container>
        {p('add_workflow')}
      </Container>
    </Dropdown>
  )
};

export default AddWorkflowButton;
