import { Tooltip, Switch } from 'antd';

import { useTranslation } from '@hooks';

import { Icon, AsideTabs } from '@components';

import { SubContentContainer, AdditionalSettingsContainer, TooltipContainer, SubContentTitle, SubTitle } from './styled';

import { AI_BEST_TIME_TO_SEND } from '../../../module/useBestChannelToSend';

export const SubContent = ({
  enabledAiBestTime,
  handleChangeEnabledIdBestTime,
  currentChannel,
  handleSelectChannel
}) => {
  const { p } = useTranslation('workflow_page');

  return (
    <SubContentContainer>
      <AdditionalSettingsContainer>
        <SubContentTitle>
          {p('additional_settings')}
        </SubContentTitle>
        <SubTitle>
          {p('ai_best_time_to_send')}

          <TooltipContainer>
            <Tooltip
              title={p('ai_best_time_to_send_tooltip')}
              placement='right'
            >
              <Icon color={'#1A232E'} name='Info-icon' size={14} />
            </Tooltip>
          </TooltipContainer>
          <Switch
            style={{
              marginLeft: '15px',
              backgroundColor: enabledAiBestTime ? '#31c447' : '',
            }}
            checked={enabledAiBestTime}
            onChange={handleChangeEnabledIdBestTime}
            size='small'
          />
        </SubTitle>
        <AsideTabs.ItemList
          active={currentChannel === AI_BEST_TIME_TO_SEND}
          onClick={() => handleSelectChannel(AI_BEST_TIME_TO_SEND)}
          disabled={!enabledAiBestTime}
        >
          <AsideTabs.ItemContent
            label={p('ai_best_time_to_send')}
          />
        </AsideTabs.ItemList>
      </AdditionalSettingsContainer>
    </SubContentContainer>
  );
};
