import { Button } from '@velitech/ui';
import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { moment, testId } from '@utils';

import clock from '@res/images/clock1_1.svg'
import clock1 from '@res/images/quiet_hours_1.svg'

import { Icon, RadioButton, RadioGroup } from '@components';

import {
  Container,
  Title,
  Image,
  ContentContainer,
  QuiteHoursSettingsContainer,
  SettingsTitle,
  SettingsOptions,
  PickersContainer,
  Actions, Divider
} from './styled';

import { WorkflowSetupSteps } from '../../constants';
import { TimePicker } from '../TimePicker';

const QuietHoursStep = ({ worker, onWorkerChange, onStepChange, loading, direction }) => {
  const { p } = useTranslation('workflow_page');

  const handleNextStep = () => {
    onStepChange(WorkflowSetupSteps.EXIT_CRITERIA);
  };

  const handleTypeChange = (type) => {
    onWorkerChange(w => ({
      ...w,
      quite_hours: type === 'any' ? null : { start: null, end: null },
    }));
  };

  const handleStartChange = (time) => {
    onWorkerChange(w => ({
      ...w,
      quite_hours: {
        ...(w.quite_hours || {}),
        start: time,
      }
    }));
  }

  const handleEndChange = (time) => {
    onWorkerChange(w => ({
      ...w,
      quite_hours: {
        ...(w.quite_hours || {}),
        end: time,
      }
    }));
  };

  const type = (worker.quite_hours && !Array.isArray(worker.quite_hours)) ? 'specific' : 'any';

  return (
    <Container custom={direction}>
      <Title data-testid={'quiet-hours-step-title'}>
        {p('send_settings')}
      </Title>
      <ContentContainer>
        <Image src={type === 'any' ? clock : clock1}/>
        <QuiteHoursSettingsContainer>
          <SettingsTitle>
            {p('quite_hours_title')}
            <Tooltip title={'Quite hours'}>
              <div>
                <Icon name="Info-icon" size={16} color="#7C94B1" />
              </div>
            </Tooltip>
          </SettingsTitle>
          <RadioGroup
            disabled={worker.status !==0}
            value={type}
            onChange={handleTypeChange}
          >
            <SettingsOptions>
              <RadioButton
                disabled={worker.status !==0}
                {...testId('quiet-hours-step-radio-btn-any-time')()}
                style={{ fontFamily: 'Manrope-SemiBold', fontSize: '18px' }}
                bigger
                name="any"
                title={p('Send_messages_at_any_time')}
              />
              <RadioButton
                disabled={worker.status !==0}
                {...testId('quiet-hours-step-radio-btn-specific-time')()}
                style={{ fontFamily: 'Manrope-SemiBold', fontSize: '18px', marginBottom: '16px' }}
                bigger
                name="specific"
                title={p('Send_messages_at_specific_time')}
              />
            </SettingsOptions>
          </RadioGroup>
          {type === 'specific' && (
            <PickersContainer>
              <TimePicker
                disabled={worker.status !==0}
                format={'HH:mm'}
                value={worker.quite_hours?.start ? moment(worker.quite_hours?.start) : void 0}
                placeholder={'Start'}
                onChange={handleStartChange}
              />
              <Divider />
              <TimePicker
                disabled={worker.status !==0}
                format={'HH:mm'}
                value={worker.quite_hours?.end ? moment(worker.quite_hours?.end) : void 0}
                placeholder={'End'}
                onChange={handleEndChange}
              />
            </PickersContainer>
          )}
        </QuiteHoursSettingsContainer>
      </ContentContainer>
      <Actions>
        <Button
          {...testId('quiet-hours-step-next-step-btn')()}
          width={130}
          onClick={handleNextStep}
          loading={loading}
          disabled={type === 'specific' && (!worker.quite_hours.start || !worker.quite_hours.end)}
        >
          {p('next')}
        </Button>
      </Actions>
    </Container>
  );
};

export default QuietHoursStep;
