import { Container, Option, OptionCaption, OptionTitle } from './styled';

import { ListNoData } from '../ListNoData';

const LinearList = ({ options, onSelect, omitValues = [] }) => {
  const handleSelect = (v) => () => {
    onSelect({ id: v });
  };

  return (
    <Container>
      {!options.length && (
        <ListNoData />
      )}
      <Option key="tags" onClick={handleSelect({ special: true, field: 'tags' })}>
        <OptionTitle>
          Tags
        </OptionTitle>
        <OptionCaption>
          {/* @TODO: customers count */}
        </OptionCaption>
      </Option>
      {options.filter(({ value }) => !omitValues.includes(value)).map(({ value, label }) => (
        <Option key={value} onClick={handleSelect(value)}>
          <OptionTitle>
            {label}
          </OptionTitle>
          <OptionCaption>
            {/* @TODO: customers count */}
          </OptionCaption>
        </Option>
      ))}
    </Container>
  );
};

export default LinearList;
