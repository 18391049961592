import { ReducerKeys } from '@constants';

export const workflowListSelector = store => store[ReducerKeys.WORKFLOW].workflows;

export const workflowAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].analytics;

export const workflowProgramAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].programAnalytics;

export const workflowNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].nodeAnalytics;

export const workflowPublishHistorySelector = store => store[ReducerKeys.WORKFLOW].publishHistory;

export const workflowLogsSelector = store => store[ReducerKeys.WORKFLOW].workflowLogs;

export const smsNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].smsNodeAnalytics;

export const emailNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].emailNodeAnalytics;

export const webpushNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].webpushNodeAnalytics;

export const viberNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].viberNodeAnalytics;

export const mobilePushNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].mobilePushAnalytics;

export const webhookNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].webhookNodeAnalytics;

export const contentCardNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].contentCardNodeAnalytics;

export const webPopupNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].webpopupNodeAnalytics;

export const nodeWebpopupElementAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].webpopupElementAnalytics;

export const workflowProgramSelector = store => store[ReducerKeys.WORKFLOW]?.program;

export const workflowWorkerSelector = store => store[ReducerKeys.WORKFLOW]?.workers;

export const workflowEntryCountSelector = store => store[ReducerKeys.WORKFLOW]?.entry_count;

export const horizonCampaignsSelector = store => store[ReducerKeys.WORKFLOW].horizonCampaigns;

export const horizonBonusesSelector = store => store[ReducerKeys.WORKFLOW].horizonBonuses;

export const horizonFreeSpinsSelector = store => store[ReducerKeys.WORKFLOW].horizonFreeSpins;

export const horizonFreeBetsSelector = store => store[ReducerKeys.WORKFLOW].horizonFreeBets;

export const initialWorker = store => store[ReducerKeys.WORKFLOW].initialWorker;

export const nodeTrendAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].nodeTrendAnalytics;

export const goalsAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].goalsAnalytics;

export const horizonCurrenciesSelector = store => store[ReducerKeys.WORKFLOW].horizonCurrencies;

export const autoSaveEnabledSelector = store => store[ReducerKeys.WORKFLOW].autoSave?.enabled;

export const horizonTagsSelector = store => store[ReducerKeys.WORKFLOW].horizonTags;
