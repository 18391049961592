import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactFlow, { isEdge, isNode, useReactFlow } from 'reactflow';

import { WorkflowActionTypes } from '@store/actions/types';
import { workflowOptionWithEventsSelector } from '@store/selectors';

import { useTransition } from '@react-spring/web';

import {
  useKeydown,
  useModalState,
  useTranslation,
  useNavigate,
} from '@hooks';

import { WorkflowNodes , WorkflowEdgeTypes, WorkflowNodeTypes } from '@constants';

import { Platform, notify, by, extract } from '@utils';
import { isLast } from '@utils/workflows/refactored/getters';

import {
  ActionsBar,
  DeleteNodeModal,
  FinishNode,
  Node,
  NodeArray,
  SplitterNode,
  Background,
  NodeSettingsModal,
  DefaultEdge, ConnectionModeBadge, CopyModeBadge, NodeSendingAnalytics,
} from './components';
import {
  Container,
  EditorContainer,
  Editor,
  BuilderWrapper,
  NodeAnalyticsContainer,
  WithAnalyticsContainer,
  AnalyticsBackground
} from './styled';

import { NodeActionsProvider } from '../../../contexts';
import WorkflowEditorContext from '../../../contexts/WorkflowEditorContext/WorkflowEditorContext';

const nodeTypes = {
  [WorkflowNodeTypes.DEFAULT]: Node,
  [WorkflowNodeTypes.ARRAY]: NodeArray,
  [WorkflowNodeTypes.FINISH]: FinishNode,
  [WorkflowNodeTypes.SPLITTER]: SplitterNode
};

const edgeTypes = {
  [WorkflowEdgeTypes.DEFAULT]: DefaultEdge
};

const defaultViewport = { x: 145, y: 145, zoom: 1 };

const snapGrid = [110, 110];

const flowStyle = { flex: 1 };

const WorkflowEditor = ({
  displayAnalytics,
  selectedAnalytics,
  showHeader,
  page = 'edit',
  children,
  preview,
  program,
  worker: workerData,
  analyticsNodeData,
  analyticTrendNodeData,
  onDownloadByChannel,
  onDownloadContacts,
  manageBestChannels,
  analyticBychannel,
}) => {
  const navigate = useNavigate()
  const editor = useContext(WorkflowEditorContext);
  const options = useSelector(workflowOptionWithEventsSelector);
  const [toRemove, setToRemove] = useState(null);
  const deleteNodeModal = useModalState();
  const { p } = useTranslation('workflow_page');
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const dispatch = useDispatch();
  const ref = useRef({ addEventListener: () => {}, removeEventListener: () => {} });

  const selectedName = editor.editable && editor.renderNodes.find(by(editor.selection))?.data?.name;
  const canDelete = editor.editable && selectedName !== 'ab' && selectedName !== 'exclude' && selectedName !== 'include';
  const canCopy = editor.editable && selectedName !== 'ab' && selectedName !== 'exclude' && selectedName !== 'include' && selectedName !== 'array';
  const { setCenter, setViewport, getViewport, project } = useReactFlow();

  const handleCenterNode = (nodeId) => {
    const node = document.querySelector(`[data-id="${nodeId}"]`);

    if (node) {
      const rect = node.getBoundingClientRect();
      const { x, y } = project({ x: rect.x + rect.width / 2 - 75, y: rect.y + rect.height / 2 - 200 });
      setCenter(x, y, { duration: 500, zoom: 0.75 });
    }
  };

  const analyticsTransition = useTransition(!!selectedAnalytics, {
    enter: { translateX: '0%' },
    from: { translateX: '100%', },
    leave: { translateX: '100%', },
  });

  const analyticsBackgroundTransition = useTransition(!!selectedAnalytics, {
    enter: { width: '675px' },
    from: { width: '1px' },
    leave: { width: '1px' },
  });

  useTransition(editor.openedNodeSettings, {
    enter: { width: '675px' },
    from: { width: '1px' },
    leave: { width: '1px' },
    onRest: () => {
      if (selectedAnalytics) {
        handleCenterNode(editor.openedNodeSettings);
      } else if (editor.memoizedViewport) {
        setViewport(editor.memoizedViewport, { duration: 500 });
      }
    }
  });

  useEffect(() => {
    if (editor.focusNode && editor.editable) {
      handleCenterNode(editor.focusNode);
    }
  }, [editor.focusNode]);

  useEffect(() => {
    if (!workerData || !workerData?.styles) {
      return;
    }

    setViewport({ zoom: workerData.styles.scale, ...workerData.styles.stage_position }, { duration: 300 });
  }, [workerData?.status]);

  const openDeleteModal = () => {
    if (!editor.editable || !canDelete) {
      return;
    }

    deleteNodeModal.open();
  }

  const handleCopy = async (id) => {
    if (!editor.editable || !canCopy) {
      return;
    }

    editor.copyNode(id);
  };

  const handleUndo = () => {
    if (!editor.actions.canUndo) {
      return;
    }

    editor.actions.undo();
  }

  const handleRedo = () => {
    if (!editor.actions.canRedo) {
      return;
    }

    editor.actions.redo();
  };

  useKeydown([
    ...Platform.select({
      macos: [
        [['command', 'z'], handleUndo],
        [['command', 'shift', 'z'], handleRedo]
      ],
      fallback: [
        [['ctrl', 'z'], handleUndo],
        [['ctrl', 'shift', 'z'], handleRedo],
      ]
    }),
  ], [], document.body, !editor.openedNodeSettings);

  const handleElementsRemove = (toRemove) => {
    if (!editor.editable) {
      return;
    }
    setToRemove(toRemove[0]);
    openDeleteModal();
  };

  const handleConfirmRemoveNode = () => {
    const tr = toRemove || { id: editor.selection };

    editor.deleteSelectedNode(tr);
    setToRemove([]);
    deleteNodeModal.close();
    notify('success', p('node_deleted'), editor.renderNodes.find(by(tr?.id))?.data?.label);
  };

  const handleConfirmRemoveBranch = () => {
    const tr = toRemove || { id: editor.selection };

    editor.deleteBranchFromSelected(tr);
    setToRemove([]);
    deleteNodeModal.close();
    notify('success', p('nodes_deleted'), `${p('from')} ${editor.renderNodes.find(by(tr?.id))?.data?.label}`);
  };

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const elements = editor.renderNodes.map(
    n => isNode(n) ? ({ ...n, position: { x: n.position.x, y: n.position.y } }) : n
  );

  useEffect(() => {
    const edges = elements.filter(el => isEdge(el)).map((el) => {
      if(workerData?.status === 1) {
        return {
          ...el,
          animated: true,
        }
      } else {
        return el;
      }
    });

    setNodes(elements.filter(el => isNode(el)));
    setEdges(edges);
  }, [elements.map(extract('id')).join('.'), workerData?.status]);

  const openedNodeSettingsData = editor.renderNodes?.find(by(editor.openedNodeSettings))?.data;

  const shouldHideEdge = (data) => {

    [
      WorkflowNodes.SWITCH_FILTER,
      WorkflowNodes.WAIT,
      WorkflowNodes.AB_SPLITTER
    ].includes(data.name) && editor.hideEdgesWhenUpdate();
  }

  const handleSettingsChange = (data, styles) => {
    editor.changeNodeData(editor.openedNodeSettings, data, styles);
    editor.setOpenedNodeSettings('');
    shouldHideEdge(data);
  };

  const defaultOpened = editor.renderNodes?.find(n => n?.data?.defaultOpened);

  useEffect(() => {
    if (defaultOpened) {
      editor.setOpenedNodeSettings(defaultOpened.id);
      if (!worker) {
        return editor.changeNodeData(defaultOpened.id, {
          ...defaultOpened?.data,
          defaultOpened: false,
          children: (defaultOpened?.data?.children || []).map(child => ({
            ...child,
            data: {
              ...(child?.data || {}),
              defaultOpened: false,
            }
          }))
        });
      }
      dispatch({
        type: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        meta: { node: defaultOpened.id, worker: worker || 'new' },
        payload: { data: {
          ...(defaultOpened.data || {}),
          defaultOpened: false,
          children: (defaultOpened?.data?.children || []).map(child => ({
            ...child,
            data: {
              ...(child?.data || {}),
              defaultOpened: false,
            }
          }))
        }
        }
      })
    }
  }, [defaultOpened]);

  const handleChangeTrigger = () => {
    if (workerData?.status === 0) {
      navigate(`/workflows/configure/${program.id}/trigger?w=${workerData.id}`);
    }
  };

  const handleDeleteNode = (id) => {
    handleElementsRemove([{ id }])
  };

  const handleMouseDown = () => {
    const newEvent = new Event("react-flow-mousedown");
    document.dispatchEvent(newEvent);
  };

  const handleChangeDirectionMode = (mode) => {
    editor.setDirectionMode(mode);

    mode !== editor.directionMode && editor.hideEdgesWhenUpdate();
  };

  const handleNodeClick = (e, n) => {
    editor.onNodeClick(e, n, getViewport());
  };

  const getDefaultViewPort = () => {

    const zoom = workerData?.styles?.scale || defaultViewport.zoom;
    const x = workerData?.styles?.stage_position?.x || defaultViewport.x;
    const y = workerData?.styles?.stage_position?.y || defaultViewport.y

    return {
      zoom,
      x,
      y
    }
  };

  return (
    <Container workerStatus={workerData?.status} switchStyle={!showHeader ? 'hidden' : page} ref={ref}>
      {children}
      {!!editor.newConnectionSource && (
        <ConnectionModeBadge onCancel={editor.cancelConnection} />
      )}
      {editor.pasteModeActive && (
        <CopyModeBadge onCancel={editor.cancelCopy} />
      )}
      <EditorContainer>
        <NodeActionsProvider
          onDelete={handleDeleteNode}
          onCopy={handleCopy}
          onChangeTrigger={handleChangeTrigger}
          workerStatus={workerData?.status}
          canDelete={canDelete}
          canCopy={editor.editable && canCopy}
          shouldUpdateNode={editor.shouldUpdateNode}
          setShouldUpdateNode={editor.setShouldUpdateNode}
          hideEdge={editor.hideEdge}
        >
          <BuilderWrapper preview={preview}>
            <WithAnalyticsContainer>
              <Editor preview={preview} background={workerData?.status} ref={editor.containerRef}>
                {/*<WorkflowPointer containerRef={editor.containerRef} />*/}
                {!preview && (
                  <ActionsBar
                    reactFlowInstance={editor.instance}
                    onFitView={editor.fitView}
                    onRedo={editor.actions.redo}
                    onUndo={editor.actions.undo}
                    canRedo={editor.actions.canRedo && editor.editable}
                    canUndo={editor.actions.canUndo && editor.editable}
                    directionMode={editor.directionMode}
                    onDirectionModeChange={handleChangeDirectionMode}
                  />
                )}
                <ReactFlow
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                  nodes={nodes}
                  edges={edges}
                  style={flowStyle}
                  onNodeClick={handleNodeClick}
                  onInit={editor.onLoad}
                  onConnect={editor.connect}
                  snapGrid={snapGrid}
                  defaultViewport={getDefaultViewPort()}
                  snapToGrid={false}
                  minZoom={0.1}
                  onPaneClick={handleMouseDown}
                  deleteKeyCode={null}
                  nodesDraggable={false}
                  fitView={preview}
                  connectionLineType="smoothstep"
                  onElementsRemove={handleElementsRemove}

                >
                  <Background
                    preview={preview}
                    workerStatus={workerData?.status}
                    variant={preview ? "dots" : "dots"}
                    gap={20}
                    color={preview ? '#FAF7EF' : '#D9D9D9'}
                    size={preview ? 0 : 1}
                    style={{ background: preview ? '#FAF7EF' : void 0 }}
                  />
                </ReactFlow>
              </Editor>
              {analyticsBackgroundTransition((style, active) => active && (
                <AnalyticsBackground style={style} />
              ))}
              {analyticsTransition((style, active) => active && (
                <NodeAnalyticsContainer style={style}>
                  <NodeSendingAnalytics
                    selectedNode={selectedAnalytics}
                    nodeData={analyticsNodeData}
                    analyticTrendNodeData={analyticTrendNodeData}
                    onDownloadByChannel={onDownloadByChannel}
                    onDownloadContacts={onDownloadContacts}
                    program={program}
                    worker={workerData}
                    manageBestChannels={manageBestChannels}
                    analyticBychannel={analyticBychannel}
                  />
                </NodeAnalyticsContainer>
              ))}
            </WithAnalyticsContainer>
          </BuilderWrapper>
        </NodeActionsProvider>
      </EditorContainer>
      {(editor.openedNodeSettings && !displayAnalytics) && (
        <NodeSettingsModal
          data={openedNodeSettingsData}
          editable={editor.editable}
          type={openedNodeSettingsData?.type}
          options={options}
          onSave={handleSettingsChange}
          opened={editor.openedNodeSettings}
          onClose={() => editor.setOpenedNodeSettings('')}
        />
      )}
      <DeleteNodeModal
        toRemove={toRemove}
        opened={deleteNodeModal.opened}
        onClose={deleteNodeModal.close}
        deleteBranchDisabled={toRemove && isLast(editor.state.root, toRemove.id)}
        onDeleteNode={handleConfirmRemoveNode}
        onDeleteBranch={handleConfirmRemoveBranch}
      />
    </Container>
  );
};

export default React.memo(WorkflowEditor);
