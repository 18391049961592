import { useNavigate as useReactRouterNavigate } from 'react-router';

import { hashAppId } from '@utils';

import { useCurrentWorkspace } from './index';

const useNavigate = () => {
  const navigate = useReactRouterNavigate();
  const currentApp = useCurrentWorkspace();

  if (!currentApp) {
    return navigate;
  }

  return (path, ...rest) => {
    if (path === -1) {
      navigate(path, ...rest);
    }

    navigate(`/app/${hashAppId(currentApp.id)}${path}`, ...rest);
  };
};

export default useNavigate;
