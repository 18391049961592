import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGiveRewardHistoryTemplates } from '@store/actions/creators';
import {
  giveRewardCampaignHistorySelector, giveRewardHistoryTemplatesSelector,
} from '@store/selectors';

import { DateRangeField, MultiSelectField, RowCell } from '@velitech/ui';
import capitalize from 'lodash.capitalize';
import uniq from 'lodash.uniq';

import { useTranslation } from '@hooks';

import { GiveRewardStatusOptions, Paths, UserPermissions } from '@constants';

import { compose, extract, identity, moment } from '@utils';

import { DateCell, Link, TableLayout, WithPermissions } from '@components';
import { IdType } from '@components/lib/styled';
import { FlexibleText } from '@components/ui/Table';

const GiveRewardTable = ({ total, table }) => {
  const data = useSelector(giveRewardCampaignHistorySelector);
  const { t, p } = useTranslation('notification_history');
  const dispatch = useDispatch();
  const templates = useSelector(giveRewardHistoryTemplatesSelector);

  useEffect(() => {
    dispatch(getGiveRewardHistoryTemplates({
      freeSpinIds: uniq((data.data || []).filter(({ reward_type }) => reward_type === 'free-spin').map(extract('reward_id'))).join(),
      bonusIds: uniq((data.data || []).filter(({ reward_type }) => reward_type === 'bonus').map(extract('reward_id'))).join(),
      freeBetIds: uniq((data.data || []).filter(({ reward_type }) => reward_type === 'free-bet').map(extract('reward_id'))).join(),
    }))
  }, [(data.data || []).map(extract('reward_id')).join()]);

  const columns = [
    {
      header: {
        label: t('labels.customer_id'),
      },
      name: 'customer_id',
      className: `notification-history-cell-id`,
      render: (d) => (
        <div>
          <FlexibleText text={d.customer_id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link type="secondary" to={`${Paths.CONTACT}/${d.customer_id}`}>{d.customer_id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      ),
    },
    {
      header: {
        label: 'Reward name',
      },
      name: 'reward_type',
      render: ({ reward_id, reward_type: type }) => {
        return (templates || {})[{ bonus: 'bonuses', 'free-spin': 'freeSpins', 'free-bet': 'freeBets' }[type]]?.data?.[reward_id]?.name || '---';
      }
    },
    // {
    //   header: {
    //     label: t('labels.language_code'),
    //     className: 'w-10',
    //   },
    //   name: 'language_code',
    //   render: (data) => {
    //     const languageItem = languages[data?.language_code] || languages['uk'];
    //     return (
    //       <LanguageItem icon={languageItem.emoji} code={languageItem.languageCode} name={languageItem.languageName} />
    //     );
    //   },
    // },
    {
      header: {
        label: t('labels.date'),
      },
      name: 'created_at',
      className: `notification-history-cell-date`,
      render: ({ created_at: date }) => <DateCell date={date}/>
    },
    {
      header: {
        label: 'Reward type',
      },
      dataIndex: 'reward_type',
      key: 'reward_type',
      render: compose(capitalize, extract('reward_type')),
    },
  ];

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  };

  return (
    <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_EMAIL} hidden>
      <TableLayout
        withTabs
        fullPage
        name="notification_history_give_reward"
        loading={data.loading}
        columns={columns}
        data={data.data || []}
        table={table}
        total={total}
        localizer={tableLocalizer}
        renderFilter={(
          <>
            <RowCell className='minWidth-250'>
              <MultiSelectField
                disableErrorSpaceReserve
                className="status-select"
                placeholder={'Select status'}
                selectedOptionsLabel="Selected options"
                availableOptionsLabel="Available options"
                label={t('labels.status')}
                options={(GiveRewardStatusOptions || []).map(o => ({ ...o, label: t(o.label) }))}
                onChange={table.filters.onChange('status', identity)}
                value={ table.filters.filters['status']}
              />
            </RowCell>
            {/* <RowCell className='minWidth-250'>
                <SingleSelectDataField
                  disableErrorSpaceReserve
                  className="status-select"
                  placeholder={'Select language code'}
                  label={t('labels.language_code')}
                  options={languageOptions() || []}
                  mapOptionLabel={(data) => <LanguageItem icon={data.emoji} code={data.value} name={data.label} />}
                  mapOptionValue={(data) => data.value}
                  onChange={table.filters.onChange('language_code', identity)}
                  value={ table.filters.filters['language_code']}
                />
              </RowCell> */}
            <RowCell>
              <DateRangeField
                label={'Date'}
                disableErrorSpaceReserve
                dateFormat={'yyyy/MM/dd'}
                from={{
                  selected: getStartDate(table.filters.filters.start_date),
                  onChange: (date) => handleChangeDate('start_date', date),
                }}
                to={{
                  selected: getEndDate(table.filters.filters.end_date),
                  onChange: (date) => handleChangeDate('end_date', date),
                }}
              />
            </RowCell>
          </>
        )}
      />
    </WithPermissions>
  );
};

export default GiveRewardTable;
