import styled, { css } from "styled-components";

export const Container = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #5cbc6a;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    
    ${({ $position }) => css`
        top: ${$position.y};
        left: ${$position.x};
        transform: translate(-50%, -50%);
    `} 
`;

export const Pointer = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50% 50% 50% 0;
    background: #5cbc6a;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    ${({ $angle }) => css`
        transform: rotate(${$angle}deg);
    `}
`;

export const Inner = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: var(--Colors-Additional-White, #FFF);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
