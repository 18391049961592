import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { WorkflowActionTypes } from '@store/actions/types';

import { useTranslation, useNavigate } from '@hooks';

import { AppRedirects, Paths, WorkflowNodes } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { by, diffFields, removeAppFromPath, testId, update } from '@utils';

import { Input, Textarea } from '@components';
import { DynamicVariablesSettings } from '@components/lib/WorkflowEditor/components';

import { RecepientsSettings } from './components'
import { Container } from './styled';

import { WorkflowEditorContext } from '../../../../../../../contexts';
import { NodeSettingsAccordion } from "../../../NodeSettingsAccordion";
import NodeSettingsTemplatesPreview from '../NodeSettingsTemplatesPreview/NodeSettingsTemplatesPreview';
import { NodeSettingsContent } from "../SendEmailNodeSettings/styled";

const TeamMessageNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const { t } = useTranslation('workflow_page');
  const messageType = value.message_type || WorkflowNodes.SEND_EMAIL;

  const handleMessageTypeChange = (message_type) => {
    onChange(s => ({ ...s, message_type }));
  };

  const handleRecepientsChange = (recipients) => {
    onChange(s => ({ ...s, recipients }));
  };

  const entity = {
    [WorkflowNodes.SEND_SMS]: options?.[TemplateTypes.SMS]?.find?.(by(value.sms_id)),
    [WorkflowNodes.SEND_EMAIL]: options?.[TemplateTypes.EMAIL]?.find?.(by(value.email_id)),
    'slack': options?.slack?.find?.(by(value.sms_id)),
  }[messageType];

  const templateOptions = {
    [WorkflowNodes.SEND_SMS]: (options?.[TemplateTypes.SMS] || []).filter((item) => item?.status !== 3).map(({ id, name, data }) => ({
      value: id,
      label: name,
      previewData: data,
    })),
    [WorkflowNodes.SEND_EMAIL]: (options?.[TemplateTypes.EMAIL] || []).filter((item) => item?.status !== 3).map(({ id, name, data }) => ({
      value: id,
      label: name,
      previewData: data,
    })),
    'slack': (options?.[TemplateTypes.SLACK] || []).filter((item) => item?.status !== 3).map(({ id, name, data }) => ({
      value: id,
      label: name,
      previewData: data,
    })),
  }[messageType];

  const handleTemplateChange = (email_id) => {
    onClearError('email_id');
    onChange(s => ({ ...s, email_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.EMAIL_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'email_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_SMS}/new`);
  };

  const handleEditTemplate = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.EMAIL_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'email_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_EMAIL}/vce/${value.email_id}`);
  };

  const handleCustomChange = (updater) => {
    onChange(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <NodeSettingsContent>
              <NodeSettingsTemplatesPreview
                value={value.email_id}
                nodeValue={value}
                onNodeValueChange={onChange}
                onChange={handleTemplateChange}
                onCreateNew={handleCreateNew}
                type={value.messageType || WorkflowNodes.SEND_EMAIL}
                // tabs={tabs}
                tab={messageType}
                onTabChange={handleMessageTypeChange}
                onEditTemplate={handleEditTemplate}
                options={templateOptions}
              />
            </NodeSettingsContent>
            {entity && (
              <RecepientsSettings
                value={value.recipients || []}
                onClearError={() => {
                  onClearError('recipients');
                  onClearError('recipients_valid');
                }}
                onChange={handleRecepientsChange}
                error={errors['recipients'] || errors['recipients_valid']}
              />
            )}
            <DynamicVariablesSettings
              value={value.dynamic || {}}
              onChange={handleCustomChange}
              variables={entity?.dynamicVariables || []}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('send-sms-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('send-sms-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default TeamMessageNodeSettings;
