import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div).attrs({
  initial: { opacity: 0, y: -60, scale: 0.98 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: -60, scale: 0.98 },
  transition: { duration: 0.27, easing: 'ease' }
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 150px;
`;

export const Title = styled.div`
  margin-top: 118px;
  width: 416px;
  top: 119px;
  text-align: center;
  padding: 0 40px;
  color: var(--text-primary-body, #1A232E);
  text-align: center;

  font-family: Manrope-SemiBold;
  font-size: 24px;
  line-height: 28px; /* 116.667% */
`;

export const TriggerTypeCards = styled.div`
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(4, minmax(180px, auto));
  justify-content: center;
  gap: 24px;
`;
