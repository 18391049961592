import { useState } from 'react';
import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';

import { SegmentsRuleTypes } from '@constants';

import { clickhouseFields2Customer, clickhouseFields2Events } from '@utils/fields';

import { Modal } from '@components';

import { FilterTypeCard } from './components';
import { Container, CardsContainer, Description, CloseButton } from './styled';

import { AggregatesList } from '../AggregatesList';
import { ArrayList } from '../ArrayList';
import { EventsList } from '../EventsList';
import { LinearList } from '../LinearList';
import { ModalListHeader } from '../ModalListHeader';

const resolveDescription = (type) => {
  switch (type) {
    case SegmentsRuleTypes.LINEAR:
      return 'filter_description_linear';
    case SegmentsRuleTypes.ARRAY:
      return 'filter_description_array';
    case SegmentsRuleTypes.EVENT:
      return 'filter_description_event';
    case SegmentsRuleTypes.AGGREGATE:
      return 'filter_description_aggregate';
    default:
      return 'filter_description';
  }
};

const systemEvents = [
  "email",
  "sms",
  "webpush",
  "viber",
  "mobilepush",
  "page_view",
  "session_start",
  "session_first",
  "session_end"
];

const SelectFilterModal = ({ opened, onClose, onSelect, testId }) => {
  const [type, setType] = useState();
  const [hoveredType, setHoveredType] = useState();
  const { p } = useTranslation('workflow_page');
  const segmentOptions = useSelector(optionsSelector);
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const linearOptions = (customerFields || []).filter(f => f.type !== 'array').map(f => ({ value: f.field, label: f.label }));
  const arrayOptions = (customerFields || []).filter(f => f.type === 'array').map(f => ({ value: f.field, label: f.label }));
  const aggregateOptions = segmentOptions?.aggregates?.map?.(({ label, name }) => ({ value: name, label }));
  const events = clickhouseFields2Events(clickhouseFields.data);
  const [searchTerm, setSearchTerm] = useState('');

  const eventOptions = events.data?.map(({ label, id, name, payload }) => {
    return {
      label,
      name,
      value: id,
      system: systemEvents.includes(name),
      payload,
      field: name,
    }
  });

  const searchedOptions = (options, searchTerm) => {
    return options.filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleSelectType = (type) => () => {
    setType(type);
  };

  const handleSelectEvent = (event) => {
    onSelect(SegmentsRuleTypes.EVENT, event.id);
    onClose();
    setType();
  };

  const handleSelectLinear = (field) => {
    if (field.id.special) {
      onSelect(SegmentsRuleTypes.ARRAY, field.id.field);
      onClose();
      setType();
      return;
    }

    onSelect(SegmentsRuleTypes.LINEAR, field.id);
    onClose();
    setType();
  };

  const handleSelectArray = (field) => {
    onSelect(SegmentsRuleTypes.ARRAY, field.id);
    onClose();
    setType();
  };

  const handleSelectAggregate = (field) => {
    onSelect(SegmentsRuleTypes.AGGREGATE, field.id);
    onClose();
    setType();
  };

  const handleClose = () => {
    setType();
    onClose();
  }

  return (
    <Modal
      style={{ width: 530, height: 300 }}
      containerStyle={{ width: 530, height: 300 }}
      contentStyles={{ padding: 0 }}
      opened={opened}
      onClose={handleClose}
    >
      <Container $s={!!type}>
        {!type && (
          <>
            <CloseButton onClick={handleClose}>
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="26" height="26" rx="13" fill="#7C94B1" fillOpacity="0.12"/>
                <path d="M17 9.7L16.3 9L13 12.3L9.7 9L9 9.7L12.3 13L9 16.3L9.7 17L13 13.7L16.3 17L17 16.3L13.7 13L17 9.7Z" fill="#7C94B1"/>
              </svg>
            </CloseButton>
            <CardsContainer>
              {[
                SegmentsRuleTypes.LINEAR,
                // SegmentsRuleTypes.ARRAY,
                SegmentsRuleTypes.EVENT,
                SegmentsRuleTypes.AGGREGATE
              ].map((type, idx) => (
                <FilterTypeCard
                  key={idx}
                  testId={`${testId}-filter-modal-card-${type}`}
                  type={type}
                  onClick={handleSelectType(type)}
                  onMouseEnter={() => setHoveredType(type)}
                  onMouseLeave={() => setHoveredType()}
                />
              ))}
            </CardsContainer>
            <Description $lighter={!hoveredType}>
              {p(resolveDescription(hoveredType))}
            </Description>
          </>
        )}
        {type === SegmentsRuleTypes.LINEAR && (
          <div data-testid={`${testId}-select-filter-modal-Player fields-list`}>
            <ModalListHeader
              testId={'select-filter-modal-Player`'}
              onSearchChange={setSearchTerm}
              title={p('linear_fields')}
              onClose={handleClose}
            />
            <LinearList
              options={searchedOptions(linearOptions, searchTerm)}
              onSelect={handleSelectLinear}
            />
          </div>
        )}
        {type === SegmentsRuleTypes.ARRAY && (
          <div data-testid={`${testId}-select-filter-modal-Arrays-list`}>
            <ModalListHeader
              testId={'select-filter-modal-Arrays-list'}
              onSearchChange={setSearchTerm}
              title={p('arrays_list')}
              onClose={handleClose}
            />
            <ArrayList
              options={searchedOptions(arrayOptions, searchTerm)}
              onSelect={handleSelectArray}
            />
          </div>
        )}
        {type === SegmentsRuleTypes.EVENT && (
          <div data-testid={`${testId}-select-filter-modal-Events-list`}>
            <ModalListHeader
              testId={'select-filter-modal-Events-list'}
              onSearchChange={setSearchTerm}
              title={p('events_list')}
              onClose={handleClose}
            />
            <EventsList
              options={searchedOptions(eventOptions, searchTerm)}
              onSelect={handleSelectEvent}
            />
          </div>
        )}
        {type === SegmentsRuleTypes.AGGREGATE && (
          <div data-testid={`${testId}-select-filter-modal-Aggregates-list`}>
            <ModalListHeader
              testId={'select-filter-modal-Aggregates-list'}
              onSearchChange={setSearchTerm}
              title={p('aggregates_list')}
              onClose={handleClose}
            />
            <AggregatesList
              options={searchedOptions(aggregateOptions, searchTerm)}
              onSelect={handleSelectAggregate}
            />
          </div>
        )}
      </Container>
    </Modal>
  );
};

export default SelectFilterModal;
