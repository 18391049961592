import { useContext, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNode } from 'reactflow';

import { workflowEditorSetNodes } from '@store/actions/creators/workflowEditor';
import { workflowWorkerSelector, workflowOptionsSelector, workflowListSelector } from '@store/selectors';

import { useTransition } from '@react-spring/web';
import { Button, Popover } from '@velitech/ui';
import { Tooltip } from 'antd';
import { StringParam, useQueryParam } from 'use-query-params';

import { useModalState, useNavigate, useTranslation } from '@hooks';

import { Paths, WorkflowNodes } from '@constants';

import { by, getAnalyticsList } from '@utils';
import { createNode } from '@utils/workflows/refactored/creators';
import { isRealtime } from '@utils/workflows/refactored/getters';
import { unwrapArrays } from '@utils/workflows/refactored/updaters';

import workflowEditorContext from '@contexts/WorkflowEditorContext/WorkflowEditorContext';

import { Input, Link, Dropdown } from '@components';
import { NodeAnalytics } from '@components/lib/WorkflowEditor/components';
import { NodeAnalyticsContainer } from '@components/lib/WorkflowEditor/components/Node/styled';

import { AddWorkflowTriggerModal } from './components';
import { EditIconSvg, SuccessIconSvg } from './components/assets';
import {
  Wrapper,
  Container,
  NodeCardContainer,
  NodeCardHeader,
  Title,
  CardHeaderLeftSide,
  CardHeaderRightSide,
  NodeBottomActions,
  NodeThumbnail,
  WorkflowList,
  IconWithStyles,
  StyledHandle,
} from './styled';

import { DirectionModes } from '../../../../../constants';
import { IconSvg } from '../../../../ui/IconSvg';
import { NodeAnalyticsPopover } from '../NodeAnalyticsPopover';

const transitionConfig = {
  from: { opacity: 0, transform: "scale(0.9)" },
  enter: { opacity: 1, transform: "scale(1)" },
  leave: { opacity: 0, transform: "scale(0.9)" },
  config: { duration: 200 },
};

const FinishNode = ({ data }) => {
  const { p } = useTranslation('workflow_page');
  const addTriggerModal = useModalState();
  const dispatch = useDispatch();
  const options = useSelector(workflowOptionsSelector);
  const navigate = useNavigate();
  const editor = useContext(workflowEditorContext);
  const [wid] = useQueryParam('w', StringParam);
  const [label, setLabel] = useState(data.label);
  const [editableLabel, setEditableLabel] = useState(false);
  const workers = useSelector(workflowWorkerSelector);
  const workflows = useSelector(workflowListSelector);
  const [hovered, setHovered] = useState(false);

  const inputRef = useRef(null);

  const worker = workers?.[wid];
  const isTriggeringAllowed =
    isRealtime(editor.state.root) && wid && worker?.status === 1;

  const nextWorkflows = (options?.workflows || [])
    .filter(({ tree_map }) => (tree_map.children || []).some(({ data: childData }) => (childData?.trigger_workflow_node_ids || []).includes(data?.id)));

  const workflowStatusDesign = worker?.status === 0;

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  const handleLabelChange = ({ target: { value } }) => {
    setLabel(value);
  };

  const handleLabelBlur = () => {
    editor.changeNodeData(data.id, { ...data, label });
  };

  const handleAddTriggerModalClose = () => {
    addTriggerModal.close();
  };

  const handleCreateTriggeringWorkflow = ({ workflowName, exitPoints }) => {
    const [tree_map, style] = createNode({
      type: WorkflowNodes.ENTRY_ANOTHER_WF,
      actionType: 'entry_point',
      translate: p,
      data: {
        trigger_workflow_id: wid,
        trigger_workflow_node_ids: exitPoints,
        validated: true,
        validation: { valid: true, errors: {} },
      },
    });

    dispatch(
      workflowEditorSetNodes({
        id: 'new',
        tree_map: unwrapArrays(tree_map, true),
        styles: {
          [style.id]: style,
        },
      })
    );

    navigate(`${Paths.CREATE_WORKFLOW}?n=${workflowName}`);
  };

  const handleEditLabel = () => {
    setEditableLabel(true);
  };

  useEffect(() => {
    if (!inputRef.current || !editableLabel) {
      return;
    }

    inputRef.current?.focus?.();
  }, [editableLabel, !!inputRef.current]);

  const handleSaveLabel = () => {
    setEditableLabel(false);
    data.label !== label && handleLabelBlur();
  };

  const handleClickChangeLabel = () => {
    editableLabel ? handleSaveLabel() : handleEditLabel();
  };

  const branchMode = editor.directionMode;
  const list = getAnalyticsList(data.analytics, data.actionType, p)
  const emptyList = list?.every(({ count }) => +count === 0);
  const hoveredTransition = useTransition(hovered, transitionConfig);
  
  return (
    <Wrapper layout>
      <Container>
        {data.analytics && (
          <NodeAnalyticsContainer>
            <Popover
              offset={{
                crossAxis: 80
              }}
              hover
              contentClassName="container"
              placement='bottom-end'
              onOpenChange={(data) => setHovered(data)}
              content={
                !emptyList ? ( 
                  hoveredTransition(({ opacity, transform, filter }, hovered) => hovered && (
                    <NodeAnalyticsPopover
                      style={{ opacity, transform, filter }}
                      data={list}
                    />
                  ))
                ) : null
              }>
              <NodeAnalytics
                data={{
                  ...(data.analytics || {}),
                }}
                testId={`workflow-node-${(editor.renderNodes || [])
                  .filter(isNode)
                  .findIndex(by('id', data.id))}`}
                type={data.name}
              />
            </Popover>
          </NodeAnalyticsContainer>
        )}
        <NodeCardContainer>
          <NodeCardHeader data-testid={'workflow-finish-node-title'}>
            <CardHeaderLeftSide>
              <NodeThumbnail type={data.actionType}>
                <IconSvg
                  size={18}
                  name={'Nodes-Finish'}
                />
              </NodeThumbnail>
              {editableLabel ? (
                <Input
                  ref={inputRef}
                  testId={'workflow-finish-node-editable-label-input'}
                  style={{
                    marginBottom: '0',
                    height: '24px',
                    width: '140px',
                    fontSize: '14px',
                    padding: '0 2px',
                    borderRadius: '4px',
                    backgroundColor: '#C9E0FA',
                  }}
                  value={label}
                  onChange={handleLabelChange}
                />
              ) : (
                <Tooltip arrowPointAtCenter title={label.length > 19 ? label : ''}>
                  <Title>{label}</Title>
                </Tooltip>
              )}
            </CardHeaderLeftSide>
            <CardHeaderRightSide>
              {workflowStatusDesign && (
                <IconWithStyles
                  onClick={handleClickChangeLabel}
                  size={14}
                  tooltipProps={{ placement: 'right' }}
                  renderIcon={
                    editableLabel ? <SuccessIconSvg testId={'workflow-builder-node-title-edit-confirm-btn'} /> : <EditIconSvg testId={'workflow-builder-node-title-edit-btn'} />
                  }
                />
              )}
            </CardHeaderRightSide>
          </NodeCardHeader>
          <NodeBottomActions>
            {isTriggeringAllowed && nextWorkflows?.length > 0 && (
              <Dropdown.Main
                title={p('started_workflows')}
              >
                {nextWorkflows?.length > 0 ? <WorkflowList data-testid={'workflow-finish-node-started-workflow-list'}>
                  {nextWorkflows.map(({ name, version, program_id, id }, index) => (
                    <Link
                      key={name}
                      data-testid={`workflow-finish-node-started-workflow-list-item-${index}`}
                      to={`${Paths.EDIT_WORKFLOW}/${program_id}/editor?w=${id}`}
                    >
                      {name} ({version})
                    </Link>
                  ))}
                </WorkflowList> : null }
              </Dropdown.Main>
            )}
            {
              <Tooltip placement={'bottom'} title={isTriggeringAllowed ? '' : p('finish_node_tooltip')}>
                <span>
                  <Button
                    data-testid={'workflow-finish-node-new-workflow-from-exit-point-btn'}
                    style={{
                      width: '100%',
                      fontSize: '11px',
                      height: '28px',
                      marginTop: '10px',
                    }}
                    variant='secondary'
                    outline='true'
                    disabled={!isTriggeringAllowed}
                    onClick={addTriggerModal.open}
                  >
                    {p('new_workflow_from_exit_point')}
                  </Button>
                </span>
              </Tooltip>
            }
          </NodeBottomActions>
        </NodeCardContainer>
        <StyledHandle
          type='target'
          position={branchMode === DirectionModes.HORIZONTAL ? 'left' : 'top'}
          id='a'
          isConnectable
          style={{ pointerEvents: 'none' }}
        />
        {isTriggeringAllowed && (
          <>
            <AddWorkflowTriggerModal
              opened={addTriggerModal.opened}
              id={data.id}
              workflows={workflows}
              onConfirm={handleCreateTriggeringWorkflow}
              onClose={handleAddTriggerModalClose}
            />
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default FinishNode;
