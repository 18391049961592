import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div).attrs({
  initial: { opacity: 0, y: 60, scale: 0.98 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: 60, scale: 0.98 },
  transition: { duration: 0.27, easing: 'ease' }
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 110px;
  gap: 54px;
  position: relative;
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 24px;
  line-height: 28px;
  position: absolute;
  top: 119px;
`;

export const SegmentsContainer = styled.div`
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
`;

export const SegmentsGrid = styled.div`
  display: grid;
  ${({ $length }) => css`
    grid-template-columns: repeat(${$length}, 1fr);
  `}
  justify-items: center;
  gap: 20px;
`;

export const AddSegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const Rows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
