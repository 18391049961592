import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 7px;
  background: #1A232E;
  padding: 8px 10px 8px 20px;
  display: flex;
  gap: 130px;
  align-items: center;
`;

export const Title = styled.div`
  color: var(--text-tertiary-body, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
`;

export const CancelButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  min-height: 28px;
  padding: var(--buttons-small-padding-TB, 5px) var(--buttons-small-padding-RL, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--buttons-big-space, 6px);
  border-radius: var(--buttons-big-radius, 7px);
  border: 1px solid var(--buttons-outline-border, #D3DBE7);

  color: var(--buttons-secondary-txt, #D3DBE7);
  text-align: center;

  font-family: Manrope-SemiBold;
  font-size: 11px;
  line-height: 16px;
  white-space: nowrap;
`;
