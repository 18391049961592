import { useTranslation } from '@hooks';

import { moment, testId } from '@utils';

import { SearchSelect, TimePicker } from '@components';

import { Container, Row, TitleRow } from './styled';

const waitOptions = [
  {
    value: false,
    label: 'Send immediately',
  },
  {
    value: true,
    label: 'Send at',
  },
];

export const SendTime = ({
  value,
  editable,
  showSendAt,
  highlight,
  handleChangeSendAt,
  handleTimeChange,
  errors,
}) => {
  const { t } = useTranslation('workflow_page');

  const titleConfig = {
    0: 'workflow_page.and_then_sendAt',
    1: 'workflow_page.and_then'
  }

  return (
    <Container>
      <TitleRow>{t(titleConfig[value.type])}</TitleRow>
      <Row style={{ gap: '10px', justifyContent: 'flex-end' }}>
        {value.type === 1 && (
          <SearchSelect
            {...testId('wait-node-settings-select-then')()}
            disabled={!editable}
            getPopupContainer={(t) =>
              t.parentElement.parentElement.parentElement
            }
            style={{ maxWidth: '156px' }}
            value={showSendAt}
            onChange={handleChangeSendAt}
            options={waitOptions}
          />
        )}
        <TimePicker
          {...testId('wait-node-settings-select-time-picker')()}
          highlight={highlight}
          disabled={!editable || (value.type === 1 && !showSendAt)}
          error={errors['send_at']}
          style={{ maxWidth: value.type === 1 ? '134px' : '200px' }}
          value={
            typeof value.send_at === 'string'
              ? moment(value.send_at)
              : value.send_at
          }
          onChange={handleTimeChange}
          onSelect={handleTimeChange}
        />
      </Row>
    </Container>
  );
};
