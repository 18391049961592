import { useTranslation } from '@hooks';

import { TemplateTypes } from '@constants/templates';

import { by } from '@utils';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const TeamMessageNodeSettingsInfo = ({ node, options }) => {
  const email = options?.[TemplateTypes.EMAIL]?.find?.(by(node.data?.email_id));
  const { t } = useTranslation();

  if (!email) {
    return (
      <Container>
        <InfoTitle>{t('labels.channel')}:</InfoTitle>
        <InfoText>Email</InfoText>
        <InfoTitle style={{ marginTop: 16 }}>{t('labels.template')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  return (
    <Container>
      <InfoTitle>{t('labels.channel')}:</InfoTitle>
      <InfoText>Email</InfoText>
      <InfoTitle style={{ marginTop: 16 }}>{t('labels.template')}:</InfoTitle>
      <InfoText>{email.name}</InfoText>
      {node.data.analytics && (
        <AnalyticsSection analytics={node.data.analytics} />
      )}
    </Container>
  );
}

export default TeamMessageNodeSettingsInfo;

