import { useSelector } from 'react-redux';

import { workflowOptionsSelector } from '@store/selectors';

import { Button } from '@velitech/ui';
import uniqBy from 'lodash.uniqby';
import { v4 as uuidv4 } from 'uuid';

import { useModalState, useTranslation } from '@hooks';

import { WorkflowNodes } from '@constants';

import { by, extract, update } from '@utils';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';
import { getExitPoints } from '@utils/workflows/refactored/getters';

import workflow from '@res/images/workflow_icon.svg';

import { WorkflowPanel } from './components';
import { Container, Title, Actions, AddWorkflowContainer } from './styled';

import AddEntityButton from '../../../AddEntityButton/AddEntityButton';
import { AddWorkflowModal } from '../../../AddWorkflowModal';
import { BackToTriggerTypeButton } from '../BackToTriggerTypeButton';

const createNode = () => {
  const id = uuidv4();
  const originId = uuidv4();

  return {
    type: WorkflowNodes.ENTRY_ANOTHER_WF,
    id,
    dummy: true,
    data: {
      id,
      name: WorkflowNodes.ENTRY_ANOTHER_WF,
      label: 'internal',
      originId,
    },
  };
}

const EntryFromWorkflowTriggerSettings = ({ value, onChange, onDiscard, onSubmit, loading, worker }) => {
  const { p } = useTranslation('workflow_page');
  const multinode = createMultinodeUpdater(
    { children: value },
    (updater) => onChange(update({ children: value }, updater).children),
    {}, () => {}
  );
  const options = useSelector(workflowOptionsSelector);
  const hasWorkflow = !!multinode.values[0]?.trigger_workflow_id;
  const addWorkflowModal = useModalState();

  const handleSelectWorkflow = ({ id }) => {
    // multinode.clearError(changeAt)('trigger_workflow_id');
    const wf = options.workflows.find(by(id));

    const updateData = s => ({
      ...s,
      trigger_workflow_id: id,
      trigger_workflow_node_ids: uniqBy(getExitPoints(wf?.tree_map), extract('id')).map(extract('id')),
      hidden: false,
    })

    if (hasWorkflow) {
      return multinode.addNode(createNode(), updateData);
    }

    multinode.changeNode(0)(updateData);
  };

  const handleExitPointsChange = (index) => (value) =>{
    const updateData = s => ({
      ...s,
      trigger_workflow_node_ids: value,
      hidden: false,
    });

    multinode.changeNode(index)(updateData);
  };

  const handleDeleteWorkflow = (deleteAt) => () => {
    if (deleteAt === 0 && multinode.values?.length === 1) {
      multinode.changeNode(0)(s => ({
        ...s,
        trigger_workflow_id: '',
      }));
    } else {
      multinode.deleteNode(deleteAt);
    }
  };

  return (
    <Container>
      <BackToTriggerTypeButton disabled={worker.status !== 0} testId={'entry-from-workflow'} onClick={onDiscard} />
      <Title data-testid="step-title">
        {p('entry_from_workflow_trigger_settings')}
      </Title>
      <AddWorkflowModal
        opened={addWorkflowModal.opened}
        onClose={addWorkflowModal.close}
        onSelect={handleSelectWorkflow}
        omitValues={multinode.values.map(extract('trigger_workflow_id'))}
      />

      {hasWorkflow && multinode.values.map((settings, index) => (
        <WorkflowPanel
          key={settings.trigger_workflow_id}
          id={settings.trigger_workflow_id}
          exitPoints={settings.trigger_workflow_node_ids}
          onExitPointsChange={handleExitPointsChange(index)}
          onDelete={handleDeleteWorkflow(index)}
        />
      ))}
      {worker.status === 0 &&
        <AddWorkflowContainer style={{ marginTop: '110px' }}>
          {!hasWorkflow && (
            <AddEntityButton
              data-testid={'entry-from-workflow-trigger-settings-choose-workflow-btn'}
              icon={workflow}
              onClick={addWorkflowModal.open}
              title={p('no_workflow_added')}
              actionTitle={p('choose_workflow')}
              containerStyle={{ width: '530px' }}
            />
          )}
          {hasWorkflow && (
            <AddEntityButton
              smaller
              icon={workflow}
              onClick={addWorkflowModal.open}
              title={p('you_can_add_other_workflows')}
              actionTitle={p('choose_workflow')}
              containerStyle={{ width: '530px' }}
            />
          )}
        </AddWorkflowContainer>
      }
      <Actions>
        <Button
          data-testid={'entry-from-workflow-trigger-settings-next-step-btn'}
          width={130}
          onClick={onSubmit}
          disabled={!hasWorkflow}
          loading={loading}
        >
          {p('next')}
        </Button>
      </Actions>
    </Container>
  );
};

export default EntryFromWorkflowTriggerSettings;
