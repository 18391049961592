import { useTranslation } from '@hooks';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const NewContactNodeSettingsInfo = ({ node }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <InfoRow>
        <InfoTitle style={{ fontSize: '14px' }}>
          {t('workflow_page.customer_addition_info')}
        </InfoTitle>
      </InfoRow>
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default NewContactNodeSettingsInfo;

