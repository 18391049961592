import { useTranslation } from '@hooks';
import useSystemEntityOptions from '@hooks/useSystemEntityOptions';

import {
  DATETIME_DATE_PICKER_OPERATORS, DATETIME_SELECT_UNIT_OPERATORS,
  MULTIPLE_VALUE_OPERATORS,
  OPERATOR_DOES_NOT_EQUAL,
  OPERATOR_NOT_CONTAINS,
  OPERATOR_NOT_ENDS_WITH,
  OPERATOR_NOT_MATCHES_REGEXP, OPERATOR_NOT_ONE_OF, OPERATOR_NOT_STARTS_WITH,
  SINGLE_VALUE_OPERATORS, WITHOUT_VALUE_OPERATORS
} from '@constants';

import { by, identity } from '@utils';

import { resolveTitle } from '@components/lib/SegmentEditor/components/DateFilterPicker/DateFilterPicker';

import { Container } from './styled';

const resolvePath = (event, fields, nestedField) => {
  if (event) {
    return ['_events', nestedField, ...fields].filter(identity);
  }

  return [...fields, nestedField].filter(identity);
};

const resolveFilter = (event) => {
  if (!event) {
    return void 0;
  }

  return { '_events.event': event };
};

const HiddenFilter = ({ title, value, autocomplete }) => {
  const { p, t } = useTranslation('segments_page');
  const path = resolvePath(autocomplete.event, autocomplete.fields, autocomplete.nestedField);
  const filter = resolveFilter(autocomplete.event, autocomplete.fields, autocomplete.nestedField);

  const systemEntityOptions = useSystemEntityOptions(path, filter);

  const v = value.value.value;
  const label = t((systemEntityOptions || []).find(by('value', Array.isArray(v) ? v[0] : v))?.label || v);

  const resolveOperatorValue = ({ operator, value }) => {
    if (DATETIME_DATE_PICKER_OPERATORS.includes(operator)) {
      return resolveTitle(value.value, false, p);
    }

    if (DATETIME_SELECT_UNIT_OPERATORS.includes(operator)) {
      if (!value) {
        return '';
      }

      return t(`labels.${value.value}`);
    }

    if (SINGLE_VALUE_OPERATORS.includes(operator)) {
      if ([OPERATOR_NOT_CONTAINS, OPERATOR_NOT_ENDS_WITH, OPERATOR_NOT_MATCHES_REGEXP, OPERATOR_DOES_NOT_EQUAL, OPERATOR_NOT_STARTS_WITH].includes(operator)) {
        return p('is_not', { value: label || p('no_value') });
      }

      return p('is', { value: label || p('no_value') });
    }

    if (MULTIPLE_VALUE_OPERATORS.includes(operator)) {
      if (value.value?.length <= 1) {
        return p(operator === OPERATOR_NOT_ONE_OF ? 'is_not' : 'is', { value: label || p('no_value') });
      }

      return p((operator === OPERATOR_NOT_ONE_OF ? 'is_not' : 'is') + '_one_of', { count: value.value?.length || 0 });
    }

    if (WITHOUT_VALUE_OPERATORS.includes(operator)) {
      return p('is', { value: p(operator) });
    }
  };

  return (
    <Container>
      {title} {resolveOperatorValue(value)}
    </Container>
  );
};

export default HiddenFilter;
