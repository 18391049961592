import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { Button } from '@velitech/ui';
import { v4 as uuidv4 } from 'uuid';

import { useModalState, useTranslation } from '@hooks';

import { OPERATOR_EQUALS, OPERATOR_MATCHES_RANGE, WorkflowNodes } from '@constants';
import { EventPresets } from '@constants/eventPresets';

import { by, extract, update } from '@utils';
import { clickhouseFields2Events } from '@utils/fields';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

import { Container, Title, Actions, EventsContainer, AddButtonContainer } from './styled';

import { AddEntityButton } from '../../../AddEntityButton';
import { AddEventModal } from '../../../AddEventModal';
import { EventFilter } from '../../../EventFilter';
import { BackToTriggerTypeButton } from '../BackToTriggerTypeButton';

const createNode = () => {
  const id = uuidv4();
  const originId = uuidv4();

  return {
    type: WorkflowNodes.EVENT,
    id,
    dummy: true,
    data: {
      id,
      name: WorkflowNodes.EVENT,
      label: 'internal',
      originId,
    },
  };
}

const EventTriggerSettings = ({ value, onChange, errors, onClearError, onDiscard, onSubmit, loading, worker }) => {
  const { p } = useTranslation('workflow_page');
  const addEventModal = useModalState();
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const events = clickhouseFields2Events(clickhouseFields.data);

  const multinode = createMultinodeUpdater(
    { children: value },
    (updater) => onChange(update({ children: value }, updater).children),
    errors, onClearError
  );
  const hasEvents = !!value[0].data.event_id;

  const handleEventChange = (changeAt) => ({ id: event_id }) => {
    multinode.clearError(changeAt)('event_id');
    const name = events.data.find(by(event_id))?.name;
    const preset = EventPresets.find(({ events }) => !!~events.indexOf(name));

    const updateData = s => ({
      ...s,
      event_id,
      event_name: name,
      simplified: !!preset,
      hidden: false,
      filter_builder: {
        logicalOperator: 'and',
        filters: (preset?.presetFields || []).map(field => ({
          field,
          operator: field === 'created_at' || field === 'b_bet_date' ? OPERATOR_MATCHES_RANGE : OPERATOR_EQUALS,
          type: field === 'created_at' || field === 'b_bet_date' ? 'datetime' : 'text',
          value: { type: 'scalar', value: '' },
        }))
      },
    })

    if (changeAt > 0) {
      return multinode.addNode(createNode(), updateData);
    }

    multinode.changeNode(changeAt)(updateData);
  };

  const handleDeleteEvent = (deleteAt) => {
    multinode.clearError(deleteAt)('event_id');
    multinode.clearError(deleteAt)('filter_builder');

    if (deleteAt === 0 && multinode.values?.length === 1) {
      multinode.changeNode(0)(s => ({
        ...s,
        event_id: '',
        event_name: '',
        filter_builder: {
          logicalOperator: 'and',
        }
      }));
    } else {
      multinode.deleteNode(deleteAt);
    }
  };

  const getError = index => key => {
    if (key === 'filters') {
      return multinode.getError(index)('filter_builder');
    }

    return multinode.getError(index)(key);
  }

  const addIcon =
     <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M17.5006 11.4681V21.0296C17.3745 21.0164 17.2466 21.005 17.126 20.9994L17.1144 20.9988H17.1028H14.7787H13.9697L14.3315 21.7224L17.2681 27.5968L17.268 27.5969L17.2732 27.6068C17.5113 28.0574 17.4256 28.6579 17.0404 29.0437C17.0402 29.0439 17.04 29.0441 17.0398 29.0443L14.9394 31.127L14.9393 31.1269L14.9304 31.1361C14.7091 31.3672 14.3963 31.5 14.0612 31.5C13.981 31.5 13.898 31.4915 13.8134 31.4738L13.8097 31.4731C13.387 31.388 13.0449 31.0986 12.8906 30.6975C12.8904 30.697 12.8902 30.6964 12.89 30.6959L9.37359 21.3232L9.25189 20.9988H8.90545H8.37518C8.16825 20.9988 8.00014 20.8307 8.00014 20.6239V19.7489V19.2489H7.50014H4.87504C4.66811 19.2489 4.5 19.0807 4.5 18.874V13.6243C4.5 13.4175 4.66811 13.2493 4.87504 13.2493H7.50014H8.00014V12.7493V11.8744C8.00014 11.6676 8.16825 11.4994 8.37518 11.4994H17.094H17.1055L17.117 11.4989C17.2406 11.4932 17.3716 11.4815 17.5006 11.4681Z" fill="#87E2BA" stroke="#0C1015"/>
       <path d="M31.426 4.677L31.4259 4.67708L31.4327 4.688C31.486 4.77358 31.5005 4.83062 31.5005 4.87495V27.6237C31.5005 27.6675 31.4864 27.7237 31.4347 27.8075C31.4176 27.8338 31.4108 27.8441 31.4032 27.8544C31.3554 27.9153 31.3065 27.9507 31.256 27.9706C31.2425 27.9758 31.2299 27.9813 31.2183 27.9868C31.1844 27.9952 31.1539 27.9986 31.1255 27.9986C31.0882 27.9986 31.0488 27.9921 31.0052 27.9774C30.9918 27.9676 30.9738 27.9551 30.9522 27.9421L30.9522 27.942L30.941 27.9356C30.8926 27.9083 30.866 27.8862 30.8517 27.8707C30.8345 27.8513 30.8176 27.8351 30.8024 27.8218C28.0896 24.547 24.3524 22.3259 20.25 21.4346V11.0658C24.3477 10.1754 28.081 7.95672 30.793 4.68828C30.8043 4.67883 30.8161 4.6682 30.828 4.6563L30.8379 4.64641L30.8472 4.63598C30.8868 4.59164 30.9354 4.55787 30.996 4.5354L31.0105 4.53312C31.0223 4.53123 31.0393 4.52837 31.0587 4.52428L31.0594 4.52412C31.0888 4.5179 31.1143 4.51101 31.1312 4.50635L31.1409 4.50366C31.1493 4.50567 31.1597 4.50845 31.174 4.51253L31.2001 4.52012L31.2268 4.52791C31.2274 4.5281 31.2281 4.52829 31.2287 4.52848C31.2487 4.53439 31.2679 4.53882 31.286 4.54209C31.3259 4.56367 31.3667 4.59783 31.4078 4.65016L31.4078 4.6502L31.4129 4.65655C31.4145 4.65844 31.4151 4.65939 31.416 4.66063C31.4173 4.66252 31.42 4.66671 31.426 4.677ZM31.1696 4.49589C31.1696 4.4959 31.1691 4.49602 31.1682 4.4962L31.1696 4.49589Z" fill="#87E2BA" stroke="#0C1015"/>
       <path d="M20.0315 11.1758L16.5938 11.4995V12.3133V21.0071L20.0315 21.3321V11.1758Z" fill="white" stroke="#0C1015"/>
     </svg>
  ;

  return (
    <Container>
      <BackToTriggerTypeButton disabled={worker.status !== 0} testId={'event-trigger'} onClick={onDiscard} />
      <AddEventModal
        triggerSettings
        onSelect={handleEventChange(multinode.values.filter(extract('event_id')).length)}
        opened={addEventModal.opened}
        onClose={addEventModal.close}
      />
      <Title data-testid="step-title">{p('event_trigger_title')}</Title>
      {hasEvents && (
        <EventsContainer>
          {multinode.values.map((settings, index) => (
            <EventFilter
              disabled={worker.status !== 0}
              testId={`event-filter-container-${index}`}
              key={index}
              value={settings}
              errors={getError(index)}
              onClearError={multinode.clearError(index)}
              onDelete={() => handleDeleteEvent(index)}
              onChange={multinode.changeNode(index)}
            />
          ))}
        </EventsContainer>
      )}
      {worker.status === 0 && (
        <AddButtonContainer style={{ marginBottom: '30px' }} $empty={!hasEvents}>
          <AddEntityButton
            icon={addIcon}
            data-testid={'segment-trigger-settings-choose-event-btn'}
            title={p(!hasEvents ? 'no_event_added' : 'add_another_event')}
            containerStyle={{ width: 530 }}
            actionTitle={p('choose_event')}
            onClick={addEventModal.open}
          />
        </AddButtonContainer>
      )
      }
      <Actions>
        <Button
          data-testid={'event-trigger-settings-next-step-btn'}
          width={130}
          disabled={!hasEvents}
          onClick={onSubmit}
          loading={loading}
        >
          {p('next_step')}
        </Button>
      </Actions>
    </Container>
  );
};

export default EventTriggerSettings;
