import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

import { Device } from '@res';

export const Container = styled.div`
    padding: 20px;
    background-color: #fff;
    height: calc(100vh - 156px);

    ${styledProp('$withoutPadding', css`
      padding: 0;
   `)}

    ${styledProp('$integrations', css`
      padding: 0 20px 20px 20px;
      height: calc(100vh - 136px) !important;
   `)}
    
    ${styledProp('$flex', css`
        display: flex;
        flex: 1;
        width: 100%;
        position: relative;
        
        & > div {
            width: 100%;
        }
    `)}
    
    ${styledProp('$table', css`
        ${({ $tabs }) => $tabs ? css`
            height: calc(100vh - 152px);
        ` : css`
            height: calc(100vh - 116px);
        `}

        & th:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
    `, css`
        & div:not(.ant-table-content):not(.show-scrollbar)::-webkit-scrollbar {
            display: none;
        }

        & div:not(.ant-table-content):not(.show-scrollbar) {
            scrollbar-width: none;
        }

        & .rbc-calendar div:not(.ant-table-content):not(.show-scrollbar) {
            overflow: scroll;
        }
    `)}
   
   @media screen and ${Device.desktopS} {
       padding: 20px 260px;
   }
`

export const WrapperContentStyled = styled.div`
    padding-bottom: 20px;
`;

