import { CardBody } from "@velitech/ui"

import { CardContainer, CardDescription, CardName, Cardstyled } from "./styled"

export const AnalyticCard = ({ name, description }) => {
  return (
    <Cardstyled> 
      <CardBody>
        <CardContainer>
          <CardName>{name}</CardName>
          <CardDescription>
            {description}
          </CardDescription>
        </CardContainer>
      </CardBody>
    </Cardstyled>
  )
}