import { useContext, useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import { useSelector } from "react-redux";
import { ResizableBox } from 'react-resizable';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useDebouncedCallback } from 'use-debounce';

import { useModalState, useTranslation } from '@hooks';

import { ViewModes } from '@constants';

import { findAttributes, replaceKeysWithValues } from "@utils";
import { clickhouseFields2Customer } from '@utils/fields';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-solarized_dark';

import {
  Divider,
  EmailSampleData,
  TemplatesGeneralAttributesDrawer,
  ToggleButton,
  ViewModeSwitch
} from '@components';
import { ApiRequestPreview } from '@components/lib/EmailTemplateEditor/components/ApiRequestPreview';
import SizeChangeHandler from '@components/lib/EmailTemplateEditor/components/CodeEditor/SizeChangeHandler';
import { FallbackValuesModal } from "@components/modals/FallbackValuesModal";
import { OpeningContainer } from '@components/ui/OpeningContainer';

import config from './aceEditorConfig';
import {
  Container,
  TabsContainer,
  Title,
  ResizableContainer,
  EditorContainer,
  PreviewContainer,
  Preview,
  PreviewInner,
  ExampleDataContainer,
  ApiRequestBodyEditorContainer,
  ApiRequestEditorContainer, TextPreview,
} from './styled.js';

import { SelectLanguage } from '../../../MultiLanguage/components/SelectLanguage/SelectLanguage.js';
import { DefaultLangModal, LanguageTab, LanguageTabs, MultiLanguageContext } from '../../../MultiLanguage/index.js';

const WAIT = 1000;

const CodeEditor = ({ value, onChange, templateType, mode = 'html', disabled, onPreviewChange, errors }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector)?.data || [];
  const fields = clickhouseFields2Customer(clickhouseFields).map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const fallbackModal = useModalState();
  const exampleFields = useModalState();
  const discountFields = useModalState();
  const container = useRef(null);
  const [viewMode, setViewMode] = useState(ViewModes.DESKTOP);
  const [resizing, setResizing] = useState(false);
  const [resizeWidth, setResizeWidth] = useState(0);
  const { p } = useTranslation('templates');
  const { langModal, multiLangTabsController } = useContext(MultiLanguageContext) || {};
  const codeKey = templateType === 'email' ? 'data': 'body';

  useEffect(() => {
    const wrapper = document.getElementById('code-editor-container');
    setResizeWidth(wrapper.offsetWidth / 2);
  }, []);

  const handleHTMLChange = (html) => {
    const key = templateType === 'email' ? 'data': 'body';
    onChange({ ...value, [key]: html });
  }

  const handleChangeApiRequest = (key, v) => {
    handleGetApiRequestPreviewInfo();
    onChange({ [key]: v });
  }

  const handleGetApiRequestPreviewInfo = useDebouncedCallback((key, v) => {
    onPreviewChange({ ...value, [key]: v });
  }, WAIT);

  const handleValidate = () => {};

  const handleToggleFieldsExample = () => {
    discountFields.close();
    exampleFields.toggle();
  };

  const handleToggleDiscountDrawer = () => {
    exampleFields.close();
    discountFields.toggle();
  };

  const handleSubmitFallbacks = (values) => {
    const replaced = replaceKeysWithValues(values, [codeKey], value);
    onChange({ 
      ...value, 
      ...replaced 
    });
  }

  const handleChangeSelectTab = (langName) => {
    const tabItem = multiLangTabsController.languages.find((lang) => lang.value === langName);
    multiLangTabsController.onSelectLangTab(tabItem.id);
  }

  const langTabs = templateType === 'email' && multiLangTabsController.languages.map((lang) => {
    const isActive = multiLangTabsController.selectedLangTab === lang.id
    const hasErrors = errors && Object.keys(errors).some((errorKey) => errors[errorKey] && errorKey.split('-')[1] === lang.id);

    return {
      ...lang,
      active: isActive,
      children: (
        <LanguageTab
          onlyOne={multiLangTabsController?.languages?.length === 1}
          isActive={isActive} 
          hasError={hasErrors}
          isDefault={lang.is_default} 
          value={lang.value} 
          icon={lang.icon} 
          onDeleteLang={() => multiLangTabsController.onRemoveLang(lang.id)}
          onSetDefaultLang={() => multiLangTabsController.onSelectDefaultLang(lang.id)}
        />),
    };
  }).filter(({ language_code }) => language_code !== null);

  return (
    <Container>
      <TabsContainer>
        { templateType === 'email' && <Title>{p('code')}</Title> }
        { templateType === 'api-request' && <Title bottom>{p('content')}</Title> }
        {/*{ templateType === 'email' &&  <SliderTabs tab={tab} tabs={tabs} onTabChange={setTab} /> }*/}
      </TabsContainer>
      {templateType === 'email' && <TabsContainer style={{ minHeight: '40px', justifyContent: 'flex-start', overflowX: langTabs?.length > 8 ? 'auto' : 'visible' }}>
        {langTabs?.length > 0 && <LanguageTabs errors={errors} tabs={langTabs} onChange={handleChangeSelectTab} />}
      </TabsContainer>}
      <ResizableContainer id="code-editor-container">
        { templateType === 'email' && (
          <EditorContainer>
            <AceEditor
              onChange={handleHTMLChange}
              value={value.data}
              onValidate={handleValidate}
              mode={mode}
              {...config}
            />
          </EditorContainer>
        )}
        { templateType === 'api-request' && (
          <>
            <ApiRequestEditorContainer>
              <OpeningContainer
                title={p('headers')}
                disabled={disabled}
                tooltip={p('supported_content_type')}
                showTitleIfExpanded={true}
                defaultOpened={false}
                content={(
                  <AceEditor
                    onChange={(v) => handleChangeApiRequest('headers', v)}
                    value={value.headers}
                    readOnly={disabled}
                    onValidate={handleValidate}
                    mode="json"
                    {...config}
                  />
                )}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  marginBottom: '2px',
                  border: '1px solid #DCDFE6',
                  // boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
                }}
              />
              <ApiRequestBodyEditorContainer>
                <div style={{ width: '100%', height: '100%', borderRadius: '10px' }}>
                  <Title relative>Body</Title>
                  <AceEditor
                    onChange={(v) => handleChangeApiRequest('body', v)}
                    value={value.body}
                    readOnly={disabled}
                    onValidate={handleValidate}
                    mode={mode}
                    {...config}
                  />
                </div>
              </ApiRequestBodyEditorContainer>
            </ApiRequestEditorContainer>
          </>
        )}
       
        <div style={ templateType === 'api-request' ? { marginTop: '50px', height: '100%', position: 'relative' } : { height: '100%' } }>
          {/*{ templateType === 'api-request' && (*/}
          {/*  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>*/}
          {/*  </div>*/}
          {/*) }*/}
         
          <PreviewContainer>
            <div style={{ position: 'absolute', top: templateType === 'email' ? '-94px' : '-39px', right: 0, display: 'flex', alignItems: 'center' }}>
              {templateType === 'email' && <SelectLanguage 
                selectedLanguages={multiLangTabsController.languages} 
                onAddLang={multiLangTabsController.onAddLang} 
                handleOpenLang={multiLangTabsController.onOpenSelectLang}
              />}
              {value?.[codeKey]?.indexOf('{{customer.') !== -1 && (
                <ToggleButton
                  testId="code-fallback-toggle"
                  opened={fallbackModal.opened}
                  style={{ marginRight: 4 }}
                  fallback
                  onToggle={fallbackModal.open}
                >
                  {p('fallback_values')}
                </ToggleButton>
              )}
              <ToggleButton
                testId="email-attributes-toggle"
                opened={exampleFields.opened}
                style={{ marginRight: 12 }}
                onToggle={handleToggleFieldsExample}
              >
                {p('customer_attributes')}
              </ToggleButton>
              <ToggleButton
                error={errors?.['unsubscribe_url'] || errors?.['subscribe_url']}
                testId="email-discount-toggle"
                style={{ marginRight: errors?.['unsubscribe_url'] || errors?.['subscribe_url'] ? 24 : 10 }}
                opened={discountFields.opened}
                onToggle={handleToggleDiscountDrawer}
              >
                {p('general_attributes')}
              </ToggleButton>
              { templateType === 'email' && (
                <>
                  <Divider vertical style={{ marginRight: 10, height: 16, background: '#909399' }} />
                  <ViewModeSwitch containerStyle={{ position: 'relative', right: 0, top: 0 }} value={viewMode} onChange={setViewMode} />
                </>
              ) }
            </div>
           
            {resizeWidth > 0 && (
              <ResizableBox
                width={resizeWidth}
                style={{ height: '100%', flex: 1 }}
                axis="x"
                minConstraints={[320, 100]}
                maxConstraints={[window.innerWidth - 370, 100]}
                handle={<SizeChangeHandler />}
                resizeHandles={['w']}
                onResizeStart={() => setResizing(true)}
                onResizeStop={() => setResizing(false)}
              >
                <PreviewInner id="preview_wrapper" isMobileMode={viewMode === ViewModes.MOBILE}>
                  { mode === 'html' && <Preview srcDoc={value.previewBody || value.body} resizing={resizing} isMobileMode={viewMode === ViewModes.MOBILE} /> }
                  { mode === 'json' && <ApiRequestPreview template={value} /> }
                  { (mode !== 'json' && mode !== 'html') && <TextPreview>{value.previewBody || value.body}</TextPreview> }
                </PreviewInner>
              </ResizableBox>
            )}
            <ExampleDataContainer ref={container} id="drawer-container" drawerVisible={exampleFields.opened || discountFields.opened}>
              <TemplatesGeneralAttributesDrawer
                onClose={discountFields.close}
                width="100%"
                hiddenTabs={templateType === 'api-request' ? ['subscription'] : []}
                opened={discountFields.opened}
                onVisibilityChanged={discountFields.setOpened}
              />
              <EmailSampleData
                onClose={exampleFields.close}
                opened={exampleFields.opened}
                width="100%"
                smaller
                getContainer={() => container.current}
                onVisibilityChanged={exampleFields.setOpened}
              />

              <FallbackValuesModal
                opened={fallbackModal.opened}
                onClose={fallbackModal.close}
                testId="fallback-modal"
                onSubmit={handleSubmitFallbacks}
                fallbacks={findAttributes([value[codeKey]], fields)}
              />
              { templateType === 'email' && <DefaultLangModal
                opened={langModal.opened}
                title={langModal.title}
                onClose={langModal.onDecline}
                onSave={langModal.onAccept}
                options={langModal.languageOptions}

              />}
            </ExampleDataContainer>
          </PreviewContainer>
        </div>
      </ResizableContainer>
    </Container>
  );
}

export default CodeEditor;

