import styled from 'styled-components';

export const SubContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdditionalSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.p`
  display: flex;
  align-items: center;
  color: var(--text-primary-body, #1a232e);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 14px;
`;

export const SubContentTitle = styled.h4`
   color: var(--text-secondary-body, #7C94B1);
    /* Heading 3 */
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
`

export const TooltipContainer = styled.p`
  display: flex;
  position: relative;
  top: -5px;
  right: -5px;
  margin-bottom: 0px;
`;
