import { useMemo, useState } from 'react';

import useUndo from 'use-undo';

import { update } from '@utils';

const useWorkerState = () => {
  const [
    { present: pointer },
    {
      set: setPointer,
      undo: pointerUndo,
      redo: pointerRedo,
      canUndo,
      canRedo,
    },
  ] = useUndo(0);
  const [rootsState, setRootsState] = useState([null]);
  const [stylesState, setStylesState] = useState([{}]);

  const root = useMemo(() => rootsState[pointer], [pointer, rootsState[pointer]]);
  const styles = useMemo(() => stylesState[pointer], [pointer, stylesState[pointer]]);

  const undo = () => {
    pointerUndo();

    return [rootsState[pointer - 1], stylesState[pointer - 1]];
  };

  const redo = () => {
    pointerRedo();

    return [rootsState[pointer + 1], stylesState[pointer + 1]];
  };

  const updateRoot = (updater) => {
    setRootsState(rs => rs.map((r, i) => i === pointer ? update(r, updater) : r));
  };

  const updateStyles = (updater) => {
    setStylesState(ss => ss.map((s, i) => i === pointer ? update(s, updater) : s));
  };

  const updateRootAndStyles = (rootUpdater, stylesUpdater) => {
    updateRoot(rootUpdater);
    updateStyles(stylesUpdater);
  };

  const setRootAndStyles = (rootUpdater, stylesUpdater, skip) => {
    if (skip) {
      return updateRootAndStyles(rootUpdater, stylesUpdater);
    }

    const updatedRoot = update(root, rootUpdater);
    const updatedStyles = update(styles, stylesUpdater);

    setRootsState((prevRootsState) => {
      let newRootsState = [...prevRootsState, updatedRoot];
      let newPointer = pointer + 1;

      if (newRootsState.length > 10) {
        newRootsState = newRootsState.slice(1);
        newPointer -= 1;
      }

      setPointer(newPointer);
      return newRootsState;
    });

    setStylesState((prevStylesState) => {
      let newStylesState = [...prevStylesState, updatedStyles];

      if (newStylesState.length > 10) {
        newStylesState = newStylesState.slice(1);
      }

      return newStylesState;
    });
  };

  return {
    undo,
    redo,
    canUndo,
    canRedo,
    root,
    styles,
    updateRoot,
    updateStyles,
    setRootAndStyles,
  }
};

export default useWorkerState;
