import styled from 'styled-components';

export const Container = styled.div`
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #303133;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #303133;
  padding: 6px 16px;
  border-radius: 7px;
  transition: 230ms ease all;
  cursor: pointer;
  
  &:hover {
    background-color: #DCDFE6;
  }
`;
