import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isNode ,
  useUpdateNodeInternals,
  useOnViewportChange
} from 'reactflow';

import { workflowOptionWithEventsSelector } from '@store/selectors';

import { useTransition } from '@react-spring/web';
import { Button, Popover } from '@velitech/ui';
import { Dropdown , Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { DirectionModes, WorkflowIcons } from '@constants';

import { by, testId, getAnalyticsList } from '@utils';

import { Icon, IconSvg } from '@components';
import { NodeAnalytics } from '@components/lib/WorkflowEditor/components';

import { CommentIconSvg, InformationIconSvg } from './assets';
import { NodeOverlayActions, TestSendingDisclaimer } from './components';
import { WebSDKDisclaimer } from './components/WebSDKDisclaimer';
import {
  Container,
  NodeThumbnail,
  Title,
  NodeAnalyticsContainer,
  StyledHandle,
  NodeCardContainer,
  NodeCardHeader,
  NodeCardInfo,
  CardHeaderLeftSide,
  CardHeaderRightSide,
  NodeBottomActions,
  IconButtonWithStyles,
} from './styled';

import { WorkflowNodes } from '../../../../../constants';
import { NodeActionsContext } from '../../../../../contexts';
import WorkflowEditorContext from '../../../../../contexts/WorkflowEditorContext/WorkflowEditorContext';
import { NodeAnalyticsPopover } from '../NodeAnalyticsPopover';
import { NodeSettingsInfo } from '../NodeSettingsDropdown/components';

const transitionConfig = {
  from: { opacity: 0, transform: "scale(0.9)" },
  enter: { opacity: 1, transform: "scale(1)" },
  leave: { opacity: 0, transform: "scale(0.9)" },
  config: { duration: 200 },
};

const Node = ({ id, data, selected }) => {
  const options = useSelector(workflowOptionWithEventsSelector);
  const editor = useContext(WorkflowEditorContext);
  const nodeActions = useContext(NodeActionsContext);
  const [hovered, setHovered] = useState(false);
  const { p, t } = useTranslation('workflow_page');
  const [hideMenu, setHideMenu] = useState(false);
  const [checkScroll, setCheckScroll] = useState(false);

  const {
    onDelete,
    onCopy,
    onChangeTrigger,
    workerStatus,
    canCopy,
    canDelete,
    shouldUpdateNode,
    setShouldUpdateNode
  } = nodeActions;

  const handleViewPortChange = () => {
    if(checkScroll && !hideMenu) {
      setHideMenu(true);
    }
  }

  useOnViewportChange({
    onChange: handleViewPortChange,
  });

  const updateNodeInternals = useUpdateNodeInternals();
  const opened = editor.openedNodeSettings === id;

  useEffect(() => {

    if(shouldUpdateNode) {
      setTimeout(() => { //Should update node after layout method
        updateNodeInternals(id);
        setShouldUpdateNode(false);
      }, 600);
    }
  }, [shouldUpdateNode, data]);

  const handleDoubleClick = () => {
    if (data.actionType === 'entry_point') {
      return;
    }

    return editor.setOpenedNodeSettings(id);
  };

  const handleClickDelete = (event) => {
    event.stopPropagation();
    onDelete(id);
  };

  const handleClickCopy = (event) => {
    event.stopPropagation();
    onCopy(id);
  }

  const resolveType = (name, label) => {
    const configType = {
      [WorkflowNodes.EMAIL]: 'labels.email',
      [WorkflowNodes.EVENT]: 'labels.event',
      [WorkflowNodes.RESOURCE]: 'labels.segment',
      [WorkflowNodes.DATA_CHANGE]: 'labels.data_change',
      [WorkflowNodes.CONTACT_CREATED]: 'labels.customer_addition',
      [WorkflowNodes.PAGE_VIEW]: 'labels.page_view',
      [WorkflowNodes.ENTRY_ANOTHER_WF]: 'labels.workflow'
    }?.[name];
    return configType || label;
  };

  const branchMode = editor.directionMode;
  const sizeIcon = {
    [WorkflowNodes.BEST_CHANNEL_TO_SEND]: '26',
    [WorkflowNodes.WEB_POPUP]: '26'
  }

  const handleClick = () => {
    const newEvent = new Event("react-flow-mousedown");
    document.dispatchEvent(newEvent);
  };

  const list = getAnalyticsList(data.analytics, data.actionType, p)
  const emptyList = list?.every(({ count }) => +count === 0);
  const hoveredTransition = useTransition(hovered, transitionConfig);
  return (
    <Container
      {...testId(
        `workflow-node-${(editor.renderNodes || [])
          .filter(isNode)
          .findIndex(by('id', data.id))}`
      )()}
      layout
      id={id}
      data-testNodeName={data.label}
      disabled={data.diff?.disabled}
      highlight={data.diff?.type}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      key={id}
      selected={selected}
      temp={data.temp}
    >
      {data.analytics && (
        <NodeAnalyticsContainer>
          <Popover 
            offset={{
              crossAxis: 80
            }}
            hover
            contentClassName="container"
            placement='bottom-end'
            onOpenChange={(data) => setHovered(data)}
            content={
              !emptyList && ( 
                hoveredTransition(({ opacity, transform, filter }, hovered) => hovered && (
                  <NodeAnalyticsPopover
                    style={{ opacity, transform, filter }}
                    data={list}
                  />
                ))
              )
            }>
            <NodeAnalytics
              data={{
                ...(data.analytics || {}),
              }}
              testId={`workflow-node-${(editor.renderNodes || [])
                .filter(isNode)
                .findIndex(by('id', data.id))}`}
              type={data.name}
            />
          </Popover>
        </NodeAnalyticsContainer>
      )}
      <Dropdown
        trigger={['hover']}
        placement='bottomRight'
        onOpenChange={(open) => {
          setCheckScroll(open);
          if(open && hideMenu) {
            setHideMenu(false);
          }
        }}
        overlay={
          data.actionType !== 'entry_point' && (canCopy || canDelete) && !hideMenu ? (
            <NodeOverlayActions
              canCopy={canCopy}
              canDelete={canDelete}
              onDelete={handleClickDelete}
              onCopy={handleClickCopy}
            />
          ) : (
            <></>
          )
        }
      >
        <NodeCardContainer type={data.actionType} $float={opened}>
          {data.actionType !== 'entry_point' && (
            <StyledHandle
              type='target'
              position={branchMode === DirectionModes.HORIZONTAL ? 'left' : 'top'}
              id='a'
              isConnectable
            >
              <Icon
                name='Plus-icon'
                size={4}
                color='#fff'
                style={{ lineHeight: 4, transform: 'translateY(0.3px)' }}
              />
            </StyledHandle>
          )}
          <StyledHandle
            type='source'
            position={branchMode === DirectionModes.HORIZONTAL ? 'right' : 'bottom'}
            id='b'
            isConnectable
            style={{
              pointerEvents:
                data?.name === 'filter' || data.name === 'splitter'
                  ? 'none'
                  : 'auto',
            }}
          >
            <Icon
              name='Plus-icon'
              size={4}
              color='#fff'
              style={{ lineHeight: 4, transform: 'translateY(0.3px)' }}
            />
          </StyledHandle>
          <NodeCardHeader data-testid={`workflow-node-title-${data.name}`}>
            <CardHeaderLeftSide>
              <NodeThumbnail type={data.actionType}>
                <IconSvg
                  size={sizeIcon[data.name] || '18'}
                  name={WorkflowIcons[data.name]}
                />
              </NodeThumbnail>
              <Title>{t(resolveType(data?.name, data?.label))}</Title>
            </CardHeaderLeftSide>
            <CardHeaderRightSide>
              {data.description && (
                <IconButtonWithStyles
                  type={'common'}
                  size={14}
                  tooltip={data.description}
                  tooltipProps={{ placement: 'right' }}
                  renderIcon={<CommentIconSvg />}
                />
              )}
              {data.validated === false && (
                <IconButtonWithStyles
                  size={14}
                  tooltip={p('node_not_configured')}
                  tooltipProps={{ placement: 'top' }}
                  renderIcon={<InformationIconSvg />}
                />
              )}
              <WebSDKDisclaimer data={data} />
              {data.validated !== false && (
                <TestSendingDisclaimer data={data} />
              )}
            </CardHeaderRightSide>
          </NodeCardHeader>
          <NodeCardInfo data-testid={`workflow-${data.name}-node-info`}>
            <NodeSettingsInfo node={{ data, id }} options={options} />
          </NodeCardInfo>
          <NodeBottomActions>
            {data.actionType === 'entry_point' && (
              <Tooltip placement={'bottom'} title={workerStatus === 0 ? '' : p('entry_node_tooltip')}>
                <span>
                  <Button
                    data-testid={'workflow-node-change-trigger-btn'}
                    disabled={workerStatus !== 0}
                    style={{
                      width: '100%',
                      fontSize: '11px',
                      height: '28px',
                    }}
                    variant='secondary'
                    outline={true}
                    onClick={onChangeTrigger}
                  >
                    {p('change_workflow_trigger')}
                  </Button>
                </span>
              </Tooltip>
            )}
          </NodeBottomActions>
        </NodeCardContainer>
      </Dropdown>
    </Container>
  );
};

export default React.memo(Node);
