import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Input } from './components';
import { Actions, Container, InnerContainer } from './styled';

import { WorkflowSetupSteps } from '../../constants';

const DescriptionStep = ({ program, onProgramChange, loading, onStepChange, direction, worker }) => {
  const { p } = useTranslation("workflow_page");

  const handleProgramNameChange = ({ target: { value } }) => {
    onProgramChange(p => ({ ...p, name: value }));
  };

  const handleProgramDescriptionChange = ({ target: { value } }) => {
    onProgramChange(p => ({ ...p, description: value }));
  };

  const handleStepChange = () => {
    onStepChange(WorkflowSetupSteps.TRIGGER);
  };

  return (
    <Container custom={direction}>
      <InnerContainer style={{ marginBottom: '50px' }}>
        <Input
          disabled={worker.status !== 0 && worker.status !== undefined}
          data-testid={'workflow-details-step-name-input'}
          style={{ marginBottom: 62 }}
          placeholder={p('setup_title_placeholder')}
          value={program.name}
          onChange={handleProgramNameChange}
        />
        <Input
          disabled={worker.status !== 0 && worker.status !== undefined}
          data-testid={'workflow-details-step-description-input'}
          placeholder={p('setup_description_placeholder')}
          value={program.description}
          onChange={handleProgramDescriptionChange}
        />
      </InnerContainer>
      <Actions>
        <Button
          data-testid={'workflow-details-next-step-btn'}
          width={130}
          loading={loading}
          disabled={!program.name}
          onClick={handleStepChange}
        >
          {p('next_step')}
        </Button>
      </Actions>
    </Container>
  );
};

export default DescriptionStep;
