import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIncludeCampaignHistoryTemplates } from '@store/actions/creators';
import {
  includeCampaignHistorySelector, includeCampaignHistoryTemplatesSelector
} from '@store/selectors';

import { DateRangeField, MultiSelectField, RowCell } from '@velitech/ui';
import uniq from 'lodash.uniq';

import { useTranslation } from '@hooks';

import { GiveRewardStatusOptions, Paths, UserPermissions } from '@constants';

import { extract, identity, moment } from '@utils';

import { DateCell, Link, TableLayout, WithPermissions } from '@components';
import { IdType } from '@components/lib/styled';
import { FlexibleText } from '@components/ui/Table';

const IncludeCampaignTable = ({ table, total }) => {
  const data = useSelector(includeCampaignHistorySelector);
  const { t, p } = useTranslation('notification_history');
  const dispatch = useDispatch();
  const templates = useSelector(includeCampaignHistoryTemplatesSelector);

  useEffect(() => {
    dispatch(getIncludeCampaignHistoryTemplates({
      ids: uniq((data.data || []).map(extract('campaign_id'))).join()
    }))
  }, [(data.data || []).map(extract('entity_id')).join()]);

  const columns = [
    {
      header: {
        label: t('labels.customer_id'),
      },
      name: 'customer_id',
      key: 'customer_id',
      className: `notification-history-cell-id`,
      render: ({ customer_id: id, ...d }) => (
        <div>
          <FlexibleText text={id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link type="secondary" to={`${Paths.CONTACT}/${id}`}>{id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      ),
    },
    {
      header: {
        label: 'Campaign name',
      },
      name: 'campaign_id',
      key: 'campaign_id',
      render: ({ campaign_id: cid }) => {
        return templates.data?.[cid]?.name || '---';
      }
    },
    // {
    //   header: {
    //     label: t('labels.language_code'),
    //     className: 'w-10',
    //   },
    //   name: 'language_code',
    //   render: (data) => {
    //     const languageItem = languages[data?.language_code] || languages['uk'];
    //     return (
    //       <LanguageItem icon={languageItem.emoji} code={languageItem.languageCode} name={languageItem.languageName} />
    //     );
    //   },
    // },
    {
      header: {
        label: t('labels.date'),
      },
      name: 'created_at',
      className: `notification-history-cell-date`,
      key: 'created_at',
      render: ({ created_at: date }) => <DateCell date={date}/>
    },
  ];

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  };

  return (
    <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_EMAIL} hidden>
      <TableLayout
        withTabs
        fullPage
        name="notification_history_include_campaign"
        loading={data.loading}
        columns={columns}
        data={data.data || []}
        table={table}
        total={total}
        localizer={tableLocalizer}
        renderFilter={(
          <>
            <RowCell className='minWidth-250'>
              <MultiSelectField
                disableErrorSpaceReserve
                className="status-select"
                placeholder={'Select status'}
                selectedOptionsLabel="Selected options"
                availableOptionsLabel="Available options"
                label={t('labels.status')}
                options={GiveRewardStatusOptions || []}
                onChange={table.filters.onChange('status', identity)}
                value={table.filters.filters['status']}
              />
            </RowCell>
            {/* <RowCell className='minWidth-250'>
                <SingleSelectDataField
                  disableErrorSpaceReserve
                  className="status-select"
                  placeholder={'Select language code'}
                  label={t('labels.language_code')}
                  options={languageOptions() || []}
                  mapOptionLabel={(data) => <LanguageItem icon={data.emoji} code={data.value} name={data.label} />}
                  mapOptionValue={(data) => data.value}
                  onChange={table.filters.onChange('language_code', identity)}
                  value={ table.filters.filters['language_code']}
                />
              </RowCell> */}
            <RowCell>
              <DateRangeField
                label={'Date'}
                disableErrorSpaceReserve
                dateFormat={'yyyy/MM/dd'}
                from={{
                  selected: getStartDate(table.filters.filters.start_date),
                  onChange: (date) => handleChangeDate('start_date', date),
                }}
                to={{
                  selected: getEndDate(table.filters.filters.end_date),
                  onChange: (date) => handleChangeDate('end_date', date),
                }}
              />
            </RowCell>
          </>
        )}
      />
    </WithPermissions>
  );
};

export default IncludeCampaignTable;
