import uniqBy from 'lodash.uniqby';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from '@hooks';

import {
  WorkflowNodes,
} from '@constants';

import { by, diffFields, extract, testId } from '@utils';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';
import { getExitPoints } from '@utils/workflows/refactored/getters';

import {
  Input,
  Textarea,
} from '@components';

import { WorkflowSettings, SelectWorkflowPanel, AddWorkflowButton } from './components';
import {
  Container,
  NotSelectedContainer,
  AddWorkflowButtonBackground, AddWorkflowButtonContainer, WorkflowsListContainer
} from './styled';

import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';

const createNode = () => {
  const id = uuidv4();
  const originId = uuidv4();

  return {
    type: WorkflowNodes.ENTRY_ANOTHER_WF,
    id,
    dummy: true,
    data: {
      id,
      name: WorkflowNodes.ENTRY_ANOTHER_WF,
      label: 'internal',
      originId,
    },
  };
}

const AnotherWorkflowNodeSettings = ({
  value: outerValue,
  onChange: outerOnChange,
  label,
  description,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const { t } = useTranslation('workflow_page');
  const multinode = createMultinodeUpdater(outerValue, outerOnChange, errors, onClearError);
  const value = multinode.values[0];
  const workflowOptions = (editable ? (options.workflows || []) : (options.workflows || [])).map(({ name, id, version }) => ({
    value: id,
    label: `${name} (${version})`,
  })).filter(({ value }) => !~multinode.values.findIndex(by('trigger_workflow_id', value)));

  const handleWorkflowChange = (changeAt) => ({ value: wf_id }) => {
    multinode.clearError(changeAt)('trigger_workflow_id');
    const wf = workflowOptions.find(by('value', wf_id))?.label;

    const updateData = s => ({
      ...s,
      trigger_workflow_id: wf_id,
      trigger_workflow_node_ids: uniqBy(getExitPoints(wf?.tree_map), extract('id')),
      hidden: false,
    })

    if (changeAt > 0) {
      multinode.addNode(createNode(), updateData);
    }

    multinode.changeNode(changeAt)(updateData);
  };

  const handleDeleteWorkflow = (deleteAt) => {
    multinode.clearError(deleteAt)('trigger_workflow_id');

    if (deleteAt === 0 && multinode.values?.length === 1) {
      multinode.changeNode(0)(s => ({
        ...s,
        trigger_workflow_id: '',
      }));
    } else {
      multinode.deleteNode(deleteAt);
    }
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredContainerStyle={{
          background: '#F0F2F6',
        }}
        requiredSettings={(
          <>
            {!!multinode.values[0].trigger_workflow_id && (
              <AddWorkflowButtonContainer>
                <AddWorkflowButton
                  options={workflowOptions}
                  editable={editable}
                  onSelect={handleWorkflowChange(multinode.values.length)}
                />
                <AddWorkflowButtonBackground/>
              </AddWorkflowButtonContainer>
            )}
            {!multinode.values[0]?.trigger_workflow_id ? (
              <NotSelectedContainer>
                <SelectWorkflowPanel
                  options={workflowOptions}
                  editable={editable}
                  onSelect={handleWorkflowChange(0)}
                />
              </NotSelectedContainer>
            ) : (
              <WorkflowsListContainer>
                {multinode.values.map((settings, index) => (
                  <WorkflowSettings
                    key={index}
                    value={settings}
                    editable={editable}
                    options={options}
                    errors={multinode.getError(index)}
                    onClearError={multinode.clearError(index)}
                    onDelete={() => handleDeleteWorkflow(index)}
                    onChange={multinode.changeNode(index)}
                  />
                ))}
              </WorkflowsListContainer>
            )}
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('another-workflow-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              {...testId('another-workflow-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default AnotherWorkflowNodeSettings;
