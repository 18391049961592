import { useRef, useState } from 'react';

import uniq from 'lodash.uniq';

import { useTranslation } from '@hooks';

import { Button, Input } from '@components';

import { CloseIcon, Container, Header, RecepientsPreview, Tag, Title, Body, SubTitle, InputContainer } from './styled';

const RecepientsSettings = ({ value, onChange, error, onClearError }) => {
  const { p } = useTranslation('workflow_page');
  const inputRef = useRef();
  const [currentValue, setCurrentValue] = useState('');

  const handleAreaClick = () => {
    inputRef.current?.focus?.();
  };

  const handleCurrentValueChange = ({ target: { value } }) => {
    onClearError?.();
    setCurrentValue(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      onClearError?.();
      onChange(uniq((value || []).concat([currentValue.trim()])));
      setCurrentValue('');
    } else if ((event.key === 'Backspace' || event.keyCode === 8) && currentValue === '') {
      onClearError?.();
      onChange(((value || []).slice(0, (value || []).length - 1)));
    }
  };

  const handleAdd = () => {
    onClearError?.();
    onChange(uniq((value || []).concat([currentValue.trim()])));
    setCurrentValue('');
  };

  const onRemoveTag = (tag) => () => {
    onClearError?.();
    onChange((value || []).filter(t => t !== tag));
  };

  return (
    <Container>
      <Header>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.25 2.75C8.92987 2.75 9.59448 2.95161 10.1598 3.32932C10.7251 3.70704 11.1657 4.2439 11.4258 4.87203C11.686 5.50015 11.7541 6.19131 11.6214 6.85812C11.4888 7.52493 11.1614 8.13744 10.6807 8.61818C10.1999 9.09892 9.58743 9.42631 8.92062 9.55895C8.25381 9.69159 7.56265 9.62351 6.93453 9.36334C6.3064 9.10316 5.76954 8.66257 5.39182 8.09727C5.01411 7.53198 4.8125 6.86737 4.8125 6.1875C4.8125 5.27582 5.17466 4.40148 5.81932 3.75682C6.46398 3.11216 7.33832 2.75 8.25 2.75ZM8.25 1.375C7.29818 1.375 6.36773 1.65725 5.57632 2.18605C4.78491 2.71486 4.16808 3.46647 3.80383 4.34584C3.43958 5.22521 3.34428 6.19284 3.52997 7.12637C3.71566 8.05991 4.17401 8.91741 4.84705 9.59045C5.52009 10.2635 6.37759 10.7218 7.31113 10.9075C8.24466 11.0932 9.21229 10.9979 10.0917 10.6337C10.971 10.2694 11.7226 9.65259 12.2514 8.86118C12.7803 8.06977 13.0625 7.13932 13.0625 6.1875C13.0625 4.91115 12.5555 3.68707 11.653 2.78455C10.7504 1.88203 9.52635 1.375 8.25 1.375Z"
            fill="#0C1015"/>
          <path
            d="M15.125 20.625H13.75V17.1875C13.75 16.2758 13.3878 15.4015 12.7432 14.7568C12.0985 14.1122 11.2242 13.75 10.3125 13.75H6.1875C5.27582 13.75 4.40148 14.1122 3.75682 14.7568C3.11216 15.4015 2.75 16.2758 2.75 17.1875V20.625H1.375V17.1875C1.375 15.9111 1.88203 14.6871 2.78455 13.7845C3.68707 12.882 4.91115 12.375 6.1875 12.375H10.3125C11.5889 12.375 12.8129 12.882 13.7155 13.7845C14.618 14.6871 15.125 15.9111 15.125 17.1875V20.625Z"
            fill="#0C1015"/>
          <path d="M22 2.75H15.125V4.125H22V2.75Z" fill="#0C1015"/>
          <path d="M22 6.1875H15.125V7.5625H22V6.1875Z" fill="#0C1015"/>
          <path d="M19.9375 9.625H15.125V11H19.9375V9.625Z" fill="#0C1015"/>
        </svg>
        <Title>
          {p('recepients_title')}
        </Title>
      </Header>
      <Body>
        <SubTitle>
          {p('recepients_subtitle')}
        </SubTitle>
        {!!(value || []).length && (
          <RecepientsPreview onClick={handleAreaClick} $error={!!error}>
            {(value || []).map(tag => {
              // eslint-disable-next-line no-useless-escape
              const invalid = !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(tag) && !!error;

              return (
                <Tag key={tag} $error={invalid}>
                  {tag}
                  <CloseIcon onClick={onRemoveTag(tag)}>
                    {!invalid ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M12 4.7L11.3 4L8 7.3L4.7 4L4 4.7L7.3 8L4 11.3L4.7 12L8 8.7L11.3 12L12 11.3L8.7 8L12 4.7Z"
                          fill="#1A232E"/>
                      </svg>
                    ) : (
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4.7L11.3 4L8 7.3L4.7 4L4 4.7L7.3 8L4 11.3L4.7 12L8 8.7L11.3 12L12 11.3L8.7 8L12 4.7Z"
                          fill="white"/>
                      </svg>
                    )}
                  </CloseIcon>
                </Tag>
              );
            })}
          </RecepientsPreview>
        )}
        <InputContainer>
          <Input
            ref={inputRef}
            value={currentValue}
            onChange={handleCurrentValueChange}
            onKeyUp={handleKeyPress}
            style={{ width: '100%' }}
            wrapperStyles={{ width: '100%' }}
            error={error}
          />
          <Button
            smaller
            onClick={handleAdd}
            appearance="discard-light"
            width={148}
          >
            Add e-mail address
          </Button>
        </InputContainer>
      </Body>
    </Container>
  );
};

export default RecepientsSettings;
