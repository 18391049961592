import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Button } from '@velitech/ui';
import omit from 'lodash.omit';

import { useModalState, useTranslation, useNavigate } from '@hooks';

import { Paths, UserPermissions } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { by, notify, testId, validateNode } from '@utils';

import { WorkflowEditorContext } from '@contexts';

import { EditTemplateModal, Modal, WithPermissions } from '@components';

import { NodeSettings, NodeSettingsPreview } from './components';
import { Container, ActionsRow, CloseButton } from './styled';

import { WorkflowNodes } from '../../../../../constants';

const NodeSettingsModal = ({ opened, data = {}, onSave, onClose, options, editable }) => {
  const editor = useContext(WorkflowEditorContext);
  const [nodeData, setNodeData] = useState(data);
  const [styles, setStyles] = useState(editor.styles[data.id]?.data || {});
  const [label, setLabel] = useState(data.label);
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [description, setDescription] = useState(data.description);
  const editTemplateModal = useModalState();
  const navigate = useNavigate();

  const { t, p } = useTranslation('workflow_page')

  useEffect(() => {
    setNodeData(data);
  }, [data]);

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  useEffect(() => {
    setDescription(data.description);
  }, [data.description]);

  useEffect(() => {
    if (!editor.styles[data.id]?.data) {
      return;
    }

    setStyles(editor.styles[data.id]?.data || {});
  }, [editor.styles[data.id]]);

  const handleClearErrorInList = (errors, field, index) => {
    const clearedErrors = errors.map((error, idx) => {
      if(index !== idx) {
        return error;
      }

      const errors = omit(error.errors, field);
      const isEmpty = Object.keys(errors).length === 0;

      if (isEmpty) {
        return {
          ...error,
          errors: {},
          valid: true,
        };
      }

      return errors;
    });

    return clearedErrors;
  };

  const handleClearError = (field, index) => {
    setErrors((errors) => {
      if(Array.isArray(errors) && index !== undefined) {
        return handleClearErrorInList(errors, field, index);
      } else {
        return omit(errors, field)
      }
    });
  };

  const handleChangeLabel = (label) => {
    handleClearError('label');
    setLabel(label);
  };

  const nodeTemplateErrorMap = {
    [WorkflowNodes.SEND_SMS]: 'sms_id',
    [WorkflowNodes.SEND_EMAIL]: 'email_id',
    [WorkflowNodes.VIBER]: 'viber_id',
    [WorkflowNodes.WEBPUSH]: 'webpush_id',
    [WorkflowNodes.MOBILE_PUSH]: 'mobile_push_id',
    [WorkflowNodes.SEND_CARD]: 'send_card_id',
    [WorkflowNodes.API_REQUEST]: 'api_request_id',
    [WorkflowNodes.TEAM_MESSAGE]: 'email_id',
    [WorkflowNodes.BEST_CHANNEL_TO_SEND]: 'templateId',
    [WorkflowNodes.WEB_POPUP]: 'web_popup_id',
  };

  const handleSave = () => {
    const isBestChannel = nodeData.name === WorkflowNodes.BEST_CHANNEL_TO_SEND;
    const addingStyles = isBestChannel ? {} : styles;
    const { validated, validation, ...data } = validateNode({ ...nodeData, label, description, ...addingStyles });

    if(isBestChannel) {
      const error = Array.isArray(validation.errors) && validation.errors?.find((error) => !error.valid);

      if(error) {
        const keyTemplateconfig = [
          'email_id',
          'sms_id',
          'content_card_id',
          'viber_id',
          'mobile_push_id',
          'webpush_id',
        ];
        const existKey = error?.errors && Object.entries(error?.errors)?.some(([templateType]) => keyTemplateconfig.includes(templateType));

        const templateError = existKey && 'please_select_the_template';

        notify('error', p(error?.errors?.notification_message || templateError || 'default_field_is_required_notify'));
      }
    }

    if (nodeData.name === WorkflowNodes.GIVE_REWARD) {
      if (nodeData?.reward_type === 'money' && validation.errors.reward_money_mapping) {
        notify('error', validation.errors.reward_money_mapping);
      }
    }

    if(data.name === 'filter') {
      const error = Array.isArray(validation.errors) && validation.errors?.find((error) => !error.valid);
      if(error) {
        notify('error', p('error_filters_for_existing_groups'));
      }
    }

    if (!validation.valid) {
      if (!!validation.errors[nodeTemplateErrorMap[data.name]]) {
        notify('error', t('labels.error'), p('please_select_template'));
      }

      return setErrors(validation.errors);
    }

    const updatedStyles = isBestChannel ? {} : styles;

    onSave(data, { ...updatedStyles, validated, validation, label, description });
  };

  const saveDraft = () => {
    onSave({ ...nodeData }, { ...styles, label, description });
  }

  const handleDiscard = () => {
    setNodeData(data);
    setStyles(editor.styles[data.id]?.data || {})
    onClose();
  }

  const resolveTemplate = () => {
    switch (nodeData.name) {
      case WorkflowNodes.SEND_EMAIL:
        return options?.[TemplateTypes.EMAIL]?.find(by(nodeData.email_id));
      case WorkflowNodes.VIBER:
        return options.vibers?.find(by(nodeData.viber_id));
      case WorkflowNodes.SEND_SMS:
        return options.smses?.find(by(nodeData.sms_id));
      case WorkflowNodes.API_REQUEST:
        return options.apiRequests?.find(by(nodeData.api_request_id));
      case WorkflowNodes.WEBPUSH:
        return options.webpushes?.find(by(nodeData.webpush_id));
      case WorkflowNodes.MOBILE_PUSH:
        return options.mobilepushes?.find(by(nodeData.mobile_push_id));
      case WorkflowNodes.SEND_CARD:
        return options['content-cards']?.find(by(nodeData['content-card_id']));
      case WorkflowNodes.WEB_POPUP:
        return options['webpopups']?.find(by(nodeData['web_popup_id']));
      default:
        return null;
    }
  };

  const handleEditTemplateConfirm = () => {
    switch (nodeData.name) {
      case WorkflowNodes.SEND_EMAIL:
        return navigate(`${Paths.TEMPLATES_EMAIL}/vce/${nodeData.email_id}?view=0&ff=1`);
      case WorkflowNodes.VIBER:
        return navigate(`${Paths.TEMPLATES_VIBER}/${nodeData.viber_id}?view=0&ff=1`);
      case WorkflowNodes.SEND_SMS:
        return navigate(`${Paths.TEMPLATES_SMS}/${nodeData.sms_id}?view=0&ff=1`);
      case WorkflowNodes.SEND_CARD:
        return navigate(`${Paths.TEMPLATES_CONTENT_CARDS}/${nodeData.content_card_id}?view=0&ff=1`);
      case WorkflowNodes.API_REQUEST:
        return navigate(`${Paths.TEMPLATES_API_REQUEST}/${nodeData.api_request_id}?view=0&ff=1`);
      case WorkflowNodes.WEBPUSH:
        return navigate(`${Paths.TEMPLATES_WEBPUSH}/${nodeData.webpush_id}?view=0&ff=1`);
      case WorkflowNodes.MOBILE_PUSH:
        return navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/${nodeData.mobile_push_id}?view=0&ff=1`);
      case WorkflowNodes.WEB_POPUP:
        return navigate(`${Paths.TEMPLATES_WEB_POPUP}/${nodeData.web_popup_id}?view=0&ff=1`);
      default:
        return null;
    }
  };

  const handleEditTemplate = () => {
    if (resolveTemplate()?.workers?.length) {
      return editTemplateModal.open();
    }

    handleEditTemplateConfirm();
  };

  const getPermissionName = () => {
    let permission = '';
    switch (data.name) {
      case WorkflowNodes.SEND_SMS:
        permission = UserPermissions.UPDATE_SMS_NODES;
        break;
      case WorkflowNodes.SEND_EMAIL:
        permission = UserPermissions.UPDATE_EMAIL_NODES;
        break;
      case WorkflowNodes.WEBPUSH:
        permission = UserPermissions.UPDATE_WEBPUSH_NODES;
        break;
      case WorkflowNodes.SEND_CARD:
        permission = UserPermissions.UPDATE_WEBPUSH_NODES;
        break;
      case WorkflowNodes.MOBILE_PUSH:
        permission = UserPermissions.UPDATE_MOBILE_PUSH_NODES;
        break;
      case WorkflowNodes.API_REQUEST:
        permission = UserPermissions.UPDATE_API_REQUEST_NODES;
        break;
      case WorkflowNodes.VIBER:
        permission = UserPermissions.UPDATE_VIBER_NODES;
        break;
      case WorkflowNodes.PUSH_DISCOUNT:
        permission = UserPermissions.UPDATE_DISCOUNT_NODES;
        break;
      default:
        permission = UserPermissions.UPDATE_WORKFLOWS;
    }

    return {
      permissionName: permission,
      shouldCheck: permission && id && (!!~[WorkflowNodes.SEND_SMS, WorkflowNodes.SEND_EMAIL, WorkflowNodes.WEBPUSH, WorkflowNodes.MOBILE_PUSH, WorkflowNodes.API_REQUEST, WorkflowNodes.VIBER, WorkflowNodes.PUSH_DISCOUNT].indexOf(data.name)),
    }
  };

  const { shouldCheck, permissionName } = getPermissionName();

  return (
    <>
      <Modal
        opened={opened && !editTemplateModal.opened}
        onClose={onClose}
        bodyStyle={{ height: 700, display: 'flex', boxShadow: 'none', marginTop: 25 }}
        containerStyle={{
          width: 800,
          border: '2px solid #FFF',
          background: '#F4F5FD',
        }}
        wide
        renderBefore={(
          <NodeSettingsPreview
            name={data.name}
            actionType={data.actionType}
            title={`${data.label} ${p('settings')}`}
            description={description}
            onDescriptionChange={setDescription}
          />
        )}
        // fullScreen
        // right
        style={{ top: '0px', maxHeight: '100vh', paddingBottom: '0px', maxWidth: 'none', width: 'auto' }}
        contentStyles={{ padding: 0, height: '100%' }}
        actionsStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
        actions={(
          <ActionsRow>
            <Button {...testId('node-settings-modal-cancel')()} variant="secondary" onClick={handleDiscard} style={{ width: '140px' }}>{t('actions.cancel')}</Button>
            { shouldCheck ? (
              <WithPermissions name={permissionName}>
                <Button {...testId('node-settings-modal-save')()} disabled={!editable} onClick={handleSave} style={{ width: '140px' }}>{t('actions.save')}</Button>
              </WithPermissions>
            ) : (
              <Button {...testId('node-settings-modal-save')()} disabled={!editable} onClick={handleSave} style={{ width: '140px' }}>{t('actions.save')}</Button>
            )
            }
          </ActionsRow>
        )}
      >
        <Container>
          <CloseButton onClick={handleDiscard}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13Z" fill="#E5E8ED"/>
              <g clipPath="url(#clip0_834_10280)">
                <path d="M17.2071 17.2067C16.9747 17.4407 16.5964 17.4413 16.3634 17.2079L13.0001 13.8393L9.63667 17.208C9.4035 17.4416 9.02511 17.4417 8.79176 17.2084C8.55841 16.975 8.55856 16.5966 8.79209 16.3635L12.1608 13L8.79209 9.63662C8.55856 9.40345 8.55841 9.02507 8.79176 8.79171C9.02511 8.55836 9.4035 8.55851 9.63667 8.79205L13.0001 12.1608L16.3662 8.79469C16.5979 8.56293 16.9737 8.56293 17.2054 8.79469C17.4372 9.02645 17.4372 9.40221 17.2054 9.63398L13.8394 13L17.2056 16.3663C17.4375 16.5982 17.4382 16.974 17.2071 17.2067Z" fill="#909399"/>
              </g>
              <defs>
                <clipPath id="clip0_834_10280">
                  <rect width="10" height="10" fill="white" transform="translate(8 8)"/>
                </clipPath>
              </defs>
            </svg>
          </CloseButton>
          <NodeSettings
            onClearError={handleClearError}
            errors={errors}
            editable={editable}
            onSaveDraft={saveDraft}
            type={data.name}
            value={nodeData}
            styles={styles}
            onStylesChange={setStyles}
            description={description}
            label={label}
            onEditTemplate={handleEditTemplate}
            onLabelChange={handleChangeLabel}
            onDescriptionChange={setDescription}
            onChange={setNodeData}
            options={options}
          />
        </Container>
      </Modal>
      <EditTemplateModal
        opened={editTemplateModal.opened}
        onClose={toWorkflow => {
          if (toWorkflow) {
            handleDiscard();
          }
          editTemplateModal.close();
        }}
        onSubmit={handleEditTemplateConfirm}
        workflows={resolveTemplate()?.workers || []}
      />
    </>
  );
};

export default NodeSettingsModal;
