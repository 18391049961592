import { getDefaultLang } from '@utils';

const getDefaultLangData = (languagesTemplates) => {
  const defaultLang = languagesTemplates.find((langTemplate) => langTemplate.is_default);
  return defaultLang?.data || languagesTemplates[0]?.data;
}

export const modifyEmailNodes = ({ id, name, languages }) => {
  return {
    value: id,
    label: name,
    previewData: getDefaultLangData(languages),
  };
};

export const modifySendNodes = ({ id, name, languages }) => {
  return {
    value: id,
    label: name,
    previewData: getDefaultLangData(languages),
  };
};

export const modifyViberNodes = ({ id, name, languages }) => {
  const defaultLangData = getDefaultLang(languages);

  return {
    value: id,
    label: name,
    previewData: {
      image_url: defaultLangData.image_url || defaultLangData.image?.url,
      data: defaultLangData.data
    },
  }
};

export const modifyAPiReqesutNode = ({ id, name, body }) => {
  return {
    value: id,
    label: name,
    previewData: body,
  };
};

export const modifyWebPush = ({ id, name, languages }) => {
  const defaultLangData = getDefaultLang(languages);
  return {
    value: id,
    label: name,
    previewData: {
      image: defaultLangData?.image,
      pushTitle: defaultLangData.title,
      pushText: defaultLangData.body,
    },
  };
};

export const modifyMobilePush = ({ id, name, message, image, title }) => {
  return {
    value: id,
    label: name,
    previewData: {
      image: image,
      pushTitle: title,
      pushText: message,
    },
  };
};

export const modifyContentCard = ({
  id,
  name,
  languages,
}) => {
  const defaultLangData = getDefaultLang(languages);
  return {
    value: id,
    label: name,
    previewData: { id, name, image: defaultLangData?.url, message: defaultLangData.message, title: defaultLangData.title, ...defaultLangData },
  };
};

export const commonModify = ({ id, name, image_url, data }) => {
  return {
    value: id,
    label: name,
    previewData: {
      image_url: image_url,
      data
    },
  }
}