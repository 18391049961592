import { useState, useMemo, useEffect, useRef } from 'react';

import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { WorkflowNodes } from '@constants';

import { moment } from '@utils';

import sendIcon from '@res/images/ai-send.svg';
import timerIcon from '@res/images/timer_icon.svg';

import { DatePicker, Icon, Input, RadioButton, RadioGroup, Switch, TimePicker } from '@components';

import { CreateTemplateCard } from './components/CreateTemplateCard'
import { TemplateCard } from './components/TemplateCard'
import {
  PreviewContainer,
  CardsContainer,
  StyledInput,
  Title,
  ContentTitle,
  Expiration,
  ExpirationContent,
  ExpirationHeader,
  ExpirationlTitle,
  ExpirationSettings,
  ExpirationSettingsOption,
  HeaderLeft,
  IconImg,
  PlaceHolder,
  SpecificSelects,
  TabsContainer,
  TabContainer,
  AiSend,
  AiSendHeader,
  AiSendContent,
  AiTitle,
  AiContentTitle,
} from './styled'

import { SelectedTemplate } from '../../components/SelectedTemplate';

const ExpirationTypes = {
  AFTER_PERIOD: 'duration',
  SPECIFIC: 'date'
};

const searchIcon =
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3124 15.5171L12.0643 11.269C13.0851 10.0435 13.5941 8.47169 13.4855 6.88047C13.3769 5.28924 12.659 3.80115 11.4811 2.72576C10.3033 1.65037 8.75616 1.07047 7.16164 1.1067C5.56713 1.14294 4.04796 1.79251 2.92018 2.9203C1.79239 4.04808 1.14282 5.56725 1.10658 7.16177C1.07035 8.75628 1.65024 10.3034 2.72564 11.4812C3.80103 12.6591 5.28912 13.377 6.88034 13.4856C8.47157 13.5943 10.0434 13.0852 11.2689 12.0645L15.517 16.3125L16.3124 15.5171ZM2.24989 7.31251C2.24989 6.31124 2.5468 5.33246 3.10307 4.49994C3.65935 3.66741 4.45 3.01854 5.37505 2.63537C6.30011 2.2522 7.31801 2.15195 8.30003 2.34729C9.28206 2.54262 10.1841 3.02478 10.8921 3.73278C11.6001 4.44079 12.0823 5.34284 12.2776 6.32487C12.473 7.30689 12.3727 8.32479 11.9895 9.24985C11.6064 10.1749 10.9575 10.9656 10.125 11.5218C9.29244 12.0781 8.31366 12.375 7.31239 12.375C5.97019 12.3735 4.68339 11.8397 3.73431 10.8906C2.78522 9.94151 2.25138 8.65471 2.24989 7.31251Z" fill="#5F728A"/>
  </svg>;

const NodeSettingsTemplatesPreview = ({ type, onChange, onNodeValueChange, nodeValue, onCreateNew, value, options, worker, tabs, tab, onTabChange, errors, onClearError, onEditTemplate, previewType, showAiSend = true }) => {
  const { p } = useTranslation('workflow_page');
  const [searchValue, setSearchValue] = useState('');
  const previewContainerRef = useRef();

  useEffect(() => {
    value && previewContainerRef.current?.scrollIntoView({
      top: 0,
    })
  }, [value])

  useEffect(() => {
    if (!nodeValue?.expiration?.type && type === WorkflowNodes.SEND_CARD) {
      onNodeValueChange(s => ({
        ...s,
        expiration: {
          ...(s.expiration || {}),
          type: ExpirationTypes.AFTER_PERIOD,
        }
      }));
    }
  }, [nodeValue]);

  const handleDurationValueChange = ({ target: { value } }) => {
    const valueAsString = typeof value === 'number' ? value.toString() : value;
    const sanitizedValue = valueAsString.replace(/^0+/, '');

    if (sanitizedValue === '' || (/^\d+$/.test(sanitizedValue) && parseInt(sanitizedValue) >= 1 && parseInt(sanitizedValue) <= 30)) {
      onNodeValueChange(s => ({
        ...s,
        expiration: {
          ...(s.expiration || {}),
          type: ExpirationTypes.AFTER_PERIOD,
          duration: sanitizedValue,
        }
      }));
      onClearError('expiration_duration');
    }
  };

  const onSpecificDateChange = (newDate) => {
    const oldDate = moment(nodeValue.expiration?.date);

    onNodeValueChange(s => ({
      ...s,
      expiration: {
        type: ExpirationTypes.SPECIFIC,
        date: moment(newDate)
          .set('hours', oldDate.get('hours') || 0)
          .set('minutes', oldDate.get('minutes') || 0)
          .set('seconds', oldDate.get('seconds') || 0),
      }
    }));
    onClearError('expiration_date');
  };

  const onSpecificTimeChange = (newTime) => {
    const oldDate = moment(nodeValue.expiration?.date);

    onNodeValueChange(s => ({
      ...s,
      expiration: {
        type: ExpirationTypes.SPECIFIC,
        date: moment(oldDate)
          .set('hours', moment(newTime).get('hours') || 0)
          .set('minutes', moment(newTime).get('minutes') || 0)
          .set('seconds', moment(newTime).get('seconds') || 0),
      }
    }));
    onClearError('expiration_date');
  };

  const onAiSpecificTimeChange = (newTime) => {

    onNodeValueChange(s => ({
      ...s,
      best_time_fallback: moment(newTime),
    }));
    onClearError('best_time_fallback');
  };
  const onRadioChange = (type) => {
    onNodeValueChange(s => ({ ...s, expiration: { type: type } }));
  };

  const handleAiToggle = () =>{
    onNodeValueChange(s => ({ ...s, best_time_enabled: !s.best_time_enabled }));
  }

  const onAiRadioChange = (type) => {
    onNodeValueChange(s => ({
      ...s,
      best_time_fallback: type === '0' ? null : moment().add(1, 'hour').startOf('hour'),
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === '-' || e.key === 'e') {
      e.preventDefault();
    }
  }

  const onInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSelectTemplate = (templateId) => {
    onChange(templateId);
  };

  const handleChooseAnother = () => {
    onChange(null);
  };

  const filteredOptions = useMemo(() => {
    return options?.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()));
  }, [options, searchValue]);

  const selectedTemplate = useMemo(() => {
    return options.find(({ value: v }) => v === value);
  }, [options, value]);

  return (
    <PreviewContainer ref={previewContainerRef}>
      {!value ? (
        <>
          <Title>{p(`choose_${type}_template`)}</Title>
          <StyledInput
            data-testid='node-settings-template-search-input'
            prefix={searchIcon}
            placeholder={'Search'}
            onChange={onInputChange}
          />
          {tabs && (
            <TabsContainer>
              {tabs.map(t => (
                <TabContainer key={t.name} onClick={() => onTabChange(t.name)} $selected={t.name === tab}>
                  {p(t.label)}
                </TabContainer>
              ))}
            </TabsContainer>
          )}
          <CardsContainer $previewType={previewType}>
            {(!searchValue && !previewType) && <CreateTemplateCard testId='node-settings-template-card-create-template' handleCreateNew={onCreateNew} />}
            {filteredOptions.map(({ value: v, label, previewData }, idx) => (
              <TemplateCard
                previewType={previewType}
                testId={`node-settings-template-card-${idx}`}
                type={type}
                onSelect={handleSelectTemplate}
                isSelected={value === v}
                value={v}
                key={v}
                label={label}
                previewData={previewData}
              />
            ))}
          </CardsContainer>
        </>
      ) : (
        <>
          <SelectedTemplate
            previewType={previewType}
            worker={worker}
            type={type}
            selectedTemplate={selectedTemplate}
            onEditTemplate={onEditTemplate}
            handleChooseAnother={handleChooseAnother}
          />
          {type === WorkflowNodes.SEND_CARD && (
            <>
              {/*<RemovalEvent nodeValue={nodeValue} onChange={onNodeValueChange} />*/}
              <Expiration>
                <ExpirationHeader>
                  <HeaderLeft>
                    <IconImg src={timerIcon} />
                    <ExpirationlTitle>{p('expiration')}</ExpirationlTitle>
                  </HeaderLeft>
                  <Tooltip title={p('expiration_tooltip')}>
                    <div>
                      <Icon name="Info-icon" size={16} color="#7C94B1" />
                    </div>
                  </Tooltip>
                </ExpirationHeader>
                <ExpirationContent>
                  <ContentTitle>
                    {p('select_option_for_removal')}
                  </ContentTitle>
                  <ExpirationSettings>
                    <RadioGroup
                      value={nodeValue?.expiration?.type ? nodeValue?.expiration?.type : ExpirationTypes.AFTER_PERIOD }
                      onChange={onRadioChange}
                    >
                      <ExpirationSettingsOption>
                        <RadioButton
                          data-testid={'duration-radio'}
                          sendCard
                          name={ExpirationTypes.AFTER_PERIOD}
                          title={p('after_period')}
                        />
                        <Input
                          disabled={nodeValue?.expiration?.type === ExpirationTypes.SPECIFIC}
                          data-testid={''}
                          wrapperStyles={{ width: '120px', marginTop: '8px', marginLeft: '19px' }}
                          style={{ width: '90px', marginBottom: '6px' }}
                          value={nodeValue?.expiration?.duration}
                          showArrowButtons={false}
                          onChange={handleDurationValueChange}
                          type="number"
                          error={errors?.['expiration_duration']}
                          errorStyle={{ whiteSpace: 'nowrap' }}
                          onKeyPress={handleKeyPress}
                          min={1}
                        />
                        <PlaceHolder>
                        Days
                        </PlaceHolder>
                      </ExpirationSettingsOption>
                      <ExpirationSettingsOption>
                        <RadioButton
                          data-testid={''}
                          sendCard
                          bigger
                          name={ExpirationTypes.SPECIFIC}
                          title={p('at_specific')}
                        />
                        <SpecificSelects>
                          <DatePicker
                            style={{ width: '130px' }}
                            sendCard
                            disabled={nodeValue?.expiration?.type === ExpirationTypes.AFTER_PERIOD}
                            data-testid="customers-filter-start-date"
                            value={nodeValue?.expiration?.date && moment(nodeValue?.expiration?.date)}
                            onChange={onSpecificDateChange}
                            type="date"
                            placeholder={'Set date'}
                            error={errors?.['expiration_date']}
                          />
                          <TimePicker
                            sendCard
                            disabled={nodeValue?.expiration?.type === ExpirationTypes.AFTER_PERIOD}
                            value={nodeValue?.expiration?.date && moment(nodeValue?.expiration?.date)}
                            format={'HH:mm'}
                            onChange={onSpecificTimeChange}
                            error={errors?.['ai_send_specific'] && ' '}
                          />
                        </SpecificSelects>
                      </ExpirationSettingsOption>
                    </RadioGroup>
                  </ExpirationSettings>
                </ExpirationContent>
              </Expiration>
            </>
          )}
          {(showAiSend && value) && (
            <AiSend>
              <AiSendHeader>
                <HeaderLeft>
                  <IconImg src={sendIcon} />
                  <AiTitle>{p('ai_send')}</AiTitle>
                  <Tooltip title={p('ai_best_time_to_send_tooltip')}>
                    <div>
                      <Icon name="Info-icon" size={16} color="#7C94B1" />
                    </div>
                  </Tooltip>
                </HeaderLeft>
                <Switch
                  testId="best-time-switch"
                  large
                  checked={!!nodeValue?.best_time_enabled}
                  onChange={handleAiToggle}
                />
              </AiSendHeader>
              <AiSendContent>
                <AiContentTitle $disabled={!nodeValue?.best_time_enabled}>
                  {p('select_option_for_ai')}
                </AiContentTitle>
                <ExpirationSettings>
                  <RadioGroup
                    disabled={!nodeValue?.best_time_enabled}
                    value={nodeValue.best_time_fallback ? '1' : '0'}
                    onChange={onAiRadioChange}
                  >
                    <ExpirationSettingsOption>
                      <RadioButton
                        data-testid={'send-immediately'}
                        sendCard
                        name="0"
                        title={p('send_immediately')}
                      />
                    </ExpirationSettingsOption>
                    <ExpirationSettingsOption>
                      <RadioButton
                        data-testid="send-at-specific-time"
                        sendCard
                        bigger
                        name="1"
                        title={p('set_specific')}
                      />
                      <SpecificSelects>
                        <TimePicker
                          sendCard
                          data-testid="best-time-fallback"
                          disabled={!nodeValue?.best_time_fallback}
                          value={!nodeValue?.best_time_fallback ? null : moment(nodeValue?.best_time_fallback)}
                          format={'HH:mm'}
                          onChange={onAiSpecificTimeChange}
                          error={errors?.['best_time_fallback'] && ' '}
                        />
                      </SpecificSelects>
                    </ExpirationSettingsOption>
                  </RadioGroup>
                </ExpirationSettings>
              </AiSendContent>
            </AiSend>
          )}
        </>
      )}
    </PreviewContainer>
  );
};

export default NodeSettingsTemplatesPreview;
