import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div).attrs({
  initial: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '-30%' : '30%',
    scale: 0.98
  }),
  animate: { opacity: 1, x: '0%', scale: 1 },
  exit: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '30%' : '-30%',
    scale: 0.98
  }),
  transition: { duration: 0.27, easing: 'ease' }
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-top: 110px;
`;

export const Image = styled.img`
  width: 380px;
  height: 332px;
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 24px;
  line-height: 28px;
  position: absolute;
  top: 119px;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 35px;
  padding-top: 50px;
  margin-top: 90px;
  margin-bottom: 70px;
  align-items: flex-start;
`;

export const ExitCriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-top: 70px;
`

export const ExitCriteriaSettings = styled.div`
`;

export const SettingsTitle = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 18px;
  color: #1A232E;
  margin-bottom: 18px;
`;

export const SettingsText = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 14px;
  color: #7C94B1;
  max-width: 380px;
  margin-bottom: 20px;
`;

export const ExitTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  gap: 18px;
`;

export const Actions = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;
