import { useTranslation } from '@hooks';

import { Container } from './styled';

const DiscardButton = (props) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container {...props}>
      {p('cancel_creation')}
    </Container>
  );
};

export default DiscardButton;
