import { useState } from 'react';

import { Checkbox, Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Container, Option, OptionTitle, OptionCaption, ApplySelected, OptionLeft } from './styled';

import { ListNoData } from '../../../ListNoData';

const SegmentsList = ({ options, onSelect, omitValues = [], multipleSelect = false }) => {
  const { p, t } = useTranslation('workflow_page');
  const [selected, setSelected] = useState([]);

  const existFilter = (optionValue) => {
    return selected.find((optionSelected) => optionSelected === optionValue);
  }

  const handleSelectFilter = (value) => () => {
    const existFilterOption = existFilter(value);

    if(existFilterOption) {
      const newFilter = selected.filter((selectedOptionValue) => selectedOptionValue !== existFilterOption);
      setSelected(newFilter)
    } else {
      setSelected([...selected, value])
    }
  };

  const handleSelect = (v) => () => {
    onSelect({ id: v });
  };

  const handleApply = () => {
    onSelect(selected);
  }
  
  return (
    <Container $hasSelected={selected?.length > 0}>
      {!options.filter(({ value }) => !omitValues.includes(value)).length && (
        <ListNoData />
      )}
      {options.filter(({ value }) => !omitValues.includes(value)).map(({ value, label, customers }, index) => {
        const isSelected = existFilter(value);

        return (
          <Option
            $selected={isSelected}
            data-testid={'segment-trigger-settings-add-default-segment-option-' + index}
            key={value}
            onClick={ multipleSelect ? handleSelectFilter(value) : handleSelect(value)}
          >
            <OptionLeft>
              {multipleSelect && <Checkbox checked={!!isSelected} onLabelClick={(e) => e.preventDefault()}/> }
              <OptionTitle>
                {label}
              </OptionTitle>
            </OptionLeft>
            <OptionCaption>
              ({p('customers_count', { count: customers })})
            </OptionCaption>
          </Option>
        )
      })}
      {selected.length > 0 && multipleSelect && (
        <ApplySelected >
          <Button size='lg' onClick={handleApply} className='vl-w-[130px]'>{t('actions.apply')}</Button>
        </ApplySelected>
      )}
    </Container>
  );
};

export default SegmentsList;
