import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

import { DirectionModes } from '@constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  ${({ $dm }) => $dm === DirectionModes.VERTICAL && css`
    flex-direction: row;
    align-items: flex-start;
  `}
`;

export const IconContainer = styled.div`
  width: 22px;
  height: 22px;
    max-height: 22px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
`;

export const AddBranchContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ $dm }) => $dm === DirectionModes.VERTICAL && css`
    flex-direction: row;
  `}
`;
