import styled from 'styled-components';

export const Container = styled.div`
  overflow: scroll;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 18px 0;
  /* border-bottom: ${props => (props.$hasBorder ? `1px solid #D3DBE7;` : `none`)};   */
  color: var(--text-primary-body, #1A232E);

  font-family: Manrope-SemiBold;
  font-size: 18px;
  line-height: 24px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom:  ${props => (props.$hasBorder ? `0px;` : `-42px`)}; 
    width: 100%;
    height: 1px;;
    background-color: #D3DBE7;
    left: 0px;
    right: 0px;
  }
`;

export const TabsContainer = styled.div`
  position: relative;
  overflow-x: scroll;
  width: 100%;
  scrollbar-width: none;
  .tab {
    &:hover {
      .tab-item:not(.active) {
        color: var(--tabs-text-hover);
      }
    }
    
    .active  {
      color: #31C447;
    }

   
  }


`
export const TabItem = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-family: Manrope-SemiBold;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #1A232E;
  cursor: pointer;
  
`