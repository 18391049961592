import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { TemplateTypes } from '@constants/templates';

import { diffFields, testId } from '@utils';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

import { Input, Multiselect, SearchSelect, Textarea } from '@components';
import { NodeSettingsAccordion } from '@components/lib/WorkflowEditor/components';

import { Container, Row } from './styled.js';

const emailInteractionOptions = [
  {
    label: 'workflow_page.sent',
    value: 'sent',
  },
  {
    label: 'workflow_page.delivered',
    value: 'delivered',
  },
  {
    label: 'workflow_page.open',
    value: 'open',
  },
  {
    label: 'workflow_page.click',
    value: 'click',
  }
];

const linkFilterOptions = [
  {
    value: 'any',
    label: 'workflow_page.any_link',
  },
  {
    value: 'matching',
    label: 'workflow_page.link_matching',
  },
];

const EmailNodeSettings = ({
  value: outerValue,
  onChange: outerOnChange,
  label,
  description,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const { t } = useTranslation('workflow_page');
  const multinode = createMultinodeUpdater(outerValue, outerOnChange, errors, onClearError);
  const segmentOptions = useSelector(optionsSelector);
  const value = multinode.values[0];
  const onChange = multinode.changeNode(0);
  const emailOptions = options?.[TemplateTypes.EMAIL]?.filter((item) => item?.status === 3).map(({ id, name }) => ({ label: name, value: id }));

  const handleEmailChange = (email_id) => {
    multinode.clearError(0)('email_id');
    onChange(s => ({ ...s, email_id }));
  }

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleValueChange = (value) => {
    multinode.clearError(0)('value');
    if (value === 'click') {
      return onChange(s => ({ ...s, value, link_filter: null }));
    }
    onChange(s => ({ ...s, value }));
  };

  const handleFieldsChange = (message_fields) => {
    onChange(s => ({ ...s, meta: message_fields }));
  };

  const handleLinkFilterChange = (link_filter) => {
    onChange(s => ({ ...s, link_filter }));
  };

  const handleLinkChange = ({ target: { value: url } }) => {
    multinode.clearError(0)('url');
    onChange(s => ({ ...s, url }));
  };

  const fieldsOptions = segmentOptions.fields?.attributes?.map(({ label, field }) => ({ value: field, label }));
  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <SearchSelect
              {...testId('email-node-settings-required-email-select')()}
              highlight={diff['email_id'] === false}
              error={multinode.getError(0)('email_id')}
              disabled={!editable}
              style={{ width: '100%' }}
              containerStyle={{ width: '100%', marginBottom: '16px' }}
              value={value.email_id}
              getPopupContainer={t => t.parentElement.parentElement.parentElement}
              placeholder={t('labels.select')}
              onChange={handleEmailChange}
              absoluteError={false}
              options={emailOptions || []}
              title={t('labels.email')}
            />
            <SearchSelect
              {...testId('email-node-settings-required-action')()}
              label={t('labels.action')}
              value={value?.value}
              highlight={diff['value'] === false}
              error={multinode.getError(0)('value')}
              style={{ width: '100%' }}
              wrapperStyles={{ width: '100%' }}
              options={emailInteractionOptions}
              tooltipError={value?.validation?.errors?.value}
              onChange={handleValueChange}
            />
            {value.value === 'click' && (
              <Row align="column">
                <SearchSelect
                  style={{ width: '100%' }}
                  highlight={diff['link_filter'] === false}
                  error={multinode.getError(0)('link_filter')}
                  options={linkFilterOptions}
                  value={value?.link_filter}
                  onChange={handleLinkFilterChange}
                />
                {value.link_filter === 'matching' && (
                  <Input
                    title={t('labels.url')}
                    error={multinode.getError(0)('url')}
                    wrapperStyles={{ marginTop: 16 }}
                    style={{ marginBottom: '0px', width: '100%' }}
                    value={value?.url}
                    onChange={handleLinkChange}
                  />
                )}
              </Row>
            )}
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('email-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={multinode.getError(0)('label')}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              {...testId('email-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
        additionalSettings={(
          <Multiselect
            {...testId('email-node-settings-additional-message-fields')()}
            disabled={!editable}
            title={t('labels.message_fields')}
            style={{ width: '100%' }}
            options={fieldsOptions}
            getParentElement={t => t.parentElement.parentElement.parentElement}
            value={value.meta || []}
            onChange={handleFieldsChange}
          />
        )}
      />
    </Container>
  );
}

export default EmailNodeSettings;

