import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';

import { by, moment, zeroIfNaN , diffFields } from '@utils';
import { clickhouseFields2Events } from '@utils/fields';

import { OptionCard } from '@components';

import { ForDuration, SendTime, TillDay, UntillAction } from './components';
import { CalendarIconSvg, ClockIconSvg, FingerIconSvg } from './icons';
import { Container, Optioncontainer, Title } from './styled';

import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';

const actionOptions = [
  {
    id: 1,
    label: 'workflow_page.wait_duration',
    description: 'workflow_page.wait_duration_description',
    icon: <ClockIconSvg />,
  },
  {
    id: 0,
    label: 'workflow_page.wait_day',
    description: 'workflow_page.wait_day_description',
    icon: <CalendarIconSvg />,
  },
  {
    id: 2,
    label: 'workflow_page.wait_untill_action',
    description: 'workflow_page.wait_untill_action_description',
    icon: <FingerIconSvg />,
  },
];

const WaitNodeSettings = ({
  value,
  onChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const { t } = useTranslation('workflow_page');
  const [showSendAt, setShowSendAt] = useState(
    value.type === 1 && !!value.send_at
  );
  // eslint-disable-next-line no-undef
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const optionsEvents = clickhouseFields2Events(clickhouseFields.data);

  useEffect(() => {
    if(!value.type) {
      //if new node default set 1 type
      //for some reason without setTimeout  new type not setted
      //TODO: Should investigate this case
      setTimeout(() => {
        handleClickOption(1);
      }, 0);
    }
  }, []);

  const handleActionChange = (type) => {
    onClearError('type');
    onChange((s) => ({ ...s, type }));

    if (type === 1) {
      onChange((s) => ({ ...s, send_at: null }));
      setShowSendAt(false);
    }

    if (type === 0 && !value.send_at) {
      onChange((s) => ({
        ...s,
        send_at: moment().set('hours', 0).set('minutes', 0).set('seconds', 0),
      }));
    }
  };

  const handleDaysChange = ({ target: { value: days } }) => {
    onClearError('days');
    onClearError('duration');
    onChange((s) => ({
      ...s,
      days,
      duration: +(
        days * 60 * 24 +
        zeroIfNaN(s.hours) * 60 +
        zeroIfNaN(s.minutes)
      ),
    }));
  };

  const handleMinutesChange = ({ target: { value: minutes } }) => {
    onClearError('minutes');
    onClearError('duration');
    onChange((s) => ({
      ...s,
      minutes,
      duration: +(
        zeroIfNaN(s.days) * 60 * 24 +
        zeroIfNaN(s.hours) * 60 +
        +minutes
      ),
    }));
  };

  const handleHoursChange = ({ target: { value: hours } }) => {
    onClearError('hours');
    onClearError('duration');
    onChange((s) => ({
      ...s,
      hours,
      duration: +(
        zeroIfNaN(s.days) * 60 * 24 +
        +(hours * 60) +
        zeroIfNaN(s.minutes)
      ),
    }));
  };

  const handleDayChange = (day) => {
    onClearError('day');
    onChange((s) => ({ ...s, day }));
  };

  const handleTimeChange = (send_at) => {
    onClearError('send_at');

    if (!send_at) {
      return onChange((s) => ({
        ...s,
        send_at: moment().set('hours', 0).set('minutes', 0).set('seconds', 0),
      }));
    }
    onChange((s) => ({ ...s, send_at }));
  };

  const handleChangeShowSendAt = (checked) => {
    if (!checked) {
      onChange((s) => ({ ...s, send_at: null }));
    }
    setShowSendAt(checked);
  };

  const diff = compareWithValue
    ? diffFields(value, compareWithValue) || {}
    : {};

  const handleClickOption = (type) => {
    handleActionChange(type);
  };

  const handleEventChange = ({ id: event_id }) => {
    const name = optionsEvents.data.find(by(event_id))?.name;
    onChange((s) => ({
      ...s,
      event_id: event_id,
      event_name: name,
      filter_builder: {
        logicalOperator: 'and',
        filters: [],
      },
    }));
  };

  const handleDeleteEvent = () => {
    onChange((s) => ({
      ...s,
      event_id: null,
      event_name: null,
      filter_builder: null,
    }));
  };

  const handleChangeExpirationAction = (expiration_action) => {
    onClearError('expiration_action');
    onChange((s) => ({
      ...s,
      expiration_action
    }));
  }

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={
          <>
            <Title>{t('workflow_page.option_title')}</Title>
            <Optioncontainer>
              {actionOptions.map(({ id, icon, description, label }) => {
                return (
                  <OptionCard
                    testId={`wait-node-settings-${label}`}
                    key={id}
                    className={value.type === id && 'active'}
                    iconNode={icon}
                    title={t(label)}
                    description={t(description)}
                    onClick={() => handleClickOption(id)}
                  />
                );
              })}
            </Optioncontainer>

            {value.type === 0 && (
              <TillDay
                highlight={diff['day'] === false}
                errors={errors}
                editable={editable}
                value={value}
                handleDayChange={handleDayChange}
              />
            )}
            {value.type === 1 && (
              <ForDuration
                highlight={diff['duration'] === false}
                errors={errors}
                editable={editable}
                value={value}
                onDaysChange={handleDaysChange}
                onHoursChange={handleHoursChange}
                onMinutesChange={handleMinutesChange}
              />
            )}
            {(value.type === 0 || value.type === 1) && (
              <SendTime
                value={value}
                editable={editable}
                showSendAt={showSendAt}
                handleChangeSendAt={handleChangeShowSendAt}
                handleTimeChange={handleTimeChange}
                highlight={diff['send_at'] === false}
                errors={errors}
              />
            )}
            {value.type === 2 && (
              <UntillAction
                value={value}
                eventListOption={(optionsEvents.data)}
                eventsFiltersOption={optionsEvents.data}
                onChangeEventFilter={onChange}
                errors={errors}
                onClearError={onClearError}
                highlight={diff['duration'] === false}
                editable={editable}
                onEventChange={handleEventChange}
                onDeleteEvent={handleDeleteEvent}
                onDaysChange={handleDaysChange}
                onHoursChange={handleHoursChange}
                onMinutesChange={handleMinutesChange}
                onChangeExpirationAction={handleChangeExpirationAction}
              />
            )}
          </>
        }
      />
    </Container>
  );
};

export default WaitNodeSettings;
