import { useTranslation } from '@hooks';

import { CancelButton, Container, Title } from './styled';

const ConnectionModeBadge = ({ onCancel }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container>
      <Title>{p('connect_branch_title')}</Title>
      <CancelButton onClick={onCancel}>
        {p('cancel_connection')}
      </CancelButton>
    </Container>
  );
};

export default ConnectionModeBadge;
