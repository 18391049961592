import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';

import { clickhouseFields2Events } from '@utils/fields';

import { Modal } from '@components';

import { Container } from './styled';

import { identity } from '../../../../../../../../utils';
import { EventsList } from '../EventsList';
import { ModalListHeader } from '../ModalListHeader';

const AddEventModal = ({ opened, onClose, onSelect, filterOptions = identity, triggerSettings }) => {
  const { p } = useTranslation('workflow_page');
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const events = clickhouseFields2Events(clickhouseFields.data);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = useMemo(() => {
    return events.data.filter(filterOptions).filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, filterOptions]);

  const handleSelect = (v) => {
    onSelect(v);
    onClose();
  };

  return (
    <Modal
      {...{ opened, onClose }}
      style={{ width: 530 }}
      containerStyle={{ width: 530 }}
      contentStyles={{ padding: 0 }}
    >
      <Container>
        <ModalListHeader testId={'add-event-modal'} onSearchChange={setSearchTerm} title={p('events_list')} onClose={onClose} triggerSettings={triggerSettings} />
        <EventsList options={(filteredOptions || []).map(({ label, id }) => ({ value: id, label }))} onSelect={handleSelect} />
      </Container>
    </Modal>
  );
};

export default AddEventModal;
