import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { testId } from '@utils';

import { Input, SearchSelect, Icon } from '@components';

import {
  Column,
  Container,
  TitleRow,
  Row,
  Title,
  TooltipContainer,
} from './styled';

import { EventFilter } from '../../../../../../../../../pages/HomeScene/pages/WorkflowScene/pages/WorkflowSetupPage/components';
import { List } from '../../../ui/List';

export const UntillAction = ({
  value,
  eventsFiltersOption,
  eventListOption,
  errors,
  onClearError,
  onEventChange,
  onDeleteEvent,
  onChangeEventFilter,
  highlight,
  editable,
  onDaysChange,
  onHoursChange,
  onMinutesChange,
  onChangeExpirationAction,
}) => {
  const { p, t } = useTranslation('workflow_page');

  const options = [
    {
      value: 1,
      label: p('proceed_workflow'),
    },
    {
      value: 0,
      label: p('exit_workflow'),
    },
  ];

  return (
    <Column>
      {!value.event_id && (
        <List
          title={p('events_list')}
          options={eventListOption}
          onSelect={onEventChange}
        />
      )}

      {value.event_id && (
        <EventFilter
          isNodeSettings={true}
          testId={{ ...testId('event-filter-container')() }}
          value={value}
          options={eventsFiltersOption}
          errors={() => errors['filter_builder']}
          onClearError={onClearError}
          onDelete={onDeleteEvent}
          onChange={onChangeEventFilter}
        />
      )}
      {value.event_id && (
        <Container>
          <Title>
            {p('max_wait_time')}
            <TooltipContainer>
              <Tooltip title={p('max_wait_time_tooltip')}>
                <Icon color='#909399' name='Info-icon' size={14} />
              </Tooltip>
            </TooltipContainer>
          </Title>
          <Row style={{ flexDirection: 'column', gap: '20px' }}>
            <Row style={{ gap: '10px' }}>
              <TitleRow style={{ marginRight: '10px' }}>
                {p('wait_for')}
              </TitleRow>
              <Input
                {...testId('wait-node-settings-select-time-days')()}
                $highlight={highlight}
                error={errors['duration']}
                disabled={!editable}
                type='number'
                style={{ marginBottom: '0' }}
                min={0}
                placeholder={t('labels.days')}
                absoluteError
                onChange={onDaysChange}
                value={value.days}
                errorStyle={{
                  position: 'absolute',
                  width: 'max-content',
                }}
              />
              <Input
                {...testId('wait-node-settings-select-time-hours')()}
                $highlight={highlight}
                error={errors['duration'] ? ' ' : ''}
                disabled={!editable}
                min={0}
                style={{ marginBottom: '0' }}
                type='number'
                placeholder={t('labels.hours')}
                absoluteError
                onChange={onHoursChange}
                value={value.hours}
              />
              <Input
                {...testId('wait-node-settings-select-time-minutes')()}
                $highlight={highlight}
                error={errors['duration'] ? ' ' : ''}
                disabled={!editable}
                min={0}
                style={{ marginBottom: '0' }}
                type='number'
                placeholder={t('labels.minutes')}
                absoluteError
                onChange={onMinutesChange}
                value={value.minutes}
              />
            </Row>
            <Row style={{ gap: '10px' }}>
              <TitleRow>{p('and_then')}</TitleRow>
              <SearchSelect
                {...testId('wait-node-settings-select-then')()}
                disabled={false}
                style={{ width: '100%' }}
                containerStyle={{ width: '100%' }}
                value={value.expiration_action}
                onChange={onChangeExpirationAction}
                options={options}
                error={errors['expiration_action']}
              />
            </Row>
          </Row>
        </Container>
      )}
    </Column>
  );
};
