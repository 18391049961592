import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 14px;
  line-height: 20px;
`;

export const Image = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  //padding: 18.75px;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  background: linear-gradient(224deg, #64ECB1 5.92%, #50D49A 94.84%);
  box-shadow: 0px 8px 18px 0px rgba(93, 190, 147, 0.40);
`;

