import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { Dropdown } from 'antd';

import { useTranslation } from '@hooks';

import { Paths, RFMColorsConfig, WorkflowNodes } from '@constants';

import { by, extract, tryParse } from '@utils';
import { clickhouseFields2Events } from '@utils/fields';

import { Link } from '@components';

import { Container, Type, Overlay } from './styled';

const resolveType = (type) => {
  return {
    [WorkflowNodes.EMAIL]: 'labels.email',
    [WorkflowNodes.EVENT]: 'labels.event',
    [WorkflowNodes.RESOURCE]: 'labels.segment',
    [WorkflowNodes.DATA_CHANGE]: 'labels.data_change',
    [WorkflowNodes.CONTACT_CREATED]: 'labels.customer_addition',
    [WorkflowNodes.PAGE_VIEW]: 'labels.page_view',
    [WorkflowNodes.ENTRY_ANOTHER_WF]: 'labels.workflow'
  }?.[type];
};

const resolveName = (type, data, options, segmentOptions, rfmAnalyticsOptions, index = 0) => {
  const id = data?.[{
    [WorkflowNodes.EMAIL]: 'email_id',
    [WorkflowNodes.EVENT]: 'event_ids',
    [WorkflowNodes.RESOURCE]: 'segment_ids',
    [WorkflowNodes.ENTRY_ANOTHER_WF]: 'trigger_workflow_id'
  }?.[type]];

  const entity = ((type === WorkflowNodes.RESOURCE || type === WorkflowNodes.EVENT) ? segmentOptions : options)?.[{
    [WorkflowNodes.EMAIL]: 'email',
    [WorkflowNodes.EVENT]: 'events',
    [WorkflowNodes.RESOURCE]: 'segments',
    [WorkflowNodes.ENTRY_ANOTHER_WF]: 'workflows'
  }?.[type]]?.concat?.({
    [WorkflowNodes.EMAIL]: [],
    [WorkflowNodes.EVENT]: [],
    [WorkflowNodes.RESOURCE]: (rfmAnalyticsOptions || []).map(({ value, ...rest }) => ({ id: value, ...rest })),
    [WorkflowNodes.ENTRY_ANOTHER_WF]: []
  }?.[type])?.find?.(by(Array.isArray(id) ? id?.[index] : id));

  return entity?.label || entity?.name;
};

const resolveLink = (type, data, options) => {
  const id = data?.[{
    [WorkflowNodes.EMAIL]: 'email_id',
    [WorkflowNodes.EVENT]: 'event_ids',
    [WorkflowNodes.RESOURCE]: 'segment_ids',
    [WorkflowNodes.ENTRY_ANOTHER_WF]: 'trigger_workflow_id',
  }?.[type]];

  return {
    [WorkflowNodes.EMAIL]: id => `/templates/emails/code/${id}`,
    [WorkflowNodes.EVENT]: ids => ids?.map?.(id => `/players/fields/event/${id}`),
    [WorkflowNodes.RESOURCE]: ids => ids?.map?.(id => `/resources/segments/${id}`),
    [WorkflowNodes.ENTRY_ANOTHER_WF]: id => `${Paths.EDIT_WORKFLOW}/${options?.workflows?.find(wf => wf.id === id)?.program_id}/editor?w=${id}`,
  }?.[type]?.(id);
};

const sortOption = (a, b) => a.cfg?.order - b.cfg?.order;

const sortSub = (a, b) => a.rank = b.rank;

const EntryPointLink = ({ tree_map, options, linkOnly }) => {
  const { t } = useTranslation();
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const events = clickhouseFields2Events(clickhouseFields.data);
  const segmentOptions = useSelector(optionsSelector);
  segmentOptions.events = events.data;

  const rfmAnalyticsOptions = (options?.rfmResources || []).map(({ id, name, resources }) => ({
    value: id,
    label: name,
    options: (resources || [])
      .filter(({ parent_segment_id }) => !parent_segment_id)
      .flatMap(({ segment_id, name, monetary_aggregate }) => [
        {
          value: segment_id,
          label: t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`),
          cfg: RFMColorsConfig.find(by('name', name)),
        },
        ...(resources || [])
          .filter(by('parent_segment_id', segment_id))
          .map(({ segment_id, name: subName }) => {
            const monetaryValues = (tryParse(monetary_aggregate) || []).find(by('monetary_ranks', +subName[subName.length - 1]));

            return {
              value: segment_id,
              label: `${t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`)}.${(+monetaryValues.min).toFixed(2)} - ${(+monetaryValues.max).toFixed(2)} revenue`,
              cfg: RFMColorsConfig.find(by('name', name)),
              rank: +subName[subName.length - 1],
            };
          })
          .sort(sortSub)
      ])
      .sort(sortOption)
  })).flatMap(extract('options'));

  const link = resolveLink(tree_map?.type, tree_map?.data, options);

  return (
    <Container>
      {!linkOnly && (tree_map?.type === WorkflowNodes.CONTACT_CREATED ? (
        <Type $nb>
          {t(resolveType(tree_map?.type))}
        </Type>
      ) : (
        <Dropdown
          overlay={(
            <Overlay>
              {(Array.isArray(link) ? link : [link]).map((link, index) => (
                <Link key={index} style={{ maxWidth: 200, color: '#31C447' }} to={link}>
                  {resolveName(tree_map?.type, tree_map?.data, options, segmentOptions, rfmAnalyticsOptions, index)}
                </Link>
              ))}
            </Overlay>
          )}
        >
          <Type>
            {t(resolveType(tree_map?.type))}
          </Type>
        </Dropdown>
      ))}
      {linkOnly && (
        <Link type="secondary" to={link}>
          {resolveName(tree_map?.type, tree_map?.data, options, segmentOptions, rfmAnalyticsOptions, 0)}
        </Link>
      )}
    </Container>
  );
};

export default EntryPointLink;
