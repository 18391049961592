import { Button } from '@velitech/ui';
import uniq from 'lodash.uniq';

import { useModalState, useTranslation } from '@hooks';

import { clamp } from '@utils';

import { SegmentCard } from './components';
import { Actions, Container, SegmentsContainer, SegmentsGrid, Rows, Title, AddSegmentContainer } from './styled';

import { AddEntityButton } from '../../../AddEntityButton';
import { AddSegmentModal } from '../../../AddSegmentModal';
import { BackToTriggerTypeButton } from '../BackToTriggerTypeButton';

const SegmentTriggerSettings = ({ value, onChange, onDiscard, onSubmit, loading, worker }) => {
  const { p } = useTranslation('workflow_page');
  const addSegmentModal = useModalState();

  const segments = value?.[0]?.data?.segment_ids || [];
  const segmentsMetadata = value?.[0]?.data?.segments_metadata || {};

  const handleSelectSegment = (selectedSegmentIds) => {
    const isArray = Array.isArray(selectedSegmentIds);
    const selectedSegments = isArray ? selectedSegmentIds : [selectedSegmentIds?.id];
    const newSegmentMeta = selectedSegments.reduce((acc, segmentId) => ({
      ...acc,
      [segmentId]: {} //nothing to add 
    }), {});

    onChange(([s]) => [{
      ...s,
      data: {
        ...s.data,
        segment_ids: uniq([
          ...(s.data.segment_ids || []),
          ...selectedSegments
        ]),
        segments_metadata: {
          ...(s.data.segments_metadata || {}),
          ...newSegmentMeta
        },
      },
    }]);

    addSegmentModal.close();
  };

  const handleSelectRfm = (rfmSegmentSelected) => {
    const newIdsList = rfmSegmentSelected.map(({ id }) => id);

    const newSegmentMeta = rfmSegmentSelected.reduce((acc, { id, parentId, type }) => ({
      ...acc,
      [id]: {
        parentId,
        type,
      }
    }), {});

    onChange(([s]) => [{
      ...s,
      data: {
        ...s.data,
        segment_ids: uniq([
          ...(s.data.segment_ids || []),
          ...newIdsList,
        ]),
        segments_metadata: {
          ...(s.data.segments_metadata || {}),
          ...newSegmentMeta
        },
      },
    }]);

    addSegmentModal.close();
  }

  const handleRemoveSegment = (id) => {
    if(!(worker.status !==0)){
      onChange(([s]) => [{
        ...s,
        data: {
          ...s.data,
          segment_ids: (s.data.segment_ids || []).filter(s => s !== id),
          segments_metadata: Object.fromEntries(Object.entries(s.data.segments_metadata || {}).filter(([s]) => s !== id)),
        },
      }]);
    }
  };

  const rows = Array.from({ length: Math.ceil(segments.length / 2) }).map((_, i, self) =>
    segments.slice(i * 2, clamp(i * 2 + 2 + Number(self.length - 1 === i), 0, segments.length))
  );

  return (
    <Container>
      <Title data-testid="step-title">{p('segment_trigger_settings')}</Title>
      <BackToTriggerTypeButton disabled={worker.status !== 0} testId={'segment-trigger'} onClick={onDiscard} />
      <AddSegmentModal
        opened={addSegmentModal.opened}
        onClose={addSegmentModal.close}
        onSelect={handleSelectSegment}
        onSelectRfm={handleSelectRfm}
        omitSegments={segments}
      />
      {!!segments.length && (
        <SegmentsContainer>
          <Rows>
            {rows.map((row, index) => (
              <SegmentsGrid key={index} $length={row.length}>
                {row.map((id) => (
                  <SegmentCard
                    disabled={worker.status !== 0}
                    key={id}
                    id={id}
                    onRemove={handleRemoveSegment}
                    {...(segmentsMetadata[id] || {})}
                  />
                ))}
              </SegmentsGrid>
            ))}
          </Rows>
        </SegmentsContainer>
      )}
      {(worker.status === 0) && (
        <AddSegmentContainer style={{ marginTop: '56px' }}>
          <AddEntityButton
            data-testid={'segment-trigger-settings-choose-segment-btn'}
            containerStyle={{ width: 530 }}
            title={!segments.length ? p('no_segments_added') : p('add_another_segment')}
            actionTitle={p('choose_segment')}
            onClick={addSegmentModal.open}
          />
        </AddSegmentContainer>
      )}
      <Actions>
        <Button
          data-testid={'segment-trigger-settings-next-step-btn'}
          width={130}
          disabled={!segments.length}
          onClick={onSubmit}
          loading={loading}
        >
          {p('next_step')}
        </Button>
      </Actions>
    </Container>
  );
};

export default SegmentTriggerSettings;
