import { Container } from './styled';

const DeleteButton = ({ onClick, style, disabled }) => {
  return (
    <Container $d={disabled} style={style} onClick={disabled ? void 0 : onClick}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.24982 5.25H6.12482V10.5H5.24982V5.25Z" fill="#C8402F"/>
        <path d="M7.87482 5.25H8.74982V10.5H7.87482V5.25Z" fill="#C8402F"/>
        <path d="M1.74982 2.625V3.5H2.62482V12.25C2.62482 12.4821 2.717 12.7046 2.8811 12.8687C3.04519 13.0328 3.26775 13.125 3.49982 13.125H10.4998C10.7319 13.125 10.9544 13.0328 11.1185 12.8687C11.2826 12.7046 11.3748 12.4821 11.3748 12.25V3.5H12.2498V2.625H1.74982ZM3.49982 12.25V3.5H10.4998V12.25H3.49982Z" fill="#C8402F"/>
        <path d="M5.24982 0.875H8.74982V1.75H5.24982V0.875Z" fill="#C8402F"/>
      </svg>
    </Container>
  );
};

export default DeleteButton;
