import { useRef, useContext, useState, useEffect } from 'react';

import { useTranslation } from '@hooks';

import { WorkflowNodes } from '@constants';

import { IconSvg } from '@components';

import { SuccessIconSvg } from './SuccessIconSvg';

import { GroupSplit } from '../';
import { GroupSplitContext } from '../../module/useGroupSplit';

const items = (confirmationModal, renameModal, translate) => [
  {
    label: <div>{translate('rename_group')}</div>,
    key: '0',
    onClick: renameModal,
  },
  {
    label: <div>{translate('delete_group')}</div>,
    key: '1',
    onClick: confirmationModal,
    type: 'delete_group',
  },
];

export const AsideContent = ({ renderError, setOpenedModal, }) => {
  const { p } = useTranslation('workflow_page');
  const [openedDropdown, setOpenedDropdown] = useState(null);
  const [editTitle, setEditTitle] = useState(false);
  const containerRef = useRef(null);
  const groupSplitCtx = useContext(GroupSplitContext);
  const { manageGroup } = groupSplitCtx;

  const groupsList = groupSplitCtx.groupsList;
  const handleOpenDropdown = (e, id) => {
    e.stopPropagation();
    const isOpen = openedDropdown === id ? null : id;
    setOpenedDropdown(isOpen);
  };

  useEffect(()=>{
    groupSplitCtx.onSelectGroup(groupSplitCtx.groupsList[0].id)
  }, [])

  const handleOnClick = (id) => {
    groupSplitCtx.onSelectGroup(id);
  };

  const confirmationModal = (id) => () => {
    setOpenedDropdown(false);
    const deletedGroupIsExclude = groupsList.some(
      (group) => group.id === id && group.actionType === WorkflowNodes.EXCLUDE
    );
    if (deletedGroupIsExclude) return;

    const isLastIncludedElement = groupsList.filter(
      (group) => group.actionType === WorkflowNodes.INCLUDE
    );
    if (isLastIncludedElement.length === 1) return;

    setOpenedModal({
      id: id,
      action: 'remove',
      open: true,
    });
  };

  const renameGroupModal = (id) => () => {
    setEditTitle(id)
    setOpenedDropdown(false);
  };

  const handleSave = (newTitle) => {
    manageGroup.onChangeGroupName(editTitle, newTitle);
    setEditTitle(false);
  }

  return (
    <GroupSplit.Main
      testId={'group-split-node-settings-add-group-btn'}
      ref={containerRef}
      title={'Split groups'}
      buttonName={'Add group'}
      onClick={manageGroup.onAddGroup}
    >
      <>
        {groupSplitCtx.groupsList.map(({ label, id, type }, idx) => (
          <div key={idx}>
            <GroupSplit.ItemList
              key={idx}
              testId={`group-split-node-settings-group-card-${label}`}
              open={openedDropdown === id}
              // highlight={groupSplitCtx.displayErrors && groupSplitCtx.getGroupError(id)}
              active={groupSplitCtx.selectedGroup?.id === id}
              onOpenDropdown={(e) => handleOpenDropdown(e, id)}
              title={label}
              onClick={() => handleOnClick(id)}
              menu={
                type !== WorkflowNodes.EXCLUDE && (
                  <GroupSplit.DropDownOverlay
                    items={items(confirmationModal(id), renameGroupModal(id), p)}
                  />
                )
              }
              renderContent={
                editTitle === id ? (
                  <GroupSplit.ItemContentWithInput
                    initialValue={label}
                    onSave={handleSave}
                    icon={<SuccessIconSvg />}
                  />
                ) : (
                  <>
                    <GroupSplit.ItemContent
                      label={label}
                      onClick={(e) => handleOpenDropdown(e, id)}
                      icon={
                        type !== WorkflowNodes.EXCLUDE && (
                          <IconSvg name='dots' size={18} />
                        )
                      }
                    />
                  </>
                )
              }
              icon={
                type !== WorkflowNodes.EXCLUDE && (
                  <IconSvg name='dots' size={18} />
                )
              }
            />
            <GroupSplit.ErrorContainer>{renderError(id)}</GroupSplit.ErrorContainer>
          </div>
        ))
        }
      </>
    </GroupSplit.Main>
  );
};
