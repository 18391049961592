import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';

import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from '@hooks';

import {
  OPERATOR_MATCHES_RANGE,
  OPERATOR_ONE_OF, WorkflowNodes,
} from '@constants';
import { EventPresets } from '@constants/eventPresets';

import { by, diffFields, testId } from '@utils';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

import {
  Input,
  Multiselect,
  Textarea,
} from '@components';

import { EventSettings, SelectEventPanel, AddEventButton } from './components';
import {
  Container,
  AddEventButtonContainer,
  EventsListContainer,
  NotSelectedContainer,
  AddEventButtonBackground
} from './styled';

import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';

const createNode = () => {
  const id = uuidv4();
  const originId = uuidv4();

  return {
    type: WorkflowNodes.EVENT,
    id,
    dummy: true,
    data: {
      id,
      name: WorkflowNodes.EVENT,
      label: 'internal',
      originId,
    },
  };
}

const EventNodeSettings = ({
  value: outerValue,
  onChange: outerOnChange,
  label,
  description,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const { t } = useTranslation('workflow_page');
  const multinode = createMultinodeUpdater(outerValue, outerOnChange, errors, onClearError);
  const segmentOptions = useSelector(optionsSelector);
  const value = multinode.values[0];
  const onChange = multinode.changeNode(0);

  const handleEventChange = (changeAt) => ({ value: event_id }) => {
    multinode.clearError(changeAt)('event_id');
    const name = options.events.find(by(event_id))?.name;
    const preset = EventPresets.find(({ events }) => !!~events.indexOf(name));

    const updateData = s => ({
      ...s,
      event_id,
      event_name: name,
      simplified: !!preset,
      hidden: false,
      filter_builder: {
        logicalOperator: 'and',
        filters: (preset?.presetFields || []).map(field => ({
          field,
          operator: field === 'created_at' ? OPERATOR_MATCHES_RANGE : OPERATOR_ONE_OF,
          type: field === 'created_at' ? 'datetime' : 'text',
          value: { type: 'scalar', value: '' },
        }))
      },
    })

    if (changeAt > 0) {
      multinode.addNode(createNode(), updateData);
    }

    multinode.changeNode(changeAt)(updateData);
  };

  const handleDeleteEvent = (deleteAt) => {
    multinode.clearError(deleteAt)('event_id');
    multinode.clearError(deleteAt)('filter_builder');

    if (deleteAt === 0 && multinode.values?.length === 1) {
      multinode.changeNode(0)(s => ({
        ...s,
        event_id: '',
        event_name: '',
        filter_builder: {
          logicalOperator: 'and',
        }
      }));
    } else {
      multinode.deleteNode(deleteAt);
    }
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const fieldsOptions = segmentOptions.fields?.attributes?.map(({ label, field }) => ({ value: field, label }));

  const handleFieldsChange = (message_fields) => {
    onChange(s => ({ ...s, meta: message_fields }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredContainerStyle={{
          background: '#F0F2F6',
        }}
        requiredSettings={(
          <>
            {!!multinode.values[0].event_id && (
              <AddEventButtonContainer>
                <AddEventButton
                  options={options}
                  onSelect={handleEventChange(multinode.values.length)}
                />
                <AddEventButtonBackground />
              </AddEventButtonContainer>
            )}
            {!multinode.values[0]?.event_id ? (
              <NotSelectedContainer>
                <SelectEventPanel
                  options={options}
                  onSelect={handleEventChange(0)}
                />
              </NotSelectedContainer>
            ) : (
              <EventsListContainer>
                {multinode.values.map((settings, index) => (
                  <EventSettings
                    key={index}
                    className="workflow-event-autocomplete"
                    value={settings}
                    editable={editable}
                    options={options}
                    errors={multinode.getError(index)}
                    onClearError={multinode.clearError(index)}
                    onDelete={() => handleDeleteEvent(index)}
                    onChange={multinode.changeNode(index)}
                  />
                ))}
              </EventsListContainer>
            )}
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('event-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              {...testId('event-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
        additionalSettings={(
          <Multiselect
            {...testId('event-node-settings-additional-message-fields')()}
            disabled={!editable}
            title={t('labels.message_fields')}
            style={{ width: '100%' }}
            options={fieldsOptions}
            getParentElement={t => t.parentElement.parentElement.parentElement}
            value={value.meta || []}
            onChange={handleFieldsChange}
          />
        )}
      />
    </Container>
  );
};

export default EventNodeSettings;
