import styled from 'styled-components';

export const Container = styled.div`  
  z-index: 1;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  display: inline-flex;
  padding: 8px 12px;
  gap: 12px;
  border-radius: 7px;
  background: var(--Elements-tooltips-bg, #1A232E);
  cursor: pointer;
`;

export const Label = styled.div`
  color: var(--Colors-Additional-White, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;
