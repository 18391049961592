import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div).attrs({
  initial: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '-30%' : '30%',
    scale: 0.98
  }),
  animate: { opacity: 1, x: '0%', scale: 1 },
  exit: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '30%' : '-30%',
    scale: 0.98
  }),
  transition: { duration: 0.27, easing: 'ease' }
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Actions = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;
