export const EMAIL_TEMPLATE_EXAMPLE_ID_PREFIX = 'example';

export const TemplateTypes = {
  SMS: 'sms',
  EMAIL: 'email',
  WEB_PUSH: 'web_push',
  MOBILE_PUSH: 'mobile_push',
  VIBER: 'viber',
  WEBHOOK: 'webhook',
  MESSAGE_FEED: 'message_feed',
  SLACK: 'slack',
  WEB_POPUP: 'web_popup'
};
