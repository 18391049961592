import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getClickhouseFields } from '@store/actions/creators/fields';
import {
  segmentContactsExampleLoadingSelector, createCustomersCountSelector, aggregatesListSelector
} from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { DateRangeField, Icon, RowCell } from '@velitech/ui';
import { Tooltip } from 'antd';
import capitalize from 'lodash.capitalize';

import { useTranslation, useNavigate, useDateFormat } from '@hooks';
import useSystemAutocomplete from '@hooks/useSystemAutocomplete';

import { resolveAggregateType, Paths, UserPermissions } from '@constants';

import { by, tryMongoDateObject, moment } from '@utils';
import { clickhouseFields2Customer, clickhouseFields2Events } from '@utils/fields';

import { DateCell, Link, TableLayout, WithPermissions } from '@components';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';

import { FieldLabel, FieldLabelRow, FieldTypeLabel } from './styled';

const resolveValue = v => {
  if (typeof v === 'boolean') {
    return String(v);
  }

  return v;
};

const countSelector = createCustomersCountSelector('index');

const SegmentContactTable = ({ contacts, localize, aggregates, disablePaddings, disableFilters, table, type, total, loading: l, pinnedColumns }) => {
  const dispatch = useDispatch();
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const navigate = useNavigate();
  const l2 = useSelector(segmentContactsExampleLoadingSelector);
  const loading = l === undefined ? l2 : l;
  const events = clickhouseFields2Events(clickhouseFields.data);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const count = useSelector(countSelector);
  const { t, p } = useTranslation('segments_page');
  const suggestions = useSystemAutocomplete();

  const isRFM = type === 'rfm';

  useEffect(() => {
    dispatch(getClickhouseFields());
  }, [])

  const redirectToEvent = (eventId, userId) => {
    navigate(`${Paths.CONTACT}/${userId}/?eventId=${eventId}`);
  }
  const format = useDateFormat();

  const dynamicColumns = clickhouseFields2Customer(clickhouseFields.data)?.map?.((field) => ({
    header: {
      label: field.label,
      sort: true,
    },
    name: field.field,
    type: field.type,
    className: `customers-cell-${field.field}`,
    render: (s) => {
      const d = s[field.field];

      if (field.type === 'aggregate' && (d === undefined || d === null || d === 'null' || d === 'NaN')) {
        return (
          <Tooltip title={t('all_contacts_page.aggregate_not-calculated')}>
            null
          </Tooltip>
        );
      }

      if (field.type === 'date' || field.type === 'datetime') {
        return <DateCell customers datetime={field.type === 'datetime'} date={d} />
      }

      if (field.type === 'event') {
        return <div onClick={() => redirectToEvent(field.id, field.user_id)} style={{ cursor: 'pointer' }}><Icon name="Export" color="#31C447" size={18} /></div>;
      }

      if (field.type === 'array') {
        return d?.join?.(' ');
      }

      if (field.type === 'date') {
        return tryMongoDateObject(d, format);
      }

      if (field.type === 'boolean') {
        return +d ? 'true' : 'false';
      }

      return (
        <FlexibleText text={resolveValue(d)} maxWidth={160}>
          {resolveValue(d)}
        </FlexibleText>
      );
    }
  })) || [];

  const aggregateColumns = (aggregates || [])?.map((aggregate) => ({
    header: {
      label: aggregate.label,
      sort: true,
    },
    name: aggregate.name,
    type: 'aggregate',
    className: `customers-cell-${aggregate.label}`,
    render: (s) => {
      const d = s[aggregate.name];

      const type = resolveAggregateType(aggregate, customerFields, events.data);

      if (suggestions[aggregate.attribute] && !!d && d !== 'NaN') {
        const value = suggestions[aggregate.attribute]['event']?.[(aggregate.filters || []).find(by('hide', true))?.value?.value]
          ?.find?.(({ value }) => String(value) === String(d))?.label;

        if (value) {
          return (
            <Tooltip title={d}>
              {value}
            </Tooltip>
          );
        }
      }

      if (type === 'date' || type === 'datetime') {
        return <DateCell customers datetime={type === 'datetime'} date={tryMongoDateObject(d, format)} />
      }

      if (d === undefined || d === null || d === 'null' || d === 'NaN') {
        return (
          <Tooltip title={t('all_contacts_page.aggregate_not-calculated')}>
            null
          </Tooltip>
        );
      }

      return resolveValue(d);
    }
  })) || [];

  const columns = [
    {
      header: {
        label: 'Unique ID',
        sort: true,
      },
      name: 'id',
      alwaysVisible: true,
      fixedIndex: 0,
      className: 'customers-cell-id',
      render: (data) => {
        return (
          <div>
            <FlexibleText text={data.id} name="id" ordering={table.ordering} maxWidth={160}>
              <Link type="secondary" to={`${Paths.CONTACT}/${data.id}`}>{data.id}</Link>
            </FlexibleText>
          </div>
        )
      },
    },
    ...dynamicColumns.filter(({ name }) => name !== 'id'),
    ...aggregateColumns,
  ];

  const modifyColumnWithPinned = (columns, pinnedColumns) => {
    const maxFixedIndex = columns.reduce((max, column) => {
      return column.fixedIndex > max ? column.fixedIndex : max;
    }, -Infinity);

    let pinnedIndex = maxFixedIndex;

    return columns.map((columnItem) => {
      if(!pinnedColumns) {
        return columnItem;
      }

      const shouldPinn = pinnedColumns?.find((pinnedColumn) => pinnedColumn.name === columnItem.name);

      if(shouldPinn) {
        pinnedIndex++;
        return {
          ...columnItem,
          alwaysVisible: true,
          fixedIndex: pinnedIndex,
        }
      }

      return columnItem
    })
  };

  const columnsWithPinned = modifyColumnWithPinned(columns, pinnedColumns)

  const getStartDate = (start_date) => {
    return start_date ? moment(start_date).toDate() : '';
  };

  const getEndDate = (end_date) => {
    return end_date ? moment(end_date).toDate() : '';
  };

  const handleChangeDate = (key, date) => {
    const updateFilter = table.filters.onChange(key, (_, ds) => ds);
    updateFilter(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  const tableLocalizer = (s, ...args) => {
    return p(`table_${s}`, ...args);
  }
  const totalCount = total || count.total;

  return (
    <WithPermissions name={UserPermissions.REVIEW_CONTACTS} hidden>
      <TableLayout
        fullPage
        withTabs
        name="all_contacts"
        table={table}
        loading={loading}
        columns={columnsWithPinned}
        data={contacts || []}
        canSelectAll={false}
        disablePaddings={disablePaddings}
        total={totalCount}
        localizer={localize || tableLocalizer}
        columnSettings={{
          renderLabel: ({ label, name }) => (
            <FieldLabelRow>
              <FieldLabel>{label}</FieldLabel>
              <FieldTypeLabel>{capitalize(columnsWithPinned.find(by('name', name))?.type)}</FieldTypeLabel>
            </FieldLabelRow>
          ),
        }}
        selection={selectedRowKeys}
        onSelectionChange={setSelectedRowKeys}
        renderFilter={isRFM || disableFilters ? null : (
          <RowCell>
            <DateRangeField
              label={'Date'}
              disableErrorSpaceReserve
              dateFormat={'yyyy/MM/dd'}
              from={{
                selected: getStartDate(table?.filters?.filters?.start_date),
                onChange: (date) => handleChangeDate('start_date', date),
              }}
              to={{
                selected: getEndDate(table?.filters?.filters?.end_date),
                onChange: (date) => handleChangeDate('end_date', date),
              }}
            />
          </RowCell>
        )}
      />
    </WithPermissions>
  );
};

export default SegmentContactTable;
